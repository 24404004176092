import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleUp } from "react-icons/fa";

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <div className="scroll-to-top">
                {isVisible && (
                    <button
                        onClick={scrollToTop}
                        className="scroll-to-top-btn"
                        style={{ borderRadius: '50px', backgroundColor: '#20c997' }}
                    >
                        <FaArrowAltCircleUp style={{ height: '16px', width: '16px', paddingBottom: '2px' }} />
                    </button>
                )}
            </div>
        </>
    );
};

export default ScrollToTop;