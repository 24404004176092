import viLocale from "date-fns/locale/vi";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dateFnsFormat from "date-fns/format";
import { format, parse } from "date-fns";
import localforage from "localforage";
import CommonData from "./commonData";
import currency from "currency.js";

const CommonHelper = {
  is_not_empty: (input) => {
    return input != null && input.length > 0;
  },
  is_empty: (input) => {
    if (input == null) return true;
    if (input.length > 0) {
      input = input.trim();
    }
    return input.length === 0;
  },
  random_code: (len) => {
    if (len === null) len = 10;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < len; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  convertToMMDDYYYY: (input) => {
    if (input != null && input !== "") {
      var _y = input.substr(0, 4);
      var _m = input.substr(4, 2);
      var _d = input.substr(6, 2);
      return _m + "/" + _d + "/" + _y;
    }
    return null;
  },
  convertToDDMMYYYY: (input) => {
    if (input != null && input !== "") {
      var _y = input.substr(0, 4);
      var _m = input.substr(4, 2);
      var _d = input.substr(6, 2);
      return _d + "/" + _m + "/" + _y;
    }
    return "";
  },
  convertToDDMMYYYYHHMM: (input) => {
    if (input != null && input !== "") {
      var _y = input.substr(0, 4);
      var _m = input.substr(4, 2);
      var _d = input.substr(6, 2);
      var _h = input.substr(9, 2);
      var _mi = input.substr(11, 2);
      return _d + "/" + _m + "/" + _y + " " + _h + ":" + _mi;
    }
    return "";
  },
  formatToDDMMYYYY: (input) => {
    if (input != null) {
      return dateFnsFormat(input, "dd/MM/yyyy", {
        viLocale,
      });
    }
    return "";
  },
  formatToYYYYMMDD: (input) => {
    if (input != null) {
      return dateFnsFormat(input, "yyyyMMdd", {
        viLocale,
      });
    }
    return "";
  },
  formatToYYYYMMDDHHMMSS: (input) => {
    if (input != null) {
      return dateFnsFormat(input, "yyyyMMdd HHmmss", {
        viLocale,
      });
    }
    return "";
  },
  formatToDDMMYYYY: (input) => {
    if (input != null) {
      return dateFnsFormat(input, "dd/MM/yyyy", {
        viLocale,
      });
    }
    return "";
  },
  parseStringToDate: (input, format) => {
    if (input != null && input !== "") {
      var _output = parse(input, format, new Date());
      //console.log(_output);
      return _output;
    }
    return null;
  },
  get_token: async () => {
    var _token = await localforage.getItem("fb.online.token");
    return _token;
  },
  save_storage_user: (data) => {
    localStorage.setItem("pastoral_user", JSON.stringify(data));
  },
  clear_storage_user: () => {
    localforage.setItem('fb.online.user', null);
  },
  get_user: async () => {
    var _storage = await localforage.getItem("fb.online.user");
    if (_storage != null) {
      //console.log(_storage)
      return _storage;
    }
    return null;
  },
  get_session_id: () => {
    return localStorage.getItem("fb.online.session");
  },
  clear_session: (data) => {
    localStorage.removeItem("fb.online.session");
  },
  sortAscending: (arr) => {
    arr.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return arr;
  },
  sortDescending: (arr) => {
    arr.sort(function (a, b) {
      if (a < b) {
        return 1;
      }
      if (a > b) {
        return -1;
      }
      return 0;
    });
    return arr;
  },
  shuffle: (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  },
  convertArrayToObject: (array) => {
    const initialValue = {};
    array.forEach((item) => {
      var k = Object.keys(item)[0];
      var v = Object.values(item)[0];
      initialValue[k] = v;
    });
    return initialValue;
  },
  convertToInt: (val) => {
    if (CommonHelper.is_not_empty(val)) {
      return parseInt(val)
    }
    return 0;
  },
  set_language: (data) => {
    localStorage.setItem("fb.online.language", JSON.stringify(data));
  },
  get_language: () => {
    var data = localStorage.getItem("fb.online.language");
    if (CommonHelper.is_not_empty(data)) {
      return JSON.parse(data);
    }
    var initLang = CommonData.language[1];
    CommonHelper.set_language(initLang);
    return initLang;
  },
  format_currency_normal: (val) => {
    var s = CommonHelper.format_currency(val);
    return s.replace(/,/g, '.');
  },
  format_currency_normal_v2: (val) => {
    // Convert the value to a string with three decimal places
    var s = (val / 1000).toFixed(3);

    // Replace commas with periods for thousands separator (if necessary)
    return s.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }, 
  format_currency: (val) => {
    //ref https://currency.js.org/
    return currency(val, { symbol: "", precision: 0 }).format();
  },
  format_currency_vnd_style: (val) => {
    var s = CommonHelper.format_currency(val);
    return s.replace(/,/g, '.') + " đồng";
  },
  format_ratio: (val) => {
    return currency(val, { symbol: "", precision: 3 }).format();
  },
  format_regno_moto: (val) => {
    var _output = "";
    if (CommonHelper.is_not_empty(val)) {
      var _input = val.replace(/[^a-zA-Z0-9đĐ]/g, '');
      _output = _input.substr(0, 4);
      if (_input.length === 8) {
        _output += "-" + _input.substr(4);
      } else if (_input.length === 9) {
        _output += "-" + _input.substr(4, 3) + "." + _input.substr(7);
      } else {
        _output = _input;
      }
      _output = _output.toUpperCase();
    }
    return _output;
  },
  format_regno_vehicle: (val) => {
    var _output = "";
    if (CommonHelper.is_not_empty(val)) {
      var _input = val.replace(/[^a-zA-Z0-9]/g, '');
      _output = _input.substr(0, 3);
      if (_input.indexOf("LD") >= 0) {
        _output = _input.substr(0, 4);
        if (_input.length === 8) {
          _output += "-" + _input.substr(4);
        } else {
          _output += "-" + _input.substr(4, 3) + "." + _input.substr(7);
        }
      } else if (_input.indexOf("NN") >= 0) {
        _output = _input.substr(0, 2);
        _output += "-" + _input.substr(2, 2) + "-" + _input.substr(4, 3) + "-" + _input.substr(7);
      } else if (_input.indexOf("NG") >= 0) {
        _output = _input.substr(0, 2);
        _output += "-" + _input.substr(2, 3) + "-" + _input.substr(5, 2) + "-" + _input.substr(7);
      } else {
        if (_input.length === 7) {
          _output += "-" + _input.substr(3);
        } else if (_input.length === 8) {
          _output += "-" + _input.substr(3, 3) + "." + _input.substr(6);
        } else {
          _output = _input;
        }
      }

      _output = _output.toUpperCase();
    }
    return _output;
  },
  kill_unicode: (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
  },
  to_upper: (str) => {
    if (CommonHelper.is_not_empty(str)) {
      return str.toUpperCase();
    }
    return "";
  },
  to_phone_number: (val) => {
    if (CommonHelper.is_not_empty(val)) {
      return val.replace(/[^0-9]/g, "");
    }
    return "";
  },
  format_phone_number: (val) => {
    if (CommonHelper.is_not_empty(val) && val.length > 7) {
      var _output = "";
      _output = val.substr(0, 3) + "-" + val.substr(3, 3) + "-" + val.substr(6);
      return _output;
    }
    return val;
  },
  validate_email_format: (val) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (CommonHelper.is_not_empty(val)) {
      if (val.match(mailformat)) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  },
  set_storage_skip_reg: (val) => {
    localStorage.setItem("fb.skip_register", val);
  },
  get_storage_skip_reg: () => {
    var val = localStorage.getItem("fb.skip_register");
    if (val === null) {
      return false;
    }
    return val === "true";
  },
  get_payment_gw_msg: (code) => {
    var _msg = "";
    switch (code) {
      case "0000":
        _msg = "Thành công";
        break;
      case "0001":
        _msg = "Lỗi không xác định";
        break;
      case "0002":
        _msg = "Tên hàm không hợp lệ";
        break;
      case "0003":
        _msg = "Merchant_site_code không hợp lệ";
        break;
      case "0004":
        _msg = "Version không hợp lệ";
        break;
      case "0005":
        _msg = "Order_code không hợp lệ";
        break;
      case "0006":
        _msg = "Order_description không hợp lệ";
        break;
      case "0007":
        _msg = "Định dạng số tiền không hợp lệ (Nên để định dạng float, Ví dụ: 10000.00)";
        break;
      case "0008":
        _msg = "Loại tiền tệ không hợp lệ";
        break;
      case "0009":
        _msg = "Buyer fullname không hợp lệ";
        break;
      case "0010":
        _msg = "Buyer_email không hợp lệ";
        break;
      case "0011":
        _msg = "Buyer_mobile không hợp lệ";
        break;
      case "0012":
        _msg = "Buyer_address không hợp lệ";
        break;
      case "0013":
        _msg = "Return_url không hợp lệ";
        break;
      case "0014":
        _msg = "Cancel_url không hợp lệ";
        break;
      case "0015":
        _msg = "Notify_url không hợp lệ";
        break;
      case "0016":
        _msg = "Time_limit không hợp lệ";
        break;
      case "0017":
        _msg = "Mã checksum không hợp lệ";
        break;
      case "0018":
        _msg = "Token_code không hợp lệ";
        break;
      case "0101":
        _msg = "Dữ liệu truyền lên đúng, nhưng không thể tạo đơn hàng cho merchant này.";
        break;
    }
    return _msg;
  },
  calculate_age: (dt) => {
    //console.log(dt)
    if (dt !== null) {
      //milliseconds in a year 1000*24*60*60*365.24 = 31556736000; 
      let today = new Date(),
        //birthay has 'Dec 25 1998'
        dob = new Date(dt),
        //difference in milliseconds
        diff = today.getTime() - dob.getTime(),
        //convert milliseconds into years
        years = Math.floor(diff / 31556736000),
        //1 day has 86400000 milliseconds
        days_diff = Math.floor((diff % 31556736000) / 86400000),
        //1 month has 30.4167 days
        months = Math.floor(days_diff / 30.4167),
        days = Math.floor(days_diff % 30.4167);

      //console.log(`${years} years ${months} months ${days} days`);
      return `${years}`;
    }
    return "0";
  },
  calculate_age_pet: (dt) => {
    if (dt !== null) {
      let today = new Date(),
        dob = new Date(dt),
        diff = today.getTime() - dob.getTime(),
        years = Math.floor(diff / 31556736000),
        days_diff = Math.floor((diff % 31556736000) / 86400000),
        months = Math.floor(days_diff / 30.4167),
        days = Math.floor(days_diff % 30.4167);

      if (years < 1) {
        return `${months} tháng`;
      }
      return `${years} tuổi`;
    }
    return "0";
  },
  validate_passport: (val) => {
    //const regex = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/
    const regex = new RegExp("^[A-Z][0-9]{8}$");
    return regex.test(val);
  },
  check_idcard_valid: (value) => {
    if (CommonHelper.is_not_empty(value)) {
      var _val = value.toUpperCase();
      var _flag = true;
      if (_val.length >= 8 && _val.length < 13) {
        if (!CommonHelper.validate_passport(_val)) { //check passport
          if (_val.length == 9) { //check id card (old form)
            const regex = /^\d{9}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else if (_val.length == 12) { // check id card (new form)
            const regex = /^\d{12}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else {
            _flag = false;
          }
        }
      } else {
        _flag = false;
      }
      return _flag;
    }
    return false;
  },
  check_idcard_pet_valid: (value) => {
    if (CommonHelper.is_not_empty(value)) {
      var _val = value.toUpperCase();
      var _flag = true;
      if (_val.length >= 8 && _val.length < 14) {
        if (!CommonHelper.validate_passport(_val)) { //check passport
          if (_val.length == 9) { //check id card (old form)
            const regex = /^\d{9}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else if (_val.length == 10) { //check tax code number
            const regex = /^\d{10}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else if (_val.length == 12) { // check id card (new form)
            const regex = /^\d{12}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else if (_val.length == 13) { // check tax code number
            const regex = /^\d{13}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else {
            _flag = false;
          }
        }
      } else {
        _flag = false;
      }
      return _flag;
    }
    return false;
  },
  check_chipno_valid: (value) => {
    if (CommonHelper.is_not_empty(value)) {
      var _val = value.toUpperCase();
      var _flag = true;
      if (_val.length >= 8 && _val.length < 16) {
        if (!CommonHelper.validate_passport(_val)) { //check passport
          if (_val.length == 9) { //check id card (old form)
            const regex = /^\d{9}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else if (_val.length == 15) { // check id card (new form)
            const regex = /^\d{15}$/
            if (!regex.test(value)) {
              _flag = false;
            }
          } else {
            _flag = false;
          }
        }
      } else {
        _flag = false;
      }
      return _flag;
    }
    return false;
  },
  formatDateExam(input) {
    const date = new Date(input);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  },
  formatOnlyDateExam(input) {
    const date = new Date(input);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
  formatOnlyDateAndYeareExam(input) {
    const date = new Date(input);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}/${year}`;
  },
  convertOnlyDateAndYearDate(input) {
      const formattedDate = CommonHelper.formatOnlyDateAndYeareExam(input); 
      const [month, year] = formattedDate.split("/").map(Number); 
      return new Date(year, month - 1, 1);
  },
  formatTimeExam(input) {
    const date = new Date(input);
    // const day = ("0" + date.getDate()).slice(-2);
    // const month = ("0" + (date.getMonth() + 1)).slice(-2);
    // const year = date.getFullYear();
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  },
};

export default CommonHelper;
