import React from 'react'
import { Modal } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import CommonHelper from '../../../helpers/common'

function ModalReject(props) {
    const { isShow, onClose, ...other } = props;

    return (
        <>
            <Modal show={isShow} backdrop="static" keyboard={false} centered>
                <Modal.Header >
                    <Modal.Title className=' text-center w-100'>
                        <div className='text-red'>
                            <FaIcons.FaExclamationTriangle />
                        </div>
                        <h5 className='fs-4 mt-2'>Thông báo</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h2
                                    className='fs-6'
                                    style={{ lineHeight: "24px" }}
                                >
                                    {(other.msg == undefined || CommonHelper.is_empty(other.msg))
                                        ? <>
                                            Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định của chúng tôi. <br />
                                            Xin vui lòng liên hệ Fubon để được tư vấn và biết thêm chi tiết.
                                        </>
                                        : other.msg}
                                </h2>
                            </div>
                            <hr />
                            <div className='col-sm-12'>
                                <p style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>CÔNG TY TNHH BẢO HIỂM FUBON (VIỆT NAM)</p>
                                <p style={{ padding: '0px', margin: '0px' }}>Điện thoại: (84) 28 39435678</p>
                                <p style={{ padding: '0px', margin: '0px' }}>Địa chỉ: Tầng 15, Tòa nhà Ree Tower, Số 9, Đường Đoàn Văn Bơ, Quận 4, TP.HCM</p>
                            </div>
                            <div className='col-sm-12 mt-5'>
                                <div className='text-center'>
                                    <button
                                        type='button'
                                        className='btn btn-success'
                                        onClick={() => { onClose(false); }}
                                    >
                                        Tôi đã hiểu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalReject;
