import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as FaIcons from 'react-icons/fa'
import { Accordion } from 'react-bootstrap'
import CommonHelper from '../../../helpers/common'
import TermCondition from '../../ctpl/vehicle/component/term-condition'
import InsuredPerson from './insured-persons'
import paiApi from '../../../api/paiApi'
import wording from '../../../assets/doc/pai-wording.vi.01.01.2022.pdf'
import wording_summary from '../../../assets/doc/tai-lieu-tom-tat-san-pham-pai.pdf'

const PaymentSuccessImage = process.env.REACT_APP_RESOURCES_URL + "img/insurance.png";

const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PAI",
    veh_sc_code: "PAI",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    pol_gender: "0",
    veh_customer_dob: "",
    pai_package: {}
};
function PaiConfirmation(props) {
    const fileUpload = useRef(null);
    const { onCheck, onSendConfirmMail, onGetStatus, list_person, user, data: valueProp, ...other } = props;
    const [state, setState] = useState(initial);
    const [{ showLoading, showModal, confirm1, confirm2, objUser }, setShow] = useState(
        { showLoading: false, showModal: false, confirm1: false, confirm2: false, objUser: null }
    );
    useEffect(() => {
        if (valueProp != null) {
            setState(valueProp);
        } else {
            setState(initial);
        }
    }, [valueProp])

    useEffect(() => {
        setShow((prev) => ({
            ...prev, objUser: user ?? { user_type: 1 }
        }))
    }, [user])

    const handle_check = (e) => {
        const { name, checked } = e.target;
        setShow((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    useEffect(() => {
        onCheck(confirm1 && confirm2);
    }, [confirm1, confirm2])

    const upload_confirmation = (e) => {
        const { name, files } = e.target;
        //console.log(files);
        if (files != null && files.length > 0) {
            var _data = {
                order_no: state.veh_order_no,
                file: files[0]
            };
            var response = paiApi.upload_pai_proposal(_data);
            response.then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 202 && res.data.data != null) {
                        setState((prev) => ({
                            ...prev,
                            file_upload: res.data.data.file_upload,
                            allow_issue_policy: res.data.data.allow_issue_policy
                        }))
                    }
                }
            })
        }

    };
    const handle_upload = () => {
        fileUpload.current.click();
    };
    const get_order = () => {
        onGetStatus();
    }
    return (
        <>
            <div className='row'>
                {
                    state.status != 2 && (
                        <>
                            <div className='col-sm-12 mt15px'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5>THÔNG TIN BẢO HIỂM</h5>
                                        <div className='mb10px d-flex'>
                                            <div>
                                                <label>Hiệu lực bảo hiểm:</label>
                                            </div>
                                            <div className='px-2' >
                                                <strong>
                                                    {CommonHelper.convertToDDMMYYYYHHMM(state.veh_fm_dt) + " - " + CommonHelper.convertToDDMMYYYYHHMM(state.veh_to_dt)}
                                                </strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='mb10px d-flex'>
                                            <div >
                                                <label>Số người được bảo hiểm:</label>
                                            </div>
                                            <div className='px-2'>
                                                <strong>{state.veh_pax}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='mb10px d-none'>
                                            <div className='float-start' style={{ width: "40%" }}>
                                                <label>Gói bảo hiểm:</label>
                                            </div>
                                            <div className='float-end text-end' style={{ width: "59%" }}>
                                                <strong>{state.pai_package.pkg_name}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='mb10px d-none'>
                                            <div className='float-start' style={{ width: "40%" }}>
                                                <label>Phí:</label>
                                            </div>
                                            <div className='float-end text-end' style={{ width: "59%" }}>
                                                <strong>{CommonHelper.format_currency_vnd_style(state.pai_package.premium)}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card mt-1'>
                                    <div className='card-body'>
                                        <h5>BÊN MUA BẢO HIỂM</h5>
                                        <div className='mb10px d-flex'>
                                            <div >
                                                <label>Họ và tên:</label>
                                            </div>
                                            <div className='px-2' >
                                                <strong>{CommonHelper.to_upper(state.veh_customer)}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='mb10px d-flex'>
                                            <div >
                                                <label>Số điện thoại:</label>
                                            </div>
                                            <div className='px-2'>
                                                <strong>{state.veh_phone}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='mb10px d-flex'>
                                            <div >
                                                <label>Email:</label>
                                            </div>
                                            <div className='px-2'>
                                                <strong>{state.veh_email}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='mb10px d-flex'>
                                            <div >
                                                <label>Địa chỉ:</label>
                                            </div>
                                            <div className='px-2'>
                                                <strong>{state.veh_address + ", " + state.veh_district_name + ", " + state.veh_province_name}</strong>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </div>
                                {objUser?.user_type > 1 && (
                                    <div className='card mt-1'>
                                        <div className='card-body'>
                                            <h5>XÁC NHẬN</h5>
                                            <div className='row'>
                                                {(state.status == 1 || state.status == 6) && (
                                                    <>
                                                        <div className='col-sm-10'>
                                                            <a className="link-primary cursor-pointer" onClick={() => { onSendConfirmMail() }}>Gửi mail yêu cầu xác  nhận</a>
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <a className="link-primary cursor-pointer" onClick={get_order}><FaIcons.FaUndo style={{ fontSize: "20px" }} /> </a>
                                                        </div>
                                                    </>
                                                )}
                                                {state.status == 7 && (
                                                    <div className='col-sm-12'>
                                                        <span><FaIcons.FaCheck style={{ color: "#07a556" }} /> Khách hàng đã xác nhận</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='card mt-1'>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Danh sách người được bảo hiểm</Accordion.Header>
                                            <Accordion.Body>
                                                <InsuredPerson data={list_person} num_of_pax={valueProp.veh_pax} hasAction={false} onEdit={() => { }} onDel={() => { }} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className='col-sm-12 mt15px d-none'>
                                <div className='alert alert-info'>
                                    <div className='row'>
                                        <div className='col'>
                                            <b>Tổng phí bảo hiểm</b> {/*(<i>Đã bao gồm VAT</i>) */}
                                        </div>
                                        <div className='col text-end'>
                                            <strong>{CommonHelper.format_currency_vnd_style(state.veh_premium)}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                {state.status == 1 && (
                    <>
                        {objUser?.user_type == 1 && state.aml_risk_score < 70 && state.aml_total_score < 70 && (
                            <div className='col-sm-12 mt-2'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-check'>
                                            <input type="checkbox" className='form-check-input' value="" disabled={state.status == 2} checked={confirm1} id="chk-confirm1" name='confirm1' onChange={handle_check} />
                                            {/* <label className="form-check-label" htmlFor="chk-confirm">
                                        Tôi cam kết các thông tin trên đều trung thực và chính xác, đồng thời tôi cam kết đã đọc, hiểu và đồng ý với <a onClick={() => { setShow((prev) => ({ ...prev, showModal: true })) }} style={{ color: "var(--bs-link-color)", cursor: "pointer" }}>Quy định và Quy tắc điều khoản</a> của FUBON cho hợp đồng Bảo hiểm này.
                                    </label> */}
                                            <label className='form-check-label' htmlFor='chk-confirm1'>
                                                Tôi cam kết: Các thông tin được cung cấp ở trên đều trung thực, chính xác; Tôi đồng ý cho Công ty Fubon sử dụng các thông tin này cho mục đích giao kết,  thực hiện Hợp đồng bảo hiểm và chia sẽ cho các Công ty trong cùng Tập đoàn Tài chính Fubon cho mục đích báo cáo, thống kê và các hoạt động nghiệp vụ.
                                            </label>
                                        </div>
                                        <div className='form-check'>
                                            <input type="checkbox" className='form-check-input' value="" disabled={state.status == 2} checked={confirm2} id="chk-confirm2" name='confirm2' onChange={handle_check} />
                                            <label className='form-check-label' htmlFor='chk-confirm2'>
                                                Tôi cam kết: Tôi đã đọc, hiểu rõ <a href={wording_summary} target="_blank">các quyền lợi bảo hiểm, điều khoản loại trừ trách nhiệm bảo hiểm, quyền và nghĩa vụ của Bên mua bảo hiểm</a> và đồng ý với các điều kiện, điều khoản bảo hiểm trong <a href={wording} target="_blank">Quy tắc bảo hiểm tai nạn con người</a>;
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {state.status == 2 && (
                    <div className='col-sm-12 mt-2 text-center'>
                        <hr style={{ marginBottom: '28px' }} />
                        <img src={PaymentSuccessImage} alt="Thanh toán thành công" style={{ margin: '16px', height: '250px', width: '250px' }} />
                        <h4 style={{ marginBottom: '16px' }}>Vui lòng tải giấy chứng nhận bảo hiểm</h4>
                        <a
                            href={state.cert_url} target='_blank'
                            download={"policy-" + state.veh_order_no + ".pdf"}
                            className='btn btn-success'
                            style={{ fontSize: '20px', borderRadius: '0px' }}
                            rel="noreferrer"
                        >
                            Download giấy chứng nhận
                            <FaIcons.FaFileDownload style={{ marginLeft: '4px' }} />
                        </a>
                        <hr style={{ marginTop: '28px' }} />
                        <div className='col-sm-12 text-start'>
                            <p style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>CÔNG TY TNHH BẢO HIỂM FUBON (VIỆT NAM)</p>
                            <p style={{ padding: '0px', margin: '0px' }}>Điện thoại: (84) 28 39435678</p>
                            <p style={{ padding: '0px', margin: '0px' }}>Địa chỉ: Tầng 15, Tòa nhà Ree Tower, Số 9, Đường Đoàn Văn Bơ, Quận 4, TP.HCM</p>
                        </div>
                    </div>
                )}
            </div>
            <TermCondition show={showModal} onClose={(e) => { setShow((prev) => ({ ...prev, showModal: e })) }} />
        </>
    )
}

export default PaiConfirmation

PaiConfirmation.propTypes = {
    //is_checked: PropTypes.bool.isRequired
    onCheck: PropTypes.func.isRequired,
    onSendConfirmMail: PropTypes.func.isRequired,
    /*onCancel: PropTypes.func.isRequired,*/
}