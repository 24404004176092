import React from 'react';
import PtiIntroduce from './pti-introduce';
import PtiGuide from './pti-guide';
import PtiQA from './pti-qa';
import ScrollToTop from './pti-scroll-to-top';
import PtiSvg from './pti-svg';
import PtiBenefits from './pti-benefits';
import "./index.css";

const PtiLandingPage = () => {

    return (
        <>
            <PtiIntroduce />
            <PtiBenefits />
            <PtiGuide />
            <PtiQA />
            <PtiSvg />
            <ScrollToTop />
        </>
    );
};

export default PtiLandingPage;