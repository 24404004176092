/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import CommonHelper from '../../../helpers/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ptiApi from '../../../api/ptiApi';
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";

function Packages(props) {
    const {
        onSelect,
        list,
        pkg_selected,
        location,
        ins_location,
        ins_pax_type,
        ins_pkg_type,
        num_of_days,
        schedule,
        setPtiPackages,
        ...other
    } = props;
    const [listPtiRender, setListPtiRender] = useState([]);
    const [ptiListPackages, setPtiListPackages] = useState([]);
    const [template, setTemplate] = useState(0);
    const [isShowed, setIsShowed] = useState(false);

    useEffect(() => {
        get_pti_packages(ins_location, ins_pax_type, ins_pkg_type, num_of_days);
    }, [ins_location, ins_pax_type, ins_pkg_type, num_of_days])

    const handle_select = (e) => {
        onSelect(e);
    };

    // PTI
    const get_pti_packages = async (ins_location, ins_pax_type, ins_pkg_type, num_of_days) => {
        try {
            let _data = {};
            if (ins_location === "Vietnam") {
                _data = {
                    location: ins_location,
                    from_date: "",
                    num_of_days,
                };
                setTemplate(0);
            } else {
                let locationData = schedule;
    
                _data = {
                    location: ins_location === "Other" ? "[{\"code\":\"NATION_142\",\"name\":\"Vietnam\"}]" : locationData,
                    from_date: "",
                    num_of_days,
                    pkg_type: ins_pkg_type,
                    pax_type: ins_pax_type,
                };
                setTemplate(1);
            }
    
            const res = await ptiApi.get_pti_premium(_data);
            if (res.code === 200) {
                setPtiListPackages(res.data);
                setPtiPackages(res.data);
            }
        } catch (error) {
            console.error("Error fetching pti packages data:", error);
        }
    };
    

    useEffect(() => {
        setListPtiRender(convertPackages(ptiListPackages));
    }, [ptiListPackages]);

    const convertPackages = (packages) => {
        const result = [];
        packages.forEach((pkg) => {
            const existingPackage = result.find((r) => r.pkg_code === pkg.pkg_code);
            if (existingPackage) {
                existingPackage.premium.push(pkg.premium);
                existingPackage.taxRatio.push(pkg.taxRatio);
                existingPackage.taxAmt.push(pkg.taxAmt);
                existingPackage.type.push(pkg.type);
            } else {
                result.push({
                    pkg_code: pkg.pkg_code,
                    pkg_name: pkg.pkg_name,
                    premium: pkg.premium,
                    taxRatio: pkg.taxRatio,
                    taxAmt: pkg.taxAmt,
                    smi1: pkg.smi1,
                    smi2: pkg.smi2,
                    smi3: pkg.smi3,
                    smi4: pkg.smi4,
                });
            }
        });
        return result;
    };

    const renderContent = () => {
        switch (template) {
            case 0:
                return (
                    <>
                        <div className="row justify-content-center mt-4" style={{ alignItems: 'center' }}>
                            <div className="d-block">
                                <h3 className="text-center" style={{ color: '#20c997', marginBottom: '32px' }}>
                                    BẢNG TÓM TẮT QUYỀN LỢI BẢO HIỂM DU LỊCH TRONG NƯỚC
                                </h3>
                                <PtiBenefitsTableVietnam
                                    listPtiRender={listPtiRender}
                                    handle_select={handle_select}
                                    pkg_selected={pkg_selected}
                                    num_of_days={num_of_days}
                                />
                            </div>
                        </div>
                    </>
                );
            case 1:
                return (
                    <>
                        <div className="row justify-content-center mt-4" style={{ alignItems: 'center' }}>
                            <div className="d-block">
                                <h3 className="text-center" style={{ color: '#20c997', marginBottom: '32px' }}>
                                    BẢNG TÓM TẮT QUYỀN LỢI BẢO HIỂM DU LỊCH NƯỚC NGOÀI
                                </h3>
                                <PtiBenefitsTableWorld
                                    listPtiRender={listPtiRender}
                                    handle_select={handle_select}
                                    pkg_selected={pkg_selected}
                                    num_of_days={num_of_days}
                                    isShowed={isShowed}
                                />
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="row justify-content-center mt-4" style={{ alignItems: 'center' }}>
                            <div className="d-block">
                                <h3 className="text-center" style={{ color: '#20c997', marginBottom: '32px' }}>
                                    BẢNG TÓM TẮT QUYỀN LỢI BẢO HIỂM NGƯỜI NƯỚC NGOÀI DU LỊCH TẠI VIỆT NAM
                                </h3>
                                <PtiBenefitsTableWorld
                                    listPtiRender={listPtiRender}
                                    handle_select={handle_select}
                                    pkg_selected={pkg_selected}
                                    num_of_days={num_of_days}
                                    isShowed={isShowed}
                                />
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderContent()}
        </>
    )
}

export default Packages;

export const PtiBenefitsTableVietnam = (props) => {
    const { listPtiRender, handle_select, pkg_selected, num_of_days, ...other } = props;
    return (
        <>
            <div className="row justify-content-center mt-4" style={{ alignItems: 'center' }}>
                <div className='d-block'>
                    <div className='d-flex' style={{ border: '4px dashed rgb(32, 201, 151)', padding: '8px' }}>
                        <div className='w-20'>
                            <div className="plan-description mat-card" style={{ marginTop: '6px', border: 'none', fontSize: '15px' }}>
                                <div className='head text-center d-none'>QUYỀN LỢI</div>
                                <div className='body' style={{ height: '1000px' }}>
                                    <p className='fs-4 fw-bolder' style={{ height: "48px", borderBottom: 'none', textAlign: 'center' }}>Quyền lợi</p>
                                    <p className='p-location' style={{ borderBottom: 'none' }}>
                                        <div className='row'>
                                            <div className='col-md-12' style={{ textAlign: 'justify' }}>
                                                <p style={{ height: '46px', fontWeight: '700', paddingTop: '8px' }}></p>
                                                <p style={{ height: '46px', fontWeight: '700', paddingTop: '8px' }}>Phần 1: TAI NẠN CÁ NHÂN</p>
                                                <p style={{ height: '100px' }}>Tử vong/thương tật toàn bộ vĩnh viễn do tai nạn<br />Dựa vào Bảng tỉ lệ bồi thường đính kèm Quy tắc bảo hiểm</p>
                                                <p style={{ height: '46px', fontWeight: '700', paddingTop: '8px' }}>Phần 2: CHI PHÍ Y TẾ TẠI VIỆT NAM</p>
                                                <p style={{ height: '46px', paddingTop: '6px' }}>1. Chi phí y tế</p>
                                                <p style={{ height: '46px', paddingTop: '6px' }}>2. Chi phí y tế liên quan đến ốm đau thai sản</p>
                                                <p style={{ height: '46px' }}>3. Quyền lợi nằm viện hằng ngày ở Việt Nam (200,000 VND/ 1 ngày)</p>
                                                <p style={{ fontWeight: '700' }}>Phần 3: VẬN CHUYỂN Y TẾ TRONG PHẠM VI LÃNH THỔ VIỆT NAM</p>
                                                <p style={{ height: '55px' }}>1. Vận chuyển cấp cứu/Di chuyển y tế khẩn cấp/Hồi hương</p>
                                                <p style={{ height: '46px', paddingTop: '6px' }}>2. Vận chuyển hài cốt/ mai táng</p>
                                                <p style={{ height: '46px', fontWeight: '700', paddingTop: '8px' }}>Phần 4: QUYỀN LỢI MỞ RỘNG</p>
                                                <p style={{ height: '120px' }}>1. Gia hạn tự động Nếu chuyến đi bị trì hoãn do NDBH bị tai nạn hay bệnh tật, hay do chuyến bay bị trì hoãn, thời hạn bảo hiểm sẽ được tự động kéo dài tương ứng với khoảng thời gian bị trì hoãn đó</p>
                                                <p className='fw-bolder' style={{ height: "46px", color: 'rgb(32, 201, 151)', fontSize: '20px' }}>Phí Bảo hiểm/ {num_of_days} ngày</p>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-80 px-1'>
                            <p className='fs-4 fw-bolder' style={{ height: "40px", textAlign: 'center', marginTop: '12px', marginBottom: '0px' }}>Hạn mức bảo hiểm (Đơn vị tính: VND)</p>
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={5}
                                navigation={true}
                                modules={[Navigation]}
                                initialSlide={5}
                                className="mySwiper"
                            >
                                {listPtiRender.map((item, i) => {
                                    return (
                                        <>
                                            <SwiperSlide key={i}>
                                                <div key={i} className={((pkg_selected !== null && pkg_selected.pkg_code) == item.pkg_code ? "active" : '')} style={{ flex: "1" }}>
                                                    <div className={"plan-pricing mat-card pet-benefit"} style={{ border: 'none' }}>
                                                        <div className='head text-center position-relative' style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white' }}>
                                                            <label className='d-block' style={{ fontSize: '22px', height: '28px' }}>{item.pkg_name}</label>
                                                            {item.pkg_code === "PACK-05" && <span className="bestsell">BEST SELL</span>}
                                                        </div>
                                                        <div className='body'>
                                                            <p className='text-center' style={{ height: '50px', fontSize: '24px' }}>
                                                            </p>
                                                            <p className='text-center' style={{ height: '103px', fontSize: '24px', paddingTop: '30px', paddingBottom: '30px' }}>
                                                                {item.smi1?.detail?.[0]?.si_amt ? CommonHelper.format_currency_normal(item.smi1.detail[0].si_amt) : "-"}
                                                            </p>
                                                            <p className='text-center' style={{ height: '46px', fontSize: '24px' }}>
                                                            </p>
                                                            <p style={{ height: '47px', fontSize: '24px' }}>
                                                                {item.smi2?.detail?.[0]?.si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[0].si_amt) : "-"}
                                                            </p>
                                                            <p style={{ height: '47px', fontSize: '24px' }}>
                                                                {item.smi2?.detail?.[1]?.si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[1].si_amt) : "-"}
                                                            </p>
                                                            <p style={{ height: '46px', fontSize: '24px' }}>
                                                                {item.smi2?.detail?.[2]?.si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[2].si_amt) : "-"}
                                                            </p>
                                                            <p style={{ height: '55px', fontSize: '24px' }}>
                                                            </p>
                                                            <p style={{ height: '55px', fontSize: '24px' }}>
                                                                {item.smi3?.detail?.[0]?.si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[0].si_amt) : "-"}
                                                            </p>
                                                            <p style={{ fontSize: '24px' }}>
                                                                {item.smi3?.detail?.[1]?.si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[1].si_amt) : "-"}
                                                            </p>
                                                            <p className='text-center' style={{ height: '46px' }}>
                                                            </p>
                                                            <p style={{ height: '120px', fontSize: '24px', paddingTop: '30px', paddingBottom: '30px' }}>
                                                                {item.smi4?.detail?.[0]?.si_amt ? item.smi4.detail[0].si_amt : ""}
                                                            </p>
                                                            <p style={{ height: '46px', fontSize: '24px', color: 'rgb(32, 201, 151)', fontWeight: '700' }}>
                                                                {item.premium ? CommonHelper.format_currency_normal_v2(item.premium) : "-"}
                                                            </p>
                                                        </div>
                                                        <div className='foot fw-bolder text-center'>
                                                            <div className="read-more-btn">
                                                                <a
                                                                    className="edu-btn btn-small btn-secondary"
                                                                    style={{ backgroundColor: '#564741' }}
                                                                    href='javascript:;'
                                                                    onClick={() => { handle_select(item) }}
                                                                >
                                                                    Chọn mua
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const PtiBenefitsTableWorld = (props) => {
    const { listPtiRender, handle_select, pkg_selected, num_of_days, ...other } = props;
    const [showAdditionalSections, setShowAdditionalSections] = useState(false); // State để quản lý việc hiển thị phần 3 và 4

    const toggleSections = () => {
        setShowAdditionalSections(!showAdditionalSections); // Chuyển đổi trạng thái hiển thị
    };

    return (
        <>
            <div className="row justify-content-center mt-4" style={{ alignItems: 'center' }}>
                <div className='d-block'>
                    <div className='d-flex' style={{ border: '4px dashed rgb(32, 201, 151)', padding: '8px' }}>
                        <div className='w-30'>
                            <div className="plan-description mat-card" style={{ marginTop: '6px', border: 'none', fontSize: '15px' }}>
                                <div className='head text-center d-none'>QUYỀN LỢI</div>
                                <div className={`body ${showAdditionalSections ? "res-packages" : "res-packages-less"}`}>
                                    <p className='fs-4 fw-bolder' style={{ height: "48px", borderBottom: 'none', textAlign: 'center' }}>Quyền lợi</p>
                                    <p className='p-location' style={{ borderBottom: 'none' }}>
                                        <div className='row'>
                                            <div className='col-md-12' style={{ textAlign: 'justify' }}>
                                                <p style={{height: '32px'}}></p>
                                                <p><b>Phần 1: Tai nạn cá nhân (*)</b></p>
                                                <p>1. Tử vong/thương tật vĩnh viễn do tai nạn</p>
                                                <p>2. Trợ cấp giáo dục trẻ em</p>
                                                <p><b>Phần 2: Chi phí y tế</b></p>
                                                <p>3. Chi phí y tế ở nước ngoài</p>
                                                <p>4. Chi phí y tế phát sinh khi trở về Việt Nam</p>
                                                <p>5. Thăm bệnh ở nước ngoài</p>
                                                <p>6. Thăm viếng để thu xếp tang lễ ở nước ngoài</p>
                                                <p>7. Đưa trẻ em về quê hương hoặc quốc gia thường trú</p>
                                                <p>8. Trợ cấp nằm viện ở nước ngoài (1,000,000 VND / ngày)</p>
                                                <p>9. Trợ cấp nằm viện ở Việt Nam (500,000 VND / ngày)</p>
                                                <p>10. Chi phí y tế liên quan đến ốm đau thai sản</p>
                                                <p>11. Vận chuyển cấp cứu</p>
                                                <p>12. Đưa thi hài về quê hương hoặc chôn cất</p>
                                                <p>13. Trợ giúp du lịch / y tế 24h</p>
                                                {
                                                    showAdditionalSections ? (<>
                                                        <p><b>Phần 3: Những sự cố ngoài ý muốn</b></p>
                                                        <p>14. Huỷ chuyến đi</p>
                                                        <p>15. Hoãn chuyến đi</p>
                                                        <p>16. Gián đoạn chuyến đi</p>
                                                        <p>17. Lỡ nối chuyến</p>
                                                        <p>18. Chuyến đi bị trì hoãn</p>
                                                        <p>19. Hành lý đến chậm</p>
                                                        <p><b>Phần 4: Mất mát</b></p>
                                                        <p>20. Mất hoặc thiệt hại hành lý và tài sản cá nhân</p>
                                                        <p>21. Mua hàng khẩn cấp</p>
                                                        <p>22. Mất hộ chiếu</p>
                                                        <p>23. Bảo hiểm cho mức miễn thường của phương tiện đi thuê</p>
                                                        <p>24. Đại lý du lịch bị chấm dứt hoạt động</p>
                                                        <p>25. Bảo vệ nhà</p>
                                                        <p>26. Không tặc</p>
                                                        <p>27. Bắt cóc & con tin</p>
                                                        <p>28. Các dịch vụ khách sạn bị gián đoạn hay hủy bỏ</p>
                                                        <p>29. Gia hạn tự động</p>
                                                        <p>(*) Tổng số tiền bồi thường cho tất cả các quyền lợi tối đa không vượt quá số tiền bảo hiểm của quyền lợi Tử vong / thương tật vĩnh viễn do tai nạn hoặc Chi phí y tế ở nước ngoài, tùy số tiền nào lớn hơn</p>
                                                        <p className='fw-bolder' style={{ height: "46px", color: 'rgb(32, 201, 151)', fontSize: '20px' }}>Phí Bảo hiểm/ {num_of_days} ngày</p>
                                                        <div className='text-center'>
                                                            <button className='text-center btn btn-outline-success mt-1' onClick={toggleSections}>
                                                                {showAdditionalSections ? "Thu gọn" : "Xem thêm"}
                                                                {showAdditionalSections ? <FaArrowUp style={{marginLeft: '8px'}}/> : <FaArrowDown style={{marginLeft: '8px'}}/>}
                                                            </button>
                                                        </div>
                                                    </>) : (<>
                                                        <p className='fw-bolder' style={{ height: "46px", color: 'rgb(32, 201, 151)', fontSize: '20px' }}>Phí Bảo hiểm/ {num_of_days} ngày</p>
                                                        <div className='text-center'>
                                                            <button className='text-center btn btn-outline-success mt-1' onClick={toggleSections}>
                                                                {showAdditionalSections ? "Thu gọn" : "Xem thêm"}
                                                                {showAdditionalSections ? <FaArrowUp style={{marginLeft: '8px'}}/> : <FaArrowDown style={{marginLeft: '8px'}}/>}
                                                            </button>
                                                        </div>
                                                    </>)
                                                }
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-70 px-1'>
                            <p className='fs-4 fw-bolder' style={{ height: "40px", textAlign: 'center', marginTop: '12px', marginBottom: '0px' }}>Hạn mức bảo hiểm (Đơn vị tính: VND)</p>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {
                                    listPtiRender.map((item, i) => {
                                        return (<>
                                            <div key={i} className={((pkg_selected !== null && pkg_selected.pkg_code) == item.pkg_code ? "active" : '')} style={{ flex: "1 1 calc(25% - 10px)", boxSizing: 'border-box' }}>
                                                <div className={"plan-pricing mat-card pet-benefit"} style={{ border: 'none' }}>
                                                    <div className='head text-center position-relative' style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white' }}>
                                                        <label className='d-block' style={{ fontSize: '18px', lineHeight: '16px' }}>{item.pkg_name}</label>
                                                        {item.pkg_code === "PACK-DIAMOND" && <span className="bestsell">BEST SELL</span>}
                                                    </div>
                                                    <div className='body' style={{ fontSize: '22px' }}>
                                                        <p style={{ height: '36px' }}>
                                                        </p>
                                                        <p style={{ lineHeight: '26px' }}>
                                                            {item?.smi1?.detail?.[0]?.si_amt ? CommonHelper.format_currency_normal(item.smi1.detail[0].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi1?.coverage?.si_amt ? CommonHelper.format_currency_normal(item.smi1.coverage.si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '24px' }}>
                                                            {"-"}
                                                        </p>
                                                        <p style={{ lineHeight: '20px' }}>
                                                            {item?.smi2?.detail?.[0].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[0].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '24px' }}>
                                                            {item?.smi2?.detail?.[1].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[1].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi2?.detail?.[2].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[2].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '24px' }}>
                                                            {item?.smi2?.detail?.[3].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[3].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi2?.detail?.[4].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[4].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi2?.detail?.[5].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[5].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi2?.detail?.[6].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[6].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '24px' }}>
                                                            {item?.smi2?.detail?.[7].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[7].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi2?.detail?.[8].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[8].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '22px' }}>
                                                            {item?.smi2?.detail?.[9].si_amt ? CommonHelper.format_currency_normal(item.smi2.detail[9].si_amt) : "-"}
                                                        </p>
                                                        <p style={{ lineHeight: '24px' }}>
                                                            {item?.smi2?.detail?.[10].hot_line ? item.smi2.detail[10].hot_line : "-"}
                                                        </p>
                                                        {
                                                            showAdditionalSections ? (<>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {"-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {item?.smi3?.detail?.[0].si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[0].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {item?.smi3?.detail?.[1].si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[1].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi3?.detail?.[2].si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[2].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi3?.detail?.[3].si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[3].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi3?.detail?.[4].si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[4].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {item?.smi3?.detail?.[5].si_amt ? CommonHelper.format_currency_normal(item.smi3.detail[5].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {"-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {item?.smi4?.detail?.[0].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[0].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[1].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[1].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[2].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[2].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[3].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[3].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[4].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[4].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[5].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[5].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[6].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[6].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {item?.smi4?.detail?.[7].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[7].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '22px' }}>
                                                                    {item?.smi4?.detail?.[8].si_amt ? CommonHelper.format_currency_normal(item.smi4.detail[8].si_amt) : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '23px' }}>
                                                                    {item?.smi4?.detail?.[9].si_amt ? (item.smi4.detail[9].si_amt) + " ngày" : "-"}
                                                                </p>
                                                                <p style={{ lineHeight: '43px' }}>
                                                                    {"-"}
                                                                </p>
                                                                <p style={{ height: '46px', fontSize: '24px', color: 'rgb(32, 201, 151)', fontWeight: '700' }}>
                                                                    {item.premium ? CommonHelper.format_currency_normal_v2(item.premium) : "-"}
                                                                </p>
                                                            </>) : (<>
                                                                <p style={{ height: '46px', fontSize: '24px', color: 'rgb(32, 201, 151)', fontWeight: '700' }}>
                                                                    {item.premium ? CommonHelper.format_currency_normal_v2(item.premium) : "-"}
                                                                </p>
                                                            </>)
                                                        }
                                                    </div>
                                                    <div className="read-more-btn text-center sticky-buy-button">
                                                        <a
                                                            className="edu-btn btn-small btn-secondary"
                                                            style={{ backgroundColor: '#564741' }}
                                                            href='javascript:;'
                                                            onClick={() => { handle_select(item) }}
                                                        >
                                                            Chọn mua
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};