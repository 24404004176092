import React, { useState, useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'
import CommonHelper from '../../../helpers/common';

const initial = [
    {
        "idx": 1,
        "id": 1,
        "fullName": "Trần Văn An",
        "gender": 0,
        "dob": "20000215",
        "age": "24"
    }
]

function InsuredPerson(props) {
    const [state, setState] = useState([initial]);
    const { data: valueProp, onEdit, onDel, hasAction, disabled, setDataPremium, ...other } = props;
    const [{
        ins_origin_premium,
        ins_premium,
        ins_tax_amt
    }, setPremium] = useState({
        ins_origin_premium: 0,
        ins_premium: 0,
        ins_tax_amt: 0
    })
    const handle_edit = (item) => {
        onEdit(item);
    }
    const handle_delete = (item) => {
        onDel(item);
    }

    useEffect(() => {
        setState(valueProp);

        // Get premium
        if ((valueProp.length === 0)) {
            setPremium((prev) => ({
                ...prev,
                ins_origin_premium: 0,
                ins_premium: 0,
                ins_tax_amt: 0
            }));
            setDataPremium((prev) => ({
                ...prev,
                veh_origin_premium: 0,
                veh_premium: 0,
                veh_tax_amt: 0
            }));
        } else {
            let total_premium = 0;
            let total_tax_amt = 0;
            for (let i = 0; i < valueProp.length; i++) {
                const item = valueProp[i];
                if (other.ins_pax_type === "1" || other.ins_pax_type === "3") {
                    total_premium += item.objPackage.premium;
                    total_tax_amt += item.objPackage.taxAmt;
                } else if (other.ins_pax_type === "2") {
                    total_premium = item.objPackage.premium;
                    total_tax_amt = item.objPackage.taxAmt;
                    break;
                }
            }
            if (other.ins_pax_type === "1" || other.ins_pax_type === "3") {
                setPremium((prev) => ({
                    ...prev,
                    ins_tax_amt: total_tax_amt,
                    ins_premium: total_premium,
                    ins_origin_premium: total_tax_amt + total_premium,
                }));
                setDataPremium((prev) => ({
                    ...prev,
                    veh_tax_amt: total_tax_amt,
                    veh_premium: total_premium,
                    veh_origin_premium: total_tax_amt + total_premium,
                }));
            } else if (other.ins_pax_type === "2") {
                setPremium((prev) => ({
                    ...prev,
                    ins_tax_amt: total_tax_amt,
                    ins_premium: total_premium,
                    ins_origin_premium: total_tax_amt + total_premium,
                }));
                setDataPremium((prev) => ({
                    ...prev,
                    veh_tax_amt: total_tax_amt,
                    veh_premium: total_premium,
                    veh_origin_premium: total_tax_amt + total_premium,
                }));
            }
        }
    }, [valueProp]);
    return (
        <div>
            {state !== null && state.length > 0 && (
                <>
                    {hasAction && (
                        <div className='row mt-3'>
                            <div className='col-sm-12'>
                                Số người tham gia bảo hiểm: <span className='fw-bolder' style={{ marginRight: '130px' }}>{state.length}</span>
                                Số ngày được bảo hiểm: <span className='fw-bolder'>{other.num_of_days}</span>
                            </div>
                        </div>
                    )}
                    <div className='row mt-3'>
                        <table className="table">
                            <thead>
                                <tr style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white' }}>
                                    <th scope="col" style={{ width: "55px" }}>STT</th>
                                    <th scope="col">Họ Tên</th>
                                    <th scope="col" style={{ width: "15%" }}>Giới tính</th>
                                    <th scope="col" style={{ width: "15%" }}>Ngày sinh</th>
                                    <th scope="col" style={{ width: "10%" }}>Tuổi</th>
                                    <th scope="col" style={{ width: "20%" }}>Gói bảo hiểm</th>
                                    {hasAction && (<th scope="col" style={{ width: "65px" }}></th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {state.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td scope="row">{i + 1}</td>
                                            <td>{item.fullName}</td>
                                            <td>{item.gender === 1 ? "Nam" : (item.gender === 2 ? "Nữ" : "Khác")}</td>
                                            <td>{CommonHelper.convertToDDMMYYYY(item.dob)}</td>
                                            <td>{CommonHelper.calculate_age(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd"))}</td>
                                            <td>
                                                {item.objPackage != null && (
                                                    <>
                                                        <div>{item.objPackage.pkg_name}</div>
                                                        <div className='text-end d-none'><b>{item.objPackage.premium > 0 ? CommonHelper.format_currency_vnd_style(item.objPackage.premium) : ""}</b></div>
                                                    </>
                                                )}
                                            </td>
                                            {hasAction && (
                                                <td>
                                                    <a
                                                        href='javascript:;'
                                                        // onClick={() => { handle_edit(item) }}
                                                        onClick={(e) => {
                                                            if (!disabled) handle_edit(item);
                                                            e.preventDefault(); // Prevent navigation if disabled
                                                        }}
                                                        style={{
                                                            color: disabled ? '#ccc' : '#0000ff',
                                                            cursor: disabled ? 'not-allowed' : 'pointer',
                                                            pointerEvents: disabled ? 'none' : 'auto'
                                                        }}
                                                    ><FaIcons.FaPencilAlt /></a>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <a
                                                        href='javascript:;'
                                                        // onClick={() => { handle_delete(item) }}
                                                        onClick={(e) => {
                                                            if (!disabled) handle_delete(item);
                                                            e.preventDefault(); // Prevent navigation if disabled
                                                        }}
                                                        style={{
                                                            color: disabled ? '#ccc' : '#0000ff',
                                                            cursor: disabled ? 'not-allowed' : 'pointer',
                                                            pointerEvents: disabled ? 'none' : 'auto'
                                                        }}
                                                    ><FaIcons.FaTimes
                                                            // style={{ color: "#ff0000" }} 
                                                            style={{ color: disabled ? '#ccc' : '#ff0000' }}
                                                        /></a>
                                                </td>
                                            )}

                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td></td>
                                    <td colSpan={2}>
                                        <b>Tổng phí bảo hiểm</b>
                                    </td>
                                    <td colSpan={3}>
                                        <div className='text-end'>
                                            {/* <b style={{ fontSize: '24px', color: 'red' }}>{CommonHelper.format_currency_vnd_style((veh_origin_premium))}</b> */}
                                            <b style={{ fontSize: '24px', color: 'red' }}>{CommonHelper.format_currency_vnd_style((ins_origin_premium))}</b>
                                        </div>
                                    </td>
                                    {hasAction && (
                                        <td></td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}

        </div>
    )
}

export default InsuredPerson;