//#region INIT

import CommonHelper from "../../../helpers/common";
import { addDays } from 'date-fns';

export const initial_person_info = {
    "idx": 0,
    "id": 0,
    "orderNo": "",
    "fullName": "",
    "idCard": "",
    "dob": "",
    "gender": 1,
    "phone": "",
    "email": "",
    "occupation": "",
    "nationality": "",
    "relationship": "0",
    "address": "",
    "isForeigner": false,
    "createdBy": "",
    "createdDate": "",
    "modifiedBy": "",
    "modifiedDate": "",
    "insPackage": "",
    "objPackage": {},
    "premium": 0,
    "action": "new", //[new, edit]
    "survey_questions": [],
    "package_code": "",
    "package_detail": ""
};

export const steps = [{ code: 1 }, { code: 2 }, { code: 3 }, { code: 4 }, { code: 5 }, { code: 6 }];

export const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

export const initial = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PTI",
    veh_sc_code: "PTI",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(addDays(new Date(), 1).setHours(0, 0, 0, 0)),
    veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(addDays(new Date(), 2).setHours(0, 0, 0, 0)),
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "0",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    "veh_province_code": "",
    "veh_province_name": "",
    "veh_district_code": "",
    "veh_district_name": "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_is_agent: false,
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    veh_gender: 1,
    veh_customer_dob: "",
    veh_time_eff: "000000",
    veh_rep_company: "",
    veh_tax_code: "",
    veh_occupation_code: "",
    veh_occupation_name: "",
    veh_national_code: "",
    veh_national_name: "",
    veh_job_type: "",
    veh_job_desc: "",
    veh_ex_occ_confirm: false,
    veh_ex_pet_confirm: false,
    is_confirm: false,
    aml_total_score: 0,
    aml_risk_score: 0,
    aml_checked: false,
    status: 1,
    user_type: 1,
    location: {}, // save item of pti_options list
    pti_package: {},
    res_post_order: [], // save response of post pet order
    cert_url: "",
    ins_promoter_code: "",
    veh_schedule_from: "",
    veh_schedule_to: "",
    veh_schedule: "",
    veh_schedule_string: "",
    veh_num_of_days: 1,
    veh_is_paid: false,
    ins_pkg_type: "TRIP",
    ins_pax_type: "1",
    ins_location: "",
};

export const locations = [
    {
        // "pti_code": "PTIPKG03",
        "location": "Vietnam",
        "bg_color": "#1e552a",
        "btn_color": "#1e552a",
        "bg_image": "Image3",
        "name": "Du lịch trong nước",
        "is_blocked": false,
        "code": "Vietnam",
    },
    {
        // "pti_code": "PTIPKG01",
        "location": "",
        "bg_color": "#2e7c3d",
        "btn_color": "#2e7c3d",
        "bg_image": "Image2",
        "name": "Du lịch nước ngoài",
        "is_blocked": false,
        "code": "World",
    },
    {
        // "pti_code": "PTIPKG02",
        "location": "",
        "bg_color": "#2da842",
        "btn_color": "#2da842",
        "bg_image": "Image1",
        "name": "Người nước ngoài du lịch tại Việt Nam",
        "is_blocked": false,
        "code": "Other",
    },
]

export const faqData = [
    {
        question: "Quyền lợi bảo hiểm?",
        answer: "FUBON chi trả các quyền lợi bảo hiểm sau nếu chúng được ghi trong Hợp đồng bảo hiểm:\na. Quyền lợi 1: Tử vong hoặc thương tật do tai nạn\nb. Quyền lợi 2: Chi phí y tế phát sinh khi Người được bảo hiểm bị tai nạn hoặc bệnh tật khi đi du lịch\nc. Các quyền lợi mở rộng (nếu có)\nChi tiết các quyền lợi được quy định cụ thể tại Hợp đồng bảo hiểm & Quy tắc bảo hiểm đính kèm."
    },
    {
        question: "Thời hạn hợp đồng, thời hạn thanh toán phí bảo hiểm?",
        answer: "Thời hạn hợp đồng: quy định chi tiết tại Hợp đồng bảo hiểm, tối đa mỗi chuyến du lịch là 180 ngày liên tiếp.\nThời hạn thanh toán phí bảo hiểm: theo thỏa thuận tại Hợp đồng bảo hiểm."
    },
    {
        question: "Nghĩa vụ kê khai thông tin trung thực và hậu quả pháp lý trong trường hợp kê khai không trung thực?",
        answer: "Bên mua bảo hiểm có nghĩa vụ kê khai đầy đủ, trung thực mọi thông tin liên quan đến Hợp đồng bảo hiểm theo yêu cầu của FUBON. Trường hợp Bên mua bảo hiểm cố ý vi phạm nghĩa vụ trên nhằm giao kết Hợp đồng bảo hiểm để được trả tiền bảo hiểm thì FUBON có quyền hủy bỏ Hợp đồng bảo hiểm, không trả tiền bảo hiểm, chỉ hoàn lại phí bảo hiểm sau khi trừ đi các chi phí hợp lý (bao gồm chi phí khai thác và quản lý). Bên mua bảo hiểm phải bồi thường thiệt hại phát sinh cho FUBON (nếu có)."
    },
    {
        question: "Điều khoản loại trừ trách nhiệm bảo hiểm?",
        answer: "FUBON không bồi thường cho những tổn thất phát sinh từ:\n1. Những tình trạng tồn tại trước hoặc thương tật, bệnh tật hoặc khuyết tật bẩm sinh không phân biệt xảy ra trước hoặc trong thời hạn bảo hiểm như: Bệnh trĩ, thoát vị đĩa đệm, phẫu thuật amidan, bệnh lý bất thường của vách ngăn mũi, cường giáp, đục thủy tinh thể, viêm xoang có phẫu thuật, lao, viêm hậu môn, viêm túi mật, sỏi niệu các loại, bệnh của bàng quang, cao huyết áp, rối loạn mỡ máu, rối loạn đường huyết, tim mạch, tai biến…\n2. Chiến tranh (tuyên bố hoặc không tuyên bố), xâm lược, hành vi của kẻ thù nước ngoài, khủng bố, chiến sự (dù chiến tranh được tuyên bố hay không), nội chiến, náo loạn dân sự, bạo loạn, nổi loạn…\n3. Tự sát, cố gắng tự sát hoặc cố ý tự gây thương tích, rối loạn tâm thần, trầm cảm, sẩy thai, sinh đẻ, bệnh hoa liễu, sử dụng rượu, ma túy hoặc các chất kích thích khác không theo chỉ định từ bác sĩ chuyên khoa, chữa trị răng miệng trừ trường hợp tai nạn gây ảnh hưởng đến khả năng phát âm và răng tự nhiên.\n4. Tai nạn khi tham gia vào các hoạt động thể thao (bao gồm bất kì loại hình luyện tập hoặc huấn luyện), bất kì loại hình thể thao chuyên nghiệp hoặc nghiệp dư nào, các cuộc đua (ngoại trừ đi bộ), đua xe các loại, các cuộc đua trên phương tiện có động cơ, leo núi, khám phá hang động, săn bắt, nhảy dù.\n5. Và các điểm loại trừ khác được quy định chi tiết tại Quy tắc bảo hiểm đính kèm Hợp đồng bảo hiểm."
    },
    {
        question: "Chấm dứt Hợp đồng bảo hiểm trước hạn?",
        answer: "Cả FUBON và Người được bảo hiểm đều không có quyền được hủy bỏ Hợp đồng bảo hiểm ngoại trừ trường hợp không có thông báo thu phí từ FUBON hoặc phí bảo hiểm không được thanh toán đầy đủ và đúng hạn hoặc các trường hợp khác theo quy định của pháp luật."
    },
    {
        question: "Lưu ý",
        answer: "Đây chỉ là tài liệu tóm tắt quy tắc, điều kiện, điều khoản bảo hiểm. Để có đầy đủ thông tin về điều khoản, điều kiện, quyền lợi bảo hiểm, loại trừ bảo hiểm… vui lòng xem chi tiết tại Quy tắc bảo hiểm đính kèm Hợp đồng bảo hiểm và những quy định cụ thể trên Hợp đồng bảo hiểm."
    },
];

export const initial_confirmation = {
    veh_pol_no: "",
    veh_certificate_no: "",
    veh_org_sc_code: "PTI",
    veh_sc_code: "PTI",
    veh_business: "N",
    veh_type: "",
    veh_type_name: "",
    veh_seat: "0",
    veh_weight: "0",
    veh_reg_no: "",
    veh_chassis_no: "",
    veh_engine_no: "",
    veh_month: "1",
    veh_fm_dt: null,
    veh_to_dt: null,
    veh_issued_date: null,
    veh_handler_code: "",
    veh_handler_name: "",
    veh_handler_name_vi: "",
    veh_is_pax: false,
    veh_pax: "",
    veh_customer_id: 0,
    veh_customer: "",
    veh_customer_idcard: "",
    veh_phone: "",
    veh_email: "",
    veh_address: "",
    veh_issue_invoice: false,
    inv_tax: "",
    inv_company_name: "",
    inv_address: "",
    veh_origin_premium: 0,
    veh_tax_ratio: 0,
    veh_tax_amt: 0,
    veh_pax_premium: 0,
    veh_premium: 0,
    veh_si_amt1: 0,
    veh_si_amt2: 0,
    veh_si_amt3: 0,
    veh_si_amt4: 0,
    veh_is_truck: false,
    veh_url: window.location.href,
    veh_order_no: "",
    veh_get_cert_via: 1,
    veh_sob_code: "",
    veh_sob_name: "",
    veh_agency_code: "",
    veh_agency_name: "",
    veh_broker_code: "",
    veh_broker_name: "",
    pol_voucher_code: "",
    pol_gender: "0",
    veh_customer_dob: "",
    pet_package: {}
};

export const initial_survey_questions = [
    {
        code: "PTI_QUES_1",
        question: "Người được bảo hiểm có tham gia Bảo hiểm du lịch nào khác không?",
        answer_yn: "",
        note: "",
        list: [],
        placeholder: "ví dụ: Đã tham gia Bảo hiểm du lịch quốc tế Liberty, có hiệu lực từ ngày 11/11/2024 đến ngày 17/11/2024."
    },
    {
        code: "PTI_QUES_2",
        question: "Người được bảo hiểm có tham gia Bảo hiểm tai nạn hay Bảo hiểm sức khỏe nào khác không?",
        answer_yn: "",
        note: "",
        list: [],
        placeholder: "ví dụ: Đã tham gia bảo hiểm tai nạn FUBON, mua cá nhân, có hiệu lực từ ngày 01/10/2024 đến ngày 01/10/2025."
    },
    {
        code: "PTI_QUES_3",
        question: "Mục đích",
        answer_yn: "",
        note: "",
        list: [],
        purpose_of_the_trip: [{ "code": "1", "name": "Du lịch", "name_en": "Travelling" }, { "code": "2", "name": "Công tác", "name_en": "Business Trip" }, { "code": "3", "name": "Du học", "name_en": "Study Abroad" }, { "code": "4", "name": "Thăm người thân", "name_en": "Visting Relatives" }, { "code": "5", "name": "Khác", "name_en": "Other" }]
    }
];

export const answer_survey_questions_default = [
    {
        code: "PTI_QUES_1",
        question: "Người được bảo hiểm có tham gia Bảo hiểm du lịch nào khác không?",
        answer_yn: "N",
        note: "",
        list: [],
        placeholder: "ví dụ: Đã tham gia Bảo hiểm du lịch quốc tế Liberty, có hiệu lực từ ngày 11/11/2024 đến ngày 17/11/2024."
    },
    {
        code: "PTI_QUES_2",
        question: "Người được bảo hiểm có tham gia Bảo hiểm tai nạn hay Bảo hiểm sức khỏe nào khác không?",
        answer_yn: "N",
        note: "",
        list: [],
        placeholder: "ví dụ: Đã tham gia bảo hiểm tai nạn FUBON, mua cá nhân, có hiệu lực từ ngày 01/10/2024 đến ngày 01/10/2025."
    },
    {
        code: "PTI_QUES_3",
        question: "Mục đích",
        answer_yn: "",
        note: "",
        list: [{ "code": "1", "name": "Du lịch", "name_en": "Travelling" }],
        purpose_of_the_trip: [{ "code": "1", "name": "Du lịch", "name_en": "Travelling" }, { "code": "2", "name": "Công tác", "name_en": "Business Trip" }, { "code": "3", "name": "Du học", "name_en": "Study Abroad" }, { "code": "4", "name": "Thăm người thân", "name_en": "Visting Relatives" }, { "code": "5", "name": "Khác", "name_en": "Other" }]
    }
];

export const error_msg_list = [
    { msg: 1 },
    { msg: 2 },
    { msg: 3 },
    { msg: 4 },
    { msg: 5 }
];
//#endregion

//#region FUNCTIONS
export const insuredObjectMapper = (data, num_of_days, location) => {
    /*const transformedSurveyQuestions = data.survey_questions.map(question => {
        // Remove 'purpose_of_the_trip' and convert 'list' to string
        const { purpose_of_the_trip, ...rest } = question;
        
        return {
            ...rest,
            // Convert 'list' to a JSON string
            list: rest.list && rest.list.length > 0 ? JSON.stringify(rest.list) : ""
        };
    });*/

    return {
        // "session_id": data.session_id,
        "id": data.id,
        "order_no": data.orderNo,
        "object_name": data.fullName,
        "id_card": data.idCard,
        "dob": data.dob,
        "gender": data.gender,
        "relationship": data.relationship,
        "phone": data.phone,
        "email": data.email,
        "national": data.national,
        "address": data.address,
        "occupation": data.occupation,
        "package_code": data.package_code,
        "package_detail": data.insPackage,
        "premium": data.premium, //parseFloat(data.premium).toFixed(6),
        "is_foreigner": false,
        "flex_value1": "",
        "flex_value2": "",
        "flex_value3": "",
        "flex_value4": "",
        "flex_value5": "",
        "flex_num1": 0,
        "flex_num2": 0,
        "flex_num3": 0,
        "flex_num4": 0,
        "flex_num5": 0,
        "survey_questions": data.survey_questions,
        "location": data.location,
        "pkg_type": data.pkg_type,
        "pax_type": data.pax_type,
        "num_of_days": data.num_of_days
    }
}

export const orderObjectMapper = (data) => {
    return {
        "ins_order_no": data.veh_order_no,
        "ins_agency_code": data.veh_agency_code,
        "ins_agency_name": data.veh_agency_name,
        "ins_handler_code": data.veh_handler_code,
        "ins_handler_name": data.veh_handler_name,
        "ins_inv_address": data.ins_inv_address,
        "ins_inv_company_name": data.inv_company_name,
        "ins_inv_tax": data.inv_tax,
        "ins_issue_invoice": data.veh_issue_invoice.toString(),
        "ins_pkg_code": data.pti_package["pkg_code"],
        "ins_organization": data.veh_business,
        "ins_address": data.veh_address,
        "ins_customer": data.veh_customer,
        "ins_customer_dob": data.veh_customer_dob,
        "ins_customer_idcard": data.veh_customer_idcard,
        "ins_gender": data.veh_gender.toString(),
        "ins_national_code": data.veh_national_code,
        "ins_national_name": data.veh_national_name,
        "ins_occupation_code": data.veh_occupation_code,
        "ins_occupation_name": data.veh_occupation_name,
        "ins_phone": data.veh_phone,
        "ins_email": data.veh_email,
        "ins_fm_dt": data.veh_fm_dt,
        "ins_to_dt": data.veh_to_dt,
        "ins_district_code": data.veh_district_code,
        "ins_district_name": data.veh_district_name,
        "ins_province_code": data.veh_province_code,
        "ins_province_name": data.veh_province_name,
        "ins_rep_company": data.veh_rep_company,
        "ins_tax_code": data.veh_tax_code,
        "ins_origin_premium": parseFloat(data.veh_premium + data.veh_tax_amt).toFixed(6),
        "ins_tax_amt": parseFloat(data.veh_tax_amt).toFixed(13),
        "ins_premium": parseFloat(data.veh_premium).toFixed(6),
        "ins_location": data.ins_location ?? "Vietnam",
        "ins_schedule": data.veh_schedule,
        "ins_sob_name": data.veh_sob_name,
        "ins_sob_code": data.veh_sob_code,
        "ins_voucher_code": "",
        "ins_promoter_code": data.ins_promoter_code,
        "ins_objects": data.ins_objects,
        "aml_checked": data.aml_checked,
        "aml_risk_score": data.aml_risk_score,
        "aml_total_score": data.aml_total_score,
        "ins_pax_type": data.ins_pax_type,
        "ins_pkg_type": data.ins_pkg_type,
    }
}

export const orderObjectMaster = (data) => {
    const location = (data.ins_location === "Vietnam")
        ? ({
            // "pti_code": "PTIPKG03",
            "location": "Vietnam",
            "bg_color": "#564741",
            "btn_color": "#564741",
            "bg_image": "Image3",
            "name": "Du lịch trong nước",
            "is_blocked": false,
            "code_option": "Vietnam",
        })
        : ((data.ins_location === "World") ?
            ({
                // "pti_code": "PTIPKG01",
                "location": "",
                "bg_color": "#2e7c3d",
                "btn_color": "#2e7c3d",
                "bg_image": "Image2",
                "name": "Du lịch nước ngoài",
                "is_blocked": false,
                "code": "World",
            }) : 
            ({
                // "pti_code": "PTIPKG02",
                "location": "",
                "bg_color": "#2da842",
                "btn_color": "#2da842",
                "bg_image": "Image1",
                "name": "Người nước ngoài du lịch tại Việt Nam",
                "is_blocked": false,
                "code": "Other",
            }));

    const res_post_order = data.ins_objects ? data.ins_objects.map(item => item.id) : [];

    const map_state_lst_person = data.ins_objects?.map((item, index) => ({
        "idx": index + 1,
        "id": item.id,
        "orderNo": data.ins_order_no,
        "fullName": item.object_name,
        "idCard": item.id_card,
        "dob": item.dob,
        "gender": item.gender,
        "phone": item.phone,
        "email": item.email,
        "national": item.national,
        "address": item.address,
        "occupation": item.occupation,
        "relationship": item.relationship,
        "isForeigner": item.is_foreigner,
        "createdBy": "",
        "createdDate": "",
        "modifiedBy": "",
        "modifiedDate": "",
        "isInsuredPerson": false,
        "insPackage": item.package_detail,
        "objPackage": safelyParseJSON(item.package_detail, {}),
        "action": "new", //[new, edit]
        "premium": item.premium,
        "survey_questions": item.survey_questions,
        "location": item.location,
        "pkg_type": item.pkg_type,
        "pax_type": item.pax_type,
        "package_code": item.package_code,
    })) ?? [];
    const firstObject = data.ins_objects?.[0] || {};

    return {
        "state": {
            "veh_pol_no": "",
            "veh_certificate_no": "",
            "veh_org_sc_code": "PTI",
            "veh_sc_code": "PTI",
            "veh_business": data.ins_organization,
            "veh_type": "",
            "veh_type_name": "",
            "veh_seat": "0",
            "veh_weight": "0",
            "veh_reg_no": "",
            "veh_chassis_no": "",
            "veh_engine_no": "",
            "veh_month": "1",
            "veh_fm_dt": data.ins_fm_dt,
            "veh_to_dt": data.ins_to_dt,
            "veh_issued_date": null,
            "veh_handler_code": data.ins_handler_code,
            "veh_handler_name": data.ins_handler_name,
            "veh_handler_name_vi": data.ins_handler_name,
            "veh_is_pax": false,
            "veh_pax": data.ins_objects.length,
            "veh_customer_id": 0,
            "veh_customer": data.ins_customer,
            "veh_customer_idcard": data.ins_customer_idcard,
            "veh_phone": data.ins_phone,
            "veh_email": data.ins_email,
            "veh_address": data.ins_address,
            "veh_province_code": data.ins_province_code,
            "veh_province_name": data.ins_province_name,
            "veh_district_code": data.ins_district_code,
            "veh_district_name": data.ins_district_name,
            "veh_issue_invoice": data.ins_issue_invoice === "N" ? false : true,
            "inv_tax": data.ins_inv_tax,
            "inv_company_name": data.ins_inv_company_name,
            "inv_address": data.ins_inv_address,
            "veh_origin_premium": data.ins_origin_premium ?? 0,
            "veh_tax_ratio": 0,
            "veh_tax_amt": data.ins_tax_amt ?? 0,
            "veh_pax_premium": 0,
            "veh_premium": data.ins_premium ?? 0,
            "veh_si_amt1": 0,
            "veh_si_amt2": 0,
            "veh_si_amt3": 0,
            "veh_si_amt4": 0,
            "veh_is_truck": false,
            "veh_url": "http://localhost:3000/sale/pti-insurance",
            "veh_order_no": data.ins_order_no,
            "veh_get_cert_via": 1,
            "veh_sob_code": data.ins_sob_code,
            "veh_sob_name": data.ins_sob_name,
            "veh_is_agent": false,
            "veh_agency_code": data.ins_agency_code,
            "veh_agency_name": data.ins_agency_name,
            "pol_voucher_code": "",
            "veh_gender": data.ins_gender,
            "veh_customer_dob": data.ins_customer_dob,
            "veh_time_eff": "000000",
            "veh_rep_company": data.ins_rep_company,
            "veh_tax_code": data.ins_tax_code,
            "veh_occupation_code": data.ins_occupation_code,
            "veh_occupation_name": data.ins_occupation_name,
            "veh_national_code": data.ins_national_code,
            "veh_national_name": data.ins_national_name,
            "veh_job_type": "",
            "veh_job_desc": "",
            "veh_ex_occ_confirm": false,
            "veh_ex_pet_confirm": false,
            "is_confirm": false,
            "aml_total_score": 0,
            "aml_risk_score": 0,
            "aml_checked": false,
            "status": data.ins_status,
            "user_type": 1,
            "location": location,
            "pti_package": safelyParseJSON(firstObject.package_detail, {}),
            "pti_action": "edit",
            "ins_promoter_code": data.ins_promoter_code,
            "veh_schedule": data.ins_schedule,
            "veh_is_paid": data.ins_is_paid,
            "ins_pkg_type": data.ins_pkg_type,
            "ins_pax_type": data.ins_pax_type,
            "ins_location": data.ins_location,
            "ins_pkg_code": data.ins_pkg_code,
            res_post_order
        },
        "state_lst_person": map_state_lst_person,
    };
};

export const safelyParseJSON = (jsonString, fallbackValue) => {
    try {
        if (CommonHelper.is_not_empty(jsonString)) {
            return JSON.parse(jsonString);
        }
        return fallbackValue;
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return fallbackValue;
    }
};

export const generateImages = (data) => {
    if (data != null && data.length > 0) {
        return data;
    }
    return [];
};

export const generateSurveyQuestions = (data) => {
    return data;
};

//#endregion

export const Occupations = [
    { 'code': '100001', 'name': 'Bác sĩ ' },
    { 'code': '100002', 'name': 'Giáo viên ' },
    { 'code': '100003', 'name': 'Kế toán ' },
    { 'code': '100004', 'name': 'Kiến trúc sư' },
    { 'code': '100005', 'name': 'Môi giới bất động sản' },
    { 'code': '100006', 'name': 'Nhà thiết kế' },
    { 'code': '100007', 'name': 'Nhân viên bán hàng' },
    { 'code': '100008', 'name': 'Nhân viên ngân hàng' },
    { 'code': '100009', 'name': 'Nhân viên văn phòng ' },
    { 'code': '100010', 'name': 'Thợ cắt tóc' },
    { 'code': '100011', 'name': 'Thư ký' },
    { 'code': '100012', 'name': 'Trợ lý ' },
    { 'code': '100013', 'name': 'Thu ngân' },
    { 'code': '100014', 'name': 'Y tá ' },
    { 'code': '100015', 'name': 'Thẩm phán' },
    { 'code': '100016', 'name': 'Kiểm soát viên' },
    { 'code': '100017', 'name': 'Chuyên viên đánh giá (nhà phê bình văn học, hội họa, ẩm thực...)' },
    { 'code': '100018', 'name': 'Nhân viên thẩm mỹ viện' },
    { 'code': '100019', 'name': 'Quản lý chăn nuôi ' },
    { 'code': '100020', 'name': 'Quản lý nhà ga ' },
    { 'code': '100021', 'name': 'Nhân viên ga tàu ' },
    { 'code': '100022', 'name': 'Quản lý hành chính ' },
    { 'code': '100023', 'name': 'Thiết kế thời trang ' },
    { 'code': '100024', 'name': 'Dược sĩ' },
    { 'code': '100025', 'name': 'Y sĩ' },
    { 'code': '100026', 'name': 'Hành chính bệnh viện ' },
    { 'code': '100027', 'name': 'Kỹ thuật viên vật lý trị liệu ' },
    { 'code': '100028', 'name': 'Nhân viên hành chính' },
    { 'code': '100029', 'name': 'Hội họa' },
    { 'code': '100030', 'name': 'Biên kịch' },
    { 'code': '100031', 'name': 'Nhân viên tráng phim' },
    { 'code': '100032', 'name': 'Nhân viên bán vé, soát vé rạp chiếu phim' },
    { 'code': '100033', 'name': 'Nhân viên rạp chiếu phim' },
    { 'code': '100034', 'name': 'Nhạc sỹ ' },
    { 'code': '100035', 'name': 'Nhân viên bán hàng trong khu vui chơi' },
    { 'code': '100036', 'name': 'Quản lý nhà hàng ' },
    { 'code': '100037', 'name': 'Quản lý khách sạn ' },
    { 'code': '100038', 'name': 'Quản lý hồ bơi' },
    { 'code': '100039', 'name': 'Nhân viên bán vé' },
    { 'code': '100040', 'name': 'Giảng viên ' },
    { 'code': '100041', 'name': 'Sinh viên ' },
    { 'code': '100042', 'name': 'Học sinh' },
    { 'code': '100043', 'name': 'Trẻ em ' },
    { 'code': '100044', 'name': 'Biên dịch viên ' },
    { 'code': '100045', 'name': 'Người tu hành ' },
    { 'code': '100046', 'name': 'Mục sư, linh mục ' },
    { 'code': '100047', 'name': 'Nhân viên spa' },
    { 'code': '100048', 'name': 'Hành chính nhân sự ' },
    { 'code': '100049', 'name': 'Nhà chuyên môn về khoa học trái đất và vật lý ' },
    { 'code': '100050', 'name': 'Nhà toán học, thống kê ' },
    { 'code': '100051', 'name': 'Điều dưỡng ' },
    { 'code': '100052', 'name': 'Nhà tư vấn tài chính ' },
    { 'code': '100053', 'name': 'Phiên dịch' },
    { 'code': '100054', 'name': 'Nhân viên y tế' },
    { 'code': '100055', 'name': 'Nhân viên tổng đài' },
    { 'code': '100056', 'name': 'Nhân viên thiết kế ' },
    { 'code': '100057', 'name': 'Nhân viên kế hoạch' },
    { 'code': '100058', 'name': 'Kế toán trưởng ' },
    { 'code': '100059', 'name': 'Tổng giám đốc' },
    { 'code': '100060', 'name': 'Phó tổng giám đốc' },
    { 'code': '100061', 'name': 'Giám đốc ' },
    { 'code': '100062', 'name': 'Giám đốc tài chính' },
    { 'code': '100063', 'name': 'Quản lý' },
    { 'code': '100064', 'name': 'Trưởng phòng ' },
    { 'code': '100065', 'name': 'Phó phòng ' },
    { 'code': '100066', 'name': 'Trưởng nhóm ' },
    { 'code': '100067', 'name': 'Công nhân phòng thí nghiệm ' },
    { 'code': '100068', 'name': 'Công nhân phòng thuốc' },
    { 'code': '100069', 'name': 'Trưởng ban' },
    { 'code': '100070', 'name': 'Đóng gói' },
    { 'code': '100071', 'name': 'Dán nhãn' },
    { 'code': '100072', 'name': 'Kiểm hàng' },
    { 'code': '100073', 'name': 'Tổng vụ' },
    { 'code': '100074', 'name': 'Nhân viên địa chính' },
    { 'code': '100075', 'name': 'Công chứng viên' },
    { 'code': '100076', 'name': 'Nhân viên ghi sổ kế toán' },
    { 'code': '100077', 'name': 'Đại lý thuế' },
    { 'code': '100078', 'name': 'Luật sư' },
    { 'code': '100079', 'name': 'Nhân viên kiểm toán' },
    { 'code': '100080', 'name': 'Lãnh đạo có ảnh hưởng chính trị' },
    { 'code': '200001', 'name': 'Bộ phận kỹ thuật ' },
    { 'code': '200002', 'name': 'Bảo trì điện' },
    { 'code': '200003', 'name': 'Cố vấn' },
    { 'code': '200004', 'name': 'Bảo trì máy ' },
    { 'code': '200005', 'name': 'Bộ phận chất lượng' },
    { 'code': '200006', 'name': 'Công nhân ' },
    { 'code': '200007', 'name': 'Thu mua' },
    { 'code': '200008', 'name': 'Công nhân lắp ráp' },
    { 'code': '200009', 'name': 'Nhân viên vẽ 2D, 3D, đứng máy CNC' },
    { 'code': '200010', 'name': 'CNC' },
    { 'code': '200011', 'name': 'Phụ xe' },
    { 'code': '200012', 'name': 'Quản lý chất lượng' },
    { 'code': '200013', 'name': 'Bác sỹ thú y' },
    { 'code': '200014', 'name': 'Công nhân may' },
    { 'code': '200015', 'name': 'Công nhân nhuộm' },
    { 'code': '200016', 'name': 'Công nhân sơn' },
    { 'code': '200017', 'name': 'Công nhân thêu' },
    { 'code': '200018', 'name': 'Công nhân sản xuất ' },
    { 'code': '200019', 'name': 'Quản lý sản xuất ' },
    { 'code': '200020', 'name': 'Trưởng máy ' },
    { 'code': '200021', 'name': 'Giám đốc kỹ thuật ' },
    { 'code': '200022', 'name': 'Giám đốc thi công' },
    { 'code': '200023', 'name': 'Giám đốc sản xuất ' },
    { 'code': '200024', 'name': 'Giám sát kỹ thuật ' },
    { 'code': '200025', 'name': 'Giám sát thu mua ' },
    { 'code': '200026', 'name': 'Nhân viên thực tập' },
    { 'code': '200027', 'name': 'Giám sát kinh doanh ' },
    { 'code': '200028', 'name': 'Kho' },
    { 'code': '200029', 'name': 'Nhân viên' },
    { 'code': '200030', 'name': 'Nhân viên kỹ thuật' },
    { 'code': '200031', 'name': 'Nhân viên vận hành máy ' },
    { 'code': '200032', 'name': 'Kiểm phẩm ' },
    { 'code': '200033', 'name': 'Kỹ sư' },
    { 'code': '200034', 'name': 'Nấu ăn ' },
    { 'code': '200035', 'name': 'Bảo mẫu' },
    { 'code': '200036', 'name': 'Bảo vệ ' },
    { 'code': '200037', 'name': 'Đầu bếp' },
    { 'code': '200038', 'name': 'Huấn luyện viên thể thao' },
    { 'code': '200039', 'name': 'May mặc' },
    { 'code': '200040', 'name': 'Người đi chào hàng' },
    { 'code': '200041', 'name': 'Nhân viên giao hàng' },
    { 'code': '200042', 'name': 'Nhân viên kinh doanh ' },
    { 'code': '200043', 'name': 'Nhân viên phục vụ' },
    { 'code': '200044', 'name': 'Nội trợ ' },
    { 'code': '200045', 'name': 'Buôn bán phế liệu' },
    { 'code': '200046', 'name': 'Bán hàng rong ' },
    { 'code': '200047', 'name': 'Buôn bán động vật ' },
    { 'code': '200048', 'name': 'Quản lý kho' },
    { 'code': '200049', 'name': 'Kinh doanh vàng bạc, đá quý' },
    { 'code': '200050', 'name': 'Ghi đồng hồ, thu phí điện nước' },
    { 'code': '200051', 'name': 'Pha chế ' },
    { 'code': '200052', 'name': 'Thợ chụp hình/ nhiếp ảnh gia' },
    { 'code': '200053', 'name': 'Người làm dịch vụ ma chay cưới hỏi' },
    { 'code': '200054', 'name': 'Giặt ủi ' },
    { 'code': '200055', 'name': 'Thợ sửa khóa ' },
    { 'code': '200056', 'name': 'Thợ trang điểm ' },
    { 'code': '200057', 'name': 'Nhân viên trạm xăng ' },
    { 'code': '200058', 'name': 'Nhân viên quản lý/ kiểm định chất lượng sản phẩm ' },
    { 'code': '200059', 'name': 'Công nhân trồng cây' },
    { 'code': '200060', 'name': 'Kỹ sư nông nghiệp ' },
    { 'code': '200061', 'name': 'Giám đốc nông trường, hợp tác xã, cán bộ khuyến nông' },
    { 'code': '200062', 'name': 'Chăn nuôi gia cầm, gia súc' },
    { 'code': '200063', 'name': 'Trồng cây cảnh ' },
    { 'code': '200064', 'name': 'Thiết kế công trình ' },
    { 'code': '200065', 'name': 'Quản lý hệ thống cung cấp nước ' },
    { 'code': '200066', 'name': 'Nuôi thủy sản' },
    { 'code': '200067', 'name': 'Nhân viên phát thư' },
    { 'code': '200068', 'name': 'Quản lý bến xe' },
    { 'code': '200069', 'name': 'Kiểm định, đăng kiểm' },
    { 'code': '200070', 'name': 'Hải quan ' },
    { 'code': '200071', 'name': 'Thiết kế, quản lý cửa hàng nội thất ' },
    { 'code': '200072', 'name': 'Kỹ thuật điện tử/ cơ điện ' },
    { 'code': '200073', 'name': 'Lắp ráp thiết bị điện tự' },
    { 'code': '200074', 'name': 'Công nhân giày da' },
    { 'code': '200075', 'name': 'Công nhân bao bì ' },
    { 'code': '200076', 'name': 'Công nhân sản xuất hóa chất ' },
    { 'code': '200077', 'name': 'Chế biến thực phẩm ' },
    { 'code': '200078', 'name': 'Mổ gia súc, gia cầm ' },
    { 'code': '200079', 'name': 'Gia công thủ công mỹ nghệ trên giấy/ vải ' },
    { 'code': '200080', 'name': 'Thợ làm đồ gốm ' },
    { 'code': '200081', 'name': 'Công nhân sản xuất nhựa' },
    { 'code': '200082', 'name': 'Công nhân gia công sản phẩm từ cao su' },
    { 'code': '200083', 'name': 'Công nhân xưởng giấy ' },
    { 'code': '200084', 'name': 'Đạo diễn ' },
    { 'code': '200085', 'name': 'Quay phim ' },
    { 'code': '200086', 'name': 'Nhân viên hậu cần' },
    { 'code': '200087', 'name': 'Phóng viên ' },
    { 'code': '200088', 'name': 'Biên tập viên ' },
    { 'code': '200089', 'name': 'MC' },
    { 'code': '200090', 'name': 'Công nhân xưởng in' },
    { 'code': '200091', 'name': 'Nhân viên khử trùng ' },
    { 'code': '200092', 'name': 'Bác sỹ/ y tá bệnh viện tâm thần ' },
    { 'code': '200093', 'name': 'Bác sỹ/ y tá tại trại giam' },
    { 'code': '200094', 'name': 'Sửa chữa máy móc văn phòng ' },
    { 'code': '200095', 'name': 'Tạp vụ ' },
    { 'code': '200096', 'name': 'Tổ phó' },
    { 'code': '200097', 'name': 'Tổ trưởng' },
    { 'code': '200098', 'name': 'Hộ lý ' },
    { 'code': '200099', 'name': 'Nhân viên vệ sinh văn phòng ' },
    { 'code': '200100', 'name': 'Chăm sóc thú ' },
    { 'code': '200101', 'name': 'Vệ sinh chuồng thú ' },
    { 'code': '200102', 'name': 'Nhân viên đạo cụ, dựng cảnh' },
    { 'code': '200103', 'name': 'Trợ lý, thư ký trường quay' },
    { 'code': '200104', 'name': 'Nhân viên phụ trách âm thanh, ánh sáng, phụ trách kỹ thuật hiện trường' },
    { 'code': '200105', 'name': 'Phóng viên truyền hình' },
    { 'code': '200106', 'name': 'Nhân viên phục trang' },
    { 'code': '200107', 'name': 'Diễn viên' },
    { 'code': '200108', 'name': 'Ca sỹ ' },
    { 'code': '200109', 'name': 'Bầu show' },
    { 'code': '200110', 'name': 'Người mẫu ' },
    { 'code': '200111', 'name': 'Quản lý khu giải trí, quán bar, karaoke' },
    { 'code': '200112', 'name': 'Công an' },
    { 'code': '200113', 'name': 'Quản lý trại giam ' },
    { 'code': '200114', 'name': 'Trọng tài ' },
    { 'code': '200115', 'name': 'Môi giới chứng khoán ' },
    { 'code': '200116', 'name': 'Nhân viên massage, spa, sauna, xông hơi (không phải trong thẩm mĩ viện)' },
    { 'code': '200117', 'name': 'Thất nghiệp ' },
    { 'code': '200118', 'name': 'Bán buôn, bán lẻ' },
    { 'code': '200119', 'name': 'Truyền thông ' },
    { 'code': '200120', 'name': 'Nông ' },
    { 'code': '200121', 'name': 'Nghiên cứu thị trường ' },
    { 'code': '200122', 'name': 'Kỹ sư môi trường ' },
    { 'code': '200123', 'name': 'Kỹ sư hóa học ' },
    { 'code': '300001', 'name': 'Cảnh sát hình sự' },
    { 'code': '300002', 'name': 'Công an, an ninh (không làm việc tại cơ quan, văn phòng)' },
    { 'code': '300003', 'name': 'Công nhân cưa gỗ' },
    { 'code': '300004', 'name': 'Công nhân sản xuất ghép gỗ, sản xuất gỗ ép, thợ mộc' },
    { 'code': '300005', 'name': 'Công nhân vệ sinh đường cống' },
    { 'code': '300006', 'name': 'Gia công kim loại' },
    { 'code': '300007', 'name': 'Giám sát xây dựng' },
    { 'code': '300008', 'name': 'Khuân vác' },
    { 'code': '300009', 'name': 'Kiểm lâm' },
    { 'code': '300010', 'name': 'Kỹ sư cơ khí' },
    { 'code': '300011', 'name': 'Kỹ sư dân dụng ' },
    { 'code': '300012', 'name': 'Làm việc liên quan đến điện cao áp ' },
    { 'code': '300013', 'name': 'Lao động tự do ' },
    { 'code': '300014', 'name': 'Lính cứu hỏa' },
    { 'code': '300015', 'name': 'Người lau cửa kính bên ngoài tòa nhà tại độ cao dưới 30 mét' },
    { 'code': '300016', 'name': 'Người nuôi cá sấu, rắn, động vật nguy hiểm' },
    { 'code': '300017', 'name': 'Nhân viên bán thịt' },
    { 'code': '300018', 'name': 'Nhân viên bảo vệ xe (quán bar, vũ trường, karaoke)' },
    { 'code': '300019', 'name': 'Nhân viên cứu hộ vùng sông nước, đồi núi' },
    { 'code': '300020', 'name': 'Nhân viên thi công, lắp đặt nội thất ' },
    { 'code': '300021', 'name': 'Nhân viên phòng độc, diệt côn trùng' },
    { 'code': '300022', 'name': 'Quản lý công trường hầm mỏ' },
    { 'code': '300023', 'name': 'Tài xế' },
    { 'code': '300024', 'name': 'Tài xế bồn chở xăng, dầu, khí đốt, chất nổ' },
    { 'code': '300025', 'name': 'Tài xế xe chở tiền ' },
    { 'code': '300026', 'name': 'Tài xế xe cứu hỏa, xe cứu thương' },
    { 'code': '300027', 'name': 'Tài xế, phụ xe khách đường dài, liên tỉnh' },
    { 'code': '300028', 'name': 'Thám tử' },
    { 'code': '300029', 'name': 'Thi công công trình thủy lợi' },
    { 'code': '300030', 'name': 'Thợ điện ' },
    { 'code': '300031', 'name': 'Thợ hàn' },
    { 'code': '300032', 'name': 'Thợ lặn' },
    { 'code': '300033', 'name': 'Thợ lắp kính công trình' },
    { 'code': '300034', 'name': 'Thợ nhôm' },
    { 'code': '300035', 'name': 'Thợ sắt/ thép' },
    { 'code': '300036', 'name': 'Thợ sơn' },
    { 'code': '300037', 'name': 'Thợ sửa xe' },
    { 'code': '300038', 'name': 'Thợ sửa xe gắn máy, ô tô' },
    { 'code': '300039', 'name': 'Thợ xây, thợ hồ, phụ hồ' },
    { 'code': '300040', 'name': 'Tra xét, bắt buôn lậu' },
    { 'code': '300041', 'name': 'Vận đông viên chuyên nghiệp' },
    { 'code': '300042', 'name': 'Nhân viên bán vật liệu xây dựng' },
    { 'code': '300043', 'name': 'Công nhân vệ sinh đường phố' },
    { 'code': '300044', 'name': 'Nhân viên phòng chống cháy rừng ' },
    { 'code': '300045', 'name': 'Hái dừa, hái cau' },
    { 'code': '300046', 'name': 'Nuôi ong' },
    { 'code': '300047', 'name': 'Giám sát công trình thủy lợi' },
    { 'code': '300048', 'name': 'Công nhân bảo trì, thi công, sửa chữa đường sắt ' },
    { 'code': '300049', 'name': 'Lái tàu' },
    { 'code': '300050', 'name': 'Nhân viên soát vé trên tàu ' },
    { 'code': '300051', 'name': 'Nhân viên phục vụ trên tàu' },
    { 'code': '300052', 'name': 'Công nhân bảo trì, sửa chữa khoang tàu' },
    { 'code': '300053', 'name': 'Công nhân bốc dỡ hàng hóa trên tàu ' },
    { 'code': '300054', 'name': 'Nhân viên tiếp nhiên liệu' },
    { 'code': '300055', 'name': 'Công nhân lắp đặt thang máy ' },
    { 'code': '300056', 'name': 'Xây dựng' },
    { 'code': '300057', 'name': 'Bảo trì văn phòng ' },
    { 'code': '300058', 'name': 'Sữa chữa/ đóng tàu thuyền ' },
    { 'code': '300059', 'name': 'Khai thác khoáng sản ' },
    { 'code': '300060', 'name': 'Thợ tiện ' },
    { 'code': '300061', 'name': 'Thợ khoang' },
    { 'code': '300062', 'name': 'Thợ đánh bóng ' },
    { 'code': '300063', 'name': 'Thợ nồi hơi' },
    { 'code': '300064', 'name': 'Công nhân luyện/ cán/ đúc kim loại ' },
    { 'code': '300065', 'name': 'Luyện than' },
    { 'code': '300066', 'name': 'Công nhân phòng đông lạnh' },
    { 'code': '300067', 'name': 'Điêu khắc ' },
    { 'code': '300068', 'name': 'Huấn luyện viên sở thú, rạp xiếc ' },
    { 'code': '300069', 'name': 'Tiếp viên (phục vụ hộp đêm, quán bar, karaoke)' },
    { 'code': '300070', 'name': 'Cứu hộ hồ bơi/ bãi biển ' },
    { 'code': '300071', 'name': 'Cảnh sát giao thông ' },
    { 'code': '300072', 'name': 'Dân quân tự vệ, dân phòng' },
    { 'code': '300073', 'name': 'Dạy lái xe ' },
    { 'code': '300074', 'name': 'Học sinh trường xiếc, thể thao, nghệ thuật' },
    { 'code': '300075', 'name': 'Khai thác đá ' },
    { 'code': '300076', 'name': 'Nhà khoa học hạt nhân ' },
    { 'code': '300077', 'name': 'Người huấn luyện chó ' },
    { 'code': '300078', 'name': 'Nhân viên làm việc tại quán bar, vũ trường ' },
    { 'code': '300079', 'name': 'Nhân viên sòng bạc ' },
    { 'code': '300080', 'name': 'Nhân viên múa cột, vũ nữ' },
    { 'code': '300081', 'name': 'Vận tải ' },
    { 'code': '300082', 'name': 'Quản lý công trình' },
    { 'code': '300083', 'name': 'Mài' },
    { 'code': '300084', 'name': 'Bảo trì lò hơi' },
    { 'code': '300085', 'name': 'Bộ phận chặt' },
    { 'code': '300086', 'name': 'Công nhân cắt ' },
    { 'code': '300087', 'name': 'Công nhân khuôn mẫu ' },
    { 'code': '300088', 'name': 'Công nhân nghiền' },
    { 'code': '300089', 'name': 'Công nhân đứng máy' },
    { 'code': '300090', 'name': 'Giám sát công trình' }
]