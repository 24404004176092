import React from 'react'
import Modal from 'react-bootstrap/Modal';

function ModalConfirmEmail(props) {
    const { onClose, show, ...other } = props;
    return (
        <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header
                closeButton
                onClick={() => { onClose(false, 0); }}
            >
                <Modal.Title>Gửi giấy yêu cầu bảo hiểm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <button
                                className='text-center btn btn-lg'
                                onClick={() => { onClose(false, 1); }}
                                style={{ cursor: "pointer", textDecoration: "none" }}
                            >
                                <div className='card'>
                                    <div className='card-body' style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white', width: '200px' }}>Gửi email</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalConfirmEmail;