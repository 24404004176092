import React, { useState, useEffect } from "react";
import { MdPolicy, MdOutlineTravelExplore } from "react-icons/md";
import { Link } from "react-router-dom";
const Intro1 = process.env.REACT_APP_RESOURCES_URL + "img/shield.png";
const Intro2 = process.env.REACT_APP_RESOURCES_URL + "img/airplane.png";
const Banner1 = process.env.REACT_APP_RESOURCES_URL + "img/travel_insurance.png";
const PtiWording = process.env.REACT_APP_RESOURCES_URL + "doc/PTI Wording (Vietnamese) Trong nuoc.pdf";

function PtiIntroduce() {
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 800) {
                // Adjust this value as needed
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <section className="container" style={{ marginBottom: "40px" }}>
                <div className="title_section" data-descr="Giới thiệu Sản phẩm" style={{ color: "#564741" }}>
                    <span>Giới thiệu Sản phẩm</span>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <h3 style={{ fontWeight: "700", color: "#564741", textAlign: 'justify' }}>Khám phá thế giới, tận hưởng từng khoảnh khắc.</h3>
                        <div style={{ marginTop: "35px" }}>
                            <img
                                src={Intro1}
                                className="float-left mg-r-24"
                                alt="intro1"
                                style={{ height: "64px", width: "64px", float: "left !important", marginRight: "24px", }}
                            />
                            <p style={{ fontSize: "20px", fontWeight: "700", textAlign: 'justify' }}>Bảo hiểm du lịch</p>
                            <p style={{ fontSize: "20px", fontWeight: "700", textAlign: 'justify' }}>Gói bảo hiểm được thiết kế phù hợp, tối ưu</p>
                        </div>
                        <div className="clearfix mg-t-28">
                            <img
                                src={Intro2}
                                className="float-left mg-r-24"
                                alt="intro2"
                                style={{ height: "64px", width: "64px", float: "left !important", marginRight: "24px" }}
                            />
                            <p style={{ textAlign: 'justify' }}>
                                Bảo hiểm du lịch của{" "}
                                <span
                                    style={{ fontWeight: "700", fontSize: "20px", color: "rgb(32, 201, 151)" }}
                                >
                                    Fubon Insurance - sản phẩm PTI{" "}
                                </span>
                                mang đến cho bạn sự yên tâm trên mọi hành trình khám phá.
                                Dù bạn đi công tác, nghỉ dưỡng hay phiêu
                                lưu, gói bảo hiểm này sẽ bảo vệ bạn trước những rủi ro không
                                lường trước như tai nạn, thương tật hay bệnh tật trong suốt
                                chuyến đi. Với quyền lợi bao gồm chi phí y tế, viện phí, hỗ trợ
                                vận chuyển y tế khẩn cấp, bảo hiểm của Fubon đảm bảo sự an toàn
                                tối đa cho bạn và gia đình. Bên cạnh đó, quy trình đăng ký và
                                nhận chứng nhận bảo hiểm trực tuyến nhanh chóng giúp bạn dễ dàng
                                chuẩn bị cho mọi chuyến hành trình.
                            </p>
                        </div>
                        <div className="clearfix mg-t-28">
                            <div className="row">
                                <div className="col-md-6">
                                    <a
                                        href={PtiWording}
                                        target="_blank"
                                        className="btn btn-success"
                                        style={{
                                            backgroundColor: "#20c997",
                                            fontWeight: "700",
                                            borderRadius: "0px",
                                            border: "none",
                                            height: "60px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                        }}
                                        rel="noreferrer"
                                    >
                                        Thông tin bảo hiểm
                                        <MdPolicy style={{ fontSize: "30px", marginLeft: "12px" }} />
                                    </a>
                                </div>
                                <div className="col-md-6">
                                    <Link
                                        to="/sale/travel-insurance"
                                        className={`btn btn-primary buy-now__media-760 ${isSticky ? "btn-animation-buy-now" : ""}`}
                                        style={{
                                            marginLeft: "60px",
                                            borderRadius: "0px",
                                            fontWeight: "700",
                                            height: "60px",
                                            backgroundColor: "orange",
                                            border: "none",
                                            paddingLeft: "40px",
                                            paddingRight: "32px",
                                            position: isSticky ? "fixed" : "relative",
                                            top: isSticky ? "20px" : "auto",
                                            right: isSticky ? "20px" : "auto",
                                            zIndex: 1000,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        Mua ngay
                                        <MdOutlineTravelExplore style={{ fontSize: "30px", marginLeft: "12px", }} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 d-none d-lg-block">
                        <img
                            className="b-lazy"
                            src={Banner1}
                            alt="Banner"
                            style={{ marginLeft: "100px", width: "71%", height: "auto", borderRadius: "50%", }}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default PtiIntroduce;
