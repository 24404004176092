import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import CommonHelper from '../../helpers/common';
import { useLoaderData } from "react-router-dom";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import TextInput from '../../components/text-input';
import AutoComplete from '../../components/auto-complete';
import DateInput from './component/date-input';
import ShortDateInput from '../../components/short-date-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Packages from './component/packages';
import petApi from '../../api/petApi';
import OnlineApi from "../../api/onlineApi";
import PetConfirmation from './component/confirmation';
import Nationality from '../../nationality';
import Occupation from '../../occupation';
import ValidateMessage from '../../components/validate-msg';
import PetProgressBar from './component/progress-bar';
import ModalReject from './component/modal-reject';
import PetOption from './component/pet-option';
import PetInfo from './component/add-pet-info';
import Authentication from '../../api/authApi';
import InsuredPet from './component/insured-pet';
import PetPayment from './component/pet-payment';
import ModalPayment from './component/modal-payment';
import {
    initial_pet_info,
    steps, // step
    initialAlert, // alert
    initial,
    pet_options, // option
    insuredObjectMapper,
    orderObjectMapper,
    orderObjectMaster,
    initial_survey_questions,
} from './util';
import "./index.css";

const flagLoading = false;

export async function loader({ params }) {
    var data = {};
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        var orderNo = params.id
        data = { ...data, orderNo };
    }
    return data;
};

function PetInsurance() {
    const _title = "BẢO HIỂM THÚ CƯNG";

    const [state, setState] = useState(initial);
    const [state_pet, setStatePet] = useState(initial_pet_info);
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ stateError, stateErrorMsg }, setStateError] = useState({
        stateError: {
            veh_fm_dt: false,
            veh_pax: false,
            veh_customer: false,
            dob: false,
            veh_customer_idcard: false,
            veh_job_desc: false,
            veh_occupation: false,
            veh_nation: false,
            veh_phone: false,
            veh_email: false,
            veh_address: false,
            veh_rep_company: false,
            veh_prov: false,
            veh_dist: false,
            veh_tax_code: false,
            inv_tax: false,
            inv_company_name: false,
            inv_address: false,
        },
        stateErrorMsg: {
            veh_fm_dt: "",
            veh_pax: "",
            veh_customer: "",
            dob: "",
            veh_customer_idcard: "",
            veh_job_desc: "",
            veh_occupation: "",
            veh_nation: "",
            veh_phone: "",
            veh_email: "",
            veh_address: "",
            veh_rep_company: "",
            veh_prov: "",
            veh_dist: "",
            veh_tax_code: "",
            inv_tax: "",
            inv_company_name: "",
            inv_address: "",
        }
    });
    const [{
        state_lst_pet,
        lst_prov,
        lst_dist,
        lst_nation,
        lst_occupation,
        lst_sob,
        lst_agency
    }, setList] = useState({
        lst_prov: [],
        lst_dist: [],
        state_lst_pet: [],
        lst_nation: Nationality,
        lst_occupation: [],
        lst_sob: [],
        lst_agency: []
    })
    const [lockField, setLockField] = useState({
        veh_customer: false,
        veh_phone: false,
        veh_email: false,
        veh_address: false,
        veh_handler_code: false,
        veh_customer_idcard: false,
        veh_job_desc: true,
    });
    const [{
        fm_dt,
        to_dt,
        dob,
        curr_step,
        show_pet_info,
        minDOB,
        maxDOB,
        user,
        show_reject,
        msg_reject,
        show_payment,
        provSel,
        distSel,
        nationalSel,
        occupationSel,
        sobSel,
        agentSel,
        show_pet_payment,
        show_payment_qr_code,
        show_payment_gw,
        show_order_no
    }, setSelected] = useState({
        fm_dt: null,
        to_dt: null,
        dob: null,
        curr_step: steps[0],
        show_personal_info: false,
        show_pet_info: false,
        show_ex_occ: false,
        show_reject: false,
        show_payment: false,
        show_upload: false,
        minDOB: addYears(new Date(), -65),
        maxDOB: addYears(new Date(), -18),
        provSel: [],
        distSel: [],
        occupationSel: [],
        nationalSel: [],
        sobSel: [],
        user: null,
        msg_reject: "",
        agentSel: [],
        show_pet_payment: false,
        show_payment_qr_code: "",
        show_payment_gw: "",
        show_order_no: "",
    })
    const [{ loading }, setShow] = useState({
        loading: flagLoading
    });
    const policy = useLoaderData();
    const [isAgentOrHandler, setIsAgentOrHandler] = useState(true);
    const [petPackages, setPetPackages] = useState([]);
    const [list_id_images, setListIdImages] = useState([]);
    const [list_images, setListImages] = useState([]);
    const [prevAmlParams, setPrevAmlParams] = useState({
        veh_customer: state.veh_customer,
        veh_customer_idcard: state.veh_customer_idcard,
        veh_tax_code: state.veh_tax_code,
        veh_national_code: state.veh_national_code,
        veh_occupation_code: state.veh_occupation_code.substring(0, 7),
        veh_sob_code: CommonHelper.is_empty(state.veh_sob_code) ? "ECO0001" : state.veh_sob_code,
        premium: state.veh_premium
    });
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [state_appraisal, setStateAppraisal] = useState(false);
    const [state_get_order, setStateGetOrder] = useState("");

    useEffect(() => {
        document.title = "Fubon Insurance - " + _title;
        //check user role to render UI Blocks
        check_user();
        var _lst_occ = Occupation.filter(x => x.organization === false);
        _lst_occ.sort((a, b) => a.name.localeCompare(b.name));
        setList((prev) => ({ ...prev, lst_occupation: _lst_occ }));
        var resUser = CommonHelper.get_user();
        resUser.then((res) => {
            var veh_handler_code = "";
            var veh_handler_name = "";
            var veh_agency_code = "";
            var veh_agency_name = "";
            var veh_is_agent = false;
            var veh_customer = "";
            var veh_customer_idcard = "";
            var veh_phone = "";
            var veh_email = "";
            var veh_address = "";
            var lst_agency = [];
            var agentSel = [];
            if (res !== null) {
                veh_handler_code = res.handler_code;
                veh_handler_name = res.handler_name;
                if (res.user_type > 1) {
                    if (res.user_type == 2) {
                        get_agent_by_handler();
                    } else if (res.user_type == 3) {
                        veh_agency_code = res.code;
                        veh_agency_name = res.name;
                        lst_agency = [{ code: veh_agency_code, name: veh_agency_name }]
                        agentSel = lst_agency;
                        veh_is_agent = true;
                    }
                    get_sob("", res.user_type);
                } else {
                    veh_customer = res.name;
                    veh_address = res.address;
                    veh_email = res.email;
                    veh_phone = res.phone;
                    veh_customer_idcard = res.id_card;
                }
                setList((prev) => ({
                    ...prev,
                    lst_agency
                }))
                setSelected((prev) => ({
                    ...prev,
                    user: res,
                    agentSel,
                }));
                setState((prev) => ({
                    ...prev,
                    user_type: res.user_type > 1 ? res.user_type : 1,
                    veh_is_agent,
                    veh_handler_code,
                    veh_handler_name,
                    veh_agency_code,
                    veh_agency_name,
                    veh_customer,
                    veh_customer_idcard,
                    veh_phone,
                    veh_email,
                    veh_address,
                }));
                /*if (res.is_agent) {
                    veh_agency_code = res.code;
                    veh_agency_name = res.name;
                } else {
                    veh_customer = res.name;
                    veh_address = res.address;
                    veh_email = res.email;
                    veh_phone = res.phone;
                    veh_customer_idcard = res.id_card;
                }
                setSelected((prev) => ({ ...prev, user: res }));
                setState((prev) => ({
                    ...prev,
                    user_type: res.user_type,
                    veh_is_agent: res.is_agent == 1,
                    veh_handler_code: _handler_code,
                    veh_handler_name: _handler_name,
                    veh_agency_code: _agent_code,
                    veh_agency_name: _agent_name,
                }));
                get_agent_by_handler();*/
            }
        }).catch(
            setSelected((prev) => ({
                ...prev,
                user: {
                    user_type: 1,
                },
                agentSel,
            }))
        )
        get_provices();
        //get_sob("");
        get_pet_packages("");
    }, []);
    useEffect(() => {
        const fetchOrder = async () => {
            if (policy !== undefined && CommonHelper.is_not_empty(policy.orderNo)) {
                try {
                    setShow((prev) => ({
                        ...prev,
                        loading: true
                    }));
                    const res = await petApi.get_order(policy.orderNo);
                    const res_status = await petApi.get_payment_status(policy.orderNo);
                    if (res != null && res.code === 200) {
                        const _convert_data = await orderObjectMaster(res.data);
                        if (_convert_data) {
                            set_data(_convert_data);
                        } else {
                            console.error("Converted data is null or undefined");
                        }
                    }
                    if (res_status != null && res_status.code === 200 && res_status.data.cert_url != null) {
                        setSelected((prev) => ({
                            ...prev,
                            curr_step: steps[4],
                        }))
                        setPaymentStatus(res_status);
                        setPaymentSuccess(true);
                        setState((prev) => ({
                            ...prev,
                            cert_url: res_status.data.cert_url
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching order:', error);
                } finally {
                    setShow((prev) => ({
                        ...prev,
                        loading: false
                    }));
                }
            }
        };
        fetchOrder();
    }, [policy]);
    useEffect(() => {
        var veh_occupation_code = "", veh_occupation_name = "";
        var veh_national_code = "", veh_national_name = "";
        if (nationalSel.length > 0) {
            veh_national_code = nationalSel[0].code;
            veh_national_name = nationalSel[0].name;
        }
        if (occupationSel.length > 0) {
            veh_occupation_code = occupationSel[0].code;
            veh_occupation_name = occupationSel[0].name;
        }
        setState((prev) => ({
            ...prev,
            veh_national_code,
            veh_national_name,
            veh_occupation_code,
            veh_occupation_name,
        }))
    }, [nationalSel, occupationSel]);
    useEffect(() => {
        var veh_agency_code = "";
        var veh_agency_name = "";
        if (agentSel.length > 0) {
            veh_agency_code = agentSel[0].code;
            veh_agency_name = agentSel[0].name;
            var arr = veh_agency_name.split(" - ");
            if (arr.length == 2) {
                veh_agency_name = arr[1]
            }
        }
        setState((prev) => ({
            ...prev,
            veh_agency_code,
            veh_agency_name
        }))
    }, [agentSel]);
    useEffect(() => {
        var inv_tax = "";
        var inv_company_name = "";
        var inv_address = "";
        if (state.veh_issue_invoice && state.veh_business == "Y") {
            inv_tax = state.veh_tax_code;
            inv_company_name = state.veh_customer;
            inv_address = state.veh_address;
        }
        setState((prev) => ({
            ...prev,
            inv_tax,
            inv_company_name,
            inv_address
        }));
    }, [state.veh_issue_invoice]);

    const get_pet_packages = async (sob) => {
        try {
            const _data = {
                "type": "",
                "from_date": "",
                "sob": "",
            }
            var res = await petApi.get_pet_premium(_data);
            if (res.code === 200) {
                setPetPackages(res.data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    const check_user = () => {
        Authentication.get_user()
            .then((res) => {
                if (res.code === 506) {
                    setIsAgentOrHandler(true);
                } else {
                    CommonHelper.get_user().then((objUser) => {
                        if (objUser.user_type === 2 || objUser.user_type === 3) {
                            setIsAgentOrHandler(false);
                            /*OnlineApi.get_sob("").then((data) => {
                                if (data !== null) {
                                    setState((prev) => ({
                                        ...prev,
                                        veh_sob: data[0].code,
                                        veh_sob_code: data[0].code,
                                        veh_sob_name: data[0].name,
                                    }));
                                }
                            }).catch((error) => {
                                console.error("Error fetching SOB:", error);
                            });*/
                        }
                    }).catch((error) => {
                        console.error("Error fetching user:", error);
                    });
                }
            })
            .catch((error) => {
                console.error("Error during authentication:", error);
            });
    };
    const select_pkg = (e) => {
        //validate_control(e);
        var _pax = 0;
        var _premium = 0;
        // var _tax = 0;
        var _str_pax = state.veh_pax;
        if (CommonHelper.is_not_empty(_str_pax)) {
            _pax = parseInt(_str_pax);
        }
        if (e != null) {
            _premium = e.premium;
            // _tax = e.taxAmt;
            var _pkg = e;
        }
        setState((prev) => ({
            ...prev,
            veh_premium: _pax * _premium,
            pet_package: _pkg
        }))
    };
    const set_data = (data) => {
        setState(data.state);
        setStatePet(data.state_pet);
        setList((prev) => ({
            ...prev,
            state_lst_pet: data.state_lst_pet
        }));

        // data.state.veh_order_no = data.veh_order_no;
        data.state.veh_sc_code = "PET";
        // data.state.status = data.status;
        data.state["cert_url"] = data.cert_url;
        data.state["file_upload"] = data.file_upload;
        var _lst_occ = [];
        if (data.state.veh_business === "Y") {
            _lst_occ = Occupation.filter(x => x.organization === true);
        } else {
            _lst_occ = Occupation.filter(x => x.organization === false);
        }
        var _occ_sel = _lst_occ.filter(x => x.code == data.state.veh_occupation_code);
        setList((prev) => ({
            ...prev,
            lst_occupation: _lst_occ,
        }))
        if (CommonHelper.is_not_empty(data.state.veh_fm_dt)) {
            var _time = data.state.veh_fm_dt.substr(9);
            data.state.veh_time_eff = _time;
        }
        var _dob = null;
        if (CommonHelper.is_not_empty(data.state.veh_customer_dob)) {
            _dob = CommonHelper.parseStringToDate(data.state.veh_customer_dob, "yyyyMMdd");
        }
        var provSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_province_code)) {
            provSel = [{ code: data.state.veh_province_code, name: data.state.veh_province_name }];
        }
        var distSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_district_code)) {
            distSel = [{ code: data.state.veh_district_code, name: data.state.veh_district_name }];
        }
        var sobSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_sob_code)) {
            sobSel = [{ code: data.state.veh_sob_code, name: data.state.veh_sob_name }];
        }
        var agentSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_agency_code)) {
            agentSel.push({ code: data.state.veh_agency_code, name: data.state.veh_agency_code + " - " + data.state.veh_agency_name });
        }

        get_provices(null);
        if (CommonHelper.is_not_empty(data.state.veh_district_code)) {
            get_districts(data.state.veh_province_code, null);
        }
        setSelected((prev) => ({
            ...prev,
            fm_dt: CommonHelper.parseStringToDate(data.state.veh_fm_dt, "yyyyMMdd HHmmss"),
            to_dt: CommonHelper.parseStringToDate(data.state.veh_to_dt, "yyyyMMdd HHmmss"),
            curr_step: steps[3],
            dob: _dob,
            occupationSel: _occ_sel,
            nationalSel: Nationality.filter(x => x.code === data.state.veh_national_code),
            sobSel,
            provSel,
            distSel,
            agentSel
        }))
    };
    const change_fm_dt = (val) => {
        var _fm_dt = null;
        var _to_dt = null;
        if (val != null) {
            // Set default time to 00:00 (noon)
            val.setHours(0);
            val.setMinutes(0);
            val.setSeconds(0);
            _fm_dt = val;
            _to_dt = addYears(val, state.veh_month);
        }
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_fm_dt: _fm_dt == null
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_fm_dt: (_fm_dt == null ? "required" : "")
            },
        }))
        setSelected((prev) => ({
            ...prev,
            fm_dt: _fm_dt,
            to_dt: _to_dt,
        }));
        setState((prev) => ({
            ...prev,
            veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(_fm_dt),
            veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(_to_dt),
        }))
    };
    const change_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            dob: val,
        }));
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                dob: val == null
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                dob: (val == null ? "required" : "")
            },
        }))
    };
    const change_occupation = (val) => {
        var _obj = { ...stateError };
        _obj.veh_occupation = false;
        var _objMsg = { ...stateErrorMsg };
        _objMsg.veh_occupation = "";
        if (val.length > 0) {
            if (val[0].exception) {
                _obj.veh_occupation = true;
                _objMsg.veh_occupation = "\"" + val[0].name + "\" thuộc ngành nghề không được bảo hiểm. Vui lòng chọn ngành nghề khác!";
            }
            var veh_job_desc = true;
            if (state.veh_business == "N") {
                if (val[0].code === "OCCR038_0100052") {
                    veh_job_desc = false;
                    _obj.veh_job_desc = true;
                    _objMsg.veh_job_desc = "required";
                } else {
                    _obj.veh_job_desc = false;
                    _objMsg.veh_job_desc = "";
                }
            } else {
                if (val[0].code === "OCCR038_1100025") {
                    veh_job_desc = false;
                    _obj.veh_job_desc = true;
                    _objMsg.veh_job_desc = "required";
                } else {
                    _obj.veh_job_desc = false;
                    _objMsg.veh_job_desc = "";
                }
            }
            setLockField((prev) => ({ ...prev, veh_job_desc }));
        } else {
            _obj.veh_occupation = true;
            _objMsg.veh_occupation = "required";
        }
        setSelected((prev) => ({
            ...prev,
            occupationSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: _obj, //{ ...stateError, veh_occupation: val.length == 0 },
            stateErrorMsg: _objMsg, //{ ...stateErrorMsg, veh_occupation: (val.length == 0 ? "required" : "") },
        }))
    };
    const change_nation = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name
        }
        setState((prev) => ({
            ...prev,
            veh_national_code: _code,
            veh_national_name: _name,
        }))
        setSelected((prev) => ({
            ...prev,
            nationalSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_nation: val.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_nation: (val.length == 0 ? "required" : "")
            },
        }))
    };
    const get_sob = (id, user_type) => {
        var res_sob = OnlineApi.get_sob(id);
        res_sob.then((data) => {
            if (data !== null) {
                setList((prev) => ({
                    ...prev,
                    lst_sob: data,
                }));
                if (user_type == 3 && data.length == 1) {
                    setSelected((prev) => ({
                        ...prev,
                        sobSel: data
                    }))
                }
            }
        })
    };
    const get_agent_by_handler = () => {
        var response = OnlineApi.get_agent_by_handler();
        response.then((res) => {
            if (res != null && res.length > 0) {
                var lst_agency = res.map((item) => {
                    return {
                        "code": item.code,
                        "name": item.code + " - " + item.name
                    }
                });
                setList((prev) => ({
                    ...prev,
                    lst_agency
                }));
            }
        })
    };
    const change_sob = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        //get_districts(_code);
        setSelected((prev) => ({
            ...prev,
            sobSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_sob_code: _code,
            veh_sob_name: _name,
        }))
    };
    const get_provices = (obj) => {
        var res_provinces = OnlineApi.get_provinces();
        res_provinces.then((data) => {
            if (data !== null) {
                setList((prev) => ({
                    ...prev,
                    lst_prov: data,
                }));
                if (obj !== undefined && obj !== null) {
                    setSelected((prev) => ({
                        ...prev,
                        provSel: data.filter(x => x.code == obj.code)
                    }))
                }
            }
        })
    };
    const change_prov = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        get_districts(_code);
        setSelected((prev) => ({
            ...prev,
            provSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_province_code: _code,
            veh_province_name: _name,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_prov: val.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_prov: (val.length == 0 ? "required" : "")
            },
        }))
    };
    const change_dist = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        setSelected((prev) => ({
            ...prev,
            distSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_district_code: _code,
            veh_district_name: _name,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_dist: val.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_dist: (val.length == 0 ? "required" : "")
            },
        }))
    };
    const get_districts = (id, obj) => {
        setSelected((prev) => ({
            ...prev,
            distSel: [],
        }));
        var res_districts = OnlineApi.get_districts(id, "");
        res_districts.then((data) => {
            if (data === null) {
                data = [];
            }
            setList((prev) => ({
                ...prev,
                lst_dist: data,
            }));
            if (obj !== undefined && obj !== null) {
                setSelected((prev) => ({
                    ...prev,
                    distSel: data.filter(x => x.code == obj.code)
                }))
            }
        })
    };
    const handle_radio_check = (e) => {
        const { name, value } = e.target;
        if (name === "veh_gender") {
            setState((prevData) => ({
                ...prevData,
                [name]: CommonHelper.convertToInt(value),
            }));
        }
    };
    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        if (name === "veh_phone") {
            _val = value.replace(/[^0-9]/g, "");
        } else if (name === "veh_pax") {
            var _pax = 0;
            var _premium = 0;
            if (CommonHelper.is_not_empty(_val) && CommonHelper.convertToInt(_val) > 0) {
                _pax = CommonHelper.convertToInt(_val);
            } else {
                _val = '0';
            }
            if (state.pet_package != null) {
                _premium = state.pet_package.premium;
            }
            setState((prev) => ({
                ...prev,
                veh_premium: _pax * _premium,
            }))
        } else if (name === "veh_business") {
            var _lst_occ = [];
            if (_val === "Y") {
                _lst_occ = Occupation.filter(x => x.organization === true);
            } else {
                _lst_occ = Occupation.filter(x => x.organization === false);
            }
            _lst_occ.sort((a, b) => a.name.localeCompare(b.name));
            setList((prev) => ({
                ...prev,
                lst_occupation: _lst_occ,
            }))
            setState((prevData) => ({
                ...prevData,
                veh_customer: "",
                veh_customer_idcard: "",
                veh_phone: "",
                veh_email: "",
                veh_address: "",
            }));
            setSelected((prevData) => ({
                ...prevData,
                dob: null,
                nationalSel: [],
                occupationSel: [],
                provSel: [],
                distSel: [],
            }));
            reset_validate(e);
        }
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
        validate_control(e);
    };
    const validate_control = (e) => {
        const { name, value } = e.target;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            _arr.push(item);
        })
        if (_arr.indexOf(name) >= 0) {
            _obj[name] = false;
            _objMsg[name] = "";
            if (CommonHelper.is_empty(value)) {
                _obj[name] = true;
                _objMsg[name] = "required";
            } else {
                if (name === "veh_pax" && CommonHelper.convertToInt(value) < 1) {
                    _obj[name] = true;
                    _objMsg[name] = "invalid";
                }
                if (name === "veh_phone") {
                    var _val = value.replace(/[^0-9]/g, "");
                    var _len = _val.length;
                    if (_len < 10 || _len > 11) {
                        _obj[name] = true;
                        _objMsg[name] = "invalid";
                    }
                }
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
    };
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const next_step = async () => {
        var _idx = steps.findIndex(x => x.code === curr_step.code);
        var flag = true;
        var _data = { ...state };
        var _objStateError = { ...stateError };
        var _objStateErrorMsg = { ...stateErrorMsg };
        var res_flag = false;
        if (curr_step.code === 2) {
            res_flag = true;
            if (_data.pet_package["pkg_code"] === undefined) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn gói bảo hiểm. Vui lòng chọn gói bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }))
            }
        } else if (curr_step.code === 3) {
            if (fm_dt === null) {
                flag = false;
                _objStateError.veh_fm_dt = true;
                _objStateErrorMsg.veh_fm_dt = "required";
            } else {
                setState((prev) => ({
                    ...prev,
                    veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt),
                    veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(to_dt),
                }))
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _objStateError,
                stateErrorMsg: _objStateErrorMsg,
            }))
            if (CommonHelper.is_empty(_data.veh_customer)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_phone)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_email)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_address) || provSel.length < 1) {
                flag = false
            } else {
                var _arr_address = [];
                if (distSel.length > 0) {
                    if (_data.veh_address.indexOf(distSel[0].name) < 0) {
                        _arr_address.push(distSel[0].name);
                    }
                    _data.veh_district_code = distSel[0].code;
                }
                if (provSel.length > 0) {
                    if (_data.veh_address.indexOf(provSel[0].name) < 0) {
                        _arr_address.push(provSel[0].name);
                    }
                    _data.veh_province_code = provSel[0].code;
                }
                _data.veh_address += ", " + _arr_address.join(", ");
            }
            if (_data.veh_issue_invoice) {
                if (CommonHelper.is_empty(_data.inv_tax)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_data.inv_company_name)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_data.inv_address)) {
                    flag = false;
                }
                setStateError((prev) => ({
                    ...prev,
                    inv_tax: CommonHelper.is_empty(_data.inv_tax),
                    inv_company_name: CommonHelper.is_empty(_data.inv_company_name),
                    inv_address: CommonHelper.is_empty(_data.inv_address),
                }))
            }

            var _len = [...state_lst_pet].length;

            let countFailed = 0;
            const petsWithAllAnswersNo = state_lst_pet.filter((item) =>
                item.survey_questions.every((sur) => sur.answer_yn === "N")
            );
            const _total_pak = _len - petsWithAllAnswersNo.length;
            if (_len == 0) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Vui lòng nhập danh sách thú cưng được bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    veh_pax: _len,
                    // veh_pax: _total_pak,
                    // ins_object: state_lst_pet
                }))
            }
            // setState((prev) => ({
            //     ...prev,
            //     veh_pax: _len,
            // }))
            if (flag) {
                res_flag = await handle_save();
                // if(curr_step.code === 4) {
                //     res_flag = true;
                // }
            }
        } else if (curr_step.code === 4) {
            res_flag = true;
            // setSelected((prev) => ({ ...prev, show_pet_payment: true }));
            if (flag) {
                get_payment({ ...state }.veh_order_no);
            }
        } else if (curr_step.code === 1) {
            res_flag = true;
            if (_data.pet_option["pet_type"] === undefined) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn loại thú cưng. Vui lòng chọn lại!",
                    is_show: true,
                    variant: "danger"
                }))
            }
        }
        if (flag && res_flag) {
            if (_idx < steps.length - 1) {
                setSelected((prev) => ({
                    ...prev,
                    curr_step: steps[_idx + 1]
                }))
            }
        }
    };
    const back_step = () => {
        var _idx = steps.findIndex(x => x.code === curr_step.code);
        setSelected((prev) => ({
            ...prev,
            curr_step: steps[_idx - 1]
        }))
    };
    const validate_email_format = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.validate_email_format(value);
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                [name]: !_flag
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                [name]: !_flag ? "Email không đúng định dạng. Vui lòng kiểm tra lại!" : ""
            },
        }))
    };
    const validate_idcard = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.check_idcard_pet_valid(value);
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                [name]: !_flag
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                [name]: !_flag ? "invalid" : ""
            },
        }))
    };
    const reset_validate = (e) => {
        const { name, value } = e.target;
        if (name === "veh_business") {
            var _obj = { ...stateError };
            //var _objMsg = {...stateErrorMsg};
            _obj.veh_customer = false;
            _obj.veh_occupation = false;
            _obj.veh_phone = false;
            _obj.veh_email = false;
            _obj.veh_prov = false;
            _obj.veh_address = false;
            _obj.veh_nation = false;
            if (value == "N") {
                _obj.dob = false;
                _obj.veh_customer_idcard = false;
                _obj.veh_job_type = false;
            } else {
                _obj.veh_tax_code = false;
                _obj.veh_rep_company = false;
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
            }))
        }
    };
    const new_pet = async () => {
        const currentAmlParams = {
            veh_customer: state.veh_customer,
            veh_customer_idcard: state.veh_customer_idcard,
            veh_tax_code: state.veh_tax_code,
            veh_national_code: state.veh_national_code,
            veh_occupation_code: state.veh_occupation_code.substring(0, 7),
            veh_sob_code: CommonHelper.is_empty(state.veh_sob_code) ? "ECO0001" : state.veh_sob_code,
            premium: state.veh_premium
        };

        const hasAmlParamsChanged = JSON.stringify(currentAmlParams) !== JSON.stringify(prevAmlParams);

        let flag_aml = true;
        if (hasAmlParamsChanged) {
            flag_aml = await check_aml_online(state.veh_order_no, state.veh_premium);
            setPrevAmlParams(currentAmlParams);
        }

        var flag = validate_info_form();
        // reset_pet_form();
        try {
            if (flag && flag_aml) {
                console.log(initial_pet_info.survey_questions)
                var _list = [...state_lst_pet];
                setStatePet((prev) => ({
                    ...prev,
                    idx: _list.length + 1,
                    id: initial_pet_info.id,
                    orderNo: state.veh_order_no,
                    chipNo: initial_pet_info.chipNo,
                    pet_name: initial_pet_info.pet_name,
                    kindOfPet: state.pet_option["pet_type"],
                    gender: initial_pet_info.gender,
                    typeOfPet: initial_pet_info.typeOfPet,
                    dob: initial_pet_info.dob,
                    age: initial_pet_info.age,
                    insPackage: initial_pet_info.insPackage,
                    objPackage: state.pet_package,
                    action: "new",
                    // survey_questions: initial_pet_info.survey_questions,
                    survey_questions: JSON.parse(JSON.stringify(initial_pet_info.survey_questions)),
                    images: initial_pet_info.images,
                }));
                setSelected((prev) => ({
                    ...prev,
                    show_pet_info: true
                }));
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Lỗi không thêm mới được thú cưng!",
                is_show: true,
                variant: "danger"
            }))
        }
        
    };
    const handleTogglePetInfo = (flag) => {
        setSelected((prev) => ({
            ...prev,
            show_pet_info: flag
        }));
    };
    const handlePostForPetInfo = async (data) => {
        let _code = "";
        if (data != null) {
            data.insPackage = JSON.stringify(data.objPackage);
            if (data.objPackage != null) {
                data.premium = data.objPackage.premium;
            }
            data.list = list_id_images;
            data.orderNo = state.veh_order_no;
            const res = await petApi.post_insured_object(insuredObjectMapper(data));
            _code = res.code;
    
            switch (res.code) {
                case 204:
                case 200:
                    data.id = res.data.id;
                    reset_list_pet(data);
                    break;
                case 705: // EXIST_DATA
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Đã tồn tại thú cưng này trong hệ thống! Vui lòng kiểm tra lại số chip!",
                        is_show: true,
                        variant: "warning"
                    }));
                    setSelected((prev) => ({
                        ...prev,
                        show_pet_info: true
                    }));
                    break;
                case 700: // NODATA
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Không có dữ liệu.!",
                        is_show: true,
                        variant: "warning"
                    }));
                    break;
                case 701: // INSERT_FAILED
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Lỗi",
                        content: "Thêm mới thất bại.!",
                        is_show: true,
                        variant: "danger"
                    }));
                    break;
                case 704: // FAILED
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Lỗi",
                        content: "Thao tác thất bại!",
                        is_show: true,
                        variant: "danger"
                    }));
                    break;
                case 722: // INVALID_PREMIUM
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Giá trị premium không hợp lệ!",
                        is_show: true,
                        variant: "warning"
                    }));
                    break;
                default: // Unknown code
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Mã phản hồi không xác định.",
                        is_show: true,
                        variant: "warning"
                    }));
            }
        }
        return _code;
    };
    const handle_close_pet_info = async (flag, data) => {
        handleTogglePetInfo(flag);
        if (data) {
            const code = await handlePostForPetInfo(data);
            return code;
        }
        return null;
    };
    const reset_list_pet = (model) => {
        if (model.action === "new") {
            var _new_lst = [...state_lst_pet];
            _new_lst.push(model);
            setList((prev) => ({
                ...prev,
                state_lst_pet: _new_lst
            }));
        } else {
            var _arr = [...state_lst_pet];
            if (_arr.length > 0) {
                var _idx = _arr.findIndex(x => x.idx == model.idx);
                if (_idx >= 0) {
                    _arr[_idx] = model;
                }
                setList((prev) => ({
                    ...prev,
                    state_lst_pet: _arr
                }));
            }
        }
    };
    const open_edit_pet = (model) => {
        model.action = "edit";
        // reset_pet_form();
        setStatePet((prev) => ({
            ...prev,
            "idx": model.idx,
            "id": model.id,
            "pet_name": model.pet_name,
            "chipNo": model.chipNo,
            "dob": model.dob,
            "gender": model.gender,
            "kindOfPet": model.kindOfPet,
            "typeOfPet": model.typeOfPet,
            "isForeigner": model.isForeigner,
            "insPackage": model.insPackage,
            "objPackage": model.objPackage,
            "action": "edit", //[new, edit]
            // survey
            "survey_questions": model.survey_questions,
            "images": model.images,
            "imagesRaw": model.imagesRaw,
        }));
        setSelected((prev) => ({
            ...prev,
            show_pet_info: true
        }))
    };
    const open_delete_pet = (model) => {
        if (model.id === 0) {
            setList((prev) => ({
                ...prev,
                state_lst_pet: state_lst_pet.filter(a => a.chipNo !== model.chipNo)
            }));
        } else {
            var response = petApi.delete_insured_pet({ id: model.id });
            response.then((res) => {
                if (res != null && res.code === 204) {
                    setList((prev) => ({
                        ...prev,
                        state_lst_pet: state_lst_pet.filter(a => a.chipNo !== model.chipNo)
                    }));
                }
            })
        }
    };
    const handle_save = async () => {
        var _data = { ...state };
        var _list_pet = [...state_lst_pet];
        if (dob != null) {
            _data.veh_customer_dob = CommonHelper.formatToYYYYMMDD(dob);
        }
        if (nationalSel.length > 0) {
            _data.veh_national_code = nationalSel[0].code;
            _data.veh_national_name = nationalSel[0].name;
        }
        if (occupationSel.length > 0) {
            _data.veh_occupation_code = occupationSel[0].code;
            _data.veh_occupation_name = occupationSel[0].name;
        }
        if (provSel.length > 0) {
            _data.veh_province_code = provSel[0].code;
            _data.veh_province_name = provSel[0].name;
        }
        if (distSel.length > 0) {
            _data.veh_district_code = distSel[0].code;
            _data.veh_district_name = distSel[0].name;
        }
        if (sobSel.length > 0) {
            _data.veh_sob = sobSel[0].code;
            _data.veh_sob_code = sobSel[0].code;
            _data.veh_sob_name = sobSel[0].name;
        }
        _data.veh_premium = 0;
        // _list.forEach((item) => {
        //     _data.veh_premium += item.premium;
        // })
        var _tax_temp = 0;
        var _origin_premium = 0;
        _list_pet.forEach((item) => {
            _data.veh_premium += item.premium + item.objPackage.taxAmt;
            _tax_temp += item.objPackage.taxAmt;
            _origin_premium += item.premium;
        })
        setState((prev) => ({
            ...prev,
            veh_premium: _data.veh_premium
        }));
        var _model = {
            veh_org_sc_code: _data.veh_org_sc_code,
            order_no: _data.veh_order_no,
            total_amt: _data.veh_premium,
            order_detail: _data,
            insured_persons: null,
            insured_pets: null
        };
        //Insert new order
        if (CommonHelper.is_empty(_data.veh_order_no)) {
            _model.insured_pets = _list_pet;
        }
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        let tempListPet = []
        for (let i = 0; i < _list_pet.length; i++) {
            tempListPet.push(insuredObjectMapper(_list_pet[i]));
        }
        var _data_order = {
            masterData: _data,
            ins_objects: tempListPet,
            taxAmt: _tax_temp,
            origin_premium: _origin_premium,
            veh_org_sc_code: _data.veh_org_sc_code,
            veh_sc_code: _data.veh_sc_code,
        }
        try {
            var res = await petApi.post_pet_order(orderObjectMapper(_data_order));
            setShow((prev) => ({
                ...prev,
                loading: false,
            }));
            if (res.code === 204 || res.code === 200) {
                const orderNo = (res.data.allow_payment && res.data.allow_payment.order_no)
                    ? (res.data.allow_payment.order_no)
                    : (res.data.pending_appraisal && res.data.pending_appraisal.order_no)
                    ? (res.data.pending_appraisal.order_no)
                    : (state.veh_order_no ? state.veh_order_no : res.data.order_no);
                var res_get_order = await petApi.get_order(orderNo);
                setState((prev) => ({
                    ...prev,
                    veh_order_no: orderNo,
                    res_post_order: res.data,
                }));
                if (user?.user_type > 1) {
                    if(res_get_order && res_get_order.code === 200 && res_get_order.data.ins_status == 3) {
                        setSelected((prev) => ({
                            ...prev,
                            show_payment: false,
                            show_order_no: orderNo,
                        }));
                        setState((prev) => ({
                            ...prev,
                            status: res_get_order.data.ins_status,
                        }));
                    } else {
                        setSelected((prev) => ({
                            ...prev,
                            show_payment: true,
                            show_order_no: orderNo,
                        }));
                        setState((prev) => ({
                            ...prev,
                            status: res_get_order.data.ins_status,
                        }));
                    }
                    // setSelected((prev) => ({
                    //     ...prev,
                    //     show_payment: true,
                    //     show_order_no: orderNo,
                    // }));
                }
                // get order by id
                // var res_get_order = await petApi.get_order(orderNo);
                if (res_get_order && res_get_order.code === 200) {
                    setStateGetOrder(res_get_order.data);
                    setState((prev) => ({
                        ...prev,
                        status: res_get_order.data.ins_status,
                    }));
                }
                // get_order_status();
                return true;
            }
            else {
                if (res.code === 704 && (res.data.allow_payment == null || res.data.lst_pass == null)) {
                    setSelected(prev => ({
                        ...prev,
                        show_reject: true,
                        msg_reject: "Thú cưng thuộc điều khoản loại trừ không được phép chỉnh sửa thông tin!!!"
                    }));
                    return false;
                } else {
                    setSelected(prev => ({
                        ...prev,
                        show_reject: true,
                        msg_reject: "Lưu yêu cầu bảo hiểm thất bại. Hãy kiểm tra lại thông tin!!!"
                    }));
                    return false;
                }
            }
        } catch (error) {
            console.error("Error during saving:", error);
            setShow((prev) => ({
                ...prev,
                loading: false,
            }));
            setSelected(prev => ({
                ...prev,
                show_reject: true,
                msg_reject: "Đã xảy ra lỗi. Vui lòng thử lại sau!"
            }));
            return false;
        }
    };
    const get_payment = (order_no) => {
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var _model = { ...state };
        var location_path = window.location.pathname;
        var return_url = window.location.origin + location_path;
        var arr = location_path.split("/");
        if (arr.length == 4) {
            arr.splice(arr.length - 1, 1)
            return_url = window.location.origin + arr.join("/");
        }
        var response = petApi.get_pet_payment({
            order_no: order_no,
            return_url,
            res_post_order: state.res_post_order,
            appraisaled: state_appraisal,
        });
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
            if (res.code === 204 || res.code === 200) {
                if (res.data !== null && res.data.payment_qr_code !== null && res.data.payment_gw !== null) {
                    if (state.res_post_order.allow_payment || state.res_post_order.lst_pass) {
                        setSelected((prev) => ({
                            ...prev,
                            show_pet_payment: true,
                            show_payment_qr_code: res.data.payment_qr_code,
                            show_payment_gw: res.data.payment_gw,
                            show_order_no: order_no,
                        }));
                    } else {
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Thông tin",
                            content: "Đã gửi yêu cầu thẩm định thú cưng!",
                            is_show: true,
                            variant: "success"
                        }));
                    }
                }
            } else {
                if (res.code === 700 && state_appraisal == true) {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Thông tin",
                        content: "Đã gửi yêu cầu thẩm định thú cưng!",
                        is_show: true,
                        variant: "success"
                    }));
                }
                // setSelected(prev => ({
                //     ...prev,
                //     show_reject: true,
                //     msg_reject: "Thanh toán thất bại! Vui lòng kiểm tra, xác nhận thông tin yêu cầu bảo hiểm!"
                // }));
                // if (state.res_post_order.allow_payment == "null" || state.res_post_order.allow_payment == "undefined") {
                //     setSelected(prev => ({
                //         ...prev,
                //         show_reject: true,
                //         msg_reject: "Thanh toán thất bại! Không có thú cưng hợp lệ để thanh toán!"
                //     }));
                // } else {
                //     setSelected(prev => ({
                //         ...prev,
                //         show_reject: true,
                //         msg_reject: "Thanh toán thất bại! Vui lòng kiểm tra, xác nhận thông tin yêu cầu bảo hiểm!"
                //     }));
                // }
            }
        })
    };
    const validate_info_form = () => {
        var _data = { ...state };
        var flag = true;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        if (CommonHelper.is_empty(_data.veh_customer)) {
            flag = false;
            _obj.veh_customer = true;
            _objMsg.veh_customer = "required";
        }
        if (CommonHelper.is_empty(_data.veh_phone)) {
            flag = false;
            _obj.veh_phone = true;
            _objMsg.veh_phone = "required";
        }
        if (CommonHelper.is_empty(_data.veh_email)) {
            flag = false;
            _obj.veh_email = true;
            _objMsg.veh_email = "required";
        }
        if (CommonHelper.is_empty(_data.veh_address)) {
            flag = false;
            _obj.veh_address = true;
            _objMsg.veh_address = "required";
        }
        if (provSel.length < 1) {
            flag = false;
            _obj.veh_prov = true;
            _objMsg.veh_prov = "required";
        }
        if (distSel.length < 1) {
            flag = false;
            _obj.veh_dist = true;
            _objMsg.veh_dist = "required";
        }
        if (occupationSel.length == 0) {
            flag = false;
            _obj.veh_occupation = true;
            _objMsg.veh_occupation = "required";
        }
        if (nationalSel.length == 0) {
            flag = false;
            _obj.veh_nation = true;
            _objMsg.veh_nation = "required";
        }
        if (CommonHelper.is_empty(_data.veh_customer_idcard)) {
            flag = false;
            _obj.veh_customer_idcard = true;
            _objMsg.veh_customer_idcard = "required";
        } else {
            if (!CommonHelper.check_idcard_pet_valid(_data.veh_customer_idcard)) {
                flag = false;
                _obj.veh_customer_idcard = true;
                _objMsg.veh_customer_idcard = "invalid";
            }
        }
        if (dob == null) {
            flag = false;
            _obj.dob = true;
            _objMsg.dob = "required";
        }
        if (_data.veh_business === "N") {
            if (CommonHelper.is_empty(_data.veh_customer_idcard)) {
                flag = false;
                _obj.veh_customer_idcard = true;
                _objMsg.veh_customer_idcard = "required";
            } else {
                if (!CommonHelper.check_idcard_pet_valid(_data.veh_customer_idcard)) {
                    flag = false;
                    _obj.veh_customer_idcard = true;
                    _objMsg.veh_customer_idcard = "invalid";
                }
            }
            if (dob == null) {
                flag = false;
                _obj.dob = true;
                _objMsg.dob = "required";
            }
        } else {
            if (CommonHelper.is_empty(_data.veh_rep_company)) {
                flag = false;
                _obj.veh_rep_company = true;
                _objMsg.veh_rep_company = "required";
            }
            if (CommonHelper.is_empty(_data.veh_tax_code)) {
                flag = false;
                _obj.veh_tax_code = true;
                _objMsg.veh_tax_code = "required";
            }
        }
        if (!lockField.veh_job_desc && CommonHelper.is_empty(_data.veh_job_desc)) {
            flag = false
        }
        if (_data.veh_issue_invoice) {
            if (CommonHelper.is_empty(_data.inv_company_name)) {
                flag = false;
                _obj.inv_company_name = true;
                _objMsg.inv_company_name = "required";
            }
            if (CommonHelper.is_empty(_data.inv_tax)) {
                flag = false;
                _obj.inv_tax = true;
                _objMsg.inv_tax = "required";
            }
            if (CommonHelper.is_empty(_data.inv_address)) {
                flag = false;
                _obj.inv_address = true;
                _objMsg.inv_address = "required";
            }
        }
        setStateError((prev) => ({
            ...prev,
            stateError: _obj,
            stateErrorMsg: _objMsg,
        }))
        return flag;
    };
    const check_aml_online = async (order_no, premium = 0) => {
        const _model = { ...state };
        let _url = "";
        _url += `customer=${_model.veh_customer}`;
        _url += `&id_card=${_model.veh_customer_idcard}`;
        _url += `&tax_code=${_model.veh_tax_code}`;
        _url += `&national_code=${_model.veh_national_code}`;
        _url += `&occupation=${_model.veh_occupation_code.substring(0, 7)}`;
        _url += "&sub_class=PET";
        _url += `&sob=${CommonHelper.is_empty(_model.veh_sob_code) ? "ECO0001" : _model.veh_sob_code}`;
        _url += `&premium=${premium}`;

        setShow(prev => ({
            ...prev,
            loading: true,
        }));

        let flag_aml = false;

        try {
            const res = await petApi.get_aml_online_check(_url);
            setShow(prev => ({
                ...prev,
                loading: false,
            }));

            let _aml_total_score = 0;
            let _aml_risk_score = 0;

            if (res && res.code === 200) {
                const _obj = JSON.parse(res.data);
                if (_obj) {
                    _aml_total_score = CommonHelper.convertToInt(_obj.total_score);
                    _aml_risk_score = CommonHelper.convertToInt(_obj.risk_name_score);

                    if (_obj.risk_name_score === 100) {
                        flag_aml = false;
                        setSelected(prev => ({
                            ...prev,
                            show_reject: true,
                            msg_reject: "Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định bán hàng online của chúng tôi. Xin vui lòng liên hệ với nhân viên của Fubon để được tư vấn & phục vụ trực tiếp."
                        }));
                    } else if (_obj.total_score > 69) {
                        flag_aml = false;
                        setSelected(prev => ({
                            ...prev,
                            show_reject: true,
                            msg_reject: "Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định bán hàng online của chúng tôi. Xin vui lòng liên hệ với nhân viên của Fubon để được tư vấn & phục vụ trực tiếp."
                        }));
                    } else if (_obj.total_score < 70) {
                        flag_aml = true;
                    }
                }
            }

            setState(prev => ({
                ...prev,
                aml_total_score: _aml_total_score,
                aml_risk_score: _aml_risk_score,
            }));
        } catch (error) {
            console.error("Error during AML check:", error);
            setShow(prev => ({
                ...prev,
                loading: false,
            }));
            setSelected(prev => ({
                ...prev,
                show_reject: true,
                msg_reject: "Đã xảy ra lỗi trong quá trình kiểm tra AML. Xin vui lòng thử lại sau."
            }));
        }

        return flag_aml;
    };
    const get_order_status = async () => {
        try {
            setShow((prev) => ({
                ...prev,
                loading: true
            }));
            const res = await petApi.get_order(state.veh_order_no);
            if (res != null && res.code === 200) {
                setState((prev) => ({
                    ...prev,
                    status: res.data.ins_status,
                }));
                setShow((prev) => ({
                    ...prev,
                    loading: false
                }));
            }
        } catch (error) {
            console.error('Error fetching order status:', error);
        }
    };
    const select_type_of_pet = (e) => {
        if (e != null) {
            var _pet_type = {
                pet_code: e.pet_code,
                pet_type: e.pet_type,
                bg_color: e.bg_color,
                btn_color: e.btn_color,
                bg_image: e.bg_image,
                name: e.name
            };
        }
        setState((prev) => ({
            ...prev,
            pet_option: _pet_type
        }))
    };
    const get_pet_proposal = (flag, val) => {
        setSelected((prev) => ({ ...prev, show_payment: flag }));
        if (val == 1) {
            send_confirmation_to_customer();
            /*var _data = { ...state };
            setShow((prev) => ({
                ...prev,
                loading: true,
            }))
            var response = paiApi.get_pai_proposal(_data.veh_order_no);
            response.then((res) => {
                const timer = setTimeout(() => {
                    setShow((prev) => ({ ...prev, loading: false }));
                }, 2000);
            })*/
        }
    }
    const send_confirmation_to_customer = () => {
        var _data = { ...state };
        setShow((prev) => ({ ...prev, loading: true }));
        const response = petApi.send_confirmation_to_customer(_data.veh_order_no);
        response.then((res) => {
            if (res != null) {
                if (res.code == 200) {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Thông tin",
                        content: "Gửi mail thành công",
                        is_show: true,
                        variant: "success"
                    }));
                }
            }
            const timer = setTimeout(() => {
                setShow((prev) => ({ ...prev, loading: false }));
            }, 2000);
        })
    }

    return (
        <>
            <>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol
                            className="breadcrumb"
                            style={{ fontWeight: '700' }}
                        >
                            <li className="breadcrumb-item">
                                <a href="/" style={{ textDecoration: 'none' }}>
                                    Trang chủ
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {_title}
                            </li>
                        </ol>
                    </nav>
                </div>
            </>
            <>
                <div
                    className='container'
                    style={{ marginTop: '32px' }}
                >
                    <div
                        className="title_section"
                        data-descr="Mua bảo hiểm thú cưng"
                        style={{ color: '#564741' }}
                    >
                        <span>
                            Mua bảo hiểm thú cưng
                        </span>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-sm-12'>
                            <div
                                className='position-relative'
                                style={{ width: "60%", margin: "auto" }}
                            >
                                <PetProgressBar
                                    onClick={() => { }}
                                    step={curr_step.code}
                                    user={user}
                                    paymentStatus={paymentStatus}
                                    paymentSuccess={paymentSuccess}
                                />
                            </div>
                        </div>
                    </div>
                    {curr_step.code === 1 && (
                        <>
                            <PetOption
                                onClick={next_step}
                                onSelect={select_type_of_pet}
                                list={pet_options}
                                pet_type_selected={state.pet_option}
                            />
                        </>
                    )}
                    {curr_step.code === 2 && (
                        <div
                            className='container panel-form mt-3 bg-white'
                            style={{
                                borderRadius: '16px',
                                border: '1px solid black',
                                boxShadow: '10px 10px rgb(131, 219, 193)',
                                padding: '16px'
                            }}
                        >
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div
                                        className='text-center mt-3'
                                        id='pet_pkg'
                                    >
                                        <h2 style={{ color: '#20c997' }}>
                                            CÁC GÓI BẢO HIỂM
                                        </h2>
                                    </div>
                                    <div className='mt-3'>
                                        <Packages
                                            onSelect={select_pkg}
                                            // list={pet_packages}
                                            list={petPackages}
                                            pkg_selected={state.pet_package}
                                            pet_option={state.pet_option}
                                            kind_of_pet={state.pet_option["pet_type"]}
                                        />
                                    </div>
                                    <div className='text-center mt-3'>
                                        <button
                                            className="btn btn-warning"
                                            onClick={back_step}
                                            style={{ borderRadius: '0px' }}
                                        >
                                            {"< QUAY LẠI"}
                                        </button>
                                        <button
                                            className="btn ms-1"
                                            style={{
                                                backgroundColor: 'rgb(0, 86, 145)',
                                                color: 'white',
                                                borderRadius: '0px'
                                            }}
                                            onClick={next_step}
                                        >
                                            {"TIẾP TỤC >"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {curr_step.code === 3 && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div
                                    className='panel-form bg-white'
                                    style={{
                                        border: '1px solid black',
                                        boxShadow: '10px 10px rgb(131, 219, 193)'
                                    }}
                                >
                                    <div
                                        className='text-center mt-3 mb-4'
                                        style={{ color: '#20c997' }}
                                    >
                                        <h2 className='fs-4'>
                                            YÊU CẦU BẢO HIỂM THÚ CƯNG
                                        </h2>
                                    </div>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <p>
                                                    <b>
                                                        <label
                                                            className="form-label fs-5 font-semibold"
                                                            style={{
                                                                borderLeft: '4px solid #20c997',
                                                                paddingLeft: '8px'
                                                            }}
                                                        >
                                                            BÊN MUA BẢO HIỂM
                                                        </label>
                                                    </b>
                                                </p>
                                                <p style={{ fontStyle: 'italic' }}>
                                                    <span style={{ color: 'red', fontWeight: '700' }}>
                                                        (*) Bên mua bảo hiểm
                                                    </span> đồng thời là Người được bảo hiểm (Chủ thú cưng)
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <label className="form-label required">
                                                        Bên mua bảo hiểm
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <TextInput
                                                            value={state.veh_customer}
                                                            name="veh_customer"
                                                            onChange={handle_change}
                                                            className={"ptb-16px " + (stateError.veh_customer ? "error" : "")}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.veh_customer}
                                                            msgType={stateErrorMsg.veh_customer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <label className="form-label required">
                                                        Ngày sinh
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <ShortDateInput
                                                            selected={dob}
                                                            minDate={minDOB}
                                                            maxDate={maxDOB}
                                                            onChange={change_dob}
                                                            className={stateError.dob ? "error" : ""}
                                                            disabled={
                                                                state.status !== 7
                                                                    && state.status !== 6
                                                                    && state.status !== 3
                                                                    ? false : true
                                                            }
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.dob}
                                                            msgType={stateErrorMsg.dob}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {state.veh_business === "N" && (
                                            <>
                                                <div className='row mt-2'>
                                                    <div className='col-sm-6'>
                                                        {/* <div className='row'>
                                                            <label className="form-label required">
                                                                Ngày sinh
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <ShortDateInput
                                                                    selected={dob}
                                                                    minDate={minDOB}
                                                                    maxDate={maxDOB}
                                                                    onChange={change_dob}
                                                                    className={stateError.dob ? "error" : ""}
                                                                    disabled={
                                                                        state.status !== 7
                                                                        && state.status !== 6
                                                                        && state.status !== 3
                                                                        ? false : true
                                                                    }
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.dob}
                                                                    msgType={stateErrorMsg.dob}
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='row d-none'>
                                                            <label className="form-label required">
                                                                Giới tính
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <div className='d-flex'>
                                                                    <div className='mr15px'>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="veh_gender"
                                                                                id={`radio-gender-1`}
                                                                                defaultValue={"1"}
                                                                                checked={state.veh_gender == 1}
                                                                                value="1"
                                                                                onChange={handle_radio_check}
                                                                                disabled={
                                                                                    state.status !== 7 
                                                                                    && state.status !== 6 
                                                                                    && state.status !== 3 
                                                                                    ? false : true
                                                                                }
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`radio-gender-1`}
                                                                            >
                                                                                Nam
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mr15px'>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="veh_gender"
                                                                                id={`radio-gender-2`}
                                                                                checked={state.veh_gender == 2}
                                                                                value="2"
                                                                                onChange={handle_radio_check}
                                                                                disabled={
                                                                                    state.status !== 7 
                                                                                    && state.status !== 6 
                                                                                    && state.status !== 3 
                                                                                    ? false : true
                                                                                }
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`radio-gender-2`}
                                                                            >
                                                                                Nữ
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <label className="form-label required">
                                                                Số CMND/ CCCD/ Hộ chiếu/ Mã số thuế
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <TextInput
                                                                    value={state.veh_customer_idcard}
                                                                    name="veh_customer_idcard"
                                                                    onChange={handle_change}
                                                                    onBlur={validate_idcard}
                                                                    className={"ptb-16px " + (stateError.veh_customer_idcard ? "error" : "")}
                                                                    disabled={
                                                                        state.status !== 7 
                                                                        && state.status !== 6 
                                                                        && state.status !== 3 
                                                                        ? false : true
                                                                    }
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.veh_customer_idcard}
                                                                    msgType={stateErrorMsg.veh_customer_idcard}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <label className='form-label required'>
                                                                Quốc tịch
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <AutoComplete
                                                                    id="veh_nation"
                                                                    options={lst_nation}
                                                                    onChange={change_nation}
                                                                    selected={nationalSel}
                                                                    placeholder={"Quốc tịch"}
                                                                    className={(stateError.veh_nation ? "error" : "")}
                                                                    disabled={
                                                                        state.status !== 7 
                                                                        && state.status !== 6 
                                                                        && state.status !== 3 
                                                                        ? false : true
                                                                    }
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.veh_nation}
                                                                    msgType={stateErrorMsg.veh_nation}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className='row mt-2'>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <label className='form-label required'>
                                                        Nghề nghiệp
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <AutoComplete
                                                            id="veh_occupation"
                                                            options={lst_occupation}
                                                            onChange={change_occupation}
                                                            selected={occupationSel}
                                                            placeholder={"Nghề nghiệp"}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                            // disabled={(["indoor", "outdoor"].indexOf(state.veh_job_type) >= 0)}
                                                            className={stateError.veh_occupation ? "error" : ""}
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.veh_occupation}
                                                            msgType={stateErrorMsg.veh_occupation}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <label className='form-label'>
                                                        Nghề nghiệp khác
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <TextInput
                                                            name="veh_job_desc"
                                                            value={state.veh_job_desc}
                                                            disabled={lockField.veh_job_desc}
                                                            onChange={handle_change}
                                                            className={stateError.veh_job_desc ? "error" : ""}
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.veh_job_desc}
                                                            msgType={stateErrorMsg.veh_job_desc}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <label className="form-label required">
                                                        Số điện thoại
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <TextInput
                                                            name="veh_phone"
                                                            value={CommonHelper.format_phone_number(state.veh_phone)}
                                                            onChange={handle_change}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                            // disabled={lockField.veh_phone}
                                                            className={"ptb-16px " + (stateError.veh_phone ? "error" : "")}
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.veh_phone}
                                                            msgType={stateErrorMsg.veh_phone}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <label className="form-label required">
                                                        Email
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <TextInput
                                                            name="veh_email"
                                                            value={state.veh_email}
                                                            onChange={handle_change}
                                                            onBlur={validate_email_format}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true}
                                                            // disabled={lockField.veh_email}
                                                            // disabled={user?.user_type 
                                                            //     && user.user_type > 1 
                                                            //     && state.status !== 7 
                                                            //     && state.status !== 6 
                                                            //     && state.status !== 3 
                                                            //     ? false : true
                                                            // }
                                                            className={"ptb-16px " + (stateError.veh_email ? "error" : "")}
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.veh_email}
                                                            msgType={stateErrorMsg.veh_email}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12'>
                                                <div className='row'>
                                                    <label className='form-label required'>
                                                        Địa chỉ liên hệ
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <div className='row'>
                                                            <div className="col-sm-6 mb-2">
                                                                <AutoComplete
                                                                    id="veh_prov"
                                                                    options={lst_prov}
                                                                    onChange={change_prov}
                                                                    selected={provSel}
                                                                    placeholder={"Tỉnh/Thành phố"}
                                                                    className={(stateError.veh_prov ? "error" : "")}
                                                                    disabled={
                                                                        state.status !== 7 
                                                                        && state.status !== 6 
                                                                        && state.status !== 3 
                                                                        ? false : true
                                                                    }
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.veh_prov}
                                                                    msgType={stateErrorMsg.veh_prov}
                                                                />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <AutoComplete
                                                                    id="veh_district"
                                                                    options={lst_dist}
                                                                    onChange={change_dist}
                                                                    selected={distSel}
                                                                    placeholder={"Quận/Huyện"}
                                                                    className={(stateError.veh_dist ? "error" : "")}
                                                                    disabled={
                                                                        state.status !== 7 
                                                                        && state.status !== 6 
                                                                        && state.status !== 3 
                                                                        ? false : true
                                                                    }
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.veh_dist}
                                                                    msgType={stateErrorMsg.veh_dist}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-sm-12'>
                                                <div className='col-sm-12 mt10px'>
                                                    <TextInput
                                                        name="veh_address"
                                                        value={state.veh_address}
                                                        placeholder="Số nhà, Tên đường"
                                                        onChange={handle_change}
                                                        disabled={
                                                            state.status !== 7 
                                                            && state.status !== 6 
                                                            && state.status !== 3 
                                                            ? false : true
                                                        }
                                                        // disabled={provSel.length < 1}
                                                        className={"ptb-16px " + (stateError.veh_address ? "error" : "")}
                                                    />
                                                    <ValidateMessage
                                                        isShow={stateError.veh_address}
                                                        msgType={stateErrorMsg.veh_address}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <label className="form-label required">
                                                Thời hạn bảo hiểm
                                            </label>
                                            <div className="col-sm-12">
                                                <div
                                                    className="float-start"
                                                    style={{ width: "49%" }}
                                                >
                                                    <DateInput
                                                        dateFormat={"dd/MM/yyyy HH:mm"}
                                                        selected={fm_dt}
                                                        minDate={addDays(new Date(), 1)}
                                                        maxDate={addMonths(new Date(), 2)}
                                                        onChange={change_fm_dt}
                                                        className={"ptb-16px " + (stateError.veh_fm_dt ? "error" : "")}
                                                        disabled={
                                                            state.status !== 7 
                                                            && state.status !== 6 
                                                            && state.status !== 3 
                                                            ? false : true
                                                        }
                                                    />
                                                </div>
                                                <div className="float-end" style={{ width: "49%" }}>
                                                    <DateInput
                                                        dateFormat={"dd/MM/yyyy HH:mm"}
                                                        selected={to_dt}
                                                        minDate={new Date()}
                                                        disabled
                                                        className={"ptb-16px "}
                                                    />
                                                </div>
                                                <div className="clearfix"></div>
                                                <ValidateMessage
                                                    isShow={stateError.veh_fm_dt}
                                                    msgType={"required"}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-sm-12'>
                                                <div className='mt-3'>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            id='veh_issue_invoice'
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="veh_issue_invoice"
                                                            checked={state.veh_issue_invoice}
                                                            onChange={handle_check}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor='veh_issue_invoice'
                                                        >
                                                            THÔNG TIN HÓA ĐƠN
                                                        </label>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className={"row " + (state.veh_issue_invoice ? "" : "d-none")}>
                                                    <div className="mt10px col-sm-6">
                                                        <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>
                                                            Mã số thuế
                                                        </label>
                                                        <TextInput
                                                            name="inv_tax"
                                                            value={state.inv_tax}
                                                            onChange={handle_change}
                                                            className={"ptb-16px " + (stateError.inv_tax ? "error" : "")}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.inv_tax}
                                                            msgType={stateErrorMsg.inv_tax}
                                                        />
                                                    </div>
                                                    <div className="mt10px col-sm-6">
                                                        <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>
                                                            Tên công ty
                                                        </label>
                                                        <TextInput
                                                            name="inv_company_name"
                                                            value={state.inv_company_name}
                                                            onChange={handle_change}
                                                            className={"ptb-16px " + (stateError.inv_company_name ? "error" : "")}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.inv_company_name}
                                                            msgType={stateErrorMsg.inv_company_name}
                                                        />
                                                    </div>
                                                    <div className="mt10px col-sm-12">
                                                        <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>
                                                            Địa chỉ công ty
                                                        </label>
                                                        <TextInput
                                                            name="inv_address"
                                                            value={state.inv_address}
                                                            onChange={handle_change}
                                                            className={"ptb-16px " + (stateError.inv_address ? "error" : "")}
                                                            disabled={
                                                                state.status !== 7 
                                                                && state.status !== 6 
                                                                && state.status !== 3 
                                                                ? false : true
                                                            }
                                                        />
                                                        <ValidateMessage
                                                            isShow={stateError.inv_address}
                                                            msgType={stateErrorMsg.inv_address}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='border-top border-2 border-dark'
                                            style={{ margin: "20px 0" }}
                                        >
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <p>
                                                    <b>
                                                        <label
                                                            className="form-label fs-5 font-semibold"
                                                            style={{
                                                                borderLeft: '4px solid #20c997',
                                                                paddingLeft: '8px'
                                                            }}
                                                        >
                                                            THÚ CƯNG ĐƯỢC BẢO HIỂM
                                                        </label>
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6 mt-2'>
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={new_pet}
                                                    style={{ borderRadius: '0px' }}
                                                    disabled={
                                                        state.status !== 7 
                                                        && state.status !== 6 
                                                        && state.status !== 3 
                                                        ? false : true
                                                    }
                                                >
                                                    <FaIcons.FaPlus /> Thêm thú cưng
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <PetInfo
                                                show={show_pet_info}
                                                data={state_pet}
                                                url={"#"}
                                                title={"THÚ CƯNG ĐƯỢC BẢO HIỂM"}
                                                onClosePet={handle_close_pet_info}
                                                listPet={state_lst_pet}
                                                masterData={state}
                                                // list_pkg={pet_packages}
                                                list_pkg={petPackages}
                                                kind_of_pet={state.pet_option["pet_type"]}
                                                setListIdImages={setListIdImages}
                                                setListImages={setListImages}
                                                handleTogglePetInfo={handleTogglePetInfo}
                                                handlePostForPetInfo={handlePostForPetInfo}
                                                setStatePet={setStatePet}
                                            />
                                        </div>
                                        <div className='row mt-3'>
                                            <InsuredPet
                                                dataPet={state_lst_pet}
                                                num_of_pax={state.veh_pax}
                                                hasActionPet={true}
                                                onEditPet={(modelPet) => { open_edit_pet(modelPet) }}
                                                onDelPet={(modelPet) => { open_delete_pet(modelPet) }}
                                                kind_of_pet={state.pet_option["pet_type"]}
                                                disabled={
                                                    state.status !== 7 
                                                    && state.status !== 6 
                                                    && state.status !== 3 
                                                    ? false : true
                                                }
                                            />
                                        </div>
                                        {
                                            !isAgentOrHandler && user != null && user.user_type == 2 ? (<>
                                                <div
                                                    className='border-top border-2 border-dark'
                                                    style={{ margin: "20px 0" }}
                                                >
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        <p>
                                                            <b>
                                                                <label
                                                                    className="form-label fs-5 font-semibold"
                                                                    style={{
                                                                        borderLeft: '4px solid #20c997',
                                                                        paddingLeft: '8px'
                                                                    }}
                                                                >
                                                                    THÔNG TIN NHÂN VIÊN NHẬP
                                                                </label>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-sm-12'>
                                                        {user != null && user.user_type == 2 && (
                                                            <div className="mt-3" >
                                                                <div className='container'>
                                                                    <div className="row mt-3">
                                                                        <label className="form-label col-sm-3">
                                                                            Kênh khai thác
                                                                        </label>
                                                                        <div className="col-sm-9">
                                                                            <AutoComplete
                                                                                id="veh_sob"
                                                                                options={lst_sob}
                                                                                onChange={change_sob}
                                                                                selected={sobSel}
                                                                                placeholder={"Kênh khai thác"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {user != null && user.user_type == 2 && lst_agency.length > 0 && (
                                                            <div className='mt-3'>
                                                                <div className='container'>
                                                                    <div className="row mt-3">
                                                                        <label className="form-label col-sm-3">
                                                                            Code đại lý
                                                                        </label>
                                                                        <div className="col-sm-9">
                                                                            <AutoComplete
                                                                                id="veh_agencies"
                                                                                options={lst_agency}
                                                                                onChange={(e) => { setSelected((prev) => ({ ...prev, agentSel: e })) }}
                                                                                selected={agentSel}
                                                                                placeholder={"Code đại lý"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>) : (<>
                                            </>)
                                        }
                                        {
                                            ((user.user_type === 1) && state.status === 1) && (<>
                                                <div
                                                    className='border-top border-2 border-dark'
                                                    style={{ margin: "20px 0" }}
                                                >
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        <p>
                                                            <b>
                                                                <label
                                                                    className="form-label fs-5 font-semibold"
                                                                    style={{
                                                                        borderLeft: '4px solid #20c997',
                                                                        paddingLeft: '8px'
                                                                    }}
                                                                >
                                                                    MÃ GIỚI THIỆU
                                                                </label>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-sm-6'>
                                                        <label className={"form-label"}>
                                                            Nhập mã giới thiệu
                                                        </label>
                                                        <TextInput
                                                            name="ins_promoter_code"
                                                            value={state.ins_promoter_code}
                                                            onChange={handle_change}
                                                            className={"ptb-16px "}
                                                            style={{ marginTop: '8px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </>)
                                        }
                                        <div className='row mt-5'>
                                            <div className='col-sm-12 text-center'>
                                                <button
                                                    className="btn btn-warning"
                                                    onClick={back_step}
                                                    style={{ borderRadius: '0px' }}
                                                    disabled={
                                                        state.status !== 7 
                                                        && state.status !== 6 
                                                        && state.status !== 3 
                                                        ? false : true
                                                    }
                                                >
                                                    {"< QUAY LẠI GÓI BẢO HIỂM"}
                                                </button>
                                                <button
                                                    className="btn btn-primary ms-1"
                                                    onClick={next_step}
                                                    style={{ borderRadius: '0px' }}
                                                >
                                                    {"TIẾP TỤC >"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {curr_step.code === 6 && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='mt-10 text-center'>
                                    <h2 style={{ fontSize: "15px" }}>
                                        BÊN MUA BẢO HIỂM
                                    </h2>
                                </div>
                                <div className='mt-10 panel-form bg-white'>
                                    <div className='container'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {curr_step.code >= 4 && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div
                                    className='mt-2 panel-form bg-white'
                                    style={{
                                        width: "900px",
                                        border: '1px solid black',
                                        boxShadow: '10px 10px rgb(131, 219, 193)'
                                    }}
                                >
                                    <div className='mt-3 text-center'>
                                        <h2
                                            className='fs-4'
                                            style={{ color: '#20c997' }}
                                        >
                                            XÁC NHẬN THÔNG TIN
                                        </h2>
                                    </div>
                                    <div className='container'>
                                        <PetConfirmation
                                            data={state}
                                            list_pet={state_lst_pet}
                                            user={user}
                                            onCheck={(e) => { setState((prev) => ({ ...prev, is_confirm: e })) }}
                                            onSendConfirmMail={() => { setSelected((prev) => ({ ...prev, show_payment: true })); }}
                                            onGetStatus={get_order_status}
                                            step_code={curr_step.code}
                                            res_post_order={state.res_post_order}
                                            paymentStatus={paymentStatus}
                                            paymentSuccess={paymentSuccess}
                                            // user_type={state.user_type}
                                            user_type={user && user.user_type ? user.user_type : ""}
                                            state_appraisal={state_appraisal}
                                            setStateAppraisal={setStateAppraisal}
                                        />
                                        <div className='text-center mt30px'>
                                            {
                                                paymentSuccess == false
                                                && <button
                                                    className="btn btn-warning"
                                                    disabled={state.aml_checked}
                                                    onClick={back_step}
                                                >
                                                    {"< QUAY LẠI"}
                                                </button>
                                            }
                                            {!paymentSuccess 
                                            && (((user.user_type === 1) && state.status === 1) 
                                            || ((user.user_type > 1) && state.status === 7)) 
                                            && state.res_post_order.allow_payment !== null 
                                            && state.aml_total_score < 70 
                                            && (
                                                <button
                                                    className="btn ms-1"
                                                    style={{ 
                                                        backgroundColor: 'rgb(0, 86, 145)', 
                                                        color: 'white' 
                                                    }}
                                                    disabled={user?.user_type 
                                                        && user.user_type > 1 
                                                        ? false : !state.is_confirm
                                                    }
                                                    onClick={next_step}
                                                >
                                                    {"THANH TOÁN"}
                                                </button>
                                            )}
                                            {
                                                (((state.status === 3 && state.res_post_order.allow_payment === null)
                                                    || (state.status === 3 && state.res_post_order.lst_reject != null))
                                                    && (<>
                                                        <button
                                                            className="btn ms-1"
                                                            style={{ 
                                                                backgroundColor: 'rgb(0, 86, 145)', 
                                                                color: 'white' 
                                                            }}
                                                            onClick={next_step}
                                                        >
                                                            {"YÊU CẦU THẨM ĐỊNH"}
                                                        </button>
                                                    </>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
            {loading && <Loading />}
            <AlertBox
                data={alertData}
                onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }}
            />
            <ModalPayment
                onClose={get_pet_proposal}
                show={show_payment}
            />
            <ModalReject
                onClose={(flag) => { setSelected((prev) => ({ ...prev, show_reject: flag })) }}
                isShow={show_reject} msg={msg_reject}
            />
            <PetPayment
                onClose={(flag) => { setSelected((prev) => ({ ...prev, show_pet_payment: flag })) }}
                isShow={show_pet_payment}
                show_payment_qr_code={show_payment_qr_code}
                show_payment_gw={show_payment_gw}
                show_order_no={show_order_no}
                updateState={setState}
            />
        </>
    )
}

export default PetInsurance;