import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider, NavLink, Outlet } from "react-router-dom";
import { Container, Row, Col, Offcanvas, Dropdown } from "react-bootstrap";
import * as FaIcon from "react-icons/fa";
import fbBanner from "./assets/images/logo.svg";
import ErrorPage from "./error-page";
import CtplMoto, { loader as CtplMotoLoader } from "./pages/ctpl/moto";
import CtplVehicle,
{ loader as CtplVehicleLoader } from "./pages/ctpl/vehicle";
import Login, { loader as loginLoader } from "./pages/login";
import vehicleApi from "./api/vehicleApi";
import Authentication from './api/authApi';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Register, { loader as regLoader } from "./pages/register";
import CommonHelper from "./helpers/common";
import Policy, { loader as policyLoader } from "./pages/policy";
import Quotation from "./pages/quotation";
import TransactionSuccess, {
  loader as TransactionSuccessLoader
} from "./pages/transaction/success";
import Order from "./pages/order";
import Home from "./pages/home";
import AgentLogin from "./pages/login/agent";
import EmployeeLogin from "./pages/login/employee";
import PaInsurance, { loader as PaInsuranceLoader } from "./pages/pai";
import FBGate from "./components/fb-gate";
//import routes from "./routes";
/*const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    element: <Home />,
    errorElement: <ErrorPage />,
    is_menu: false,
  },
  {
    path: "/dang-nhap",
    element: <Login />,
    errorElement: <ErrorPage />,
    loader: loginLoader,
    is_menu: false,
  },
  {
    path: "/dai-ly/dang-nhap",
    element: <AgentLogin />,
    errorElement: <ErrorPage />,
    is_menu: false,
  },
  {
    path: "/nhan-vien/dang-nhap",
    element: <EmployeeLogin />,
    errorElement: <ErrorPage />,
    is_menu: false,
  },
  {
    path: "/dang-ky",
    element: <Register />,
    errorElement: <ErrorPage />,
    loader: regLoader,
    is_menu: false,
  },
  {
    path: "/bao-gia-xe-co-gioi",
    element: <Quotation />,
    errorElement: <ErrorPage />,
    is_menu: false,
  },
  {
    path: "/danh-sach-hop-dong",
    element: <Policy />,
    errorElement: <ErrorPage />,
    loader: policyLoader,
    is_menu: false,
  },
  {
    path: "/sale/ctpl-moto",
    element: <CtplMoto />,
    errorElement: <ErrorPage />,
    loader: CtplMotoLoader,
    is_menu: true,
    title: "Bảo hiểm xe máy",
  },
  {
    path: "/sale/ctpl-moto/:id",
    element: <CtplMoto />,
    errorElement: <ErrorPage />,
    loader: CtplMotoLoader,
    is_menu: false,
  },
  {
    path: "/sale/ctpl-vehicle",
    element: <CtplVehicle />,
    errorElement: <ErrorPage />,
    loader: CtplVehicleLoader,
    is_menu: true,
    title: "Bảo hiểm ô tô",
  },
  {
    path: "/sale/ctpl-vehicle/:id",
    element: <CtplVehicle />,
    errorElement: <ErrorPage />,
    loader: CtplVehicleLoader,
    is_menu: false,
  },
  {
    path: "/sale/transaction",
    element: <TransactionSuccess />,
    errorElement: <ErrorPage />,
    loader: TransactionSuccessLoader,
    is_menu: false,
    title: "Bảo hiểm ô tô",
  },
  {
    path: "/sale/transaction/:id",
    element: <TransactionSuccess />,
    errorElement: <ErrorPage />,
    loader: TransactionSuccessLoader,
    is_menu: false,
  },
  {
    path: "/orders",
    element: <Order />,
    errorElement: <ErrorPage />,
    is_menu: false,
    title: "Quản lý đơn hàng",
  },
  {
    path: "/sale/pa-insurance",
    element: <PaInsurance />,
    errorElement: <ErrorPage />,
    is_menu: true,
    title: "Bảo hiểm tai nạn",
  },
  {
    path: "/sale/pa-insurance/:id",
    element: <PaInsurance />,
    errorElement: <ErrorPage />,
    loader: PaInsuranceLoader,
    is_menu: false,
    title: "Bảo hiểm tai nạn",
  },
]);
*/

const MenuBar = () => {
  var _routes = [

    {
      path: "/sale/ctpl-moto",
      is_menu: true,
      title: "Bảo hiểm xe máy",
    },
    {
      path: "/sale/ctpl-vehicle",
      is_menu: true,
      title: "Bảo hiểm ô tô",
    },
    {
      path: "/sale/pa-insurance",
      is_menu: true,
      title: "Bảo hiểm tai nạn",
    },
    {
      path: "/sale/travel-home",
      is_menu: true,
      title: "Bảo hiểm du lịch",
    },
    {
      path: "/sale/pet-home",
      is_menu: true,
      title: "Bảo hiểm thú cưng",
    },
  ];
  var curr_url = window.location.pathname;
  const lst = _routes.map((item, i) => {
    if (item.is_menu) {
      var _active = "";
      if (curr_url.indexOf(item.path) >= 0) {
        _active = " active";
      }
      return (
        <li className={"nav-item" + _active} key={i}>
          <a
            className={"nav-link "}
            href={item.path}
          >{item.title}</a>
        </li>
      );
    }
  })
  return (<ul className="navbar-nav">{lst}</ul>);
}

//https://fubon.secomm.vn/san-pham/bao-hiem-xe-co-gioi
function App() {
  const [state, setState] = useState(null);
  const [{ showFBGate }, setSelected] = useState({
    showFBGate: false,
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_user = () => {
    var data = Authentication.get_user();
    data.then((res) => {
      if (res.code === 506) {
        setState(null);
        CommonHelper.clear_storage_user();
      } else {
        var objUser = CommonHelper.get_user();
        objUser.then((e) => {
          var _url = window.location.pathname;
          if (e.user_type == 2) {
            window.location.href = _url != "/" ? _url.replace("sale", "fubon") : "/fubon/ctpl-moto";
          } else if (e.user_type == 3) {
            window.location.href = _url != "/" ? _url.replace("sale", "dai-ly") : "/dai-ly/ctpl-moto";
          }
          setState(e);
        })
      }
    })
  };
  useEffect(() => {
    Authentication.check_token_expired();
    get_user();
    //console.log(router.state.location.pathname)
    //console.log(router.routes);
  }, [])
  const log_out = () => {
    var response = Authentication.logout();
    response.then((res) => {
      window.location.href = "/";
    })
  }
  return (
    <div>
      <div className="bg-fubon">
        <div className="bg-fubon" style={{ height: "40px", marginBottom: "0px" }}>
          <Container fluid>
            <Row className="bg-fubon--gradient" style={{ padding: "5px 0", height: "40px" }}>
              <Col className="position-relative">
                <div className="position-absolute start-0" style={{ top: "3px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <FaIcon.FaEnvelope style={{ color: "#fff" }} /> &nbsp;
                        <a href="mailto:cskh.ins@fubon.com"
                          className="form-label"
                          style={{ color: "#fff", textDecoration: "none" }}>
                          cskh.ins@fubon.com
                        </a>
                        <a href="/tai-tai-lieu"
                          className="form-label"
                          style={{ color: "#fff", textDecoration: "none", marginLeft: '8px' }}>
                          | Tài liệu triển khai SPBH trên Môi trường mạng
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-absolute end-0">
                  <div className="container">
                    <div className="row">
                      {state === null && (
                        <div className="col" style={{ marginTop: "3px" }}>
                          <a onClick={() => { setSelected((prev) => ({ ...prev, showFBGate: true })) }} className="nav-link d-inline-block cursor-pointer" style={{ color: "#fff" }}>Đăng nhập</a>
                          <span style={{ color: "#fff" }}>&nbsp; | &nbsp;</span>
                          <a href='/dang-ky' className="nav-link d-inline-block" style={{ color: "#fff" }}>Đăng ký</a>
                        </div>
                      )}
                      {state !== null && (
                        <div className="col">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" variant={""}>
                              {/* <FaIcon.FaUser style={{ color: "#fff" }} /> */}
                              {"Xin chào, " + state.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="/danh-sach-hop-dong">Danh sách hợp đồng</Dropdown.Item>
                              <Dropdown.Item href="/bao-gia-xe-co-gioi" className="d-none">Báo giá xe cơ giới</Dropdown.Item>
                              <Dropdown.Item href="/thong-tin-ca-nhan">Cập nhật thông tin cá nhân</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item eventKey="4" onClick={log_out}>Đăng xuất</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className=" navbar-light bg-light">
              <Col>
                <nav className="navbar navbar-expand-lg" style={{}}>
                  <div className="container-fluid" style={{ padding: "0px" }}>
                    <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                      <img src={fbBanner} />
                    </a>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation" onClick={handleShow}>
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                      <MenuBar />
                    </div>
                  </div>
                </nav>
                <div className="clearfix"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="clearfix" style={{ marginTop: "75px" }}></div>
      <Container fluid>
        <div className="wrapper">
          <div>
            <Outlet />
          </div>
          <div className="push"></div>
        </div>
      </Container>
      <footer style={{ margin: "40px 0" }}>
        <div className="bg-fubon">
          <div className="bg-fubon--gradient" style={{ height: "4px" }}></div>
        </div>
        <Container >
          <Row>
            <div className="col-sm-12" style={{ paddingTop: "10px", paddingBottom: "10px", borderBottom: "2px solid #a1a1a1", marginBottom: "20px" }}>
              <a className="navbar-brand d-block" href="https://fubonins.com.vn/" target="_blank" style={{ marginTop: "20px" }}>
                <img src={fbBanner} />
              </a>
            </div>
          </Row>
          <Row>
            <div className="col-sm-4">
              <div>
                <h5>Trụ sở chính</h5>
              </div>
              <div>
                <a
                  className="nav-link "
                  href="https://goo.gl/maps/kRqRYbZQfKaWJvS28" target="_blank"
                >
                  Tầng 15, Tòa nhà Ree Tower, Số 9, Đường Đoàn Văn Bơ, Quận 4, TP.HCM
                </a>
              </div>
              <div>
                Điện thoại: <a href="tel:(84) 28 39435678">(84) 28 39435678</a>
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <h5>Chi nhánh Hà Nội</h5>
              </div>
              <div>
                <a
                  className="nav-link "
                  href="https://goo.gl/maps/3NcoyJdUBMHBwk7y6" target="_blank"
                >
                  Tầng 3, Tòa nhà Lakeside Green, Số 33 Trúc Bạch, Phường Trúc Bạch, Quận Ba Đình, Hà Nội
                </a>
              </div>
              <div>
                Điện thoại: <a href="tel:(84) 24 39347225">(84) 24 39347225</a>
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <h5>Chi nhánh Bình Dương</h5>
              </div>
              <div>
                <a
                  className="nav-link "
                  href="https://goo.gl/maps/xqktFArUDiGDhwhu7" target="_blank"
                >
                  Số 226, đường GS 01, Khu ĐT-TM-DV Quảng Trường Xanh, khu phố Nhị Đồng 2, phường Dĩ An, thành phố Dĩ An, tỉnh Bình Dương
                </a>
              </div>
              <div>
                Điện thoại: <a href="tel:(84) 274 3776531">(84) 274 3776531</a>
              </div>
            </div>
          </Row>
        </Container>
      </footer>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
              <img src={fbBanner} />
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <MenuBar />
        </Offcanvas.Body>
      </Offcanvas>
      {showFBGate && (<FBGate onClose={() => { setSelected((prev) => ({ ...prev, showFBGate: false })) }} type={"login"} />)}
    </div>
  );
}

export default App;
