import React, { useState, useEffect } from 'react';
import QAIcon from "../../../assets/images/qa-2.jpg"
import { Collapse } from 'react-bootstrap';
import { FaPlus, FaMinus } from "react-icons/fa";
import { faqData } from '../util';

function PtiQA() {
    const [openCollapseIndex, setOpenCollapseIndex] = useState(-1);
    useEffect(() => {
        setOpenCollapseIndex(0);
    }, []);

    const handleCollapseToggle = (index) => {
        setOpenCollapseIndex(index === openCollapseIndex ? -1 : index);
    };

    return (
        <>
            <section className="container">
                <div className="title_section" data-descr="Câu hỏi thường gặp" style={{ color: '#564741' }}>
                    <span>Câu hỏi thường gặp</span>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <img
                            className="b-lazy faq_img"
                            src={QAIcon}
                            alt='QA'
                            style={{ marginBottom: '16px' }}
                        />
                    </div>
                    <div className="col-lg-8 col-12">
                        <div id="accordion" style={{ fontSize: "16px" }}>
                            {faqData.map((faq, index) => (
                                <div
                                    className="card pd-5 pd-x-10 mg-b-15 collapse-hover"
                                    style={{ marginBottom: '16px' }}
                                    key={index}
                                >
                                    <div className="clearfix">
                                        <a
                                            style={{ marginTop: '8px', marginBottom: '8px', height: '40px' }}
                                            className="btn-collapse"
                                            onClick={() => handleCollapseToggle(index)}
                                        >
                                            <div
                                                className="float-left mg-6 mobile-collapse-question"
                                                style={{ verticalAlign: 'middle', marginTop: '8px', marginLeft: '8px', float: 'left !important' }}
                                            >
                                                <p 
                                                    className="numberCircle float-left"
                                                    style={{ marginTop: '-8px', marginRight: '8px', marginLeft: '8px', marginBottom: '32px', textAlign: 'center', justifyContent: 'center', float: 'left !important' }}
                                                >
                                                    {index + 1}
                                                </p>
                                                {faq.question}
                                            </div>
                                            <span className="float-right mg-8" style={{ float: 'right !important', margin: '8px' }}>
                                                {
                                                    index === openCollapseIndex ? (<>
                                                        <FaMinus style={{ fontSize: '16px', marginRight: '16px' }} />
                                                    </>) : (<>
                                                        <FaPlus style={{ fontSize: '16px', marginRight: '16px' }} />
                                                    </>)
                                                }
                                            </span>
                                        </a>
                                    </div>
                                    <Collapse in={index === openCollapseIndex}>
                                        <div
                                            className="card-header mg-b-15 mobile-collapse-answer"
                                            style={{ border: "none", marginTop: '8px', height: '40px' }}
                                        >
                                            {faq.answer.split('\n').map((line, i) => (
                                                <React.Fragment key={i}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PtiQA;