import React, { useState, useRef, useEffect } from 'react';
import CommonHelper from '../../../helpers/common';
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import ptiApi from '../../../api/ptiApi';
import TextInput from '../../../components/text-input';
import ShortDateInput from '../../../components/short-date-input';
import AlertBox from '../../../components/alert';
import Nationality from '../../../nationality';
import AutoComplete from '../../../components/auto-complete';
import ValidateMessage from '../../../components/validate-msg';
import { initialAlert } from '../util';
import { CiCircleRemove } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";

const data = [
    {
        "full_name": "Trần Văn Chương",
        "gender": "1",
        "id_card": "023456789",
        "dob": "20060412",
        "relationship": "5",
        "package": "PAIPKG04",
        "nationality": [
            {
                "code": "VT",
                "name": "VIETNAM"
            }
        ],
        // "occupation": [
        //     {
        //         "code": "100001",
        //         "name": "Bác sĩ "
        //     }
        // ],
        "error": false,
        "err_msg": ""
    },
    {
        "full_name": "Nguyễn Ngọc Ánh",
        "gender": "2",
        "id_card": "089189001457",
        "dob": "19890202",
        "relationship": "2",
        "package": "PAIPKG04",
        "nationality": [
            {
                "code": "VT",
                "name": "VIETNAM"
            }
        ],
        // "occupation": [
        //     {
        //         "code": "100001",
        //         "name": "Bác sĩ "
        //     }
        // ],
        "error": false,
        "err_msg": ""
    }
]

const lst_relationship = [
    { 'code': '1', 'name': 'Bản thân' },
    { 'code': '2', 'name': 'Vợ/Chồng' },
    { 'code': '3', 'name': 'Con cái' },
    { 'code': '4', 'name': 'Bố/Mẹ' },
    { 'code': '5', 'name': 'Anh/Chị/Em Ruột' },
    { 'code': '6', 'name': 'Người có quan hệ nuôi dưỡng, cấp dưỡng' },
];

function PtiModalUpload(props) {
    const [alertData, setAlertData] = useState(initialAlert);
    const [filterList, setFilterList] = useState([]);
    const { onClose, onLoading, model, show,
        ptiPackages,
        pkg_selected,
        survey_questions_upload,
        setSurveyQuestionsUpload,
        handleNoteChange,
        ...other } = props;
    const [{ lst_data, lst_pkg, lst_occ, lst_nation, lst_ins_com, lst_purpose, lst_subclass,
        survey_questions }, setList] = useState({
            lst_data: [],
            lst_pkg: other.packages,
            lst_occ: [],
            lst_nation: [],
            lst_ins_com: [],
            lst_purpose: [],
            lst_subclass: [],
            survey_questions: [],
        });
    const [{ arr_pur, arr_subclass, comSel, note_PAI, note_FFC, note_ques_1 }, setSelected] = useState({
        arr_pur: [], arr_subclass: [], comSel: [], note_PAI: "", note_FFC: "", note_ques_1: "",
    })
    const [state, setState] = useState(null);
    useEffect(() => {
        if (show) {
            var lst_ins_com = [];
            var lst_purpose = [];
            var lst_subclass = [];
            var survey_questions = [];
            var lst_question = other.list_survey_question;
            if (lst_question.length > 0) {
                lst_question.forEach((item) => {
                    survey_questions.push({
                        answer_yn: item.template.answer_yn,
                        code: item.code,
                        question: item.question,
                        question_en: item.question_en,
                        list: item.template.list,
                        note: item.template.note,
                    });
                    if (item.code == "PTI_QUES_1") {
                        lst_ins_com = item.list;
                    } else if (item.code == "PTI_QUES_3") {
                        lst_purpose = item.list;
                    } else if (item.code == "PTI_QUES_2") {
                        lst_subclass = item.list;
                    }
                })
            }
            setList((prev) => ({
                ...prev,
                lst_occ: other.occupations,
                lst_nation: other.nationalities,
                lst_ins_com, lst_purpose, lst_subclass, survey_questions
            }))
            if (data.length > 0) {
                // render_data(data);
            }
            setState(model);
        }
    }, [show])
    useEffect(() => {
        var _lst = [...survey_questions];
        if (_lst.length > 0) {
            _lst.forEach((x) => {
                if (x.code == "PTI_QUES_1") {
                    x.note = note_ques_1
                    x.list = JSON.stringify(comSel)
                } else if (x.code == "PTI_QUES_2") {
                    var _arr_note = [];
                    if (arr_subclass.indexOf("PAI") >= 0) {
                        _arr_note.push({ "code": "PAI", "name": note_PAI })
                    }
                    if (arr_subclass.indexOf("FFC") >= 0) {
                        _arr_note.push({ "code": "FFC", "name": note_FFC })
                    }
                    x.list = JSON.stringify(_arr_note);
                } else if (x.code == "PTI_QUES_3") {
                    x.list = JSON.stringify([...arr_pur]);
                }
            })
            setList((prev) => ({ ...prev, survey_questions: _lst }));
        }
    }, [comSel, note_ques_1, arr_subclass, note_PAI, note_FFC, arr_pur]);
    const fileUpload = useRef(null);
    const uploadExcel = (e) => {
        const { name, files } = e.target;
        if (files != null && files.length > 0) {
            // var pti_pakage = state.pti_package;
            var pti_pakage = pkg_selected;
            var pkg_code = "";
            if (pti_pakage != null) {
                pkg_code = pti_pakage.pkg_code;
            }
            var _data = {
                file: files[0],
                veh_business: state.veh_business,
                lst_data,
                pkg_code
            };
            onLoading(true);
            var response = ptiApi.import_insured_person(_data);
            response.then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200 && res.data.data != null) {
                        render_data(res.data.data);
                    }
                }
                const timer = setTimeout(() => {
                    onLoading(false);
                    if (fileUpload.current) {
                        fileUpload.current.value = "";
                        fileUpload.current.type = "text";
                        fileUpload.current.type = "file";
                    }
                }, 2000);
            })
        }
    };
    const get_template = () => {
        onLoading(true);
        var response = ptiApi.get_excel_template();
        response.then((res) => {
            const timer = setTimeout(() => {
                onLoading(false);
            }, 2000);
        })
    }
    const handleUpload = () => {
        fileUpload.current.click()
    };
    const render_data = (data) => {
        var lst = [];
        data.forEach((item, i) => {
            var premium = 0;
            var _package = '';
            var nationName = '';
            var nationality = [];
            // var occupationName = "";
            // var occupation = [];
            var relationship = CommonHelper.is_empty(item.relationship) ? "0" : item.relationship;
            var relationshipName = "";
            var err_msg = "";
            var error = false;
            var objPackage = null;
            var insPackage = "";
            if (ptiPackages && ptiPackages.length > 0) {
                // var _objPkg = [...ptiPackages].filter(x => x.pkg_code === item.pkg_code);
                var _objPkg = pkg_selected;
                if (_objPkg != null) {
                    premium = _objPkg.premium;
                    _package = _objPkg.pkg_name;
                    objPackage = _objPkg;
                    insPackage = JSON.stringify(objPackage);
                }
            }
            var _objRelationship = lst_relationship.filter(x => x.code == item.relationship);
            if (_objRelationship != null && _objRelationship.length > 0) {
                relationshipName = _objRelationship[0].name;
            }
            if (item.nationality != null && item.nationality.length > 0) {
                nationality = item.nationality;
                nationName = item.nationality[0].name
            }
            // if (item.occupation != null && item.occupation.length > 0) {
            //     occupationName = item.occupation[0].name
            //     occupation = item.occupation;
            // }
            if (CommonHelper.is_not_empty(item.id_card)) {
                if (!CommonHelper.check_idcard_valid(item.id_card)) {
                    error = true;
                    err_msg = "id_card";
                }
            }
            if (CommonHelper.is_not_empty(item.id_card)) {
                var _person = {
                    fullName: item.full_name,
                    idCard: item.id_card,
                    gender: item.gender,
                    package: _package,
                    dob: item.dob,
                    insPackage: insPackage,
                    objPackage: objPackage,
                    premium: premium,
                    nationName: nationName,
                    nationality: nationality,
                    // occupationName: occupationName,
                    // occupation: occupation,
                    relationship: relationship,
                    relationshipName: relationshipName,
                    survey_questions: [...survey_questions],
                    error: error,
                    err_msg: err_msg,
                };
                lst.push(_person);
            }

        })
        setList((prev) => ({
            ...prev,
            lst_data: lst,
        }))
    }
    const handle_save = () => {
        var lst = [...lst_data];
        if (lst != null && lst.length > 0) {
            if (lst && lst.length > 0) {
                var check_flag = true;
                let adultCount = lst.filter(item => CommonHelper.calculate_age(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd")) >= 18).length;
                let minorCount = lst.length - adultCount; // number of people younger than 18 years old

                for (let i = 0; i < lst.length; i++) {

                    // Check current person's age
                    if (lst.length > 2 && (adultCount > 2) && state.ins_pax_type == 2) {
                        // If the list has >= 2 people and 2 people are >= 18 years old
                        // alert("Gói gia đình tối đa 2 người lớn, 2 trẻ em!");
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Lỗi",
                            content: "Gói gia đình tối đa 2 người lớn, 2 trẻ em!",
                            is_show: true,
                            variant: "danger"
                        }))
                        check_flag = false;
                        break;
                    }
                    if (lst.length > 2 && (minorCount) > 2 && state.ins_pax_type == 2) {
                        // If the list has >= 2 people and 2 people are >= 18 years old
                        // alert("Gói gia đình tối đa 2 người lớn, 2 trẻ em!");
                        setAlertData((prev) => ({
                            ...prev,
                            title: "Lỗi",
                            content: "Gói gia đình tối đa 2 người lớn, 2 trẻ em!",
                            is_show: true,
                            variant: "danger"
                        }))
                        check_flag = false;
                        break;
                    }
                }
                if (check_flag) {
                    onClose(false, lst);
                }
            }
            // onClose(false, lst);
        }
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        setSelected((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleSubclassChange = (e) => {
        const { checked, value } = e.target;
        var _arr = [...arr_subclass]
        var _note_pai = note_PAI;
        var _note_ffc = note_FFC;
        if (checked) {
            _arr.push(value);
        } else {
            var _idx = _arr.indexOf(value);
            _arr.splice(_idx, 1);
            if (value == "PAI") {
                _note_pai = "";
            } else if (value == "FFC") {
                _note_ffc = "";
            }
        }
        setSelected((prev) => ({
            ...prev,
            arr_subclass: _arr,
            note_PAI: _note_pai,
            note_FFC: _note_ffc
        }));
    }
    const handleAnswerChange = (obj, e) => {
        const { value, checked } = e.target;
        if (obj != null) {
            var _lst = [...survey_questions];
            var _idx = _lst.findIndex(x => x.code == obj.code);
            if (_idx >= 0) {
                _lst[_idx].answer_yn = value;
            }
            setList((prev) => ({ ...prev, survey_questions: _lst }));
        }
    };
    const handlePurposeChange = (e) => {
        const { checked, value } = e.target;
        var _arr = [...arr_pur]
        if (checked) {
            _arr.push(value);
        } else {
            var _idx = _arr.indexOf(value);
            _arr.splice(_idx, 1);
        }
        setSelected((prev) => ({ ...prev, arr_pur: _arr }));
    };
    const handleDeletePerson = (delItem) => {
        const filteredByPersonId = lst_data.filter(person => person !== delItem);
        setFilterList(filteredByPersonId);
    }
    useEffect(() => {
        setList((prev) => ({
            ...prev,
            lst_data: filterList,
        }));
    }, [filterList])
    return (
        <>
            <Modal
                show={show}
                onHide={() => { onClose(false, 0); }}
                backdrop="static"
                dialogClassName='modal-70w'
                keyboard={false}
            >
                <Modal.Header closeButton onClick={() => { onClose(false, 0); }}>
                    <Modal.Title>Upload danh sách người được bảo hiểm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container' style={{ maxHeight: "750px", overflowY: "auto" }}>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <table className='table table-bordered'>
                                    <tbody>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td style={{ width: "70px" }}>Bước 1</td>
                                            <td>Tải (download) file template</td>
                                            <td className='text-center' style={{ width: "165px" }}>
                                                <button type="button" className="btn btn-outline-dark" onClick={get_template} style={{ borderRadius: '0px' }}><FaIcons.FaDownload /> Tải file xuống</button>
                                            </td>
                                        </tr>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td style={{ width: "70px" }}>Bước 2</td>
                                            <td colSpan={2}>Nhập thông tin</td>
                                        </tr>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td style={{ width: "70px" }}>Bước 3</td>
                                            <td>Tải (upload) file template</td>
                                            <td style={{ width: "165px" }}>
                                                <div className='position-relative text-center'>
                                                    <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '0px' }} onClick={handleUpload} ><FaIcons.FaUpload /> Tải file</button>
                                                    <input
                                                        type="file"
                                                        ref={fileUpload}
                                                        onChange={uploadExcel}
                                                        style={{ opacity: "0" }}
                                                        className="position-absolute w-100"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {lst_data.length > 0 && (
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className='col-sm-12' style={{ height: "400px", overflowY: "scroll" }}>
                                                        <table className='table table-bordered'>
                                                            <thead style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white' }}>
                                                                <tr style={{ verticalAlign: 'middle' }}>
                                                                    <th style={{ width: "5%" }} className='text-center'>#</th>
                                                                    <th style={{ width: "20%" }} className='text-center'>Họ tên</th>
                                                                    <th style={{ width: "10%" }} className='text-center'>Giới tính</th>
                                                                    <th style={{ width: "6%" }} className='text-center'>Ngày sinh</th>
                                                                    <th style={{ width: "15%" }} className='text-center'>Số CMND/ CCCD/ Định danh/ Hộ chiếu </th>
                                                                    <th style={{ width: "8%" }} className='text-center'>Quốc tịch</th>
                                                                    <th style={{ width: "8%" }} className='text-center d-none'>Công việc</th>
                                                                    <th style={{ width: "10%" }} className='text-center d-none'>Quan hệ</th>
                                                                    <th style={{ width: "8%" }} className='text-center'>Gói bảo hiểm</th>
                                                                    <th style={{ width: "10%" }} className='text-center d-none'>Phí</th>
                                                                    <th style={{ width: "5%" }} className='text-center'>Xóa</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className='d-none'>
                                                                    <td colSpan="11" className="text-center" style={{ backgroundColor: 'orange', color: 'white' }}>
                                                                        <button
                                                                            // onClick={new_person} 
                                                                            className="btn"
                                                                            style={{ width: "100%", backgroundColor: 'orange', color: 'white', fontWeight: '700' }}
                                                                        >
                                                                            <FaPlus style={{ marginRight: '8px' }}></FaPlus>
                                                                            Thêm người được bảo hiểm
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                {lst_data.map((item, i) => (
                                                                    <tr key={i} style={{ verticalAlign: 'middle' }}>
                                                                        <td className='text-end'>{i + 1}</td>
                                                                        <td>{item.fullName}</td>
                                                                        <td>{item.gender == "1" ? "Nam" : "Nữ"}</td>
                                                                        <td className={CommonHelper.is_empty(item.fullName) ? "error" : ""}>{CommonHelper.convertToDDMMYYYY(item.dob)}</td>
                                                                        <td className={(item.error && item.err_msg == "id_card") ? "error" : ""}>{item.idCard}</td>
                                                                        <td className={(item.error && item.err_msg == "nationality") ? "error" : ""}>{item.nationName}</td>
                                                                        {/* <td className={(item.error && item.err_msg == "occupation") ? "error" : ""}>{item.occupationName}</td> */}
                                                                        <td className={(item.error && item.err_msg == "relationship") ? "error d-none" : "d-none"}>{item.relationshipName}</td>
                                                                        <td className={(item.error && item.err_msg == "package") ? "error" : ""}>{item.package}</td>
                                                                        <td className='text-end d-none'>{CommonHelper.format_currency_vnd_style(item.premium)}</td>
                                                                        <td className='text-center' style={{ color: 'red', fontSize: '30px' }}><CiCircleRemove style={{ cursor: 'pointer' }} onClick={() => handleDeletePerson(item)} /></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td style={{ width: "70px" }}>Bước 4</td>
                                            <td colSpan={2}>
                                                Trả lời khảo sát
                                                <div className="row ml-4 mt-1" style={{ maxWidth: '1160px', marginLeft: '4px' }}>
                                                    {survey_questions.length > 0 ? (
                                                        survey_questions.map((item, index) => (
                                                            <div className="card mb-3" key={item.code}>
                                                                <div className="card-body">
                                                                    <p className="card-text font-weight-bold">
                                                                        {index + 1}. {item.question}
                                                                    </p>
                                                                    {item.code == "PTI_QUES_1" && (
                                                                        <>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name={`question_${index}`}
                                                                                    id={`question_${index}_yes`}
                                                                                    value="Y"
                                                                                    checked={item.answer_yn === 'Y'}
                                                                                    onClick={(e) => handleAnswerChange(item, e)} />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={`question_${index}_yes`} >
                                                                                    Có
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name={`question_${index}`}
                                                                                    id={`question_${index}_no`}
                                                                                    value="N"
                                                                                    checked={item.answer_yn === 'N'}
                                                                                    onClick={(e) => handleAnswerChange(item, e)} />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={`question_${index}_no`} >
                                                                                    Không
                                                                                </label>
                                                                            </div>
                                                                            {item.answer_yn === 'Y' && (
                                                                                <div className="mt-3">
                                                                                    <div className='row'>
                                                                                        <div className='col-sm-7'>
                                                                                            <label className='form-label'>Công ty bảo hiểm</label>
                                                                                            <AutoComplete
                                                                                                id="sel_ins_com"
                                                                                                options={lst_ins_com}
                                                                                                onChange={(e) => { setSelected((prev) => ({ ...prev, comSel: e })) }}
                                                                                                selected={comSel}
                                                                                                placeholder={"Công ty bảo hiểm"} />
                                                                                        </div>
                                                                                        <div className='col-sm-5'>
                                                                                            <label className="form-label">Số tiền bảo hiểm</label>
                                                                                            <TextInput
                                                                                                value={note_ques_1}
                                                                                                name="note_ques_1"
                                                                                                onChange={handle_change}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {item.code === 'PTI_QUES_3' && (
                                                                        <div className="checkbox-group">
                                                                            {lst_purpose.length > 0 && (
                                                                                <>
                                                                                    {lst_purpose.map((item, i) => (
                                                                                        <div className="form-check form-check-inline" key={i}>
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                name={`purpose_${index}`}
                                                                                                id={`purpose_${item.code}`}
                                                                                                value={item.code}
                                                                                                onClick={(e) => { handlePurposeChange(e) }}
                                                                                                checked={arr_pur.indexOf(item.code) >= 0}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor={`purpose_${item.code}`}
                                                                                            >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {item.code === 'PTI_QUES_2' && (
                                                                        <>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name={`question_2_${index}`}
                                                                                    id={`question_2_${index}_yes`}
                                                                                    value="Y"
                                                                                    checked={item.answer_yn === 'Y'}
                                                                                    onChange={(e) => handleAnswerChange(item, e)} />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={`question_2_${index}_yes`} >
                                                                                    Có
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name={`question_2_${index}`}
                                                                                    id={`question_2_${index}_no`}
                                                                                    value="N"
                                                                                    checked={item.answer_yn === 'N'}
                                                                                    onChange={(e) => handleAnswerChange(item, e)} />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={`question_2_${index}_no`} >
                                                                                    Không
                                                                                </label>
                                                                            </div>
                                                                            {lst_subclass.length > 0 && item.answer_yn == "Y" && (
                                                                                <>
                                                                                    {lst_subclass.map((subItem, i) => (
                                                                                        <div className='col-sm-12 mt-2'>
                                                                                            <div className='row'>
                                                                                                <div className='col-sm-6'>
                                                                                                    <div className="checkbox-group">
                                                                                                        <div className="form-check form-check-inline" key={i}>
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                name={`subclass_${subItem.code}`}
                                                                                                                id={`subclass_${subItem.code}`}
                                                                                                                value={subItem.code}
                                                                                                                onClick={(e) => { handleSubclassChange(e) }}
                                                                                                                checked={arr_subclass.indexOf(subItem.code) >= 0} />
                                                                                                            <label className="form-check-label"
                                                                                                                htmlFor={`subclass_${subItem.code}`} >
                                                                                                                {subItem.name}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-sm-6'>
                                                                                                    <label className="form-label">Số tiền bảo hiểm</label>
                                                                                                    {subItem.code == "PAI" && (
                                                                                                        <TextInput
                                                                                                            value={note_PAI}
                                                                                                            name={`note_${subItem.code}`}
                                                                                                            disabled={arr_subclass.indexOf(subItem.code) < 0}
                                                                                                            onChange={handle_change}
                                                                                                        />
                                                                                                    )}
                                                                                                    {subItem.code == "FFC" && (
                                                                                                        <TextInput
                                                                                                            value={note_FFC}
                                                                                                            name={`note_${subItem.code}`}
                                                                                                            disabled={arr_subclass.indexOf(subItem.code) < 0}
                                                                                                            onChange={handle_change}
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>Không có câu hỏi khảo sát nào</p>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-sm-12 text-center mb-4 mr-4'>
                                <button type='button' className='btn btn-primary' style={{ marginRight: "20px", backgroundColor: 'rgb(32, 201, 151)', color: 'white', border: 'none', borderRadius: '0px', fontWeight: '700' }} onClick={handle_save}>Lưu thông tin</button>
                                <button type='button' className='btn btn-secondary' style={{ borderRadius: '0px', marginRight: '16px' }} onClick={() => { onClose(false, 0); }}>Huỷ bỏ</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <AlertBox
                data={alertData}
                onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }}
            />
        </>
    )
}

export default PtiModalUpload;
