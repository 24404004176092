import React, { useState, useEffect } from 'react'
import fbBanner from "../../../../assets/images/logo.svg";
import CommonHelper from '../../../../helpers/common';
import { Modal, Button, Spinner } from "react-bootstrap";
import TextInput from '../../../../components/text-input';
import ShortDateInput from '../../../../components/short-date-input';
import AlertBox from '../../../../components/alert';
import trainingApi from '../../../../api/trainingApi';
import { Link } from 'react-router-dom';
import file from "../../../../assets/doc/THU DONG Y XU LY.pdf";
import "./register-agent-exam.css";
import OnlineApi from '../../../../api/onlineApi';
import addYears from "date-fns/addYears";
import { addDays, addMonths } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import UploadAgentList from './component/upload-agent-reg-info';
import NoImage from '../../../../assets/images/gallery.png';
import { init_place_of_issue } from '../../util/init';

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const initial = {
    fullname: "",
    dob: "",
    sex: "",
    identity: "",
    date_of_issue: "",
    place_of_issue: "",
    address: "",
    phone: "",
    email: "",
    exam_purpose: 1,
    organization: "",
    exam_time: "",
    exam_place: "",
};

const initialUpload = {
    fullName: "",
    identityNo: "",
    identityFrontImage: null,
    identityBackImage: null,
};

function RegisterAgentExam() {
    const [state, setState] = useState(initial);
    const [stateUpload, setStateUpload] = useState(initialUpload);
    const [alertData, setAlertData] = useState(initialAlert);
    const [stateError, setStateError] = useState({
        fullname: false,
        dob: false,
        sex: false,
        identity: false,
        date_of_issue: false,
        place_of_issue: false,
        email: false,
        // exam_purpose: false,
        identityFrontImage: false,
        identityBackImage: false,
        address: false,
        phone: false,
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [{ dob, minDOB, maxDOB, exam_time, minExamTime, maxExamTime, doi, minDOI, maxDOI }, setSelected] = useState({
        dob: null,
        minDOB: addYears(new Date(), -65),
        maxDOB: addYears(new Date(), -18),
        exam_time: null,
        minExamTime: addMonths(new Date(), 1),
        maxExamTime: addMonths(new Date(), 6),
        minDOI: addYears(new Date(), -10),
        maxDOI: new Date(),
    })
    const [startDate, setStartDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [frontImage, setFrontImage] = useState(NoImage);
    const [backImage, setBackImage] = useState(NoImage);
    const [place_of_issue_list, setPlaceOfIssueList] = useState(init_place_of_issue);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (stateUpload.identityFrontImage) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setFrontImage(event.target.result);
            };
            reader.readAsDataURL(stateUpload.identityFrontImage);
        } else {
            setFrontImage(NoImage);
        }

        if (stateUpload.identityBackImage) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setBackImage(event.target.result);
            };
            reader.readAsDataURL(stateUpload.identityBackImage);
        } else {
            setBackImage(NoImage);
        }
    }, [stateUpload.identityFrontImage, stateUpload.identityBackImage]);

    const getMinDate = () => {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 2);
    };

    const getMaxDate = () => {
        const today = new Date();
        return new Date(today.getFullYear(), 11, 31);
    };

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await OnlineApi.get_provinces();
                if (response) {
                    setProvinces(response);
                }
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        };
        fetchProvinces();
    }, []);

    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        setState((prevData) => ({ ...prevData, [name]: _val }));
        setStateUpload((prevData) => ({ ...prevData, [name]: _val }));
        if (_val !== null && _val !== undefined) {
            setStateError((prev) => ({ ...prev, [name]: false }))
        }
    };

    const handleImageChange = (e, name) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setStateUpload((prevData) => ({ ...prevData, [name]: file }));
            setStateError((prev) => ({ ...prev, [name]: false }));
        } else {
            setStateUpload((prevData) => ({ ...prevData, [name]: null }));
        }
    };

    const validate_form = () => {
        var flag = true;
        var _data = { ...state };
        let errors = {};
        if (CommonHelper.is_empty(_data.fullname)) {
            flag = false;
            errors.fullname = true;
        } else if (CommonHelper.is_empty(_data.dob)) {
            flag = false;
            errors.dob = true;
        } else if (CommonHelper.is_empty(_data.sex)) {
            flag = false;
            errors.sex = true;
        } else if (CommonHelper.is_empty(_data.identity)) {
            flag = false;
            errors.identity = true;
        } else if (!CommonHelper.check_idcard_valid(_data.identity)) {
            flag = false;
            errors.identity = true;
        } else if (CommonHelper.is_empty(_data.date_of_issue)) {
            flag = false;
            errors.date_of_issue = true;
        } else if (CommonHelper.is_empty(_data.place_of_issue)) {
            flag = false;
            errors.place_of_issue = true;
        } else if (!CommonHelper.is_empty(_data.email) && !CommonHelper.validate_email_format(_data.email)) {
            flag = false;
            errors.email = true;
        } else if (CommonHelper.is_empty(_data.address)) {
            flag = false;
            errors.address = true;
        } else if (CommonHelper.is_empty(_data.phone)) {
            flag = false;
            errors.phone = true;
        } else if (!stateUpload.identityFrontImage) {
            flag = false;
            errors.identityFrontImage = true;
        }
        else if (!stateUpload.identityBackImage) {
            flag = false;
            errors.identityBackImage = true;
        }
        else {
            errors.fullname = false;
        }
        setStateError((prev) => ({ ...prev, ...errors }));
        return flag;
    }

    const handleRegister = async (state) => {
        try {
            setIsLoading(true);
            const _data = { ...state };
            _data.sex = (state.sex === "true");
            _data.dob = CommonHelper.formatToYYYYMMDD(state.dob);
            _data.date_of_issue = CommonHelper.formatToYYYYMMDD(state.date_of_issue);
            _data.exam_purpose = (_data.exam_purpose == 1) ? "Làm đại lý bảo hiểm cá nhân" : "Làm đại lý viên của tổ chức";
            if (state.exam_time) {
                _data.exam_time = CommonHelper.formatToYYYYMMDD(state.exam_time);
            }

            const response = await trainingApi.post_agent_reg_info(JSON.stringify(_data));
            if (response.code === 200) {
                return response;
            } else {
                console.error("Invalid response format", response);
                return null;
            }
        } catch (error) {
            console.error("Error during post_agent_reg_info API call:", error);
            return null;
        } finally {
            setIsLoading(false); // Tắt trạng thái loading
        }
    };

    const handleUpload = async (data) => {
        try {
            const response = await trainingApi.post_agent_identity_image(data);
            return response;
        } catch (error) {
            console.error("Error during post_agent_id_image API call:", error);
            return null;
        }
    };

    const submit = async () => {
        const flag = validate_form();
        const { fullname, dob, sex, identity, date_of_issue, place_of_issue, address, phone } = stateError;
        const { identityFrontImage, identityBackImage } = stateUpload;

        if (!flag) {
            if (fullname || dob || sex || identity || date_of_issue || place_of_issue || address || phone) {
                setAlertData({
                    title: "Lỗi",
                    content: "Vui lòng nhập đầy đủ thông tin đăng ký!",
                    is_show: true,
                    variant: "danger",
                });
            }
            if (identity) {
                setAlertData({
                    title: "Cảnh báo",
                    content: "Vui lòng nhập đúng số CMTND quy định (CMND: 9 số, CCCD: 12 số)!",
                    is_show: true,
                    variant: "warning",
                });
            } else if (!identityFrontImage) {
                setAlertData({
                    title: "Cảnh báo",
                    content: "Chưa chọn ảnh CMTND/Hộ chiếu mặt trước",
                    is_show: true,
                    variant: "warning",
                });
            } else if (!identityBackImage) {
                setAlertData({
                    title: "Cảnh báo",
                    content: "Chưa chọn ảnh CMTND/Hộ chiếu mặt sau",
                    is_show: true,
                    variant: "warning",
                });
            } else if (identityFrontImage.name === identityBackImage.name) {
                setAlertData({
                    title: "Cảnh báo",
                    content: "2 file ảnh không được cùng tên!",
                    is_show: true,
                    variant: "warning",
                });
            }
        } else {
            try {
                const _data_upload = {
                    fullName: state.fullname,
                    identityNo: state.identity,
                    identityFrontImage: stateUpload.identityFrontImage,
                    identityBackImage: stateUpload.identityBackImage,
                }
                const response = await handleRegister(state);
                const resUpload = await handleUpload(_data_upload);

                if (response) {
                    setTermsAccepted(false);
                    setAlertData({
                        title: "Thông báo",
                        content: "Đăng ký thông tin thi chứng chỉ Đại lý bảo hiểm thành công!",
                        is_show: true,
                        variant: "success",
                    });
                } else {
                    setAlertData({
                        title: "Lỗi",
                        content: "Đăng ký không thành công. Vui lòng kiểm tra lại thông tin đăng ký!",
                        is_show: true,
                        variant: "danger",
                    });
                    return;
                }

                if (resUpload.code === 200) {
                    setAlertData({
                        title: "Thông báo",
                        content: "Đăng ký thông tin thành công!",
                        is_show: true,
                        variant: "success",
                    });
                } else {
                    setAlertData({
                        title: "Lỗi",
                        content: "Đăng ký không thành công. Vui lòng kiểm tra lại thông tin upload!",
                        is_show: true,
                        variant: "danger",
                    });
                }
            } catch (error) {
                setAlertData({
                    title: "Lỗi",
                    content: "Đã xảy ra lỗi, vui lòng thử lại sau.",
                    is_show: true,
                    variant: "danger",
                });
            }
        }
    };

    const change_dob = (val) => {
        setState((prev) => ({ ...prev, dob: val }));
        setStateError((prev) => ({ ...prev, dob: false }))
    };

    const change_doi = (val) => {
        setState((prev) => ({ ...prev, date_of_issue: val }));
        setStateError((prev) => ({ ...prev, date_of_issue: false }))
    };

    const change_exam_time = (val) => {
        setStartDate(val);
        setState((prev) => ({ ...prev, exam_time: val }));
        setStateError((prev) => ({ ...prev, exam_time: false }))
    };

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    return (
        <>
            {
                isLoading ? (<>
                    <>
                        <Spinner 
                            animation="border" 
                            size="sm" 
                            role="status" 
                            aria-hidden="true" 
                            className="me-2"
                            center
                        />
                        Đang xử lý... Vui lòng chờ trong khoảng thời gian 1 phút để hệ thống ghi nhận và gửi mail!
                    </>
                </>) : (<>
                    <div className='bg-modal-gate bg-agent-exam-info responsive-container' style={{ overflowY: "auto", overflowX: 'hidden', zIndex: '1' }}>
                        <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                            <a className="navbar-brand" href="https://fubonins.com.vn/" target="_blank">
                                <img src={fbBanner} />
                            </a>
                        </div>
                        <div className='login-panel agent-reg-info' style={{ width: '700px', marginBottom: '40px' }}>
                            <div className='text-center responsive-title'><h1>ĐĂNG KÝ THI CHỨNG CHỈ <br /> ĐẠI LÝ BẢO HIỂM</h1></div>
                            <div >
                                <div className='row mt15px'>
                                    <div className='col-md-6'>
                                        <div className="form-floating responsive-control">
                                            <TextInput name="fullname" placeholder="Họ và tên" value={state.fullname} onChange={handle_change}
                                                style={{ borderRadius: '0px' }} className={stateError.fullname === true ? "error" : ""} />
                                            <label className='required'>Họ và tên</label>
                                        </div>
                                        <div className="mt10px responsive-control">
                                            <div className='col-sm-12'>
                                                <label className='form-label required' style={{ marginBottom: '4px' }}>Ngày sinh</label>
                                                <ShortDateInput selected={state.dob} minDate={minDOB} maxDate={maxDOB} onChange={change_dob}
                                                    style={{ height: '58px', borderRadius: '0px' }} className={stateError.dob === true ? "error" : ""} />
                                            </div>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <select name="sex" value={state.sex} onChange={handle_change} style={{ cursor: 'pointer', borderRadius: '0px' }}
                                                className={stateError.sex === true ? "error form-select" : "form-select"} >
                                                <option value="">Chọn giới tính</option>
                                                <option value="true">Nam</option>
                                                <option value="false">Nữ</option>
                                            </select>
                                            <label >Chọn giới tính</label>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <TextInput name="identity" placeholder="Nhập số CMTND" value={state.identity} onChange={handle_change}
                                                style={{ borderRadius: '0px' }} className={stateError.identity === true ? "error" : ""} />
                                            <label className='required'>Nhập số CMTND</label>
                                        </div>
                                        <div className="mt5px responsive-control">
                                            <div className='col-sm-12'>
                                                <label className='form-label required' style={{ marginBottom: '4px', borderRadius: '0px' }}>Ngày cấp</label>
                                                <ShortDateInput selected={state.date_of_issue} minDate={minDOI} maxDate={maxDOI} onChange={change_doi}
                                                    style={{ height: '58px', borderRadius: '0px' }} className={stateError.date_of_issue === true ? "error" : ""} />
                                            </div>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <select name="place_of_issue" value={state.place_of_issue} onChange={handle_change} style={{ cursor: 'pointer', borderRadius: '0px' }}
                                                className={stateError.place_of_issue === true ? "error form-select" : "form-select"} >
                                                <option value="">Nơi cấp</option>
                                                {place_of_issue_list.map((province, index) => (
                                                    <option key={index} value={province}>{province}</option>
                                                ))}
                                            </select>
                                            <label >Nơi cấp</label>
                                        </div>
                                        <div className="responsive-control">
                                            <label style={{ marginTop: '16px' }}>Nếu là số CCCD thì chọn Bộ Công An (0)</label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-floating responsive-control">
                                            <TextInput name="address" placeholder="Địa chỉ thường trú" value={state.address} onChange={handle_change}
                                                style={{ borderRadius: '0px' }} />
                                            <label >Địa chỉ thường trú</label>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <TextInput name="email" placeholder="Email" value={state.email}
                                                onChange={handle_change} style={{ borderRadius: '0px' }} className={stateError.email === true ? "error" : ""} />
                                            <label >Email</label>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <TextInput name="phone" placeholder="Điện thoại" value={state.phone} onChange={handle_change} className={stateError.phone === true ? "error" : ""} style={{ borderRadius: '0px' }} />
                                            <label >Điện thoại</label>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <div style={{ marginBottom: '0px', fontWeight: 'bold' }}>Mục đích đăng ký thi:</div>
                                            <div style={{ fontSize: '14px' }}>Làm đại lý bảo hiểm cá nhân:
                                                <input name="exam_purpose" type="radio" checked={state.exam_purpose == 1} value="1"
                                                    onClick={handle_change} style={{ cursor: 'pointer', height: '16px', width: '16px', marginLeft: '8px' }} />
                                            </div>
                                            <div style={{ fontSize: '14px' }}>Làm đại lý viên của tổ chức:
                                                <input name="exam_purpose" type="radio" checked={state.exam_purpose == 2} value="2"
                                                    onClick={handle_change} style={{ cursor: 'pointer', marginLeft: '17px', height: '16px', width: '16px' }} />
                                            </div>
                                        </div>
                                        <div className="form-floating mt5px responsive-control">
                                            <TextInput name="organization" placeholder="Tên tổ chức" value={state.organization} onChange={handle_change}
                                                style={{ borderRadius: '0px' }} />
                                            <label >Tên tổ chức</label>
                                        </div>
                                        <div className="mt15px responsive-control">
                                            <label className='form-label' style={{ marginBottom: '4px' }}>Thời gian thi mong muốn</label>
                                            <div className='col-sm-12'>
                                                <ReactDatePicker selected={startDate} onChange={(date) => change_exam_time(date)} dateFormat="dd/MM/yyyy"
                                                    minDate={getMinDate()} maxDate={getMaxDate()}
                                                    isClearable style={{ height: '58px', borderRadius: '0px', border: '0px solid #ccc' }}
                                                    className={stateError.exam_time ? "error" : ""} />
                                            </div>
                                        </div>
                                        <div className="form-floating mt15px responsive-control">
                                            <select name="exam_place" value={state.exam_place} onChange={handle_change} style={{ cursor: 'pointer', borderRadius: '0px' }}
                                                className={stateError.exam_place === true ? "error form-select" : "form-select"} >
                                                <option value="">Chọn địa điểm</option>
                                                {provinces.map((province, index) => (
                                                    <option key={index} value={province.name}>{province.name}</option>
                                                ))}
                                            </select>
                                            <label >Địa điểm thi mong muốn</label>
                                        </div>
                                    </div>
                                    <div className='row mt15px'>
                                        <div className='text-center responsive-title'><h1>UPLOAD ẢNH CMTND XÁC THỰC</h1></div>
                                        <div className='col-md-6'>
                                            <div className="responsive-control"
                                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', marginLeft: '32px' }}>
                                                <img
                                                    src={frontImage}
                                                    style={{ height: '300px', width: '380px', padding: '30px', alignItems: 'center' }}
                                                />
                                                <label className='required' style={{ marginBottom: '20px' }}>Ảnh mặt trước ảnh CMTND/Hộ chiếu</label>
                                                <input
                                                    name="identityFrontImage"
                                                    type='file'
                                                    title='Chọn ảnh mặt trước CMTND/Hộ chiếu'
                                                    style={{ alignItems: 'center' }}
                                                    onChange={(e) => handleImageChange(e, "identityFrontImage")}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="responsive-control"
                                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <img
                                                    src={backImage}
                                                    style={{ height: '300px', width: '380px', padding: '30px', alignItems: 'center' }}
                                                />
                                                <label className='required' style={{ marginBottom: '20px' }}>Ảnh mặt sau ảnh CMTND/Hộ chiếu</label>
                                                <input
                                                    name="identityBackImage"
                                                    type='file'
                                                    title='Chọn ảnh mặt sau CMTND/Hộ chiếu'
                                                    style={{ alignItems: 'center' }}
                                                    onChange={(e) => handleImageChange(e, "identityBackImage")}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt10px'>
                                    <div className='row mt-3'>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className='col-md-1' style={{ paddingTop: '8px', paddingLeft: '16px' }}>
                                                    <input className='responsive-checkbox' style={{ height: '20px', width: '20px', cursor: 'pointer', padding: '8px', borderRadius: '0px' }} type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
                                                </div>
                                                <div className='col-md-11'>
                                                    <label className="form-label responsive-term" style={{ marginLeft: '10px', textAlign: 'justify' }}>
                                                        Tôi đã đọc <a style={{ color: 'red', fontWeight: 'bold', textDecoration: 'none' }} href={file}
                                                            target="_blank">Thư đồng ý </a>về việc xử lý dữ liệu cá nhân và đồng ý cho phép Fubon được phép xử lý dữ liệu cá nhân của tôi cho các mục đích và phạm vi được nêu tại <a style={{ color: 'red', fontWeight: 'bold', textDecoration: 'none' }} href={file}
                                                                target="_blank">Thư đồng ý </a> về việc xử lý dữ liệu cá nhân
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt15px responsive-button text-center' >
                                    <button className="btn btn-primary responsive-button-detail" style={{ height: '60px', width: '150px', marginRight: '16px', borderRadius: '0px', fontWeight: '700' }} onClick={() => submit()} disabled={!termsAccepted}>
                                        ĐĂNG KÝ
                                    </button>
                                    <Link className="btn btn-outline-success ms-1 responsive-button-detail" style={{ height: '60px', width: '150px', paddingTop: '16px', textAlign: 'center', borderRadius: '0px', border: '2px solid green', fontWeight: '700' }} to="/elearning/dang-nhap">
                                        ĐĂNG NHẬP
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Modal show={showModal} onHide={handleModalClose} className="custom-modal" backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>Upload file Excel chứa thông tin đăng ký thi Chứng chỉ ĐLBH</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UploadAgentList />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleModalClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleModalClose}>
                                    Upload
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
                    </div>
                </>)
            }
        </>
    )
}

export default RegisterAgentExam;