import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import { useLoaderData } from "react-router-dom";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import CommonHelper from '../../helpers/common';
import TextInput from '../../components/text-input';
import AutoComplete from '../../components/auto-complete';
import ShortDateInput from '../../components/short-date-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';
import Packages from './component/pti-packages';
import ptiApi from '../../api/ptiApi';
import OnlineApi from "../../api/onlineApi";
import Nationality from '../../nationality';
import Occupation from '../../occupation';
import ValidateMessage from '../../components/validate-msg';
import PtiProgressBar from './component/pti-progress-bar';
import ModalReject from './component/pti-modal-reject';
import PtiOption from './component/pti-option';
import Authentication from '../../api/authApi';
import PtiPayment from './component/pti-payment';
import ModalConfirmEmail from './component/pti-modal-confirm-email';
import {
    initial_person_info,
    steps,
    initialAlert,
    initial,
    locations,
    insuredObjectMapper,
    orderObjectMapper,
    orderObjectMaster,
    initial_survey_questions,
} from './util';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "./index.css";
import PtiInfo from './component/pti-add-person-info';
import InsuredPerson from './component/pti-insured-person';
import PtiConfirmation from './component/pti-confirmation';
import PtiTrip from './component/pti-trip';
import PtiModalUpload from './component/pti-modal-upload';

const flagLoading = false;

export async function loader({ params }) {
    var data = {};
    if (params.id !== undefined && CommonHelper.is_not_empty(params.id)) {
        var orderNo = params.id
        data = { ...data, orderNo };
    }
    return data;
};

function PtiInsurance() {
    const _title = "BẢO HIỂM DU LỊCH";

    const [state, setState] = useState(initial);
    const [state_person, setStatePerson] = useState(initial_person_info);
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ stateError, stateErrorMsg }, setStateError] = useState({
        stateError: {
            veh_fm_dt: false,
            veh_to_dt: false,
            veh_pax: false,
            veh_customer: false,
            dob: false,
            veh_customer_idcard: false,
            veh_job_desc: false,
            veh_occupation: false,
            veh_nation: false,
            veh_phone: false,
            veh_email: false,
            veh_address: false,
            veh_rep_company: false,
            veh_prov: false,
            veh_dist: false,
            veh_tax_code: false,
            inv_tax: false,
            inv_company_name: false,
            inv_address: false,
            veh_schedule: false,
            veh_province_name: false,
            veh_district_name: false,
        },
        stateErrorMsg: {
            veh_fm_dt: "",
            veh_to_dt: "",
            veh_pax: "",
            veh_customer: "",
            dob: "",
            veh_customer_idcard: "",
            veh_job_desc: "",
            veh_occupation: "",
            veh_nation: "",
            veh_phone: "",
            veh_email: "",
            veh_address: "",
            veh_rep_company: "",
            veh_prov: "",
            veh_dist: "",
            veh_tax_code: "",
            inv_tax: "",
            inv_company_name: "",
            inv_address: "",
            veh_schedule: "",
            veh_province_name: "",
            veh_district_name: "",
        }
    });
    const [{ state_lst_person, lst_region, lst_prov, lst_dist, lst_nation, lst_occupation,
        lst_sob, lst_agency, pti_packages, lst_survey_question }, setList] = useState({
            lst_prov: [],
            lst_dist: [],
            state_lst_person: [],
            lst_nation: Nationality,
            lst_occupation: [],
            lst_sob: [],
            lst_agency: [],
            pti_packages: [],
            lst_region: [],
            lst_survey_question: [],
        })
    const [lockField, setLockField] = useState({
        veh_customer: false,
        veh_phone: false,
        veh_email: false,
        veh_address: false,
        veh_handler_code: false,
        veh_customer_idcard: false,
        veh_job_desc: true,
    });
    const [collapse_field, setCollapseField] = useState({
        item1: false,
        item2: false,
        item3: false,
        item4: false,
    })
    const [{
        fm_dt,
        to_dt,
        dob,
        curr_step,
        show_personal_info,
        minDOB,
        maxDOB,
        user,
        show_reject,
        msg_reject,
        show_payment,
        show_upload,
        provSel,
        distSel,
        nationalSel,
        occupationSel,
        sobSel,
        agentSel,
        show_pti_payment,
        show_payment_qr_code,
        show_payment_gw,
        show_order_no,
        schedule,
        num_of_days, // số ngày bảo hiểm
    }, setSelected] = useState({
        fm_dt: addDays(new Date(), 1).setHours(0, 0, 0, 0),
        to_dt: addDays(new Date(), 2).setHours(0, 0, 0, 0),
        dob: null,
        curr_step: steps[0],
        show_personal_info: false,
        show_ex_occ: false,
        show_reject: false,
        show_payment: false,
        show_upload: false,
        minDOB: addYears(new Date(), -65),
        maxDOB: addYears(new Date(), -18),
        provSel: [],
        regionSel: [],
        distSel: [],
        occupationSel: [],
        nationalSel: [],
        sobSel: [],
        user: null,
        msg_reject: "",
        agentSel: [],
        show_pti_payment: false,
        show_payment_qr_code: "",
        show_payment_gw: "",
        show_order_no: "",
        schedule: [],
        num_of_days: 1, // số ngày bảo hiểm
    })
    const [{ loading }, setShow] = useState({
        loading: flagLoading
    });
    const policy = useLoaderData();
    const [isAgentOrHandler, setIsAgentOrHandler] = useState(true);
    const [ptiPackages, setPtiPackages] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [state_appraisal, setStateAppraisal] = useState(false);
    const [regions, setRegions] = useState([]);
    const [survey_questions_upload, setSurveyQuestionsUpload] = useState(initial_survey_questions)
    const [disabledUpload, setDisabledUpload] = useState(true);

    useEffect(() => {
        document.title = "Fubon Insurance - " + _title;
        //check user role to render UI Blocks
        check_user();
        var _lst_occ = Occupation.filter(x => x.organization === false);
        _lst_occ.sort((a, b) => a.name.localeCompare(b.name));
        setList((prev) => ({ ...prev, lst_occupation: _lst_occ }));
        var resUser = CommonHelper.get_user();
        resUser.then((res) => {
            var veh_handler_code = "";
            var veh_handler_name = "";
            var veh_agency_code = "";
            var veh_agency_name = "";
            var veh_is_agent = false;
            var veh_customer = "";
            var veh_customer_idcard = "";
            var veh_phone = "";
            var veh_email = "";
            var veh_address = "";
            var lst_agency = [];
            var agentSel = [];
            if (res !== null) {
                veh_handler_code = res.handler_code;
                veh_handler_name = res.handler_name;
                if (res.user_type > 1) {
                    if (res.user_type == 2) {
                        get_agent_by_handler();
                    } else if (res.user_type == 3) {
                        veh_agency_code = res.code;
                        veh_agency_name = res.name;
                        lst_agency = [{ code: veh_agency_code, name: veh_agency_name }]
                        agentSel = lst_agency;
                        veh_is_agent = true;
                    }
                    get_sob("", res.user_type);
                } else {
                    veh_customer = res.name;
                    veh_address = res.address;
                    veh_email = res.email;
                    veh_phone = res.phone;
                    veh_customer_idcard = res.id_card;
                }
                setList((prev) => ({
                    ...prev,
                    lst_agency
                }))
                setSelected((prev) => ({
                    ...prev,
                    user: res,
                    agentSel,
                }));
                setState((prev) => ({
                    ...prev,
                    user_type: res.user_type > 1 ? res.user_type : 1,
                    veh_is_agent,
                    veh_handler_code,
                    veh_handler_name,
                    veh_agency_code,
                    veh_agency_name,
                    veh_customer,
                    veh_customer_idcard,
                    veh_phone,
                    veh_email,
                    veh_address,
                }));
            }
        }).catch(
            setSelected((prev) => ({
                ...prev,
                user: {
                    user_type: 1,
                },
                agentSel,
            })))
        get_provinces();
        get_regions();
        get_survey_question();
    }, []);
    useEffect(() => {
        if(state.ins_location && state.ins_pax_type && state.ins_pkg_type && num_of_days) {
            get_pti_packages(state.ins_location, state.ins_pax_type, state.ins_pkg_type, num_of_days);
        }
    }, [state.ins_location, state.ins_pax_type, state.ins_pkg_type, num_of_days]);

    useEffect(() => {
        update_premium(ptiPackages);
    }, [ptiPackages])

    useEffect(() => {
        update_premium_person(state_lst_person);
    }, [state_lst_person])

    useEffect(() => {
        setList((prev) => ({
            ...prev,
            state_lst_person: state_lst_person
        }))
    }, [state_lst_person])
    const get_pti_packages = async (ins_location, ins_pax_type, ins_pkg_type, num_of_days) => {
        try {
            let _data = {};
            if (ins_location === "Vietnam") {
                _data = {
                    location: ins_location,
                    from_date: "",
                    num_of_days,
                };
                if(_data.location && _data.num_of_days) {
                    const res = await ptiApi.get_pti_premium(_data);
                    if (res.code === 200) {
                        setPtiPackages(res.data);
                    }
                }
            } else {
                let locationData = schedule;
    
                _data = {
                    location: ins_location === "Other" ? "[{\"code\":\"NATION_142\",\"name\":\"Vietnam\"}]" : locationData,
                    from_date: "",
                    num_of_days,
                    pkg_type: ins_pkg_type,
                    pax_type: ins_pax_type,
                };
                if(_data.location && _data.pax_type && _data.pkg_type && _data.num_of_days) {
                    const res = await ptiApi.get_pti_premium(_data);
                    if (res.code === 200) {
                        setPtiPackages(res.data);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching pti packages data:", error);
        }
    };

    useEffect(() => {
        const fetchOrder = async () => {
            if (policy !== undefined && CommonHelper.is_not_empty(policy.orderNo)) {
                try {
                    setShow((prev) => ({
                        ...prev,
                        loading: true
                    }));
                    const res = await ptiApi.get_order(policy.orderNo);
                    const res_status = await ptiApi.get_payment_status(policy.orderNo);
                    if (res != null && res.code === 200) {
                        const _convert_data = orderObjectMaster(res.data);
                        if (_convert_data) {
                            set_data(_convert_data);
                        } else {
                            console.error("Converted data is null or undefined");
                        }
                    }
                    if (res_status != null && res_status.code === 200 && res_status.data.cert_url != null) {
                        setSelected((prev) => ({
                            ...prev,
                            curr_step: steps[5],
                        }))
                        setPaymentStatus(res_status);
                        setPaymentSuccess(true);
                        setState((prev) => ({
                            ...prev,
                            cert_url: res_status.data.cert_url
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching order:', error);
                } finally {
                    setShow((prev) => ({
                        ...prev,
                        loading: false
                    }));
                }
            }
        };
        fetchOrder();
    }, [policy]);
    useEffect(() => {
        var veh_occupation_code = "", veh_occupation_name = "";
        var veh_national_code = "", veh_national_name = "";
        if (nationalSel.length > 0) {
            veh_national_code = nationalSel[0].code;
            veh_national_name = nationalSel[0].name;
        }
        if (occupationSel.length > 0) {
            veh_occupation_code = occupationSel[0].code;
            veh_occupation_name = occupationSel[0].name;
        }
        setState((prev) => ({
            ...prev,
            veh_national_code,
            veh_national_name,
            veh_occupation_code,
            veh_occupation_name,
        }))
    }, [nationalSel, occupationSel]);
    useEffect(() => {
        var veh_agency_code = "";
        var veh_agency_name = "";
        if (agentSel.length > 0) {
            veh_agency_code = agentSel[0].code;
            veh_agency_name = agentSel[0].name;
            var arr = veh_agency_name.split(" - ");
            if (arr.length == 2) {
                veh_agency_name = arr[1]
            }
        }
        setState((prev) => ({
            ...prev,
            veh_agency_code,
            veh_agency_name
        }))
    }, [agentSel]);
    useEffect(() => {
        var inv_tax = "";
        var inv_company_name = "";
        var inv_address = "";
        if (state.veh_issue_invoice && state.veh_business == "Y") {
            inv_tax = state.veh_tax_code;
            inv_company_name = state.veh_customer;
            inv_address = state.veh_address;
        }
        setState((prev) => ({
            ...prev,
            inv_tax,
            inv_company_name,
            inv_address
        }));
    }, [state.veh_issue_invoice]);

    const toggleCollapse = (itemKey) => {
        setCollapseField((prev) => ({
            ...prev,
            [itemKey]: !prev[itemKey], // toggle the specific item
        }));
    };
    const convertJson = (json) => {
        const nations = [];
        json.forEach(region => {
            nations.push(...region.list_nation);
        });
        const uniqueData = Array.from(new Map(nations.map(item => [item.code, item])).values());
        // return nations;
        return uniqueData;
    }
    const get_regions = async () => {
        try {
            var res = await ptiApi.get_region();
            if (res.code === 200) {
                let _data = [];
                if (state.ins_location !== "Vietnam") {
                    _data = convertJson(res.data);
                } else {
                    _data = lst_prov;
                }
                setRegions(_data);
            }
        } catch (error) {
            console.error("Error fetching regions data:", error);
        }
    };
    const update_premium = async (ptiPackages) => {
        if (curr_step.code !== 4 && curr_step.code !== 5) {
            const updatedPersons = state_lst_person.map(person => {
                const matchingPackage = ptiPackages.find(pkg => pkg.pkg_code === person.objPackage.pkg_code);
                if (matchingPackage) {
                    return {
                        ...person,
                        premium: matchingPackage.premium,
                        insPackage: JSON.stringify(matchingPackage),
                        objPackage: {
                            ...matchingPackage,
                            smi1: JSON.stringify(matchingPackage.smi1),
                            smi2: JSON.stringify(matchingPackage.smi2),
                            smi3: JSON.stringify(matchingPackage.smi3),
                            smi4: JSON.stringify(matchingPackage.smi4)
                        }
                    };
                }
                return person;
            });

            const update_pti_package = ptiPackages.find(pkg => pkg.pkg_code === state.pti_package.pkg_code);
            if (update_pti_package) {
                setState((prev) => ({
                    ...prev,
                    pti_package: update_pti_package
                }));
            }

            if (updatedPersons && updatedPersons.length > 0) {
                let total_premium = 0;
                let total_tax_amt = 0;
                for (let i = 0; i < updatedPersons.length; i++) {
                    const item = updatedPersons[i];
                    if (state.ins_pax_type === "1" || state.ins_pax_type === "3") {
                        total_premium += item.objPackage.premium;
                        total_tax_amt += item.objPackage.taxAmt;
                    } else if (state.ins_pax_type === "2") {
                        total_premium = item.objPackage.premium;
                        total_tax_amt = item.objPackage.taxAmt;
                        break;
                    }
                }
                if (state.ins_pax_type === "1" || state.ins_pax_type === "3") {
                    setState((prev) => ({
                        ...prev,
                        veh_tax_amt: total_tax_amt,
                        veh_premium: total_premium,
                        veh_origin_premium: total_tax_amt + total_premium,
                    }));
                } else if (state.ins_pax_type === "2") {
                    setState((prev) => ({
                        ...prev,
                        veh_tax_amt: total_tax_amt,
                        veh_premium: total_premium,
                        veh_origin_premium: total_tax_amt + total_premium,
                    }));
                }
                setList((prev) => ({
                    ...prev,
                    state_lst_person: updatedPersons
                }))
            } 
            // else {
            //     setList((prev) => ({
            //         ...prev,
            //         state_lst_person: prev.state_lst_person
            //     }))
            // }
        }
    };
    const update_premium_person = async (state_lst_person) => {
        if (curr_step.code !== 4 && curr_step.code !== 5) {
            if (state_person.objPackage != null) {
                // if (state_lst_person.length === 0) {
                if ((state_lst_person.length === 0) || (state_lst_person.length === 1)) {
                    state_person.premium = state_person.objPackage.premium;
                    setState((prev) => ({
                        ...prev,
                        veh_tax_amt: state_person.objPackage.taxAmt,
                        veh_premium: state_person.objPackage.premium,
                        veh_origin_premium: state_person.premium + state_person.objPackage.taxAmt,
                    }));
                } else {
                    let total_premium = 0;
                    let total_tax_amt = 0;
                    for (let i = 0; i < state_lst_person.length; i++) {
                        const item = state_lst_person[i];
                        if (state.ins_pax_type === "1" || state.ins_pax_type === "3") {
                            total_premium += item.objPackage.premium;
                            total_tax_amt += item.objPackage.taxAmt;
                        } else if (state.ins_pax_type === "2") {
                            total_premium = item.objPackage.premium;
                            total_tax_amt = item.objPackage.taxAmt;
                            break;
                        }
                    }
                    if (state.ins_pax_type === "1" || state.ins_pax_type === "3") {
                        setState((prev) => ({
                            ...prev,
                            veh_tax_amt: total_tax_amt,
                            veh_premium: total_premium,
                            veh_origin_premium: total_tax_amt + total_premium,
                        }));
                    } else if (state.ins_pax_type === "2") {
                        setState((prev) => ({
                            ...prev,
                            veh_tax_amt: total_tax_amt,
                            veh_premium: total_premium,
                            veh_origin_premium: total_tax_amt + total_premium,
                        }));
                    }
                }
            }
        }
    };
    const get_survey_question = () => {
        const response = ptiApi.get_survey_question();
        response.then((res) => {
            if (res.code == 200) {
                setList((prev) => ({ ...prev, lst_survey_question: res.data }));
            }
        })
    }
    const check_user = () => {
        Authentication.get_user()
            .then((res) => {
                if (res.code === 506) {
                    setIsAgentOrHandler(true);
                } else {
                    CommonHelper.get_user().then((objUser) => {
                        if (objUser.user_type === 2 || objUser.user_type === 3) {
                            setIsAgentOrHandler(false);
                        }
                    }).catch((error) => {
                        console.error("Error fetching user:", error);
                    });
                }
            })
            .catch((error) => {
                console.error("Error during authentication:", error);
            });
    };
    const select_pkg = (e) => {
        //validate_control(e);
        var _pax = 0;
        var _str_pax = state.veh_pax;
        if (CommonHelper.is_not_empty(_str_pax)) {
            _pax = parseInt(_str_pax);
        }
        if (e != null) {
            var _pkg = e;
        }
        setState((prev) => ({
            ...prev,
            pti_package: _pkg
        }))
    };
    const set_data = (data) => {
        setState(data.state);
        //setStatePerson(data.state_person);
        setList((prev) => ({
            ...prev,
            state_lst_person: data.state_lst_person,
        }));
        setSelected((prev) => ({
            ...prev,
            schedule: data.state.veh_schedule ? JSON.parse(data.state.veh_schedule) : []
        }));

        data.state.veh_sc_code = "PTI";
        data.state["cert_url"] = data.cert_url;
        data.state["file_upload"] = data.file_upload;
        var _lst_occ = [];
        if (data.state.veh_business === "Y") {
            _lst_occ = Occupation.filter(x => x.organization === true);
        } else {
            _lst_occ = Occupation.filter(x => x.organization === false);
        }
        var _occ_sel = _lst_occ.filter(x => x.code == data.state.veh_occupation_code);
        setList((prev) => ({
            ...prev,
            lst_occupation: _lst_occ,
        }))
        if (CommonHelper.is_not_empty(data.state.veh_fm_dt)) {
            var _time = data.state.veh_fm_dt.substr(9);
            data.state.veh_time_eff = _time;
        }
        var _dob = null;
        if (CommonHelper.is_not_empty(data.state.veh_customer_dob)) {
            _dob = CommonHelper.parseStringToDate(data.state.veh_customer_dob, "yyyyMMdd");
        }
        var provSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_province_code)) {
            provSel = [{ code: data.state.veh_province_code, name: data.state.veh_province_name }];
        }
        var distSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_district_code)) {
            distSel = [{ code: data.state.veh_district_code, name: data.state.veh_district_name }];
        }
        var sobSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_sob_code)) {
            sobSel = [{ code: data.state.veh_sob_code, name: data.state.veh_sob_name }];
        }
        var agentSel = [];
        if (CommonHelper.is_not_empty(data.state.veh_agency_code)) {
            agentSel.push({ code: data.state.veh_agency_code, name: data.state.veh_agency_code + " - " + data.state.veh_agency_name });
        }

        get_provinces(null);
        if (CommonHelper.is_not_empty(data.state.veh_district_code)) {
            get_districts(data.state.veh_province_code, null);
        }
        setSelected((prev) => ({
            ...prev,
            fm_dt: CommonHelper.parseStringToDate(data.state.veh_fm_dt, "yyyyMMdd HHmmss"),
            to_dt: CommonHelper.parseStringToDate(data.state.veh_to_dt, "yyyyMMdd HHmmss"),
            curr_step: steps[4],
            dob: _dob,
            occupationSel: _occ_sel,
            nationalSel: Nationality.filter(x => x.code === data.state.veh_national_code),
            sobSel,
            provSel,
            distSel,
            agentSel
        }))
        let _fm_dt = CommonHelper.parseStringToDate(data.state.veh_fm_dt, "yyyyMMdd HHmmss");
        let _to_dt = CommonHelper.parseStringToDate(data.state.veh_to_dt, "yyyyMMdd HHmmss");
        let diffDays = differenceInCalendarDays(_to_dt, _fm_dt);
        get_pti_packages(data.state.ins_location, data.state.ins_pax_type, data.state.ins_pkg_type, diffDays);
    };

    useEffect(() => {
        var veh_fm_dt = "", veh_to_dt = "";
        var num_of_days = 0;
        if (fm_dt != null) {
            veh_fm_dt = CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt);
        }
        if (to_dt != null) {
            veh_to_dt = CommonHelper.formatToYYYYMMDDHHMMSS(to_dt);
        }
        if (fm_dt != null && to_dt != null) {
            num_of_days = differenceInCalendarDays(to_dt, fm_dt);
        }
        setState((prev) => ({ ...prev, veh_fm_dt, veh_to_dt }));
        setSelected((prev) => ({ ...prev, num_of_days }));
    }, [fm_dt, to_dt]); // Watch changes in both 'Ngày đi' and 'Ngày về'
    const change_fm_dt = (val) => {
        var fm_dt = val;
        var to_dt = addDays(fm_dt, 1);
        if (state.ins_pkg_type == "ANNUAL") {
            to_dt = addYears(fm_dt, 1);
        } else if (state.ins_pkg_type == "TRIP") {
            to_dt = addDays(fm_dt, 1);
        }
        setSelected((prev) => ({
            ...prev, fm_dt, to_dt
        }));
    };
    const change_to_dt = (val) => {
        let _to_dt = null;
        if (val != null) {
            // Set default time to midnight
            // val.setHours(0, 0, 0, 0);  // Set hours, minutes, seconds, and milliseconds to 0

            _to_dt = val;
        }

        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_to_dt: val == null
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_to_dt: val == null ? "required" : ""
            },
        }));

        setSelected((prev) => {
            const updatedState = {
                ...prev,
                to_dt: _to_dt,
            };

            // Recalculate num_of_days if fm_dt is set
            if (fm_dt) {
                // const diffDays = differenceInCalendarDays(_to_dt, fm_dt) + 1;
                const diffDays = differenceInCalendarDays(_to_dt, fm_dt);
                updatedState.num_of_days = diffDays > 0 ? diffDays : 1; // Ensure at least 1
            }

            return updatedState;
        });
        setState((prev) => ({
            ...prev,
            veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(_to_dt),
        }));
    };
    const change_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            dob: val,
        }));
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                dob: val == null
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                dob: (val == null ? "required" : "")
            },
        }))
    };
    const change_occupation = (val) => {
        var _obj = { ...stateError };
        _obj.veh_occupation = false;
        var _objMsg = { ...stateErrorMsg };
        _objMsg.veh_occupation = "";
        if (val.length > 0) {
            if (val[0].exception) {
                _obj.veh_occupation = true;
                _objMsg.veh_occupation = "\"" + val[0].name + "\" thuộc ngành nghề không được bảo hiểm. Vui lòng chọn ngành nghề khác!";
            }
            var veh_job_desc = true;
            if (state.veh_business == "N") {
                if (val[0].code === "OCCR038_0100052") {
                    veh_job_desc = false;
                    _obj.veh_job_desc = true;
                    _objMsg.veh_job_desc = "required";
                } else {
                    _obj.veh_job_desc = false;
                    _objMsg.veh_job_desc = "";
                }
            } else {
                if (val[0].code === "OCCR038_1100025") {
                    veh_job_desc = false;
                    _obj.veh_job_desc = true;
                    _objMsg.veh_job_desc = "required";
                } else {
                    _obj.veh_job_desc = false;
                    _objMsg.veh_job_desc = "";
                }
            }
            setLockField((prev) => ({ ...prev, veh_job_desc }));
        } else {
            _obj.veh_occupation = true;
            _objMsg.veh_occupation = "required";
        }
        setSelected((prev) => ({
            ...prev,
            occupationSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: _obj, //{ ...stateError, veh_occupation: val.length == 0 },
            stateErrorMsg: _objMsg, //{ ...stateErrorMsg, veh_occupation: (val.length == 0 ? "required" : "") },
        }))
    };
    const change_nation = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name
        }
        setState((prev) => ({
            ...prev,
            veh_national_code: _code,
            veh_national_name: _name,
        }))
        setSelected((prev) => ({
            ...prev,
            nationalSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_nation: val.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_nation: (val.length == 0 ? "required" : "")
            },
        }))
    };
    const get_sob = (id, user_type) => {
        var res_sob = OnlineApi.get_sob(id);
        res_sob.then((data) => {
            if (data !== null) {
                setList((prev) => ({
                    ...prev,
                    lst_sob: data,
                }));
                if (user_type == 3 && data.length == 1) {
                    setSelected((prev) => ({
                        ...prev,
                        sobSel: data
                    }))
                }
            }
        })
    };
    const get_agent_by_handler = () => {
        var response = OnlineApi.get_agent_by_handler();
        response.then((res) => {
            if (res != null && res.length > 0) {
                var lst_agency = res.map((item) => {
                    return {
                        "code": item.code,
                        "name": item.code + " - " + item.name
                    }
                });
                setList((prev) => ({
                    ...prev,
                    lst_agency
                }));
            }
        })
    };
    const change_sob = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        setSelected((prev) => ({
            ...prev,
            sobSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_sob_code: _code,
            veh_sob_name: _name,
        }))
    };
    const get_provinces = (obj) => {
        var res_provinces = OnlineApi.get_provinces();
        res_provinces.then((data) => {
            if (data !== null) {
                setList((prev) => ({
                    ...prev,
                    lst_prov: data,
                }));
                if (obj !== undefined && obj !== null) {
                    setSelected((prev) => ({
                        ...prev,
                        provSel: data.filter(x => x.code == obj.code)
                    }))
                }
            }
        })
    };
    const change_prov = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        get_districts(_code);
        setSelected((prev) => ({
            ...prev,
            provSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_province_code: _code,
            veh_province_name: _name,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_prov: val.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_prov: (val.length == 0 ? "required" : "")
            },
        }))
    };
    const change_schedule = (val) => {
        setSelected((prev) => ({
            ...prev,
            schedule: val
        }))
        setState((prev) => ({
            ...prev,
            veh_schedule: JSON.stringify(val),
            // ins_location: JSON.stringify(val)
        }))
        let flag = (val != null && val.length > 0)
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_schedule: !flag
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_schedule: (!flag ? "required" : "")
            },
        }))
    };
    const change_dist = (val) => {
        var _code = "";
        var _name = "";
        if (val.length > 0) {
            _code = val[0].code;
            _name = val[0].name;
        }
        setSelected((prev) => ({
            ...prev,
            distSel: val
        }))
        setState((prev) => ({
            ...prev,
            veh_district_code: _code,
            veh_district_name: _name,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                veh_dist: val.length == 0
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                veh_dist: (val.length == 0 ? "required" : "")
            },
        }))
    };
    const get_districts = (id, obj) => {
        setSelected((prev) => ({
            ...prev,
            distSel: [],
        }));
        var res_districts = OnlineApi.get_districts(id, "");
        res_districts.then((data) => {
            if (data === null) {
                data = [];
            }
            setList((prev) => ({
                ...prev,
                lst_dist: data,
            }));
            if (obj !== undefined && obj !== null) {
                setSelected((prev) => ({
                    ...prev,
                    distSel: data.filter(x => x.code == obj.code)
                }))
            }
        })
    };
    const handle_radio_check = (e) => {
        const { name, value } = e.target;
        if (name === "veh_gender") {
            setState((prevData) => ({
                ...prevData,
                [name]: CommonHelper.convertToInt(value),
            }));
        }
    };
    const handle_location_check = (e) => {
        const { name, value } = e.target;
        if (name === "ins_location") {
            setState((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    const handle_pax_type_check = (e) => {
        const { name, value } = e.target;
        if (name === "ins_pax_type") {
            setState((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    const handle_pkg_type_check = (e) => {
        const { name, value } = e.target;
        if (name === "ins_pkg_type") {
            setState((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            if (value == "ANNUAL") {
                var to_dt = addYears(fm_dt, 1);
                setSelected((prev) => ({ ...prev, to_dt }))
            } else {
                var to_dt = addDays(fm_dt, 1);
                setSelected((prev) => ({ ...prev, to_dt }))
            }
        }
    };
    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        if (name === "veh_phone") {
            _val = value.replace(/[^0-9]/g, "");
        } else if (name === "veh_pax") {
            var _pax = 0;
            var _premium = 0;
            var _tax = 0;
            if (CommonHelper.is_not_empty(_val) && CommonHelper.convertToInt(_val) > 0) {
                _pax = CommonHelper.convertToInt(_val);
            } else {
                _val = '0';
            }
        } else if (name === "veh_business") {
            var _lst_occ = [];
            if (_val === "Y") {
                _lst_occ = Occupation.filter(x => x.organization === true);
            } else {
                _lst_occ = Occupation.filter(x => x.organization === false);
            }
            _lst_occ.sort((a, b) => a.name.localeCompare(b.name));
            setList((prev) => ({
                ...prev,
                lst_occupation: _lst_occ,
            }))
            setState((prevData) => ({
                ...prevData,
                veh_customer: "",
                veh_customer_idcard: "",
                veh_phone: "",
                veh_email: "",
                veh_address: "",
            }));
            setSelected((prevData) => ({
                ...prevData,
                dob: null,
                nationalSel: [],
                occupationSel: [],
                provSel: [],
                distSel: [],
            }));
            reset_validate(e);
        }
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
        validate_control(e);
    };
    const validate_control = (e) => {
        const { name, value } = e.target;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            _arr.push(item);
        })
        if (_arr.indexOf(name) >= 0) {
            _obj[name] = false;
            _objMsg[name] = "";
            if (CommonHelper.is_empty(value)) {
                _obj[name] = true;
                _objMsg[name] = "required";
            } else {
                if (name === "veh_pax" && CommonHelper.convertToInt(value) < 1) {
                    _obj[name] = true;
                    _objMsg[name] = "invalid";
                }
                if (name === "veh_phone") {
                    var _val = value.replace(/[^0-9]/g, "");
                    var _len = _val.length;
                    if (_len < 10 || _len > 11) {
                        _obj[name] = true;
                        _objMsg[name] = "invalid";
                    }
                }
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
    };
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const next_step = async () => {
        var _idx = steps.findIndex(x => x.code === curr_step.code);
        var flag = true;
        var _data = { ...state };
        var _objStateError = { ...stateError };
        var _objStateErrorMsg = { ...stateErrorMsg };
        var res_flag = false;
        if (curr_step.code === 3) { //Gói bảo hiểm
            res_flag = true;
            if (_data.pti_package["pkg_code"] === undefined) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn gói bảo hiểm. Vui lòng chọn gói bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }))
            } else {
                let check_premium_pkg = (_data.pti_package && _data.pti_package.premium > 0);
                flag = check_premium_pkg;
                if(check_premium_pkg === false) {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Lỗi",
                        content: "Địa điểm quý khách chọn không được Fubon bảo hiểm theo gói năm. Vui lòng quay lại để thay đổi địa điểm!",
                        is_show: true,
                        variant: "danger"
                    }))
                }
            }
        } else if (curr_step.code === 4) { //Yêu cầu bảo hiểm
            if (fm_dt === null) {
                flag = false;
                _objStateError.veh_fm_dt = true;
                _objStateErrorMsg.veh_fm_dt = "required";
            } else {
                setState((prev) => ({
                    ...prev,
                    veh_fm_dt: CommonHelper.formatToYYYYMMDDHHMMSS(fm_dt),
                    veh_to_dt: CommonHelper.formatToYYYYMMDDHHMMSS(to_dt),
                }))
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _objStateError,
                stateErrorMsg: _objStateErrorMsg,
            }))
            if (CommonHelper.is_empty(_data.veh_customer)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_phone)) {
                flag = false
            }
            if (CommonHelper.is_empty(_data.veh_email)) {
                flag = false
            }
            if (_data.location && _data.location.code === "Other" && _data.veh_business === "N") {
            } else {
                if (CommonHelper.is_empty(_data.veh_address) || provSel.length < 1) {
                    flag = false
                } else {
                    var _arr_address = [];
                    if (distSel.length > 0) {
                        if (_data.veh_address.indexOf(distSel[0].name) < 0) {
                            _arr_address.push(distSel[0].name);
                        }
                        _data.veh_district_code = distSel[0].code;
                    }
                    if (provSel.length > 0) {
                        if (_data.veh_address.indexOf(provSel[0].name) < 0) {
                            _arr_address.push(provSel[0].name);
                        }
                        _data.veh_province_code = provSel[0].code;
                    }
                    _data.veh_address += ", " + _arr_address.join(", ");
                }
            }
            if (_data.veh_issue_invoice) {
                if (CommonHelper.is_empty(_data.inv_tax)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_data.inv_company_name)) {
                    flag = false;
                }
                if (CommonHelper.is_empty(_data.inv_address)) {
                    flag = false;
                }
                setStateError((prev) => ({
                    ...prev,
                    inv_tax: CommonHelper.is_empty(_data.inv_tax),
                    inv_company_name: CommonHelper.is_empty(_data.inv_company_name),
                    inv_address: CommonHelper.is_empty(_data.inv_address),
                }))
            }
            var _len = [...state_lst_person].length;
            if (_len == 0) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Vui lòng nhập danh sách người được bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    veh_pax: _len,
                }))
            }
            if (flag) {
                res_flag = await handle_save();
            }
        } else if (curr_step.code === 5) { //Xác nhận thanh toán
            res_flag = true;
            // setSelected((prev) => ({ ...prev, show_pti_payment: true }));
            if (flag) {
                get_payment({ ...state }.veh_order_no);
            }
        } else if (curr_step.code === 1) { //Chọn vùng du lịch
            res_flag = true;
            if (_data.location["code"] === undefined) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn vùng du lịch. Vui lòng chọn lại!",
                    is_show: true,
                    variant: "danger"
                }))
            }
        } else if (curr_step.code === 2) {
            res_flag = true;
            if (!fm_dt) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn ngày bắt đầu. Vui lòng chọn lại!",
                    is_show: true,
                    variant: "danger"
                }))
            }
            if (!to_dt) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn ngày kết thúc. Vui lòng chọn lại!",
                    is_show: true,
                    variant: "danger"
                }))
            }
            if (schedule.length < 1) {
                flag = false;
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Quý khách chưa chọn địa điểm hành trình du lịch. Vui lòng chọn lại!",
                    is_show: true,
                    variant: "danger"
                }))
            }
        }
        if (flag && res_flag) {
            if (_idx < steps.length - 1) {
                setSelected((prev) => ({
                    ...prev,
                    curr_step: steps[_idx + 1]
                }))
            }
        }
    };
    const back_step = () => {
        var _idx = steps.findIndex(x => x.code === curr_step.code);
        setSelected((prev) => ({
            ...prev,
            curr_step: steps[_idx - 1]
        }))
    };
    const validate_email_format = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.validate_email_format(value);
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                [name]: !_flag
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                [name]: !_flag ? "Email không đúng định dạng. Vui lòng kiểm tra lại!" : ""
            },
        }))
    };
    const validate_idcard = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.check_idcard_valid(value);
        setStateError((prev) => ({
            ...prev,
            stateError: {
                ...stateError,
                [name]: !_flag
            },
            stateErrorMsg: {
                ...stateErrorMsg,
                [name]: !_flag ? "invalid" : ""
            },
        }))
    };
    const reset_validate = (e) => {
        const { name, value } = e.target;
        if (name === "veh_business") {
            var _obj = { ...stateError };
            _obj.veh_customer = false;
            _obj.veh_occupation = false;
            _obj.veh_phone = false;
            _obj.veh_email = false;
            _obj.veh_prov = false;
            _obj.veh_address = false;
            _obj.veh_nation = false;
            if (value == "N") {
                _obj.dob = false;
                _obj.veh_customer_idcard = false;
                _obj.veh_job_type = false;
            } else {
                _obj.veh_tax_code = false;
                _obj.veh_rep_company = false;
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
            }))
        }
    };
    const new_person = async () => {
        var flag = validate_info_form();
        var flag_aml = false;
        if(flag) {
            flag_aml = await check_aml_online(state.veh_order_no, state.veh_premium);
        }
        try {
            if (flag && flag_aml) {
                var _list = [...state_lst_person];
                setStatePerson((prev) => ({
                    ...prev,
                    "idx": _list.length + 1,
                    "id": 0,
                    "orderNo": state.veh_order_no,
                    "fullName": initial_person_info.fullName,
                    "idCard": initial_person_info.idCard,
                    "dob": initial_person_info.dob,
                    "gender": initial_person_info.gender,
                    "phone": initial_person_info.phone,
                    "email": initial_person_info.email,
                    "nationality": initial_person_info.nationality,
                    "occupation": initial_person_info.occupation,
                    "relationship": initial_person_info.relationship,
                    "address": initial_person_info.address,
                    "occupation": initial_person_info.occupation,
                    "isForeigner": initial_person_info.isForeigner,
                    "objPackage": state.pti_package,
                    "insPackage": initial_person_info.insPackage,
                    "isInsuredPerson": false,
                    "action": "new", //[new, edit]
                    "survey_questions": [],//JSON.parse(JSON.stringify(initial_person_info.survey_questions)),
                    "package_code": initial_person_info.package_code,
                    "package_detail": initial_person_info.package_detail,
                    "premium": initial_person_info.premium,
                    "pkg_type": state.ins_pkg_type,
                    "pax_type": state.ins_pax_type,
                }));
                var _data = {};
                if (dob != null) {
                    _data.veh_customer_dob = CommonHelper.formatToYYYYMMDD(dob);
                }
                if (nationalSel.length > 0) {
                    _data.veh_national_code = nationalSel[0].code;
                    _data.veh_national_name = nationalSel[0].name;
                }
                if (occupationSel.length > 0) {
                    _data.veh_occupation_code = occupationSel[0].code;
                    _data.veh_occupation_name = occupationSel[0].name;
                }
                setState((prev) => ({
                    ...prev,
                    veh_customer_dob: _data.veh_customer_dob,
                    veh_national_code: _data.veh_national_code,
                    veh_national_name: _data.veh_national_name,
                    veh_occupation_code: _data.veh_occupation_code,
                    veh_occupation_name: _data.veh_occupation_name,
                }))
                setSelected((prev) => ({ ...prev, show_personal_info: true }))
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Lỗi không thêm mới người được bảo hiểm!",
                is_show: true,
                variant: "danger"
            }))
        }
    }
    const begin_upload = async () => {
        var flag = validate_info_form();
        var flag_aml = false;
        if(flag) {
            flag_aml = await check_aml_online(state.veh_order_no, state.veh_premium);
        }
        try {
            if(flag && flag_aml) {
                setSelected((prev) => ({ ...prev, show_upload: true }))
            }
        } catch (error) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Lỗi không upload được danh sách người được bảo hiểm!",
                is_show: true,
                variant: "danger"
            }))
        }
    }
    const handle_upload = (flag, data) => {
        setSelected((prev) => ({ ...prev, show_upload: flag }))
        var _list = [...state_lst_person];
        if (data != 0 && data.length > 0) {
            var _new_lst = _list;
            setSurveyQuestionsUpload(data[0].survey_questions);
            data.forEach((item) => {
                var _obj = {
                    "idx": _list.length + 2,
                    "id": initial_person_info.id,
                    "orderNo": state.veh_order_no,
                    "fullName": item.fullName,
                    "idCard": item.idCard,
                    "dob": item.dob,
                    "gender": item.gender,
                    "phone": item.phone,
                    "email": item.email,
                    "national": JSON.stringify(item.nationality),
                    "occupation": JSON.stringify(item.occupation),
                    "relationship": CommonHelper.is_empty(item.relationship) ? "0" : item.relationship,
                    "address": item.address,
                    "package_code": item.package_code,
                    "premium": item.premium,
                    "objPackage": item.objPackage,
                    "insPackage": item.insPackage,
                    "isInsuredPerson": false,
                    "action": "new", //[new, edit]
                    "survey_questions": item.survey_questions,
                    "package_detail": item.insPackage,
                    num_of_days: num_of_days,
                    ins_location: state.ins_location,
                    pkg_type: state.ins_pkg_type,
                    pax_type: state.ins_pax_type,
                    location: state.ins_location != "Vietnam"
                        ? (state.location.code === "Other" ? "[{\"code\":\"NATION_142\",\"name\":\"Vietnam\"}]" : state.veh_schedule) : "Vietnam"
                }
                if (CommonHelper.is_not_empty(item.idCard)) {
                    _new_lst.push(_obj);
                }
            })
            // if (CommonHelper.is_not_empty(state.veh_order_no) && _new_lst.length > 0) {
            if (_new_lst && _new_lst.length > 0) {
                _new_lst.forEach((item) => {
                    handle_save_person(item, _new_lst);
                })
            }
            setList((prev) => ({ ...prev, state_lst_person: _new_lst }));
        }
    }
    const handle_save_person = (data, lst_person) => {
        if (data != null) {
            data.relationship = CommonHelper.is_empty(data.relationship) ? "0" : data.relationship;
            data.insPackage = JSON.stringify(data.objPackage);
            data.package_code = data.objPackage.pkg_code;
            data.num_of_days = num_of_days;
            data.ins_location = state.ins_location;
            data.pkg_type = state.ins_pkg_type;
            data.pax_type = state.ins_pax_type;
            data.location = state.ins_location != "Vietnam"
                ? (state.location.code === "Other" ? "[{\"code\":\"NATION_142\",\"name\":\"Vietnam\"}]" : state.veh_schedule) : "Vietnam";
            if (data) {
                var insured_object = insuredObjectMapper(data);
                var response = ptiApi.post_insured_object(insured_object);
                response.then((res) => {
                    if (res.code === 204) {
                        data.id = res.data.id;
                        // reset_list(data);
                    }
                }).catch((error) => {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Lỗi",
                        content: "Lỗi lưu thông tin người được bảo hiểm!",
                        is_show: true,
                        variant: "danger"
                    }));
                })
            } else {
                // reset_list(data);
            }
        }
    }
    const handle_close_personal_info = (flag, data) => {
        setSelected((prev) => ({
            ...prev,
            show_personal_info: flag
        }))
        if (data != null) {
            data.relationship = CommonHelper.is_empty(data.relationship) ? "0" : data.relationship;
            data.insPackage = JSON.stringify(data.objPackage);
            data.num_of_days = num_of_days;
            data.ins_location = state.ins_location;
            data.pkg_type = state.ins_pkg_type;
            data.pax_type = state.ins_pax_type;
            data.location = state.ins_location != "Vietnam"
                ? (state.location.code === "Other" ? "[{\"code\":\"NATION_142\",\"name\":\"Vietnam\"}]" : state.veh_schedule) : "Vietnam";
            // data.package_code = state.package_code;
            if (data) {
                var insured_object = insuredObjectMapper(data);
                var response = ptiApi.post_insured_object(insured_object);
                response.then((res) => {
                    if (res.code === 204) {
                        data.id = res.data.id;
                        reset_list(data);
                    }
                }).catch((error) => {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Lỗi",
                        content: "Lỗi không lưu được thông tin người được bảo hiểm!",
                        is_show: true,
                        variant: "danger"
                    }));
                    console.error(error);
                })
            } else {
                reset_list(data);
            }
        }
    }
    const reset_list = (model) => {
        if (model.action === "new") {
            var _new_lst = [...state_lst_person];
            _new_lst.push(model);
            setList((prev) => ({ ...prev, state_lst_person: _new_lst }));
        } else {
            var _arr = [...state_lst_person];
            if (_arr.length > 0) {
                var _idx = _arr.findIndex(x => x.id == model.id);
                if (_idx >= 0) {
                    _arr[_idx] = model;
                }
                setList((prev) => ({ ...prev, state_lst_person: _arr }));
            }
        }
    }
    const open_edit = (model) => {
        model.action = "edit";
        setStatePerson((prev) => ({
            ...prev,
            "idx": model.idx,
            "id": model.id,
            "action": "edit", //[new, edit]
            "insPackage": model.insPackage,
            "objPackage": model.objPackage,
            "package_code": model.package_code,
            "package_detail": model.insPackage,
            "survey_questions": model.survey_questions,
            "orderNo": model.orderNo,
            "fullName": model.fullName,
            "idCard": model.idCard,
            "dob": model.dob,
            "gender": model.gender,
            "relationship": model.relationship,
            "phone": model.phone,
            "email": model.email,
            "national": model.national,
            "address": model.address,
            "occupation": model.occupation,
            "isForeigner": model.isForeigner,
            // "insPackage": model.insPackage,
            // "objPackage": model.objPackage,
            // "package_code": model.package_code,
            // "package_detail": model.insPackage,
            // "survey_questions": model.survey_questions,
            "location": model.location,
            "num_of_days": model.num_of_days,
            "pkg_type": model.pkg_type,
            "pax_type": model.pax_type,
        }));
        setSelected((prev) => ({ ...prev, show_personal_info: true }))
    }
    const open_delete = (model) => {
        if (model.id === 0) {
            setList((prev) => ({ ...prev, state_lst_person: state_lst_person.filter(item => item !== model) }));
        } else {
            var response = ptiApi.delete_insured_object({ id: model.id });
            response.then((res) => {
                if (res != null && res.code === 204) {
                    setList((prev) => ({ ...prev, state_lst_person: state_lst_person.filter(item => item !== model) }));
                }
            }).catch((error) => {
                setAlertData((prev) => ({
                    ...prev,
                    title: "Lỗi",
                    content: "Không xóa được người được bảo hiểm!",
                    is_show: true,
                    variant: "danger"
                }));
            })
        }
    }
    const handle_save = async () => {
        var _data = { ...state };
        var _list = [...state_lst_person];
        if (dob != null) {
            _data.veh_customer_dob = CommonHelper.formatToYYYYMMDD(dob);
        }
        if (nationalSel.length > 0) {
            _data.veh_national_code = nationalSel[0].code;
            _data.veh_national_name = nationalSel[0].name;
        }
        if (occupationSel.length > 0) {
            _data.veh_occupation_code = occupationSel[0].code;
            _data.veh_occupation_name = occupationSel[0].name;
        }
        if (provSel.length > 0) {
            _data.veh_province_code = provSel[0].code;
            _data.veh_province_name = provSel[0].name;
        }
        if (distSel.length > 0) {
            _data.veh_district_code = distSel[0].code;
            _data.veh_district_name = distSel[0].name;
        }
        if (sobSel.length > 0) {
            _data.veh_sob = sobSel[0].code;
            _data.veh_sob_code = sobSel[0].code;
            _data.veh_sob_name = sobSel[0].name;
        }
        _data.veh_pax = _list.length + "";
        let _convert_list = []
        for (let i = 0; i < state_lst_person.length; i++) {
            _convert_list.push(insuredObjectMapper(state_lst_person[i]));
        }
        _data.ins_objects = _convert_list;
        var _model = _data;
        if (CommonHelper.is_empty(_data.veh_order_no)) {
            _model.insured_persons = _list;
        }
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        try {
            let RES_FLAG = false;
            var res = await ptiApi.post_pti_order(orderObjectMapper(_model));
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
            switch (res.code) {
                case 204:
                case 200:
                    var lst_insured_object = res.data.lst_insured_object;
                    lst_insured_object.forEach((x) => {
                        var _idx = _list.findIndex(y => y.id_card == x.id_card && y.object_name == x.object_name)
                        if (_idx >= 0) {
                            _list[_idx].id = x.id;
                        }
                    })
                    setList((prev) => ({ ...prev, state_lst_person: _list }));
                    setState((prev) => ({
                        ...prev,
                        veh_origin_premium: res.data.premium,
                        veh_order_no: res.data.order_no,
                        res_post_order: res.data.lst_insured_object.map(item => item.id),
                    }));
                    if (user != null && user.user_type > 1) {
                        setSelected((prev) => ({ ...prev, show_payment: true }));
                    }
                    return true;
                // break;
                case 506: // OUT_OF_SESSION
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Tài khoản hết phiên đăng nhập! Vui lòng đăng nhập lại!",
                        is_show: true,
                        variant: "warning"
                    }));
                    RES_FLAG = false;
                    break;
                case 700: // NODATA
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Không có dữ liệu!",
                        is_show: true,
                        variant: "warning"
                    }));
                    RES_FLAG = false;
                    break;
                case 721: // INVALID_EFFECT_DATE
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Ngày hiệu lực không hợp lệ! Vui lòng chọn lại!",
                        is_show: true,
                        variant: "warning"
                    }));
                    RES_FLAG = false;
                    break;
                case 722: // INVALID_PREMIUM
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: res.customMsg !== "" ? res.customMsg : "Phí bảo hiểm không hợp lệ! Vui lòng kiểm tra lại hoặc chọn gói khác!",
                        is_show: true,
                        variant: "warning"
                    }));
                    break;
                case 704: // FAILED
                    RES_FLAG = false;
                    break;
                default: // Unknown code
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Cảnh báo",
                        content: "Mã phản hồi không xác định.",
                        is_show: true,
                        variant: "warning"
                    }));
                    RES_FLAG = false;
            }
            return RES_FLAG;
        } catch (error) {
            console.error("Error during saving:", error);
        }

    }
    const get_payment = (order_no) => {
        setShow((prev) => ({
            ...prev,
            loading: true,
        }))
        var location_path = window.location.pathname;
        var return_url = window.location.origin + location_path;
        var arr = location_path.split("/");
        if (arr.length == 4) {
            arr.splice(arr.length - 1, 1)
            return_url = window.location.origin + arr.join("/");
        }
        var response = ptiApi.get_pti_payment({
            order_no: order_no,
            return_url,
            total_amt: state.veh_origin_premium,
            list: state.res_post_order.sort((a, b) => a - b)
        });
        response.then((res) => {
            setShow((prev) => ({
                ...prev,
                loading: false,
            }))
            if (res.code === 204 || res.code === 200) {
                if (res.data !== null && res.data.payment_qr_code !== null && res.data.payment_gw !== null) {
                    if (state.res_post_order) {
                        setSelected((prev) => ({
                            ...prev,
                            show_pti_payment: true,
                            show_payment_qr_code: res.data.payment_qr_code,
                            show_payment_gw: res.data.payment_gw,
                            show_order_no: order_no,
                        }));
                    } else { }
                }
            } else {
                setSelected((prev) => ({
                    ...prev,
                    show_pti_payment: false,
                }));
            }
        }).catch((error) => {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Lỗi khi mở form thanh toán! Vui lòng thử lại!",
                is_show: true,
                variant: "danger"
            }));
        })
    };
    const validate_info_form = () => {
        var _data = { ...state };
        var flag = true;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        if (!fm_dt) {
            flag = false;
            _obj.veh_fm_dt = true;
            _objMsg.veh_fm_dt = "required";
        }
        if (!to_dt) {
            flag = false;
            _obj.veh_to_dt = true;
            _objMsg.veh_to_dt = "required";
        }
        if (CommonHelper.is_empty(_data.veh_customer)) {
            flag = false;
            _obj.veh_customer = true;
            _objMsg.veh_customer = "required";
        }
        if (CommonHelper.is_empty(_data.veh_phone)) {
            flag = false;
            _obj.veh_phone = true;
            _objMsg.veh_phone = "required";
        }
        if (CommonHelper.is_empty(_data.veh_email)) {
            flag = false;
            _obj.veh_email = true;
            _objMsg.veh_email = "required";
        }
        if (schedule.length < 1) {
            flag = false;
            _obj.veh_schedule = true;
            _objMsg.veh_schedule = "required";
        }
        if (_data.location && _data.location.code === "Other" && _data.veh_business === "N") {
            if (CommonHelper.is_empty(_data.veh_province_name)) {
                flag = false;
                _obj.veh_province_name = true;
                _objMsg.veh_province_name = "required";
            }
            if (CommonHelper.is_empty(_data.veh_district_name)) {
                flag = false;
                _obj.veh_district_name = true;
                _objMsg.veh_district_name = "required";
            }
            if (CommonHelper.is_empty(_data.veh_address)) {
                flag = false;
                _obj.veh_address = true;
                _objMsg.veh_address = "required";
            }
        } else {
            if (distSel.length < 1) {
                flag = false;
                _obj.veh_dist = true;
                _objMsg.veh_dist = "required";
            }
            if (provSel.length < 1) {
                flag = false;
                _obj.veh_prov = true;
                _objMsg.veh_prov = "required";
            }
            if (CommonHelper.is_empty(_data.veh_address)) {
                flag = false;
                _obj.veh_address = true;
                _objMsg.veh_address = "required";
            }
        }
        if (occupationSel.length == 0) {
            flag = false;
            _obj.veh_occupation = true;
            _objMsg.veh_occupation = "required";
        }
        if (nationalSel.length == 0) {
            flag = false;
            _obj.veh_nation = true;
            _objMsg.veh_nation = "required";
        }
        if (_data.veh_business === "N") {
            if (CommonHelper.is_empty(_data.veh_customer_idcard)) {
                flag = false;
                _obj.veh_customer_idcard = true;
                _objMsg.veh_customer_idcard = "required";
            } else {
                if (!CommonHelper.check_idcard_valid(_data.veh_customer_idcard)) {
                    flag = false;
                    _obj.veh_customer_idcard = true;
                    _objMsg.veh_customer_idcard = "invalid";
                }
            }
            if (dob == null) {
                flag = false;
                _obj.dob = true;
                _objMsg.dob = "required";
            }
        } else {
            if (CommonHelper.is_empty(_data.veh_rep_company)) {
                flag = false;
                _obj.veh_rep_company = true;
                _objMsg.veh_rep_company = "required";
            }
            if (CommonHelper.is_empty(_data.veh_tax_code)) {
                flag = false;
                _obj.veh_tax_code = true;
                _objMsg.veh_tax_code = "required";
            }
        }
        if (!lockField.veh_job_desc && CommonHelper.is_empty(_data.veh_job_desc)) {
            flag = false
        }
        if (_data.veh_issue_invoice) {
            if (CommonHelper.is_empty(_data.inv_company_name)) {
                flag = false;
                _obj.inv_company_name = true;
                _objMsg.inv_company_name = "required";
            }
            if (CommonHelper.is_empty(_data.inv_tax)) {
                flag = false;
                _obj.inv_tax = true;
                _objMsg.inv_tax = "required";
            }
            if (CommonHelper.is_empty(_data.inv_address)) {
                flag = false;
                _obj.inv_address = true;
                _objMsg.inv_address = "required";
            }
        }
        setStateError((prev) => ({
            ...prev,
            stateError: _obj,
            stateErrorMsg: _objMsg,
        }))
        return flag;
    };
    const check_aml_online = async (order_no, premium = 0) => {
        const _model = { ...state };
        let _url = "";
        _url += `customer=${_model.veh_customer}`;
        _url += `&id_card=${_model.veh_customer_idcard}`;
        _url += `&tax_code=${_model.veh_tax_code}`;
        _url += `&national_code=${_model.veh_national_code}`;
        _url += `&occupation=${_model.veh_occupation_code.substring(0, 7)}`;
        _url += "&sub_class=PTI";
        _url += `&sob=${CommonHelper.is_empty(_model.veh_sob_code) ? "ECO0001" : _model.veh_sob_code}`;
        _url += `&premium=${premium}`;

        setShow(prev => ({
            ...prev,
            loading: true,
        }));

        let flag_aml = false;

        try {
            const res = await ptiApi.get_aml_online_check(_url);
            setShow(prev => ({
                ...prev,
                loading: false,
            }));

            let aml_total_score = 0;
            let aml_risk_score = 0;
            let aml_checked = false;
            if (res != null) {
                if (res.code == 200) {
                    const _obj = JSON.parse(res.data);
                    if (_obj != null) {
                        aml_total_score = CommonHelper.convertToInt(_obj.total_score);
                        aml_risk_score = CommonHelper.convertToInt(_obj.risk_name_score);
                        aml_checked = true;
                        if (aml_risk_score >= 70 || aml_total_score >= 70) {
                            flag_aml = false;
                            setSelected(prev => ({
                                ...prev,
                                show_reject: true,
                                msg_reject: "Rất tiếc hồ sơ yêu cầu bảo hiểm của bạn chưa phù hợp với quy định bán hàng online của chúng tôi. Xin vui lòng liên hệ với nhân viên của Fubon để được tư vấn & phục vụ trực tiếp."
                            }));
                        } else {
                            flag_aml = true;
                        }
                    } else {
                        flag_aml = false;
                    }
                } else {
                    flag_aml = false;
                }
            } else {
                flag_aml = false;
            }
            setState((prev) => ({
                ...prev,
                aml_checked,
                aml_risk_score,
                aml_total_score,
            }));
            setState(prev => ({
                ...prev,
                aml_total_score,
                aml_risk_score,
            }));
        } catch (error) {
            console.error("Error during AML check:", error);
            setShow(prev => ({
                ...prev,
                loading: false,
            }));
            setSelected(prev => ({
                ...prev,
                show_reject: true,
                msg_reject: "Đã xảy ra lỗi trong quá trình kiểm tra AML. Xin vui lòng thử lại sau."
            }));
        }

        return flag_aml;
    };
    const get_order_status = async () => {
        try {
            setShow((prev) => ({
                ...prev,
                loading: true
            }));
            const res = await ptiApi.get_order(state.veh_order_no);
            if (res != null && res.code === 200) {
                setState((prev) => ({
                    ...prev,
                    status: res.data.ins_status,
                }));
                setShow((prev) => ({
                    ...prev,
                    loading: false
                }));
            }
        } catch (error) {
            console.error('Error fetching order status:', error);
        }
    };
    const select_location = (e) => {
        if (e != null) {
            var _location = {
                // pet_code: e.pet_code,
                location: e.location,
                bg_color: e.bg_color,
                btn_color: e.btn_color,
                bg_image: e.bg_image,
                name: e.name,
                code: e.code,
            };
            var _loc = e.location;
            // if (e.code == "Other") {
            //     // _loc = [{ "code": "VT", "name": "VIETNAM" }];
            //     _loc = "";
            //     setState((prev) => ({
            //         ...prev,
            //         location: _location,
            //         ins_location: _loc,
            //         veh_schedule: JSON.stringify([{ "code": "NATION_142", "name": "Vietnam" }]),
            //     }))
            //     setSelected((prev) => ({
            //         ...prev,
            //         schedule: [{ "code": "NATION_142", "name": "Vietnam" }]
            //     }));
            // }
            setState((prev) => ({
                ...prev,
                location: _location,
                ins_location: e.code
            }))
        }
    };
    const get_pti_proposal = (flag, val) => {
        setSelected((prev) => ({ ...prev, show_payment: flag }));
        if (val == 1) {
            send_confirmation_to_customer();
        }
    }
    const send_confirmation_to_customer = () => {
        var _data = { ...state };
        setShow((prev) => ({ ...prev, loading: true }));
        const response = ptiApi.send_confirmation_to_customer(_data.veh_order_no);
        response.then((res) => {
            if (res != null) {
                if (res.code == 200) {
                    setAlertData((prev) => ({
                        ...prev,
                        title: "Thông tin",
                        content: "Gửi mail thành công",
                        is_show: true,
                        variant: "success"
                    }));
                }
            }
            const timer = setTimeout(() => {
                setShow((prev) => ({ ...prev, loading: false }));
            }, 2000);
        }).catch((error) => {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Lỗi gửi email tới khách hàng!",
                is_show: true,
                variant: "danger"
            }));
        })
    }

    useEffect(() => {
        if ([2, 3, 4, 5].includes(curr_step.code)) {
            window.scrollTo(0, 0);
        }
    }, [curr_step.code]);

    /*useEffect(() => {
        let _flag = false;
        survey_questions_upload.forEach((question, index) => {
            // Nếu mã câu hỏi khác câu số 3 là multi checkbox thì nếu không trả lời thì khóa upload.
            if (question.code != "PTI_QUES_3" && CommonHelper.is_empty(question.answer_yn)) {
                _flag = true;
            }
            // Nếu mã câu hỏi khác câu số 3 là multi checkbox thì nếu trả lời là Có - Y mà không điền thông tin thì khóa.
            if (question.code != "PTI_QUES_3" && question.answer_yn === 'Y' && CommonHelper.is_empty(question.note)) {
                _flag = true;
            }
            // Nếu mã câu hỏi == câu số 3 là multi checkbox thì nếu số câu check được lưu vào list mà bằng 0 thì khóa.
            if (question.code == "PTI_QUES_3" && question.list.length == 0) {
                _flag = true;
            }
        });
        setDisabledUpload(_flag);
    }, [survey_questions_upload])*/

    const handleAnswerChange = (index, value) => {
        setSurveyQuestionsUpload((prev) => {
            const updatedQuestions = [...prev];
            updatedQuestions[index].answer_yn = value;
            if (value === 'N') {
                updatedQuestions[index].note = '';
            }
            return updatedQuestions;
        });

    };
    const handleNoteChange = (index, value) => {
        setSurveyQuestionsUpload((prev) => {
            const updatedQuestions = [...prev];
            updatedQuestions[index].note = value;
            return updatedQuestions;
        });

    };
    const handlePurposeChange = (index, purpose, isChecked) => {
        setSurveyQuestionsUpload((prev) => {
            const updatedQuestions = [...prev];
            const selectedQuestion = updatedQuestions[index];

            // Ensure 'list' is an array
            if (!Array.isArray(selectedQuestion.list)) {
                selectedQuestion.list = [];
            }

            if (isChecked) {
                // Add the entire purpose object to the list if checked
                selectedQuestion.list = [
                    ...selectedQuestion.list,
                    { code: purpose.code, name: purpose.name, name_en: purpose.name_en }
                ];
            } else {
                // Remove the purpose object from the list if unchecked
                selectedQuestion.list = selectedQuestion.list.filter(
                    item => item.code !== purpose.code
                );
            }

            return updatedQuestions;
        });
    };

    return (
        <>
            <>
                <div style={{ marginBottom: '32px' }}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{ fontWeight: '700' }}>
                            <li className="breadcrumb-item">
                                <a href="/" style={{ textDecoration: 'none' }}>Trang chủ</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">BẢO HIỂM DU LỊCH</li>
                        </ol>
                    </nav>
                </div>
            </>
            <>
                <section className="container" style={{ maxWidth: '1780px' }}>
                    <PtiProgressBar
                        step={curr_step.code}
                        user={user}
                        paymentStatus={paymentStatus}
                        paymentSuccess={paymentSuccess}
                        data={state}
                    />
                    {curr_step.code === 1 && (
                        <>
                            <PtiOption
                                onClick={next_step}
                                onSelect={select_location}
                                list={locations}
                                location_selected={state.location}
                                ins_location={state.ins_location}
                                ins_pax_type={state.ins_pax_type}
                                ins_pkg_type={state.ins_pkg_type}
                                handle_location_check={handle_location_check}
                                handle_pax_type_check={handle_pax_type_check}
                                handle_pkg_type_check={handle_pkg_type_check}
                            />
                        </>
                    )}
                    {curr_step.code === 2 && (<>
                        <PtiTrip
                            state={state}
                            fm_dt={fm_dt}
                            to_dt={to_dt}
                            lst_prov={lst_prov}
                            schedule={schedule}
                            stateError={stateError}
                            stateErrorMsg={stateErrorMsg}
                            change_fm_dt={change_fm_dt}
                            change_to_dt={change_to_dt}
                            change_schedule={change_schedule}
                            num_of_days={num_of_days}
                            location_selected={state.location}
                            ins_location={state.ins_location}
                            ins_pax_type={state.ins_pax_type}
                            ins_pkg_type={state.ins_pkg_type}
                            handle_location_check={handle_location_check}
                            handle_pax_type_check={handle_pax_type_check}
                            handle_pkg_type_check={handle_pkg_type_check}
                            regions={regions}
                        />
                        <div className='text-center mt-3'>
                            <button
                                className="btn btn-warning"
                                onClick={back_step}
                                style={{ borderRadius: '0px' }}
                            >
                                <FaIcons.FaArrowAltCircleLeft /> {" QUAY LẠI"}
                            </button>
                            <button
                                className="btn ms-1"
                                style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white', borderRadius: '0px' }}
                                onClick={next_step}
                            >
                                {"TIẾP TỤC "} <FaIcons.FaArrowAltCircleRight />
                            </button>
                        </div>
                    </>)}
                    {curr_step.code === 3 && (
                        <>
                            <Packages
                                onSelect={select_pkg}
                                list={ptiPackages}
                                setPtiPackages={setPtiPackages}
                                pkg_selected={state.pti_package}
                                location={state.location}
                                location_selected={state.location}
                                num_of_days={num_of_days}
                                ins_location={state.ins_location}
                                ins_pax_type={state.ins_pax_type}
                                ins_pkg_type={state.ins_pkg_type}
                                handle_location_check={handle_location_check}
                                handle_pax_type_check={handle_pax_type_check}
                                handle_pkg_type_check={handle_pkg_type_check}
                                schedule={state.veh_schedule}
                            />
                            <div className='text-center mt-3'>
                                <button
                                    className="btn btn-warning"
                                    onClick={back_step}
                                    style={{ borderRadius: '0px' }}
                                >
                                    <FaIcons.FaArrowAltCircleLeft /> {" QUAY LẠI"}
                                </button>
                                <button
                                    className="btn ms-1"
                                    style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white', borderRadius: '0px' }}
                                    onClick={next_step}
                                >
                                    {"TIẾP TỤC "} <FaIcons.FaArrowAltCircleRight />
                                </button>
                            </div>
                        </>
                    )}
                    {curr_step.code === 4 && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='panel-form bg-white' style={{ border: '1px solid black', boxShadow: '10px 10px rgb(131, 219, 193)' }}>
                                    <div className='text-center mt-3 mb-4' style={{ color: '#20c997', marginBottom: '16px' }}>
                                        <h2 className='text-center mt-4' style={{ color: '#20c997' }}>YÊU CẦU BẢO HIỂM DU LỊCH</h2>
                                    </div>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <p>
                                                    <b>
                                                        <label onClick={() => toggleCollapse('item2')} className="form-label fs-5 font-semibold" style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>
                                                            BÊN MUA BẢO HIỂM
                                                            {collapse_field.item2 ? (
                                                                <FaIcons.FaArrowAltCircleDown style={{ marginLeft: '510px', cursor: 'pointer', color: '#ffc107' }} />
                                                            ) : (
                                                                <FaIcons.FaArrowAltCircleUp style={{ marginLeft: '510px', cursor: 'pointer', color: '#ffc107' }} />
                                                            )}
                                                        </label>
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            !collapse_field.item2 && (<>
                                                <div className='row'>
                                                    <div className='col-sm-3'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="veh_business" id={`radio-1`}
                                                                checked={state.veh_business !== "Y"} value="N"
                                                                onClick={handle_change} />
                                                            <label className="form-check-label" htmlFor={`radio-1`}>
                                                                Cá nhân
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-9'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="veh_business" id={`radio-2`}
                                                                checked={state.veh_business === "Y"} value="Y"
                                                                onClick={handle_change} />
                                                            <label className="form-check-label" htmlFor={`radio-2`}>
                                                                Tổ chức
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <label className="form-label required">
                                                                {state.veh_business === "Y" ? "Bên mua bảo hiểm" : "Họ tên"}
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <TextInput value={state.veh_customer} name="veh_customer" onChange={handle_change}
                                                                    className={stateError.veh_customer ? "error" : ""} />
                                                                <ValidateMessage isShow={stateError.veh_customer} msgType={stateErrorMsg.veh_customer} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {state.veh_business === "N" && (
                                                    <>
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className="form-label required">
                                                                        Ngày sinh
                                                                    </label>
                                                                    <div className='col-sm-12'>
                                                                        <ShortDateInput
                                                                            selected={dob}
                                                                            minDate={minDOB}
                                                                            maxDate={maxDOB}
                                                                            onChange={change_dob}
                                                                            className={stateError.dob ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.dob} msgType={stateErrorMsg.dob} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className="form-label required">
                                                                        Số CMND/ CCCD/ Hộ chiếu
                                                                    </label>
                                                                    <div className='col-sm-12'>
                                                                        <TextInput value={state.veh_customer_idcard}
                                                                            name="veh_customer_idcard" onChange={handle_change}
                                                                            onBlur={validate_idcard}
                                                                            className={stateError.veh_customer_idcard ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_customer_idcard} msgType={stateErrorMsg.veh_customer_idcard} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className='form-label required'>Quốc tịch</label>
                                                                    <div className='col-sm-12'>
                                                                        <AutoComplete
                                                                            id="veh_nation"
                                                                            options={lst_nation}
                                                                            onChange={change_nation}
                                                                            selected={nationalSel}
                                                                            placeholder={"Quốc tịch"}
                                                                            className={stateError.veh_nation ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_nation} msgType={stateErrorMsg.veh_nation} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className="form-label required">
                                                                        Giới tính
                                                                    </label>
                                                                    <div className='col-sm-12'>
                                                                        <div className='d-flex'>
                                                                            <div className='mr15px'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="veh_gender" id={`radio-gender-1`} defaultValue={"1"}
                                                                                        checked={state.veh_gender == 1} value="1" onChange={handle_radio_check} />
                                                                                    <label className="form-check-label" htmlFor={`radio-gender-1`}>
                                                                                        Nam
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='mr15px'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="veh_gender" id={`radio-gender-2`}
                                                                                        checked={state.veh_gender == 2} value="2" onChange={handle_radio_check} />
                                                                                    <label className="form-check-label" htmlFor={`radio-gender-2`}>
                                                                                        Nữ
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className='form-label required'>Nghề nghiệp</label>
                                                                    <div className='col-sm-12'>
                                                                        <AutoComplete
                                                                            id="veh_occupation"
                                                                            options={lst_occupation}
                                                                            onChange={change_occupation}
                                                                            selected={occupationSel}
                                                                            placeholder={"Nghề nghiệp"}
                                                                            disabled={(["indoor", "outdoor"].indexOf(state.veh_job_type) >= 0)}
                                                                            className={stateError.veh_occupation ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_occupation} msgType={stateErrorMsg.veh_occupation} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className='form-label'>Nghề nghiệp khác</label>
                                                                    <div className='col-sm-12'>
                                                                        <TextInput
                                                                            name="veh_job_desc"
                                                                            value={state.veh_job_desc}
                                                                            disabled={lockField.veh_job_desc}
                                                                            onChange={handle_change}
                                                                            className={stateError.veh_job_desc ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_job_desc} msgType={stateErrorMsg.veh_job_desc} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {state.veh_business === "Y" && (
                                                    <>
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className="form-label required">
                                                                        Ngành nghề
                                                                    </label>
                                                                    <div className='col-sm-12'>
                                                                        <AutoComplete
                                                                            id="veh_occupation"
                                                                            options={lst_occupation}
                                                                            onChange={change_occupation}
                                                                            selected={occupationSel}
                                                                            placeholder={"Ngành nghề"}
                                                                            className={stateError.veh_occupation ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_occupation} msgType={stateErrorMsg.veh_occupation} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className="form-label required">
                                                                        Mã số thuế
                                                                    </label>
                                                                    <div className='col-sm-12'>
                                                                        <TextInput
                                                                            name="veh_tax_code"
                                                                            value={state.veh_tax_code}
                                                                            onChange={handle_change}
                                                                            className={stateError.veh_tax_code ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_tax_code} msgType={stateErrorMsg.veh_tax_code} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!lockField.veh_job_desc && (
                                                            <div className='row mt-2'>
                                                                <label className='form-label'>Ngành nghề khác</label>
                                                                <div className='col-sm-12'>
                                                                    <TextInput
                                                                        name="veh_job_desc"
                                                                        value={state.veh_job_desc}
                                                                        disabled={lockField.veh_job_desc}
                                                                        onChange={handle_change}
                                                                        className={stateError.veh_job_desc ? "error" : ""} />
                                                                    <ValidateMessage isShow={stateError.veh_job_desc} msgType={stateErrorMsg.veh_job_desc} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className="form-label required">
                                                                        Người đại diện pháp luật
                                                                    </label>
                                                                    <div className='col-sm-12'>
                                                                        <TextInput
                                                                            name="veh_rep_company"
                                                                            value={state.veh_rep_company}
                                                                            onChange={handle_change}
                                                                            className={stateError.veh_rep_company ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_rep_company} msgType={stateErrorMsg.veh_rep_company} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <div className='row'>
                                                                    <label className='form-label required'>Quốc tịch người đại diện</label>
                                                                    <div className='col-sm-12'>
                                                                        <AutoComplete
                                                                            id="veh_nation_rep"
                                                                            options={lst_nation}
                                                                            onChange={change_nation}
                                                                            selected={nationalSel}
                                                                            placeholder={"Quốc tịch"}
                                                                            className={stateError.veh_nation ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_nation} msgType={stateErrorMsg.veh_nation} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className='row mt-2'>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <label className="form-label required">
                                                                Số điện thoại
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <TextInput
                                                                    name="veh_phone"
                                                                    value={CommonHelper.format_phone_number(state.veh_phone)}
                                                                    onChange={handle_change}
                                                                    disabled={lockField.veh_phone}
                                                                    className={stateError.veh_phone ? "error" : ""} />
                                                                <ValidateMessage isShow={stateError.veh_phone} msgType={stateErrorMsg.veh_phone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <label className="form-label required">
                                                                Email
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <TextInput
                                                                    name="veh_email"
                                                                    value={state.veh_email}
                                                                    onChange={handle_change}
                                                                    onBlur={validate_email_format}
                                                                    disabled={lockField.veh_email}
                                                                    className={stateError.veh_email ? "error" : ""} />
                                                                <ValidateMessage isShow={stateError.veh_email} msgType={stateErrorMsg.veh_email} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-sm-12'>
                                                        <div className='row'>
                                                            {
                                                                (state.location && state.location.code === "Other" && state.veh_business !== "Y") ? (<>
                                                                    <label className='form-label required'>Địa chỉ liên hệ</label>
                                                                    <div className='col-sm-12'>
                                                                        <div className='row'>
                                                                            <div className="col-sm-6">
                                                                                <TextInput
                                                                                    name="veh_province_name"
                                                                                    value={state.veh_province_name}
                                                                                    onChange={handle_change}
                                                                                    placeholder={"Quốc gia"}
                                                                                />
                                                                                <ValidateMessage isShow={stateError.veh_province_name} msgType={stateErrorMsg.veh_province_name} />
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <TextInput
                                                                                    name="veh_district_name"
                                                                                    value={state.veh_district_name}
                                                                                    onChange={handle_change}
                                                                                    placeholder={"Tỉnh/Thành phố"}
                                                                                />
                                                                                <ValidateMessage isShow={stateError.veh_district_name} msgType={stateErrorMsg.veh_district_name} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-12 mt10px'>
                                                                        <TextInput
                                                                            name="veh_address"
                                                                            value={state.veh_address}
                                                                            placeholder="Số nhà, Tên đường"
                                                                            onChange={handle_change}
                                                                            disabled={CommonHelper.is_empty(state.veh_province_name)}
                                                                        />
                                                                        <ValidateMessage isShow={stateError.veh_address} msgType={stateErrorMsg.veh_address} />
                                                                    </div>
                                                                </>) : (<>
                                                                    <label className='form-label required'>Địa chỉ liên hệ</label>
                                                                    <div className='col-sm-12'>
                                                                        <div className='row'>
                                                                            <div className="col-sm-6">
                                                                                {/*<label className="form-label">Tỉnh/Thành phố</label> */}
                                                                                <AutoComplete
                                                                                    id="veh_prov"
                                                                                    options={lst_prov}
                                                                                    onChange={change_prov}
                                                                                    selected={provSel}
                                                                                    placeholder={"Tỉnh/Thành phố"}
                                                                                    className={stateError.veh_prov ? "error" : ""} />
                                                                                <ValidateMessage isShow={stateError.veh_prov} msgType={stateErrorMsg.veh_prov} />
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                {/*<label className="form-label">Quận/Huyện</label> */}
                                                                                <AutoComplete
                                                                                    id="veh_district"
                                                                                    options={lst_dist}
                                                                                    onChange={change_dist}
                                                                                    selected={distSel}
                                                                                    placeholder={"Quận/Huyện"}
                                                                                    className={stateError.veh_dist ? "error" : ""} />
                                                                                <ValidateMessage isShow={stateError.veh_dist} msgType={stateErrorMsg.veh_dist} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-12 mt10px'>
                                                                        <TextInput
                                                                            name="veh_address"
                                                                            value={state.veh_address}
                                                                            placeholder="Số nhà, Tên đường"
                                                                            onChange={handle_change}
                                                                            disabled={provSel.length < 1}
                                                                            className={stateError.veh_address ? "error" : ""} />
                                                                        <ValidateMessage isShow={stateError.veh_address} msgType={stateErrorMsg.veh_address} />
                                                                    </div>
                                                                </>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-sm-12'>
                                                        <div className='mt-3'>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    id='veh_issue_invoice'
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="veh_issue_invoice"
                                                                    checked={state.veh_issue_invoice}
                                                                    onChange={handle_check}
                                                                    disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                                />
                                                                <label className="form-check-label" htmlFor='veh_issue_invoice'>THÔNG TIN HÓA ĐƠN</label>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                        <div className={"row " + (state.veh_issue_invoice ? "" : "d-none")}>
                                                            <div className="mt10px col-sm-6">
                                                                <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>Mã số thuế</label>
                                                                <TextInput
                                                                    name="inv_tax"
                                                                    value={state.inv_tax}
                                                                    onChange={handle_change}
                                                                    className={"ptb-16px " + (stateError.inv_tax ? "error" : "")}
                                                                    disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.inv_tax}
                                                                    msgType={stateErrorMsg.inv_tax}
                                                                />
                                                            </div>
                                                            <div className="mt10px col-sm-6">
                                                                <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>Tên công ty</label>
                                                                <TextInput
                                                                    name="inv_company_name"
                                                                    value={state.inv_company_name}
                                                                    onChange={handle_change}
                                                                    className={"ptb-16px " + (stateError.inv_company_name ? "error" : "")}
                                                                    disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.inv_company_name}
                                                                    msgType={stateErrorMsg.inv_company_name}
                                                                />
                                                            </div>
                                                            <div className="mt10px col-sm-12">
                                                                <label className={"form-label" + (state.veh_issue_invoice ? " required" : "")}>Địa chỉ công ty</label>
                                                                <TextInput
                                                                    name="inv_address"
                                                                    value={state.inv_address}
                                                                    onChange={handle_change}
                                                                    className={"ptb-16px " + (stateError.inv_address ? "error" : "")}
                                                                    disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                                />
                                                                <ValidateMessage
                                                                    isShow={stateError.inv_address}
                                                                    msgType={stateErrorMsg.inv_address}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='border-top border-2 border-dark' style={{ margin: "20px 0" }}></div>
                                            </>)
                                        }
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <p>
                                                    <b>
                                                        <label onClick={() => toggleCollapse('item3')} className="form-label fs-5 font-semibold" style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>
                                                            DANH SÁCH NGƯỜI ĐƯỢC BẢO HIỂM
                                                            {collapse_field.item3 ? (
                                                                <FaIcons.FaArrowAltCircleDown style={{ marginLeft: '350px', cursor: 'pointer', color: '#ffc107' }} />
                                                            ) : (
                                                                <FaIcons.FaArrowAltCircleUp style={{ marginLeft: '350px', cursor: 'pointer', color: '#ffc107' }} />
                                                            )}
                                                        </label>
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            !collapse_field.item3 && (<>
                                                <div className='row'>
                                                    <div className='col-sm-6 mt-2'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            onClick={new_person}
                                                            style={{ borderRadius: '0px' }}
                                                            disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                        >
                                                            <FaIcons.FaPlus /> Thêm người được bảo hiểm
                                                        </button>
                                                    </div>
                                                    {/* Disabled */}
                                                    <div className='col-sm-6 mt-2' style={{ textAlign: 'right' }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-success"
                                                            onClick={begin_upload}
                                                            style={{ borderRadius: '0px' }}
                                                            disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                        >
                                                            <FaIcons.FaFileExcel /> Upload DS Người được bảo hiểm
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <PtiInfo
                                                        show={show_personal_info}
                                                        data={state_person}
                                                        url={"#"}
                                                        title={"Quan hệ với bên mua bảo hiểm"}
                                                        onClose={handle_close_personal_info}
                                                        list={state_lst_person}
                                                        masterData={state}
                                                        // list_pkg={pti_packages} 
                                                        list_pkg={ptiPackages}
                                                        // PTI
                                                        num_of_days={num_of_days}
                                                        setSelected={setSelected}
                                                        setStatePerson={setStatePerson}
                                                        schedule={state.veh_schedule}
                                                        ins_pax_type={state.ins_pax_type}
                                                        ins_pkg_type={state.ins_pkg_type}
                                                        pkg_selected={state.pti_package}
                                                        list_survey_question={lst_survey_question}
                                                        pkg_code={state.pti_package.pkg_code}
                                                    />
                                                </div>
                                            </>)
                                        }
                                        <div className='row'>
                                            <InsuredPerson
                                                data={state_lst_person}
                                                num_of_pax={state.veh_pax}
                                                hasAction={true}
                                                onEdit={(model) => { open_edit(model) }}
                                                onDel={(model) => { open_delete(model) }}
                                                num_of_days={num_of_days}
                                                list_pkg={ptiPackages}
                                                disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                ins_pax_type={state.ins_pax_type}
                                                ins_pkg_type={state.ins_pkg_type}
                                                setDataPremium={setState}
                                            />
                                        </div>
                                        {
                                            !isAgentOrHandler && user != null && user.user_type == 2 ? (<>
                                                <div className='border-top border-2 border-dark' style={{ margin: "20px 0" }}></div>
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        <p>
                                                            <b>
                                                                <label onClick={() => toggleCollapse('item4')} className="form-label fs-5 font-semibold" style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>
                                                                    THÔNG TIN NHÂN VIÊN NHẬP
                                                                    {collapse_field.item4 ? (
                                                                        <FaIcons.FaArrowAltCircleDown style={{ marginLeft: '415px', cursor: 'pointer', color: '#ffc107' }} />
                                                                    ) : (
                                                                        <FaIcons.FaArrowAltCircleUp style={{ marginLeft: '415px', cursor: 'pointer', color: '#ffc107' }} />
                                                                    )}
                                                                </label>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    !collapse_field.item4 && (<>
                                                        <div className='row mt-3'>
                                                            <div className='col-sm-12'>
                                                                {user != null && user.user_type == 2 && (
                                                                    <div className="mt-3" >
                                                                        <div className='container'>
                                                                            <div className="row mt-3">
                                                                                <label className="form-label col-sm-3">Kênh khai thác</label>
                                                                                <div className="col-sm-9">
                                                                                    <AutoComplete
                                                                                        id="veh_sob"
                                                                                        options={lst_sob}
                                                                                        onChange={change_sob}
                                                                                        selected={sobSel}
                                                                                        placeholder={"Kênh khai thác"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {user != null && user.user_type == 2 && lst_agency.length > 0 && (
                                                                    <div className='mt-3'>
                                                                        <div className='container'>
                                                                            <div className="row mt-3">
                                                                                <label className="form-label col-sm-3">Code đại lý</label>
                                                                                <div className="col-sm-9">
                                                                                    <AutoComplete
                                                                                        id="veh_agencies"
                                                                                        options={lst_agency}
                                                                                        onChange={(e) => { setSelected((prev) => ({ ...prev, agentSel: e })) }}
                                                                                        selected={agentSel}
                                                                                        placeholder={"Code đại lý"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>)
                                                }
                                            </>) : (<></>)
                                        }
                                        {
                                            ((user.user_type === 1) && state.status === 1) && (<>
                                                <div className='border-top border-2 border-dark d-none' style={{ margin: "20px 0" }}></div>
                                                <div className='row d-none'>
                                                    <div className='col-sm-12'>
                                                        <p>
                                                            <b>
                                                                <label className="form-label fs-5 font-semibold" style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>MÃ GIỚI THIỆU</label>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row mt-1 d-none'>
                                                    <div className='col-sm-6'>
                                                        <label className={"form-label"}>Nhập mã giới thiệu</label>
                                                        <TextInput
                                                            name="ins_promoter_code"
                                                            value={state.ins_promoter_code}
                                                            onChange={handle_change}
                                                            className={"ptb-16px "}
                                                            style={{ marginTop: '8px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </>)
                                        }
                                        <div className='row mt-5'>
                                            <div className='col-sm-12 text-center'>
                                                <button
                                                    className="btn btn-warning"
                                                    onClick={back_step}
                                                    style={{ borderRadius: '0px' }}
                                                    disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                >
                                                    <FaIcons.FaArrowAltCircleLeft /> {" QUAY LẠI GÓI BẢO HIỂM"}
                                                </button>
                                                <button
                                                    className="btn btn-primary ms-1"
                                                    onClick={next_step}
                                                    style={{ borderRadius: '0px', backgroundColor: 'rgb(32, 201, 151)', color: 'white', border: 'none' }}
                                                >
                                                    {"TIẾP TỤC "} <FaIcons.FaArrowAltCircleRight />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {curr_step.code >= 5 && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='mt-2 panel-form bg-white' style={{ width: "900px", border: '1px solid black', boxShadow: '10px 10px rgb(131, 219, 193)' }}>
                                    <div className='mt-3 text-center'>
                                        {
                                            !state.cert_url ? (<>
                                                <h2 className='fs-4' style={{ color: '#20c997' }}>XÁC NHẬN THÔNG TIN</h2>
                                            </>) : (<>
                                                <label className='form-label' style={{ fontSize: "40px", color: "#4caf50" }}>
                                                    <FaIcons.FaCheckCircle />
                                                </label>
                                                <h2 className='fs-4' style={{ color: "#4caf50" }}>ĐÃ THANH TOÁN</h2>
                                            </>)
                                        }
                                    </div>
                                    <div className='container'>
                                        <PtiConfirmation
                                            data={state}
                                            list_person={state_lst_person}
                                            user={user}
                                            onCheck={(e) => { setState((prev) => ({ ...prev, is_confirm: e })) }}
                                            onSendConfirmMail={() => { setSelected((prev) => ({ ...prev, show_payment: true })); }}
                                            onGetStatus={get_order_status}
                                            step_code={curr_step.code}
                                            paymentStatus={paymentStatus}
                                            paymentSuccess={paymentSuccess}
                                            // user_type={state.user_type}
                                            user_type={user && user.user_type ? user.user_type : ""}
                                            state_appraisal={state_appraisal}
                                            setStateAppraisal={setStateAppraisal}
                                            disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                            num_of_days={num_of_days}
                                            schedule={schedule}
                                            ins_pax_type={state.ins_pax_type}
                                            ins_pkg_type={state.ins_pkg_type}
                                            ins_location={state.ins_location}
                                            location_selected={state.location}
                                            list_pkg={ptiPackages}
                                            setDataPremium={setState}
                                            cert_url={state.cert_url}
                                        />
                                        {
                                            !state.cert_url && (<>
                                                <div className='text-center mt30px'>
                                                    {
                                                        (paymentSuccess === false)
                                                        && <button
                                                            className="btn btn-warning"
                                                            onClick={back_step}
                                                        >
                                                            <FaIcons.FaArrowAltCircleLeft /> {" QUAY LẠI"}
                                                        </button>
                                                    }
                                                    {!paymentSuccess
                                                        && (((user.user_type === 1) && state.status === 1)
                                                            || ((user.user_type > 1) && state.status === 7))
                                                        && state.res_post_order !== null
                                                        && state.aml_total_score < 70
                                                        && (
                                                            <button
                                                                className="btn ms-1"
                                                                style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white' }}
                                                                disabled={user?.user_type && user.user_type > 1 ? false : !state.is_confirm}
                                                                onClick={next_step}
                                                            >
                                                                {"THANH TOÁN"}
                                                            </button>
                                                        )}
                                                </div>
                                            </>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </>
            {loading && <Loading />}
            <AlertBox
                data={alertData}
                onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }}
            />
            <ModalConfirmEmail
                onClose={get_pti_proposal}
                show={show_payment}
            />
            <ModalReject
                onClose={(flag) => { setSelected((prev) => ({ ...prev, show_reject: flag })) }}
                isShow={show_reject} msg={msg_reject}
            />
            <PtiPayment
                onClose={(flag) => { setSelected((prev) => ({ ...prev, show_pti_payment: flag })) }}
                isShow={show_pti_payment}
                show_payment_qr_code={show_payment_qr_code}
                show_payment_gw={show_payment_gw}
                show_order_no={show_order_no}
                updateState={setState}
            />
            <PtiModalUpload
                onClose={handle_upload}
                show={show_upload}
                onLoading={(e) => { setShow((prev) => ({ ...prev, loading: e })) }}
                occupations={lst_occupation}
                nationalities={lst_nation}
                model={state}
                //other
                ptiPackages={ptiPackages}
                pkg_selected={state.pti_package}
                survey_questions_upload={survey_questions_upload}
                setSurveyQuestionsUpload={setSurveyQuestionsUpload}
                handleAnswerChange={handleAnswerChange}
                handleNoteChange={handleNoteChange}
                handlePurposeChange={handlePurposeChange}
                disabledUpload={disabledUpload}
                list_survey_question={lst_survey_question}
                new_person={new_person}
            />
        </>
    )
}

export default PtiInsurance;