import React, { useState } from 'react';
import { FaArrowAltCircleRight } from "react-icons/fa";
const Image1 = process.env.REACT_APP_RESOURCES_URL + "img/du-lich-nuoc-ngoai-tai-vietnam.jpg";
const Image2 = process.env.REACT_APP_RESOURCES_URL + "img/du-lich-toan-cau.jpg";
const Image3 = process.env.REACT_APP_RESOURCES_URL + "img/du-lich-vietnam.jpg";

const PtiOption = (props) => {
    const {
        onClick,
        onSelect,
        list,
        location_selected,
        ...other
    } = props;
    const [selectedLocation, setSelectedLocation] = useState(location_selected);

    const handle_select = (item) => {
        setSelectedLocation(item);
        onSelect(item);
    };

    return (
        <>
            <section
                className="container"
                style={{ border: '1px solid black', boxShadow: '10px 10px rgb(131, 219, 193)', marginBottom: '16px', borderRadius: "16px" }}
            >
                <h2 className='text-center mt-4 d-none' style={{ color: '#20c997' }}>CHỌN VÙNG DU LỊCH</h2>
                {/* Hiển thị các lựa chọn khi "Mở Rộng" được bấm */}

                <div className="row mt-4">
                    <div className="non-carousel-insurance-content">
                        {
                            list.map((item, i) => {
                                const isDisabled = item.is_blocked;
                                return (
                                    <div
                                        key={i}
                                        className={`pti-mobile-card package-info package-border pti-header-normal text-center ${(selectedLocation.code == item.code) ? "active" : ""}`}
                                        style={{
                                            marginLeft: '10px',
                                            padding: '16px',
                                            border: selectedLocation !== null && (selectedLocation.code == item.code) ? '4px solid rgb(32, 201, 151)' : 'none',
                                            backgroundColor: isDisabled ? 'lightgray' : 'white',
                                            pointerEvents: isDisabled ? 'none' : 'auto',
                                            opacity: isDisabled ? 0.5 : 1
                                        }}
                                    >
                                        <div className="pti-header"
                                            style={{
                                                background: isDisabled ? 'gray' : item.bg_color,
                                                height: '100px',
                                                verticalAlign: 'middle',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <span
                                                className="title font-weight-bold"
                                                style={{
                                                    fontWeight: '700',
                                                    fontSize: '25px',
                                                    color: isDisabled ? 'darkgray' : 'white',
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                            <br />
                                        </div>
                                        <div className="package-price-container package-price-display" style={{ background: "rgb(131 219 193)" }}>
                                            <div
                                                className="buy-package-btn cursor-pointer"
                                                style={{ background: isDisabled ? 'gray' : item.btn_color }}
                                                onClick={() => { handle_select(item) }}
                                            >
                                                <a style={{ padding: '6px auto', color: isDisabled ? 'darkgray' : 'white' }}>CHỌN</a>
                                            </div>
                                        </div>
                                        <div className="pti-content" style={{ height: '300px', padding: '0px' }}>
                                            <img
                                                src={i === 0 ? Image3 : (i === 1 ? Image2 : Image1)}
                                                alt={item.bg_image} style={{ width: "375px" }}
                                                loading='lazy'
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-sm-12'>
                        <div className='text-center mt-3'>
                            <button
                                className="btn ms-1"
                                style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white', borderRadius: '0px' }}
                                onClick={onClick}
                            >
                                {"TIẾP TỤC "} <FaArrowAltCircleRight />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PtiOption;

