import React, { useState, useEffect } from 'react'
import { FaCheck } from 'react-icons/fa';

function PtiProgressBar(props) {
    const { step, user, paymentStatus, paymentSuccess, ...other } = props;
    const [{ volume, curr_step }, setSelected] = useState({
        volume: 0,
        curr_step: 0,
    });
    useEffect(() => {
        setSelected((prev) => ({
            ...prev,
            curr_step: step,
            volume: ((step - 1) * 100 / 5)
        }))
    }, [step]);

    return (
        <>
            <div className='row mb-5'>
                <div className='col-sm-12'>
                    <div className='position-relative' style={{ width: "70%", margin: "auto" }}>
                        <div className='progress' style={{ height: "5px" }}>
                            <div
                                className='progress-bar'
                                role="progressbar"
                                style={{ width: volume + "%", backgroundColor: '#20c997' }}
                                aria-valuenow={volume}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                            </div>
                        </div>
                        <div className='d-flex position-absolute w-100' style={{ alignItems: "center", top: "-8px" }}>
                            <div className='w-1per3'>
                                <div
                                    className='progress-circle-pti active '
                                    style={{ textAlign: 'center', color: '#20c997' }}
                                >
                                    <FaCheck style={{ paddingBottom: '4px', color: 'white' }} />
                                </div>
                            </div>
                            <div className='w-1per3'>
                                <div
                                    className={'progress-circle-pti ' + (curr_step >= 2 ? "active" : "")}
                                    style={{ textAlign: 'center' }}
                                >
                                    <FaCheck style={{ paddingBottom: '4px', color: 'white' }} />
                                </div>
                            </div>
                            <div className='w-1per3'>
                                <div
                                    className={'progress-circle-pti ' + (curr_step >= 3 ? "active" : "")}
                                    style={{ textAlign: 'center' }}
                                >
                                    <FaCheck style={{ paddingBottom: '4px', color: 'white' }} />
                                </div>
                            </div>
                            <div className='w-1per3'>
                                <div
                                    className={'progress-circle-pti ' + (curr_step >= 4 ? "active" : "")}
                                    style={{ textAlign: 'center' }}
                                >
                                    <FaCheck style={{ paddingBottom: '4px', color: 'white' }} />
                                </div>
                            </div>
                            <div className='w-1per3'>
                                <div
                                    className={'progress-circle-pti ' + (curr_step >= 5 ? "active" : "")}
                                    style={{ textAlign: 'center' }}
                                >
                                    <FaCheck style={{ paddingBottom: '4px', color: 'white' }} />
                                </div>
                                <div
                                    className='progress-circle-pti position-absolute end-0 top-0'
                                    style={{
                                        textAlign: 'center',
                                        backgroundColor: curr_step >= 6 && (paymentSuccess === true || other.data.cert_url) ? '#20c997' : ''
                                    }}
                                >
                                    <FaCheck style={{ paddingBottom: '4px', color: 'white' }} />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-1'>
                            <div className='w-25'>
                                <span className='font-semibold' style={{ marginLeft: "-60px" }}>Chọn vùng du lịch</span>
                            </div>
                            <div className='w-25'>
                                <span className={curr_step >= 1 ? "font-semibold" : ""} style={{ marginLeft: "-25px" }}>Hành trình du lịch</span>
                            </div>
                            <div className='w-25'>
                                <span className={curr_step >= 1 ? "font-semibold" : ""} style={{ marginLeft: "30px" }}>Gói bảo hiểm</span>
                            </div>
                            <div className='w-25'>
                                <span className={curr_step >= 2 ? "font-semibold" : ""} style={{ marginLeft: "45px" }}>Yêu cầu bảo hiểm</span>
                            </div>
                            <div className='w-25 position-relative'>
                                <span className={'position-absolute ' + (curr_step >= 5 ? "font-semibold" : "")} style={{ right: "-40px" }}>Xác nhận thanh toán</span>
                            </div>
                            <div className='w-25 position-relative'>
                                <span
                                    className={'position-absolute ' + (curr_step >= 6 && (paymentSuccess === true || other.data.cert_url) ? "font-semibold" : "")}
                                    style={{ right: "-40px" }}
                                >
                                    {curr_step >= 6 && (paymentSuccess === true || other.data.cert_url) ? "Đã thanh toán" : "Thanh toán"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PtiProgressBar;
