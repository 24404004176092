import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import ErrorPage from "./error-page";
import CtplMoto, { loader as CtplMotoLoader } from "./pages/ctpl/moto";
import CtplVehicle,
{ loader as CtplVehicleLoader } from "./pages/ctpl/vehicle";
import Login, { loader as loginLoader } from "./pages/login";
import vehicleApi from "./api/vehicleApi";
import Authentication from './api/authApi';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Register, { loader as regLoader } from "./pages/register";
import CommonHelper from "./helpers/common";
import Policy, { loader as policyLoader } from "./pages/policy";
import Quotation from "./pages/quotation";
import HistoryList from "./pages/examination/component/history";
import TransactionSuccess, {
    loader as TransactionSuccessLoader
} from "./pages/transaction/success";
import Order from "./pages/order";
import AgentLogin from "./pages/login/agent";
import EmployeeLogin from "./pages/login/employee";
import PaInsurance, { loader as PaInsuranceLoader } from "./pages/pai";
import App from "./App";
import FbHandlerLayout from "./layouts/fb-handler";
import AgentLayout from "./layouts/agent";
import IssuePolicy, {
    loader as IssuePolicyLoader
} from "./pages/pai/issue-policy";
import PaiOrderConfirmation, { loader as PaiOrderConfirmationLoader } from "./pages/pai/order-confirmation";
import PtiInsurance, { loader as PtiInsuranceLoader } from "./pages/pti";
import PtiLandingPage from "./pages/pti/component/pti-landing-page";
import PtiOrderConfirmation, {
    loader as PtiOrderConfirmationLoader
} from "./pages/pti/order-confirmation";
import PetInsurance, { loader as PetInsuranceLoader } from "./pages/pet";
import PetIssuePolicy, {
    loader as PetIssuePolicyLoader
} from "./pages/pet/issue-policy";
import PetOrderConfirmation, {
    loader as PetOrderConfirmationLoader
} from "./pages/pet/order-confirmation";
import PetLandingPage from "./pages/pet/component/pet-landing-page";
import QRCode, { loader as QRCodeLoader } from "./pages/qr-code";
import GetQR from "./pages/qr-code/component/get-qr";
import CustomerReset from "./pages/reset-pass";
import EmployeeReset from "./pages/reset-pass/employee";
import AgentReset from "./pages/reset-pass/agent";

// Agent Examination
import ExaminationManager from "./pages/examination/component/form/add-form";
import QuestionsList from "./pages/examination/component/question";
import AccountExam from "./pages/examination/component/account";
import RegisterExam from "./pages/examination/component/register";
import Exam from "./pages/examination/component/layout";
import VoteExam from "./pages/examination/component/vote";
import CMSLogin from "./pages/login/cms";
import CMSLayout from "./layouts/cms";
import CMSReset from "./pages/reset-pass/cms";
import TopicsList from "./pages/examination/component/topic";
import RegisterAgentExam from "./pages/examination/component/register/register-agent-exam";
import CMSRegisterAgentExam from "./pages/examination/component/register/cms/cms-register-agent-exam";
import AgentRegInfoList from "./pages/examination/component/register/component/agent-reg-info-list";
import VotesList from "./pages/examination/component/vote/component/vote-list";
import Documents from "./pages/docs";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        is_menu: false,
        children: [
            {
                path: "/",
                element: <Home />,
                errorElement: <ErrorPage />,
                index: true,
            },
            {
                path: "/tai-tai-lieu",
                element: <Documents />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/dang-nhap",
                element: <Login />,
                errorElement: <ErrorPage />,
                loader: loginLoader,
                is_menu: false,
            },
            {
                path: "/dang-ky",
                element: <Register />,
                errorElement: <ErrorPage />,
                loader: regLoader,
                is_menu: false,
            },
            {
                path: "/reset-pwd",
                element: <CustomerReset />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/danh-sach-hop-dong",
                element: <Policy />,
                errorElement: <ErrorPage />,
                loader: policyLoader,
                is_menu: false,
            },
            {
                path: "/sale/ctpl-moto",
                element: <CtplMoto />,
                errorElement: <ErrorPage />,
                loader: CtplMotoLoader,
                is_menu: true,
                title: "Bảo hiểm xe máy",
            },
            {
                path: "/sale/ctpl-moto/:id",
                element: <CtplMoto />,
                errorElement: <ErrorPage />,
                loader: CtplMotoLoader,
                is_menu: false,
            },
            {
                path: "/sale/ctpl-vehicle",
                element: <CtplVehicle />,
                errorElement: <ErrorPage />,
                loader: CtplVehicleLoader,
                is_menu: true,
                title: "Bảo hiểm ô tô",
            },
            {
                path: "/sale/ctpl-vehicle/:id",
                element: <CtplVehicle />,
                errorElement: <ErrorPage />,
                loader: CtplVehicleLoader,
                is_menu: false,
            },
            {
                path: "/sale/transaction",
                element: <TransactionSuccess />,
                errorElement: <ErrorPage />,
                loader: TransactionSuccessLoader,
                is_menu: false,
                title: "Bảo hiểm ô tô",
            },
            {
                path: "/sale/transaction/:id",
                element: <TransactionSuccess />,
                errorElement: <ErrorPage />,
                loader: TransactionSuccessLoader,
                is_menu: false,
            },
            {
                path: "/orders",
                element: <Order />,
                errorElement: <ErrorPage />,
                is_menu: false,
                title: "Quản lý đơn hàng",
            },
            {
                path: "/sale/pa-insurance",
                element: <PaInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/sale/pa-insurance/:id",
                element: <PaInsurance />,
                errorElement: <ErrorPage />,
                loader: PaInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/sale/pa-confirmation",
                element: <PaiOrderConfirmation />,
                errorElement: <ErrorPage />,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/sale/pa-confirmation/:class/:id",
                element: <PaiOrderConfirmation />,
                errorElement: <ErrorPage />,
                loader: PaiOrderConfirmationLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/sale/pa-confirmation/:id",
                element: <PaiOrderConfirmation />,
                errorElement: <ErrorPage />,
                loader: PaiOrderConfirmationLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/sale/travel-insurance",
                element: <PtiInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm du lịch",
            },
            {
                path: "/sale/travel-home",
                element: <PtiLandingPage />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm du lịch",
            },
            {
                path: "/sale/travel-insurance/:id",
                element: <PtiInsurance />,
                errorElement: <ErrorPage />,
                loader: PtiInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm du lịch",
            },
            {
                path: "/sale/travel-confirmation/:id",
                element: <PtiOrderConfirmation />,
                errorElement: <ErrorPage />,
                loader: PtiOrderConfirmationLoader,
                is_menu: false,
                title: "Bảo hiểm du lịch",
            },
            {
                path: "/sale/pet-insurance",
                element: <PetInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/sale/pet-insurance/:id",
                element: <PetInsurance />,
                errorElement: <ErrorPage />,
                loader: PetInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/sale/pet-home",
                element: <PetLandingPage />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/sale/pet-confirmation/:id",
                element: <PetOrderConfirmation />,
                errorElement: <ErrorPage />,
                loader: PetOrderConfirmationLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/sale/pet-approved-pending-appraisal/:id",
                element: <PetOrderConfirmation />,
                errorElement: <ErrorPage />,
                loader: PetOrderConfirmationLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
        ]
    },
    {
        path: "/exam",
        element: <Exam />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },
    {
        path: "/vote",
        element: <VoteExam />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },
    /*{
        path: "/register-exam",
        element: <RegisterExam />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },*/
    {
        path: "/dai-ly/dang-nhap",
        element: <AgentLogin />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },
    {
        path: "/nhan-vien/dang-nhap",
        element: <EmployeeLogin />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },
    {
        path: "/dang-ky-thi-dai-ly",
        element: <RegisterAgentExam />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },
    {
        path: "/fubon",
        element: <FbHandlerLayout />,
        children: [
            {
                path: "/fubon/ctpl-moto",
                element: <CtplMoto />,
                errorElement: <ErrorPage />,
                loader: CtplMotoLoader,
                is_menu: true,
                title: "Bảo hiểm xe máy",
                index: true
            },
            {
                path: "/fubon/ctpl-moto/:id",
                element: <CtplMoto />,
                errorElement: <ErrorPage />,
                loader: CtplMotoLoader,
                is_menu: false,
            },
            {
                path: "/fubon/pa-insurance",
                element: <PaInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/fubon/pa-insurance/:id",
                element: <PaInsurance />,
                errorElement: <ErrorPage />,
                loader: PaInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/fubon/pa-insurance/issue-policy/:id",
                element: <IssuePolicy />,
                errorElement: <ErrorPage />,
                loader: IssuePolicyLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/fubon/ctpl-vehicle",
                element: <CtplVehicle />,
                errorElement: <ErrorPage />,
                loader: CtplVehicleLoader,
                is_menu: true,
                title: "Bảo hiểm ô tô",
            },
            {
                path: "/fubon/ctpl-vehicle/:id",
                element: <CtplVehicle />,
                errorElement: <ErrorPage />,
                loader: CtplVehicleLoader,
                is_menu: false,
            },
            {
                path: "/fubon/bao-gia-xe-co-gioi",
                element: <Quotation />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/fubon/danh-sach-hop-dong",
                element: <Order />,
                errorElement: <ErrorPage />,
                //loader: policyLoader,
                is_menu: false,
            },
            {
                path: "/fubon/transaction/:id",
                element: <TransactionSuccess />,
                errorElement: <ErrorPage />,
                loader: TransactionSuccessLoader,
                is_menu: false,
            },
            {
                path: "/fubon/doi-mat-khau",
                element: <EmployeeReset />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/fubon/pet-insurance",
                element: <PetInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/fubon/pet-insurance/:id",
                element: <PetInsurance />,
                errorElement: <ErrorPage />,
                loader: PetInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/fubon/pet-insurance/issue-policy/:id",
                element: <PetIssuePolicy />,
                errorElement: <ErrorPage />,
                loader: PetIssuePolicyLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/fubon/travel-insurance",
                element: <PtiInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm du lịch",
            },
            {
                path: "/fubon/travel-insurance/:id",
                element: <PtiInsurance />,
                errorElement: <ErrorPage />,
                loader: PtiInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm du lịch",
            },
        ]
    },
    {
        path: "/dai-ly",
        element: <AgentLayout />,
        children: [
            {
                path: "/dai-ly/danh-sach-hop-dong",
                element: <Order />,
                errorElement: <ErrorPage />,
                //loader: policyLoader,
                is_menu: false,
            },
            {
                path: "/dai-ly/ctpl-moto",
                element: <CtplMoto />,
                errorElement: <ErrorPage />,
                loader: CtplMotoLoader,
                is_menu: true,
                title: "Bảo hiểm xe máy",
                index: true
            },
            {
                path: "/dai-ly/ctpl-moto/:id",
                element: <CtplMoto />,
                errorElement: <ErrorPage />,
                loader: CtplMotoLoader,
                is_menu: false,
            },
            {
                path: "/dai-ly/pa-insurance",
                element: <PaInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/dai-ly/pa-insurance/:id",
                element: <PaInsurance />,
                errorElement: <ErrorPage />,
                loader: PaInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/dai-ly/pa-insurance/issue-policy/:id",
                element: <IssuePolicy />,
                errorElement: <ErrorPage />,
                loader: IssuePolicyLoader,
                is_menu: false,
                title: "Bảo hiểm tai nạn",
            },
            {
                path: "/dai-ly/ctpl-vehicle",
                element: <CtplVehicle />,
                errorElement: <ErrorPage />,
                loader: CtplVehicleLoader,
                is_menu: true,
                title: "Bảo hiểm ô tô",
            },
            {
                path: "/dai-ly/ctpl-vehicle/:id",
                element: <CtplVehicle />,
                errorElement: <ErrorPage />,
                loader: CtplVehicleLoader,
                is_menu: false,
            },
            {
                path: "/dai-ly/transaction/:id",
                element: <TransactionSuccess />,
                errorElement: <ErrorPage />,
                loader: TransactionSuccessLoader,
                is_menu: false,
            },
            {
                path: "/dai-ly/doi-mat-khau",
                element: <AgentReset />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/dai-ly/pet-insurance",
                element: <PetInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/dai-ly/pet-insurance/:id",
                element: <PetInsurance />,
                errorElement: <ErrorPage />,
                loader: PetInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/dai-ly/pet-insurance/issue-policy/:id",
                element: <PetIssuePolicy />,
                errorElement: <ErrorPage />,
                loader: PetIssuePolicyLoader,
                is_menu: false,
                title: "Bảo hiểm thú cưng",
            },
            {
                path: "/dai-ly/travel-insurance",
                element: <PtiInsurance />,
                errorElement: <ErrorPage />,
                is_menu: true,
                title: "Bảo hiểm du lịch",
            },
            {
                path: "/dai-ly/travel-insurance/:id",
                element: <PtiInsurance />,
                errorElement: <ErrorPage />,
                loader: PtiInsuranceLoader,
                is_menu: false,
                title: "Bảo hiểm du lịch",
            },
        ]
    },
    {
        path: "/cms/dang-nhap",
        element: <CMSLogin />,
        errorElement: <ErrorPage />,
        is_menu: false,
    },
    {
        path: "/cms",
        element: <CMSLayout />,
        // element: <PrivateRoute element={CMSLayout}/>,
        children: [
            /*{
                path: "/cms/tao-cau-hoi",
                element: <ExaminationManager />,
                // element: <PrivateRoute element={ExaminationManager} />,
                errorElement: <ErrorPage />,
                is_menu: false,
                index: true,
            },
            {
                path: "/cms/danh-sach-cau-hoi",
                element: <QuestionsList />,
                // element: <PrivateRoute element={QuestionsList} />,
                errorElement: <ErrorPage />,
                is_menu: false,
                index: true,
            },
            {
                path: "/cms/chu-de-cau-hoi",
                element: <TopicsList />,
                // element: <PrivateRoute element={QuestionsList} />,
                errorElement: <ErrorPage />,
                is_menu: false,
                index: true,
            },
            {
                path: "/cms/lich-su-thi",
                element: <HistoryList />,
                // element: <PrivateRoute element={HistoryList} />,
                errorElement: <ErrorPage />,
                is_menu: false,
                index: true,
            },
            {
                path: "/cms/tai-khoan-thi",
                element: <AccountExam />,
                // element: <PrivateRoute element={AccountExam} />,
                errorElement: <ErrorPage />,
                index: false,
            },
            {
                path: "/cms/danh-sach-qr",
                element: <QRList />,
                // element: <PrivateRoute element={QRList} />,
                errorElement: <ErrorPage />,
                is_menu: false,
                index: true,
            },
            {
                path: "/cms/qr",
                element: <GetQR />,
                // element: <PrivateRoute element={GetQR} />,
                errorElement: <ErrorPage />,
                index: false,
            },
            {
                path: "/cms/doi-mat-khau",
                element: <CMSReset />,
                // element: <PrivateRoute element={CMSReset} />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/cms/dang-ky-thi-dai-ly",
                element: <CMSRegisterAgentExam />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/cms/danh-sach-dang-ky-thi-chung-chi",
                element: <AgentRegInfoList />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },
            {
                path: "/cms/danh-gia-dao-tao",
                element: <VotesList />,
                errorElement: <ErrorPage />,
                is_menu: false,
            },*/
        ]
    },
    {
        path: "/qr/:hash",
        element: <QRCode />,
        errorElement: <ErrorPage />,
        is_menu: false,
        loader: QRCodeLoader,
    },
    /*{
        path: "/qr",
        element: <GetQR />,
        errorElement: <ErrorPage />,
        index: false,
    },*/
]);

export default routes;