import React, { useState, useEffect } from 'react'
import addYears from "date-fns/addYears";
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import TextInput from '../../../components/text-input';
import ShortDateInput from '../../../components/short-date-input';
import CommonHelper from '../../../helpers/common';
import AlertBox from '../../../components/alert';
import Nationality from '../../../nationality';
import AutoComplete from '../../../components/auto-complete';
import ValidateMessage from '../../../components/validate-msg';
import ptiApi from '../../../api/ptiApi';
import { initialAlert, initial_person_info } from '../util';
import "./index.css";

function PtiInfo(props) {
    const [state, setState] = useState(initial_person_info);
    const { onClose, data: valueProp, show, setStatePerson, ins_pax_type, ins_pkg_type, pkg_selected, pkg_code, ...other } = props;
    const [{ stateError, stateErrorMsg }, setStateError] = useState({
        stateError: {
            fullName: false,
            idCard: false,
            dob: false,
            national: false,
            address: false,
            ptiPackage: false,
            phone: false,
            email: false,
            survey_questions: false,
        },
        stateErrorMsg: {
            fullName: "",
            idCard: "",
            dob: "",
            national: "",
            address: "",
            ptiPackage: "",
            phone: "",
            email: "",
            survey_questions: "",
        }
    });
    const [{ lst_nation, lst_pkg, lst_person,
        lst_question, lst_ins_com, lst_purpose, lst_subclass }, setList] = useState({
            lst_nation: Nationality,
            lst_person: [],
            lst_pkg: [],
            lst_question: [],
            lst_ins_com: [],
            lst_purpose: [],
            lst_subclass: [],
        })
    const [alertData, setAlertData] = useState(initialAlert);
    const [{ dob, maxDOB, minDOB, nationalSel, isBusiness, packageSel, showPackage,
        lst_prov, lst_dist, provSel, distSel, checkFill, arr_pur, arr_subclass, comSel, subclassSel }, setSelected] = useState({
            dob: null,
            minDOB: addYears(new Date(), -70), // 70 years old restriction
            maxDOB: addWeeks(new Date(), -6),  // 6 weeks old restriction
            nationalSel: [],
            isBusiness: false,
            packageSel: [],
            showPackage: show,
            lst_prov: [],
            lst_dist: [],
            provSel: [],
            distSel: [],
            arr_pur: [],
            arr_subclass: [],
            checkFill: false,
            comSel: [],
            subclassSel: [],
        });
    useEffect(() => {
        if (show) {
            if (valueProp.id > 0) {
                get_object(valueProp.id)
            } else {
                var nationalSel = [];
                var lst_ins_com = [];
                var lst_purpose = [];
                var lst_subclass = [];
                var lst_question = other.list_survey_question;
                var packageSel = [];
                var _lst_person = other.list;
                var survey_questions = [];
                var package_code = "";
                var package_detail = "";
                var premium = 0
                if (pkg_selected != null) {
                    package_code = pkg_selected.pkg_code;
                    package_detail = pkg_selected.pkg_detail;
                    premium = pkg_selected.premium;
                }
                if (_lst_person != null && _lst_person.length > 0) {
                    setList((prev) => ({
                        ...prev,
                        lst_person: _lst_person
                    }))
                }
                set_opt_pkg(other.list_pkg);

                if (lst_question.length > 0) {
                    lst_question.forEach((item) => {
                        survey_questions.push({
                            answer_yn: item.template.answer_yn,
                            code: item.code,
                            question: item.question,
                            question_en: item.question_en,
                            list: item.template.list,
                            note: item.template.note,
                        });
                        if (item.code == "PTI_QUES_1") {
                            lst_ins_com = item.list;
                        } else if (item.code == "PTI_QUES_3") {
                            lst_purpose = item.list;
                        } else if (item.code == "PTI_QUES_2") {
                            lst_subclass = item.list;
                        }
                    })
                }
                if (valueProp.objPackage != null && other.list_pkg != null) {
                    var filter_pkg = other.list_pkg.filter(x => x.pkg_code == valueProp.objPackage.pkg_code);
                    if (filter_pkg.length > 0) {
                        packageSel = filter_pkg;
                        packageSel[0]["name"] = packageSel[0].pkg_name;
                    }
                }
                setState((prev) => ({
                    ...prev,
                    "idx": valueProp.idx,
                    "id": 0,
                    "orderNo": valueProp.orderNo,
                    "fullName": valueProp.fullName,
                    "idCard": valueProp.idCard,
                    "dob": valueProp.dob,
                    "gender": valueProp.gender,
                    "phone": valueProp.phone,
                    "email": valueProp.email,
                    "national": valueProp.national,
                    "address": valueProp.address,
                    "isForeigner": valueProp.isForeigner,
                    "action": valueProp.action,
                    "objPackage": valueProp.objPackage,
                    "location": valueProp.location,
                    "num_of_days": other.num_of_days,
                    "pkg_type": valueProp.pkg_type,
                    "pax_type": valueProp.pax_type,
                    premium,
                    package_code,
                    package_detail,
                    survey_questions,
                    "note_PAI": "",
                    "note_FFC": "",
                }))
                setSelected((prev) => ({
                    ...prev,
                    isBusiness: other.masterData.veh_business === "Y",
                    nationalSel,
                    packageSel,
                    dob: null,
                }))
                setStateError((prev) => ({
                    ...prev,
                    stateError: {
                        fullName: false,
                        idCard: false,
                        dob: false,
                        national: false,
                        address: false,
                        ptiPackage: false,
                        phone: false,
                        email: false,
                    },
                    stateErrorMsg: {
                        fullName: "",
                        idCard: "",
                        dob: "",
                        national: "",
                        address: "",
                        ptiPackage: "",
                        phone: "",
                        email: "",
                    }
                }))
                setList((prev) => ({ ...prev, lst_question, lst_ins_com, lst_purpose, lst_subclass }))
            }
        }
    }, [show]);
    useEffect(() => {
        var _lst = [...state.survey_questions];
        var _idx = _lst.findIndex(x => x.code == "PTI_QUES_1");
        if (_idx >= 0) {
            if (comSel.length > 0) {
                _lst[_idx].list = JSON.stringify(comSel);
            } else {
                _lst[_idx].list = ""
            }
        }
        setState((prev) => ({ ...prev, survey_questions: _lst }));
    }, [comSel]);
    useEffect(() => {
        var _lst = [...state.survey_questions];
        var _idx = _lst.findIndex(x => x.code == "PTI_QUES_2");
        var _arr_note = [];
        if (_idx >= 0) {
            if (arr_subclass.indexOf("PAI") >= 0) {
                _arr_note.push({ "code": "PAI", "name": state.note_PAI })
            }
            if (arr_subclass.indexOf("FFC") >= 0) {
                _arr_note.push({ "code": "FFC", "name": state.note_FFC })
            }
            if (_arr_note.length > 0) {
                _lst[_idx].list = JSON.stringify(_arr_note);
            }
        }
        setState((prev) => ({ ...prev, survey_questions: _lst }));
    }, [arr_subclass, state.note_FFC, state.note_PAI]);
    useEffect(() => {
        var _lst = [...state.survey_questions];
        var _idx = _lst.findIndex(x => x.code == "PTI_QUES_3");
        var _arr = [...arr_pur]
        if (_idx >= 0) {
            if (_arr && _arr.length > 0) {
                _lst[_idx].list = JSON.stringify(_arr);
            } else {
                _lst[_idx].list = "";
            }
        }
        setState((prev) => ({ ...prev, survey_questions: _lst }));
    }, [arr_pur]);
    const get_object = (id) => {
        const response = ptiApi.get_insured_object(id);
        response.then((res) => {
            if (res != null && res.code == 200) {
                set_data(res.data)
            }
        })
    }
    const handle_close = () => {
        setSelected((prev) => ({ ...prev, showPackage: false }))
        onClose(false, null);
    }
    const handle_change = (e) => {
        const { name, value } = e.target;
        var _val = value;
        var _state = { ...state };
        setState((prevData) => ({
            ...prevData,
            [name]: _val,
        }));
        validate_control(e);
    }
    const handle_check = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            var nationalSel = [{ code: other.masterData.veh_national_code, name: other.masterData.veh_national_name }];
            var dob = other.masterData.veh_customer_dob;
            setState((prevData) => ({
                ...prevData,
                fullName: other.masterData.veh_customer,
                gender: other.masterData.veh_gender,
                idCard: other.masterData.veh_customer_idcard,
                dob,
                national: JSON.stringify(nationalSel),
            }));
            setSelected((prev) => ({
                ...prev,
                dob: CommonHelper.parseStringToDate(dob, "yyyyMMdd"),
                nationalSel,
                checkFill: true,
            }))
        }
        else {
            setState((prevData) => ({
                ...prevData,
                fullName: "",
                gender: true,
                idCard: "",
                dob: "",
                national: "",
            }));
            setSelected((prev) => ({
                ...prev,
                dob: null,
                nationalSel: [],
                checkFill: false,
            }))
        }

    };
    const validate_control = (e) => {
        const { name, value } = e.target;
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            _arr.push(item);
        })
        if (_arr.indexOf(name) >= 0) {
            _obj[name] = false;
            _objMsg[name] = "";
            if (CommonHelper.is_empty(value)) {
                _obj[name] = true;
                _objMsg[name] = "required";
            }
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
    }
    const validate_idcard = (e) => {
        const { name, value } = e.target;
        var _flag = CommonHelper.check_idcard_valid(value);
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, [name]: !_flag },
            stateErrorMsg: { ...stateErrorMsg, [name]: !_flag ? "invalid" : "" },
        }))
    }
    const change_dob = (val) => {
        setSelected((prev) => ({
            ...prev,
            dob: val,
        }));
        var _dob = "";
        if (val != null) {
            _dob = CommonHelper.formatToYYYYMMDD(val);
        }
        setState((prev) => ({
            ...prev,
            dob: _dob,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, dob: val == null },
            stateErrorMsg: { ...stateErrorMsg, dob: (val == null ? "required" : "") },
        }))
    };
    const change_raw_dob = (e) => {
        var _val = e.target.value;
        if (CommonHelper.is_not_empty(_val)) {
            _val = _val.replace(/[^0-9]/g, "");
            if (_val.length === 8) {
                var _dob = CommonHelper.parseStringToDate(_val, "ddMMyyyy");
                if (_dob >= minDOB && _dob <= maxDOB) {
                    setSelected((prev) => ({
                        ...prev,
                        dob: _dob,
                    }));
                } else { }
            }
        }
    }
    const handle_radio_check = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: name === "gender" ? (parseInt(value)) : value,
        }));
    };
    const change_nation = (val) => {
        if (val.length > 0) {
            setState((prevData) => ({
                ...prevData,
                national: JSON.stringify(val),
            }));
        } else {
            setState((prevData) => ({
                ...prevData,
                national: null,
            }));
        }
        setSelected((prev) => ({
            ...prev,
            nationalSel: val
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, national: val.length === 0 },
            stateErrorMsg: { ...stateErrorMsg, national: (val.length === 0 ? "required" : "") },
        }))
    };
    const validate_form = () => {
        var flag = true;
        var _data = { ...state };
        var _obj = { ...stateError };
        var _objMsg = { ...stateErrorMsg };
        var _arr = [];
        Object.keys(_obj).map((item) => {
            //_arr.push(item);
            if (_obj[item]) {
                flag = false;
            }
        })
        if (CommonHelper.is_empty(_data.fullName)) {
            flag = false;
            _obj.fullName = true;
            _objMsg.fullName = "required";
        }
        if (CommonHelper.is_empty(_data.idCard)) {
            flag = false
            _obj.idCard = true;
            _objMsg.idCard = "required";
        }
        if (nationalSel.length === 0) {
            flag = false
            _obj.national = true;
            _objMsg.national = "required";
        }
        if (dob === null) {
            flag = false
            _obj.dob = true;
            _objMsg.dob = "required";
        }
        _arr = other.list;
        if (_arr.length > 0) {
            let adultCount = _arr.filter(item => CommonHelper.calculate_age(CommonHelper.parseStringToDate(item.dob, "yyyyMMdd")) >= 18).length;
            let minorCount = _arr.length - adultCount; // number of people younger than 18 years old

            for (let i = 0; i < _arr.length; i++) {
                const item = _arr[i];

                if (_data.action === "new") {
                    if (item.fullName === _data.fullName) {
                        flag = false;
                        _obj.fullName = true;
                        _objMsg.fullName = "Tên người được bảo hiểm đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                        break;
                    }
                    if (item.idCard === _data.idCard) {
                        flag = false;
                        _obj.idCard = true;
                        _objMsg.idCard = "Số CMND/CCND/số định danh đã tồn tại trong danh sách. Vui lòng kiểm tra lại!";
                        break;
                    }

                    // Check current person's age
                    const currentAge = CommonHelper.calculate_age(CommonHelper.parseStringToDate(_data.dob, "yyyyMMdd"));
                    if (currentAge >= 18) {
                        if (_arr.length >= 2 && (adultCount + 1) > 2 && ins_pax_type == 2) {
                            // If the list has >= 2 people and 2 people are >= 18 years old
                            alert("Gói gia đình tối đa 2 người lớn, 2 trẻ em!");
                            flag = false;
                            break;
                        }
                    } else {
                        if (_arr.length >= 2 && (minorCount + 1) > 2 && ins_pax_type == 2) {
                            // If the list has >= 2 people and 2 people are >= 18 years old
                            alert("Gói gia đình tối đa 2 người lớn, 2 trẻ em!");
                            flag = false;
                            break;
                        }
                    }
                }
            }
        }
        var _objMsgErr = "";
        _data.survey_questions.forEach((question, index) => {
            if (question.code != "PTI_QUES_3" && CommonHelper.is_empty(question.answer_yn)) {
                flag = false;
                _objMsgErr = "Chưa trả lời khai báo thông tin!";
            } else if (question.code == "PTI_QUES_3" && arr_pur.length == 0) {
                flag = false;
                _objMsgErr = "Chưa điền nội dung khai báo!";
            }
        });
        if (_objMsgErr) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: _objMsgErr,
                is_show: true,
                variant: "danger"
            }));
        }
        if (!flag) {
            setStateError((prev) => ({
                ...prev,
                stateError: _obj,
                stateErrorMsg: _objMsg,
            }))
        }
        return flag
    }
    const handle_save = () => {
        var flag = validate_form();
        if (flag) {
            var _data = { ...state };
            setSelected((prev) => ({ ...prev, showPackage: false }))
            onClose(false, _data);
        }
    }
    const select_pkg = (e) => {
        var objPackage = {};
        setSelected((prev) => ({
            ...prev,
            packageSel: e
        }));
        var _premium = 0;
        if (e.length > 0) {
            _premium = e[0].premium;
            objPackage = {
                pkg_code: e[0].pkg_code,
                pkg_name: e[0].pkg_name,
                premium: e[0].premium,
                taxRatio: e[0].taxRatio,
                taxAmt: e[0].taxAmt,
                smi1: e[0].smi1,
                smi2: e[0].smi2,
                smi3: e[0].smi3,
                smi4: e[0].smi4,
            };
        }
        setState((prev) => ({
            ...prev,
            package_code: objPackage.pkg_code ?? pkg_code,
            insPackage: JSON.stringify(objPackage),
            objPackage,
            premium: _premium,
        }))
        setStateError((prev) => ({
            ...prev,
            stateError: { ...stateError, ptiPackage: e.length == 0 },
            stateErrorMsg: { ...stateErrorMsg, ptiPackage: (e.length == 0 ? "required" : "") },
        }))
    }
    const set_opt_pkg = (lst) => {
        if (lst != null && lst.length > 0) {
            lst.forEach((item) => {
                item["name"] = item.pkg_name;
            })
        }
        setList((prev) => ({
            ...prev,
            lst_pkg: lst,
        }))
    }
    const set_data = (obj) => {
        if (obj != null) {
            var nationalSel = [], comSel = [], arr_subclass = [], arr_pur = [];
            var lst_question = other.list_survey_question;
            var dob = CommonHelper.parseStringToDate(obj.dob, "yyyyMMdd");
            var packageSel = [];
            var lst_person = other.list;
            var survey_questions = [];
            var note_PAI = "", note_FFC = "";
            var package_code = obj.package_code ?? other.masterData.ins_pkg_code;
            if (lst_person != null && lst_person.length > 0) {
                setList((prev) => ({
                    ...prev,
                    lst_person
                }))
            }
            set_opt_pkg(other.list_pkg);
            if (CommonHelper.is_not_empty(obj.national)) {
                nationalSel = JSON.parse(obj.national);
            }

            if (lst_question.length > 0) {
                var lst_ins_com = [];
                var lst_purpose = [];
                var lst_subclass = [];

                lst_question.forEach((item) => {
                    survey_questions.push({
                        answer_yn: item.template.answer_yn,
                        code: item.code,
                        question: item.question,
                        question_en: item.question_en,
                        list: item.template.list,
                        note: item.template.note,
                    });
                    if (item.code == "PTI_QUES_1") {
                        lst_ins_com = item.list;
                    } else if (item.code == "PTI_QUES_3") {
                        lst_purpose = item.list;
                    } else if (item.code == "PTI_QUES_2") {
                        lst_subclass = item.list;
                    }
                })
                setList((prev) => ({ ...prev, lst_ins_com, lst_purpose, lst_subclass, }));
            }

            if (obj.survey_questions != null && obj.survey_questions.length > 0) {
                survey_questions = obj.survey_questions;
                survey_questions.forEach((item, i) => {
                    var obj_item = item;
                    obj_item.list = "";
                    if (item.code == "PTI_QUES_1") {
                        if (item.list != null && item.list.length > 0) {
                            comSel = item.list
                            if (item.list.length > 0) {
                                obj_item.list = JSON.stringify(item.list);
                            }
                        }
                    } else if (item.code == "PTI_QUES_2") {
                        if (item.list != null && item.list.length > 0) {
                            item.list.forEach((x) => {
                                arr_subclass.push(x.code);
                                if (x.code == "PAI") {
                                    note_PAI = x.name;
                                } else if (x.code == "FFC") {
                                    note_FFC = x.name;
                                }
                            })
                            obj_item.list = JSON.stringify(item.list)
                        }
                    } else if (item.code == "PTI_QUES_3") {
                        arr_pur = item.list
                        if (item.list.length > 0) {
                            obj_item.list = JSON.stringify(item.list);
                        }
                    }
                })
            }
            if (valueProp.objPackage != null && other.list_pkg != null) {
                var filter_pkg = other.list_pkg.filter(x => x.pkg_code == valueProp.objPackage.pkg_code);
                if (filter_pkg.length > 0) {
                    packageSel = filter_pkg;
                    packageSel[0]["name"] = packageSel[0].pkg_name;
                }
            }
            setState((prev) => ({
                ...prev,
                //"idx": valueProp.idx,
                "id": obj.id,
                "orderNo": obj.order_no,
                "fullName": obj.object_name,
                "idCard": obj.id_card,
                "dob": obj.dob,
                "gender": obj.gender,
                "phone": obj.phone,
                "email": obj.email,
                "national": obj.national,
                "address": obj.address,
                "isForeigner": obj.isForeigner,
                "premium": obj.premium,
                "action": valueProp.action,
                //"objPackage": obj.package_detail,
                "package_name": obj.package_name,
                "package_detail": obj.package_detail,
                "location": obj.location,
                "num_of_days": obj.num_of_days,
                "pkg_type": obj.pkg_type,
                "pax_type": obj.pax_type,
                package_code,
                survey_questions,
                note_PAI,
                note_FFC,
            }))
            setSelected((prev) => ({
                ...prev,
                isBusiness: other.masterData.veh_business === "Y",
                nationalSel,
                packageSel,
                dob,
                comSel,
                arr_subclass,
                arr_pur,
            }))
            setStateError((prev) => ({
                ...prev,
                stateError: {
                    fullName: false,
                    idCard: false,
                    dob: false,
                    national: false,
                    address: false,
                    ptiPackage: false,
                    phone: false,
                    email: false,
                },
                stateErrorMsg: {
                    fullName: "",
                    idCard: "",
                    dob: "",
                    national: "",
                    address: "",
                    ptiPackage: "",
                    phone: "",
                    email: "",
                }
            }))
            setList((prev) => ({ ...prev, lst_question, }))
        }
    }
    const handleAnswerChange = (obj, e) => {
        const { value, checked } = e.target;
        if (obj != null) {
            var _lst = [...state.survey_questions];
            var _idx = _lst.findIndex(x => x.code == obj.code);
            if (_idx >= 0) {
                _lst[_idx].answer_yn = value;
            }
            setState((prev) => ({ ...prev, survey_questions: _lst }));
        }
    };
    const change_note_ques_1 = (e) => {
        const { value } = e.target;
        var _lst = [...state.survey_questions];
        var _idx = _lst.findIndex(x => x.code == "PTI_QUES_1");
        if (_idx >= 0) {
            _lst[_idx].note = value;
        }
        setState((prev) => ({ ...prev, survey_questions: _lst }));
    }
    const handlePurposeChange = (e) => {
        const { checked, value } = e.target;
        var _arr = [...arr_pur]
        if (checked) {
            _arr.push(value);
        } else {
            var _idx = _arr.indexOf(value);
            _arr.splice(_idx, 1);
        }
        // var _lst = [...state.survey_questions];
        // var _idx = _lst.findIndex(x => x.code == "PTI_QUES_3");
        // console.log("_idx", _idx);
        // if (_idx >= 0) {
        //     if (_arr && _arr.length > 0) {
        //         _lst[_idx].list = JSON.stringify(_arr);
        //         console.log(new Date(), _lst[_idx].list)
        //     } else {
        //         _lst[_idx].list = "";
        //     }
        // }
        setSelected((prev) => ({ ...prev, arr_pur: _arr }));
    };
    const handleSubclassChange = (e) => {
        const { checked, value } = e.target;
        var _arr = [...arr_subclass]
        if (checked) {
            _arr.push(value);
        } else {
            var _idx = _arr.indexOf(value);
            _arr.splice(_idx, 1);
        }
        setSelected((prev) => ({ ...prev, arr_subclass: _arr }));
    }

    return (
        <>
            <div
                style={{
                    display: show ? "block" : "none",
                    borderRadius: 'opx',
                    border: '1px solid #20c997',
                    padding: '16px'
                }}
                className={(show ? " showbs" : "")}
            >
                <div
                    className={(show ? " showbs" : "")}
                >
                    <div className='bottom-sheet-header'>
                        <div className='close-box'>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handle_close}
                            >
                            </button>
                        </div>
                    </div>
                    <div className='bottom-sheet-body'>
                        <div style={{ marginTop: "10px" }}>
                            <div className='container'>
                                <div className='row'>
                                    <div
                                        className='col-sm-12'
                                        style={{ backgroundColor: '#20c997', paddingBottom: '8px', color: 'white', height: '32px', marginBottom: '12px', textAlign: 'center' }}
                                    >
                                        <p>
                                            <b>
                                                <label className="form-label fs-5 font-semibold">NGƯỜI ĐƯỢC BẢO HIỂM</label>
                                            </b>
                                        </p>
                                    </div>
                                </div>
                                {
                                    (other.list && other.list.length === 0) && (<>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <div className='col-sm-12'>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            className={`form-check-input ${checkFill ? "checked-checkbox" : ""}`}
                                                            name='relationship'
                                                            onClick={(e) => handle_check(e)}
                                                            checked={checkFill}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '0px',
                                                                marginRight: '4px',
                                                                border: '2px solid rgb(32, 201, 151)',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        Hành khách là chủ hợp đồng?
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                }
                                <div className='row mt-2'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">Họ Tên</label>
                                            <div className='col-sm-12'>
                                                <TextInput
                                                    value={state.fullName}
                                                    name="fullName"
                                                    onChange={handle_change}
                                                    className={stateError.fullName ? "error" : ""}
                                                />
                                                <ValidateMessage isShow={stateError.fullName} msgType={stateErrorMsg.fullName} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">Số CMND/ CCCD/ Định danh/ Hộ chiếu</label>
                                            <div className='col-sm-12'>
                                                <TextInput
                                                    value={state.idCard}
                                                    name="idCard"
                                                    onBlur={validate_idcard}
                                                    onChange={handle_change}
                                                    className={stateError.idCard ? "error" : ""}
                                                />
                                                <ValidateMessage isShow={stateError.idCard} msgType={stateErrorMsg.idCard} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">Ngày sinh</label>
                                            <div className='col-sm-12'>
                                                <ShortDateInput
                                                    selected={dob}
                                                    minDate={minDOB}
                                                    maxDate={maxDOB}
                                                    onChange={change_dob} onChangeRaw={change_raw_dob}
                                                    className={stateError.dob ? "error" : ""}
                                                />
                                                <ValidateMessage isShow={stateError.dob} msgType={stateErrorMsg.dob} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className="form-label required">Giới tính</label>
                                            <div className='col-sm-12'>
                                                <div className='d-flex'>
                                                    <div className='mr15px'>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="gender"
                                                                id={`radio-per-gender-1`}
                                                                defaultValue={"1"}
                                                                checked={state.gender === 1}
                                                                value="1"
                                                                onChange={handle_radio_check}
                                                            />
                                                            <label className="form-check-label" htmlFor={`radio-per-gender-1`}>Nam</label>
                                                        </div>
                                                    </div>
                                                    <div className='mr15px'>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="gender"
                                                                id={`radio-per-gender-2`}
                                                                checked={state.gender === 2}
                                                                value="2"
                                                                onChange={handle_radio_check}
                                                            />
                                                            <label className="form-check-label" htmlFor={`radio-per-gender-2`}>Nữ</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <label className='form-label required'>Quốc tịch</label>
                                            <div className='col-sm-12'>
                                                <AutoComplete
                                                    id="national"
                                                    options={lst_nation}
                                                    onChange={change_nation}
                                                    selected={nationalSel}
                                                    placeholder={"Quốc tịch"}
                                                    className={stateError.national ? "error" : ""}
                                                />
                                                <ValidateMessage isShow={stateError.national} msgType={stateErrorMsg.national} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    ins_pax_type !== "2" && (<>
                                        <div className='row mt-2'>
                                            <label className='form-label required'>Gói bảo hiểm</label>
                                            <div className='col-sm-6'>
                                                <AutoComplete
                                                    id="veh_pti_pkg"
                                                    renderMenuItemChildren={(option) => (
                                                        <>
                                                            <div >
                                                                <b>{option.name}</b>
                                                            </div>
                                                            <div>
                                                                <small>Phí: {CommonHelper.format_currency_vnd_style(option.premium)}</small>
                                                            </div>
                                                        </>
                                                    )}
                                                    className={stateError.ptiPackage ? "error" : ""}
                                                    options={lst_pkg}
                                                    selected={packageSel}
                                                    onChange={select_pkg}
                                                />
                                                <ValidateMessage isShow={stateError.ptiPackage} msgType={stateErrorMsg.ptiPackage} />
                                            </div>
                                            <div className='col-sm-6'>
                                                <label className='form-label'>
                                                    {packageSel.length > 0 && (
                                                        <>
                                                            {"Phí: " + CommonHelper.format_currency_vnd_style(packageSel[0].premium)}
                                                        </>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </>)
                                }
                                <div className="row mt-4">
                                    <div className='col-sm-12'
                                        style={{
                                            backgroundColor: '#20c997',
                                            paddingBottom: '8px',
                                            color: 'white',
                                            height: '32px',
                                            marginBottom: '12px',
                                            textAlign: 'center'
                                        }}>
                                        <p>
                                            <b>
                                                <label className="form-label fs-5 font-semibold">
                                                    HẠNG MỤC KHAI BÁO
                                                </label>
                                            </b>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <h5 className='bold'>
                                            Hãy trả lời các câu hỏi dưới đây:
                                        </h5>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    {state.survey_questions.length > 0 ? (
                                        state.survey_questions.map((item, index) => (
                                            <div className="card mb-3" key={item.code}>
                                                <div className="card-body">
                                                    <p className="card-text font-weight-bold">
                                                        {index + 1}. {item.question}
                                                    </p>
                                                    {/* Check if the item is PTI_QUES_3 */}
                                                    {item.code == "PTI_QUES_1" && (
                                                        <>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`question_${index}`}
                                                                    id={`question_${index}_yes`}
                                                                    value="Y"
                                                                    checked={item.answer_yn === 'Y'}
                                                                    onClick={(e) => handleAnswerChange(item, e)} />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`question_${index}_yes`} >
                                                                    Có
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`question_${index}`}
                                                                    id={`question_${index}_no`}
                                                                    value="N"
                                                                    checked={item.answer_yn === 'N'}
                                                                    onClick={(e) => handleAnswerChange(item, e)} />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`question_${index}_no`} >
                                                                    Không
                                                                </label>
                                                            </div>
                                                            {item.answer_yn === 'Y' && (
                                                                <div className="mt-3">
                                                                    <div className='row'>
                                                                        <div className='col-sm-7'>
                                                                            <label className='form-label'>Công ty bảo hiểm</label>
                                                                            <AutoComplete
                                                                                id="sel_ins_com"
                                                                                options={lst_ins_com}
                                                                                onChange={(e) => { setSelected((prev) => ({ ...prev, comSel: e })) }}
                                                                                selected={comSel}
                                                                                placeholder={"Công ty bảo hiểm"} />
                                                                            {/* <ValidateMessage isShow={stateError.occupation} msgType={stateErrorMsg.occupation} /> */}
                                                                        </div>
                                                                        <div className='col-sm-5'>
                                                                            <label className="form-label">Số tiền bảo hiểm</label>
                                                                            <TextInput
                                                                                value={item.note}
                                                                                name="fullName"
                                                                                onChange={(e) => { change_note_ques_1(e) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {item.code === 'PTI_QUES_3' && (
                                                        <div className="checkbox-group">
                                                            {lst_purpose.length > 0 && (
                                                                <>
                                                                    {lst_purpose.map((item, i) => (
                                                                        <div className="form-check form-check-inline" key={i}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                name={`purpose_${index}`}
                                                                                id={`purpose_${item.code}`}
                                                                                value={item.code}
                                                                                onClick={(e) => { handlePurposeChange(e) }}
                                                                                checked={arr_pur.indexOf(item.code) >= 0}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`purpose_${item.code}`}
                                                                            >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                    {item.code === 'PTI_QUES_2' && (
                                                        <>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`question_2_${index}`}
                                                                    id={`question_2_${index}_yes`}
                                                                    value="Y"
                                                                    checked={item.answer_yn === 'Y'}
                                                                    onChange={(e) => handleAnswerChange(item, e)} />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`question_2_${index}_yes`} >
                                                                    Có
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`question_2_${index}`}
                                                                    id={`question_2_${index}_no`}
                                                                    value="N"
                                                                    checked={item.answer_yn === 'N'}
                                                                    onChange={(e) => handleAnswerChange(item, e)} />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`question_2_${index}_no`} >
                                                                    Không
                                                                </label>
                                                            </div>
                                                            {lst_subclass.length > 0 && item.answer_yn == "Y" && (
                                                                <>
                                                                    {lst_subclass.map((subItem, i) => (
                                                                        <div className='col-sm-12 mt-2'>
                                                                            <div className='row'>
                                                                                <div className='col-sm-6'>
                                                                                    <div className="checkbox-group">
                                                                                        <div className="form-check form-check-inline" key={i}>
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                name={`subclass_${subItem.code}`}
                                                                                                id={`subclass_${subItem.code}`}
                                                                                                value={subItem.code}
                                                                                                onClick={(e) => { handleSubclassChange(e) }}
                                                                                                checked={arr_subclass.indexOf(subItem.code) >= 0} />
                                                                                            <label className="form-check-label"
                                                                                                htmlFor={`subclass_${subItem.code}`} >
                                                                                                {subItem.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-sm-6'>
                                                                                    <label className="form-label">Số tiền bảo hiểm</label>
                                                                                    {subItem.code == "PAI" && (
                                                                                        <TextInput
                                                                                            value={state.note_PAI}
                                                                                            name={`note_${subItem.code}`}
                                                                                            disabled={arr_subclass.indexOf(subItem.code) < 0}
                                                                                            onChange={handle_change}
                                                                                        />
                                                                                    )}
                                                                                    {subItem.code == "FFC" && (
                                                                                        <TextInput
                                                                                            value={state.note_FFC}
                                                                                            name={`note_${subItem.code}`}
                                                                                            disabled={arr_subclass.indexOf(subItem.code) < 0}
                                                                                            onChange={handle_change}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Không có câu hỏi khảo sát nào</p>
                                    )}
                                </div>
                                <div className='row mt-3'>
                                    <div className='text-center'>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={handle_close}
                                            style={{ borderRadius: '0', fontWeight: '700', marginLeft: '4px', marginRight: '4px' }}
                                        >
                                            {"Hủy"}
                                        </button>
                                        <button
                                            className="btn btn-outline-success ms-1"
                                            onClick={handle_save}
                                            style={{ borderRadius: '0', fontWeight: '700' }}
                                        >
                                            {"Lưu"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </>
    )
}

export default PtiInfo;