import React, { useState, useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'
import { Accordion } from 'react-bootstrap'
import CommonHelper from '../../../helpers/common'
import InsuredPerson from './pti-insured-person'
import { initial } from '../util'
const wording = process.env.REACT_APP_RESOURCES_URL + 'doc/PTI Wording (Vietnamese) Trong nuoc.pdf'
const wording_world = process.env.REACT_APP_RESOURCES_URL + 'doc/final-newPTIoutbound wording (noncovid) 20112024 - VIE.pdf'
const wording_other = process.env.REACT_APP_RESOURCES_URL + 'doc/final-newPTIoutbound wording (noncovid) 20112024 - ENG.pdf'
const wording_summary = process.env.REACT_APP_RESOURCES_URL + 'doc/TAI LIEU TOM TAT - PTI.pdf'
const wording_summary_world = process.env.REACT_APP_RESOURCES_URL + 'doc/TAI LIEU TOM TAT - newPTIoutbound - VIE.pdf'
const wording_summary_other = process.env.REACT_APP_RESOURCES_URL + 'doc/TAI LIEU TOM TAT - newPTIoutbound - ENG.pdf'
const PaymentSuccessImage = process.env.REACT_APP_RESOURCES_URL + "img/insurance.png";

function PtiConfirmation(props) {
    const { onCheck, onSendConfirmMail, onGetStatus, list_person, user, data: valueProp, schedule, paymentSuccess, veh_premium, veh_tax_amt, setDataPremium, setStateMaster, cert_url, location_selected, ...other } = props;
    const [state, setState] = useState(initial);
    const [{ confirm1, confirm2, objUser }, setShow] = useState(
        { showLoading: false, showModal: false, confirm1: false, confirm2: false, objUser: null }
    );
    useEffect(() => {
        if (valueProp != null) {
            setState(valueProp);
        } else {
            setState(initial);
        }
    }, [valueProp])
    useEffect(() => {
        setShow((prev) => ({
            ...prev, objUser: user ?? { user_type: 1 }
        }))
    }, [user])
    useEffect(() => {
        onCheck(confirm1 && confirm2);
    }, [confirm1, confirm2])
    const handle_check = (e) => {
        const { name, checked } = e.target;
        setShow((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };
    const get_order = () => {
        onGetStatus();
    }
    return (
        <>
            <div className='row'>
                {
                    !cert_url && (<>
                        <div className='col-sm-12 mt15px'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>THÔNG TIN BẢO HIỂM</h5>
                                    <div className='mb10px d-flex'>
                                        <div>
                                            <label>Hình thức:</label>
                                        </div>
                                        <div className='px-2' >
                                            <strong>
                                                {state.ins_location == "Vietnam" ? "Du lịch trong nước" : (state.ins_location == "World" ? "Du lịch nước ngoài" : "Người nước ngoài du lịch tại Việt Nam")}
                                            </strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex'>
                                        <div>
                                            <label>Hiệu lực bảo hiểm:</label>
                                        </div>
                                        <div className='px-2' >
                                            <strong>
                                                {CommonHelper.convertToDDMMYYYYHHMM(state.veh_fm_dt) + " - " + CommonHelper.convertToDDMMYYYYHHMM(state.veh_to_dt)}
                                            </strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex'>
                                        <div >
                                            <label>Số người được bảo hiểm:</label>
                                        </div>
                                        <div className='px-2'>
                                            <strong>{state.veh_pax}</strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex'>
                                        <div>
                                            <label>Số ngày được bảo hiểm:</label>
                                        </div>
                                        <div className='px-2'>
                                            <strong>{other.num_of_days}</strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    {
                                        other.ins_location != "Vietnam" && (<>
                                            <div className='mb10px d-flex'>
                                                <div>
                                                    {
                                                        other.ins_pax_type && (<>
                                                            Loại khách hàng: <span className='fw-bolder'>{other.ins_pax_type == "1" ? "Cá nhân" : (other.ins_pax_type == "2" ? "Gia đình" : "Nhóm")}</span>
                                                        </>)
                                                    }
                                                </div>
                                                <div style={{ marginLeft: '12px' }}>
                                                    {
                                                        other.ins_pkg_type && (<>
                                                            Loại hợp đồng: <span className='fw-bolder ml-4'>{other.ins_pkg_type == "TRIP" ? "Chuyến" : "Năm"}</span>
                                                        </>)
                                                    }
                                                </div>
                                                <div className='clearfix'></div>
                                            </div>
                                        </>)
                                    }
                                    <div className='mb10px d-flex d-none'>
                                        <div className='row'>
                                            <label>Hành trình du lịch:</label>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex d-none'>
                                        <div className='row' style={{ marginLeft: '0px' }}>
                                            <p style={{ borderRadius: '8px', padding: '16px', backgroundColor: 'rgb(32, 201, 151)', fontWeight: '700', fontSize: '20px', color: 'white' }}>
                                                {schedule && schedule.length > 0
                                                    ? [...schedule, schedule[0]]
                                                        .filter(item => item && item.name)
                                                        .map((item, idx, arr) => {
                                                            if (idx === 0) {
                                                                return (
                                                                    <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                        {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Xuất phát)</sup>
                                                                    </span>
                                                                );
                                                            }
                                                            if (idx === arr.length - 1) {
                                                                return (
                                                                    <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                        {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Điểm về)</sup>
                                                                    </span>
                                                                );
                                                            }
                                                            return (
                                                                <span key={idx}>
                                                                    {item.name}
                                                                </span>
                                                            );
                                                        })
                                                        .reduce((prev, curr) => [prev, ' -> ', curr])
                                                    : "Chưa có hành trình du lịch"}
                                            </p>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    {
                                        (other.ins_location === "Vietnam") ? (<>
                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Hành trình du lịch: </label>
                                                    <p style={{ borderRadius: '8px', padding: '16px', backgroundColor: 'rgb(32, 201, 151)', fontWeight: '700', fontSize: '20px', color: 'white' }}>
                                                        {schedule && schedule.length > 0
                                                            ? [...schedule, schedule[0]]
                                                                .filter(item => item && item.name)
                                                                .map((item, idx, arr) => {
                                                                    if (idx === 0) {
                                                                        return (
                                                                            <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                                {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Xuất phát)</sup>
                                                                            </span>
                                                                        );
                                                                    }
                                                                    if (idx === arr.length - 1) {
                                                                        return (
                                                                            <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                                {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Điểm về)</sup>
                                                                            </span>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <span key={idx}>
                                                                            {item.name}
                                                                        </span>
                                                                    );
                                                                })
                                                                .reduce((prev, curr) => [prev, ' -> ', curr])
                                                            : "Chưa có hành trình du lịch"}
                                                    </p>
                                                </div>
                                            </div>
                                        </>) : (
                                            ((valueProp && valueProp.veh_schedule !== "")) && (
                                                <>
                                                    <div className='row mt-2'>
                                                        <div className='col-md-12'>
                                                            <label className='form-label'>Hành trình du lịch</label>
                                                            <p style={{ borderRadius: '8px', padding: '16px', backgroundColor: 'rgb(32, 201, 151)', fontWeight: '700', fontSize: '20px', color: 'white' }}>
                                                                {schedule && schedule.length > 0
                                                                    // ? [...schedule, schedule[0]]
                                                                    ? [...schedule]
                                                                        .filter(item => item && item.name)
                                                                        .map((item, idx, arr) => {
                                                                            // if (idx === 0) {
                                                                            //     return (
                                                                            //         <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                            //             {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Xuất phát)</sup>
                                                                            //         </span>
                                                                            //     );
                                                                            // }
                                                                            // if (idx === arr.length - 1) {
                                                                            //     return (
                                                                            //         <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                            //             {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Điểm về)</sup>
                                                                            //         </span>
                                                                            //     );
                                                                            // }
                                                                            return (
                                                                                <span key={idx}>
                                                                                    {item.name}
                                                                                </span>
                                                                            );
                                                                        })
                                                                        .reduce((prev, curr) => [prev, ' , ', curr])
                                                                    : "Chưa có hành trình du lịch"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <div className='card mt-1'>
                                <div className='card-body'>
                                    <h5 style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>BÊN MUA BẢO HIỂM</h5>
                                    <div className='mb10px d-flex'>
                                        <div >
                                            <label>Họ và tên:</label>
                                        </div>
                                        <div className='px-2' >
                                            <strong>{CommonHelper.to_upper(state.veh_customer)}</strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex'>
                                        <div >
                                            <label>Số điện thoại:</label>
                                        </div>
                                        <div className='px-2'>
                                            <strong>{state.veh_phone}</strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex'>
                                        <div >
                                            <label>Email:</label>
                                        </div>
                                        <div className='px-2'>
                                            <strong>{state.veh_email}</strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='mb10px d-flex'>
                                        <div >
                                            <label>Địa chỉ:</label>
                                        </div>
                                        <div className='px-2'>
                                            <strong>{state.veh_address + ", " + state.veh_district_name + ", " + state.veh_province_name}</strong>
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                </div>
                            </div>
                            {objUser?.user_type > 1 && (
                                <div className='card mt-1'>
                                    <div className='card-body'>
                                        <h5 style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>XÁC NHẬN</h5>
                                        <div className='row'>
                                            {(state.status == 1 || state.status == 6) && (
                                                <>
                                                    <div className='col-sm-10'>
                                                        <a className="link-primary cursor-pointer" onClick={() => { onSendConfirmMail() }}>Gửi mail yêu cầu xác  nhận</a>
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        <a className="link-primary cursor-pointer" onClick={get_order}><FaIcons.FaUndo style={{ fontSize: "20px" }} /> </a>
                                                    </div>
                                                </>
                                            )}
                                            {(state.status == 7 || state.status == 2) && (
                                                <div className='col-sm-12'>
                                                    <span><FaIcons.FaCheck style={{ color: "#07a556" }} /> Khách hàng đã xác nhận</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='card mt-1'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <p style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px', fontSize: '1.25rem', color: 'black', fontWeight: '700' }}>
                                                DANH SÁCH NGƯỜI ĐƯỢC BẢO HIỂM
                                            </p>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <InsuredPerson
                                                data={list_person}
                                                num_of_pax={valueProp.veh_pax}
                                                hasAction={false}
                                                onEdit={() => { }} onDel={() => { }}
                                                ins_pax_type={other.ins_pax_type}
                                                ins_pkg_type={other.ins_pkg_type}
                                                list_pkg={other.ptiPackages}
                                                setDataPremium={setDataPremium}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        {state.status == 1 && (
                            <>
                                {paymentSuccess === false && objUser?.user_type == 1 && state.aml_risk_score < 70 && state.aml_total_score < 70 && (
                                    <div className='col-sm-12 mt-2'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='form-check'>
                                                    <input type="checkbox" className='form-check-input' value="" disabled={state.status == 2} checked={confirm1} id="chk-confirm1" name='confirm1' onChange={handle_check} />
                                                    <label className="form-check-label" htmlFor="chk-confirm">
                                                        Tôi cam kết các thông tin trên đều trung thực và chính xác, đồng thời tôi cam kết đã đọc, hiểu và đồng ý với <a href={(other.ins_location == "Vietnam") ? wording_summary : (other.ins_location == "World" ? wording_summary_world : wording_summary_other)} onClick={() => { setShow((prev) => ({ ...prev, showModal: true })) }} style={{ fontWeight: '700', fontSize: '16px' }} target="_blank">Quy định và Quy tắc điều khoản</a> của FUBON cho hợp đồng Bảo hiểm này.
                                                    </label>
                                                    <label className='form-check-label' htmlFor='chk-confirm1'>
                                                        Tôi cam kết: Các thông tin được cung cấp ở trên đều trung thực, chính xác; Tôi đồng ý cho Công ty Fubon sử dụng các thông tin này cho mục đích giao kết,  thực hiện Hợp đồng bảo hiểm và chia sẻ cho các Công ty trong cùng Tập đoàn Tài chính Fubon cho mục đích báo cáo, thống kê và các hoạt động nghiệp vụ.
                                                    </label>
                                                </div>
                                                <div className='form-check'>
                                                    <input type="checkbox" className='form-check-input' value="" disabled={state.status == 2} checked={confirm2} id="chk-confirm2" name='confirm2' onChange={handle_check} />
                                                    <label className='form-check-label' htmlFor='chk-confirm2'>
                                                        Tôi cam kết: Tôi đã đọc, hiểu rõ <a href={((other.ins_location == "Vietnam")) ? wording : (other.ins_location == "World" ? wording_world : wording_other)} style={{ fontWeight: '700', fontSize: '16px' }} target="_blank">các quyền lợi bảo hiểm, điều khoản loại trừ trách nhiệm bảo hiểm, quyền và nghĩa vụ của Bên mua bảo hiểm</a> và đồng ý với các điều kiện, điều khoản bảo hiểm trong <a href={(other.ins_location == "Vietnam") ? wording : (other.ins_location == "World" ? wording_world : wording_other)} style={{ fontWeight: '700', fontSize: '16px' }} target="_blank">Quy tắc bảo hiểm du lịch</a>.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>)
                }
                {(paymentSuccess == true || state.cert_url) && (
                    <div className='col-sm-12 mt-2 text-center'>
                        <hr style={{ marginBottom: '28px' }} />
                        <img src={PaymentSuccessImage} alt="Thanh toán thành công" style={{ margin: '16px', height: '250px', width: '250px' }} />
                        <h4 style={{ marginBottom: '16px' }}>Vui lòng tải giấy chứng nhận bảo hiểm</h4>
                        <a
                            href={state.cert_url} target='_blank'
                            download={"policy-" + state.veh_order_no + ".pdf"}
                            className='btn btn-success'
                            style={{ fontSize: '20px', borderRadius: '0px' }}
                            rel="noreferrer"
                        >
                            Download giấy chứng nhận
                            <FaIcons.FaFileDownload style={{ marginLeft: '4px' }} />
                        </a>
                        <hr style={{ marginTop: '28px' }} />
                        <div className='col-sm-12 text-start'>
                            <p style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>CÔNG TY TNHH BẢO HIỂM FUBON (VIỆT NAM)</p>
                            <p style={{ padding: '0px', margin: '0px' }}>Điện thoại: (84) 28 39435678</p>
                            <p style={{ padding: '0px', margin: '0px' }}>Địa chỉ: Tầng 15, Tòa nhà Ree Tower, Số 9, Đường Đoàn Văn Bơ, Quận 4, TP.HCM</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default PtiConfirmation;