import api from "./api";
import axios from "axios";
import CommonHelper from "../helpers/common";

const ptiApi = {
    get_order: async (id) => {
        var callApi = await api.get(
            "pti/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_sob: async (id) => {
        var callApi = await api.get(
            "online/get_sob?code=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_region: async () => {
        var callApi = await api.get(
            "pti/get_region"
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pti_proposal: async (id) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pti_proposal?order_no=" + id + "&session_id=" + session_id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_pti_policy: async (id) => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pti_policy?order_no=" + id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    get_pti_debitnote: async (id) => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "order/get_pti_debitnote?order_no=" + id,
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    post_pti_order: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            model.session_id = session_id;
            var callApi = await api.post(
                // "pet/post_order", data
                "pti/post_order", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    post_insured_object: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            model.session_id = session_id;
            var callApi = await api.post(
                "pti/post_insured_object", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    issue_pti_policy: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                order_no: model.veh_order_no,
                order_detail: model,
            };
            var callApi = await api.post(
                "order/issue_pet_policy", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    delete_insured_object: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                id: model.id
            };
            var callApi = await api.post(
                "pet/delete_insured_object", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_insured_object: async (id = 0) => {
        var callApi = await api.get(
            "pti/get_insured_object/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_pti_payment: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            let data = {}
            data = {
                "session_id": session_id,
                "order_no": model.order_no,
                "return_url": model.return_url,
                "notify_url": process.env.REACT_APP_API_ENDPOINT,
                "total_amt": model.total_amt,
                "list": model.list,
            }
            var callApi = await api.post(
                "pti/get_payment", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    upload_pti_proposal: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        const formData = new FormData();
        formData.append("pti_pp", model.file);
        formData.append("order_no", model.order_no);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "order/upload_pti_proposal_form", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_pti_payment_check_order: async (id) => {
        var callApi = await api.get(
            "order/get_pti_payment_check_order?order=" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_payment_status: async (id) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.get(
            "pti/get_payment_status/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_aml_online_check: async (model) => {
        if (model != null) {
            /*var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                insured_person: model,
            };*/
            var callApi = await api.get(
                "customer/get_aml_online_check?" + model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    send_notification_check_aml: async (model) => {
        if (model != null) {
            var callApi = await api.post(
                "order/send_notification_check_aml", model
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    get_pti_order_confirmation: async (req) => {
        var callApi = await api.post(
            "order/get_pti_order_confirmation", req
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_excel_template: async () => {
        var token = await CommonHelper.get_token();
        axios({
            url: process.env.REACT_APP_API_ENDPOINT + "pti/get_excel_template",
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`, },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pti_template.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    import_insured_pti: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("pti_import", model.file);
        formData.append("default_pkg", model.pkg_code);
        formData.append("veh_business", model.veh_business);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "order/import_insured_pti", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_pti_appr_pending_appraisal: async (req) => {
        if (CommonHelper.is_not_empty(req)) {
            var callApi = await api.get("pti/pti_appr_pending_appraisal/" + req);
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
        return null;
    },
    send_confirmation_to_customer: async (order_no) => {
        if (order_no != null) {
            const session_id = CommonHelper.get_session_id();
            const url_redirect = window.location.origin;
            const data = {
                session_id,
                url_redirect
            };
            const apiUrl = `pti/send_confirmation_to_customer/${order_no}`;
            try {
                const callApi = await api.put(apiUrl, data);
                if (callApi.code === 401) {
                    console.log(callApi);
                }
                return callApi;
            } catch (error) {
                console.error("Error sending confirmation to customer:", error);
            }
        }
    },    
    get_pti_customer_confirmation: async (req) => {
        if (CommonHelper.is_not_empty(req)) {
            var callApi = await api.get(
                "pti/pti_customer_confirmation/" + req
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
        return null;
    },
    get_pti_premium: async (model) => {
        if (model != null) {
            var data = model;
            var callApi = await api.post(
                "pti/get_pti_premium", data)
            if (callApi.code === 500) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    upload_pti_attachment: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        const formData = new FormData();
        if (model.id != null) {
            formData.append("id", model.id);
        }
        if (model.order_no != null) {
            formData.append("order_no", model.order_no);
        }
        if (model.pti_attachment != null) {
            formData.append("pti_attachment", model.pti_attachment);
        }
        if (session_id != null) {
            formData.append("session_id", session_id);
        }
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "pti/upload_pti_attachment", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response.data;
    },
    delete_insured_attachment: async (model) => {
        if (model != null) {
            var session_id = CommonHelper.get_session_id();
            var data = {
                session_id: session_id,
                id: model
            };
            var callApi = await api.post(
                "pti/delete_insured_attachment", data
            );
            if (callApi.code === 401) {
                console.log(callApi);
            }
            return callApi;
        }
    },
    import_insured_person: async (model) => {
        var token = await CommonHelper.get_token();
        var session_id = CommonHelper.get_session_id();
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("file_import", model.file);
        formData.append("default_pkg", model.pkg_code);
        formData.append("veh_business", model.veh_business);
        formData.append("session_id", session_id);
        var response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "pti/import_insured_person", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        return response;
    },
    get_survey_question: async () => {
        var callApi = await api.get("pti/get_survey_question");
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
};

export default ptiApi;