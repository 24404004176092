import React from 'react'
import vi from 'date-fns/locale/vi';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('vi', vi);

function DateInputCustom(props) {
    // const handleDateChange = (date) => {
    //     if (date) {
    //         // Set default time to 12:00 PM (noon)
    //         date.setHours(12);
    //         date.setMinutes(0);
    //         date.setSeconds(0);
    //     }
    //     props.onChange(date);
    // };

    // return (
    //     <ReactDatePicker
    //         {...props}
    //         shouldCloseOnSelect
    //         isClearable
    //         showPopperArrow={false}
    //         // showTimeSelect
    //         // timeCaption='Giờ'
    //         locale="vi"
    //         dateFormat={props.dateFormat === undefined ? "dd/MM/yyyy HH:mm:00" : props.dateFormat}
    //         showMonthDropdown={true}
    //         dropdownMode="select"
    //         className={'form-control form-control-sm ' + props.className}
    //     // onChange={handleDateChange} 
    //     />
    // )

    return (
        <ReactDatePicker
            {...props}
            shouldCloseOnSelect
            // isClearable
            showPopperArrow={false}
            // showTimeSelect // Enables time selection
            // timeIntervals={props.timeIntervals || 30} // Defines time interval (default is 15 minutes)
            // timeCaption='Giờ'
            locale="vi"
            dateFormat={props.dateFormat === undefined ? "dd/MM/yyyy 00:00" : props.dateFormat} // Removes fixed ":00" after seconds
            showMonthDropdown={true}
            dropdownMode="select"
            className={'form-control form-control-sm ' + props.className}
        />
    )
}

export default DateInputCustom;