import React, { useState, useEffect } from 'react';
import DateInputCustom from './pti-date-input';
import { addDays } from 'date-fns';
import ValidateMessage from '../../../components/validate-msg';
import AutoComplete from '../../../components/auto-complete';
import ptiApi from '../../../api/ptiApi';
import { Typeahead } from 'react-bootstrap-typeahead'

const PtiTrip = (props) => {
    const {
        state,
        fm_dt,
        to_dt,
        lst_prov,
        schedule,
        stateError,
        stateErrorMsg,
        change_fm_dt,
        change_to_dt,
        change_schedule,
        num_of_days,
        location_selected,
        ins_location,
        handle_location_check,
        ins_pax_type,
        handle_pax_type_check,
        ins_pkg_type,
        handle_pkg_type_check,
        regions,
        ...other
    } = props;

    const [state_regions, setStateRegions] = useState([]);

    useEffect(() => {
        get_regions();
    }, [])

    const get_regions = async () => {
        try {
            var res = await ptiApi.get_region();
            if (res.code === 200) {
                setStateRegions(res.data);
            }
        } catch (error) {
            console.error("Error fetching regions data:", error);
        }
    };

    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='panel-form bg-white'>
                        <h2 className='text-center' style={{ color: '#20c997' }}>HÀNH TRÌNH DU LỊCH</h2>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <p>
                                    <b>
                                        <label className="form-label fs-5 font-semibold" style={{ borderLeft: '4px solid #20c997', paddingLeft: '8px' }}>
                                            THÔNG TIN HÀNH TRÌNH
                                        </label>
                                    </b>
                                </p>
                            </div>
                        </div>
                        {
                            (location_selected && (location_selected.code === "Other" || ins_location == "Other")) ? (<>
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <label className='form-label required'>Quốc gia thường trú</label>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className="col-sm-12 mb-2">
                                                    <AutoComplete
                                                        id="veh_schedule"
                                                        options={regions.filter(region => !(region.code === "NATION_142" && region.name === "Vietnam"))}
                                                        onChange={change_schedule}
                                                        selected={schedule}
                                                        placeholder={"Chọn địa điểm"}
                                                        className={(stateError.veh_schedule ? "error" : "")}
                                                        disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                    />
                                                    <ValidateMessage isShow={stateError.veh_schedule} msgType={stateErrorMsg.veh_schedule} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>) : (<>
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <label className='form-label required'>Chọn địa điểm hành trình</label>
                                        <div className='col-sm-12'>
                                            <div className='row'>
                                                <div className="col-sm-12 mb-2">
                                                    <AutoCompleteCustom
                                                        id="veh_schedule"
                                                        options={ins_location === "Vietnam" ? lst_prov : (regions)}
                                                        onChange={change_schedule}
                                                        selected={schedule}
                                                        placeholder={"Chọn địa điểm"}
                                                        className={(stateError.veh_schedule ? "error" : "")}
                                                        disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                                        multiple
                                                    />
                                                    <ValidateMessage isShow={stateError.veh_schedule} msgType={stateErrorMsg.veh_schedule} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    ins_location === "Vietnam" ? (<>
                                        <div className='row mt-2'>
                                            <div className='col-md-12'>
                                                <label className='form-label'>Hành trình du lịch</label>
                                                <p style={{ borderRadius: '8px', padding: '16px', backgroundColor: 'rgb(32, 201, 151)', fontWeight: '700', fontSize: '20px', color: 'white' }}>
                                                    {schedule && schedule.length > 0
                                                        ? [...schedule, schedule[0]]
                                                            .filter(item => item && item.name)
                                                            .map((item, idx, arr) => {
                                                                if (idx === 0) {
                                                                    return (
                                                                        <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                            {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Xuất phát)</sup>
                                                                        </span>
                                                                    );
                                                                }
                                                                if (idx === arr.length - 1) {
                                                                    return (
                                                                        <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                            {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Điểm về)</sup>
                                                                        </span>
                                                                    );
                                                                }
                                                                return (
                                                                    <span key={idx}>
                                                                        {item.name}
                                                                    </span>
                                                                );
                                                            })
                                                            .reduce((prev, curr) => [prev, ' -> ', curr])
                                                        : "Chưa có hành trình du lịch"}
                                                </p>
                                            </div>
                                        </div>
                                    </>) : (<>
                                        <div className='row mt-2'>
                                            <div className='col-md-12'>
                                                <label className='form-label'>Hành trình du lịch</label>
                                                <p style={{ borderRadius: '8px', padding: '16px', backgroundColor: 'rgb(32, 201, 151)', fontWeight: '700', fontSize: '20px', color: 'white' }}>
                                                    {schedule && schedule.length > 0
                                                        // ? [...schedule, schedule[0]]
                                                        ? [...schedule]
                                                            .filter(item => item && item.name)
                                                            .map((item, idx, arr) => {
                                                                // if (idx === 0) {
                                                                //     return (
                                                                //         <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                //             {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Xuất phát)</sup>
                                                                //         </span>
                                                                //     );
                                                                // }
                                                                // if (idx === arr.length - 1) {
                                                                //     return (
                                                                //         <span key={idx} style={{ fontWeight: 'bold' }}>
                                                                //             {item.name} <sup style={{ color: 'yellow', fontSize: '12px' }}>(Điểm về)</sup>
                                                                //         </span>
                                                                //     );
                                                                // }
                                                                return (
                                                                    <span key={idx}>
                                                                        {item.name}
                                                                    </span>
                                                                );
                                                            })
                                                            .reduce((prev, curr) => [prev, ' , ', curr])
                                                        : "Chưa có hành trình du lịch"}
                                                </p>
                                            </div>
                                        </div>
                                    </>)
                                }
                            </>)
                        }
                        {
                            (ins_location !== "Vietnam") && (<>
                                <div className='row mt-2'>
                                    <div className='col-sm-12'>
                                        <div className='row mt-2'>
                                            <label className="form-label">Loại khách hàng</label>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div className='row'>
                                                        <div className='col-sm-4 mb-2'>
                                                            <div
                                                                className="form-check"
                                                                style={{ width: '236px', height: '31px', paddingLeft: '32px', paddingTop: '4px', border: '1px solid #ccc' }}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                    type="radio"
                                                                    name="ins_pax_type"
                                                                    id={`loai-khach-1`}
                                                                    defaultValue={"1"}
                                                                    value="1"
                                                                    checked={ins_pax_type == "1"}
                                                                    onClick={handle_pax_type_check}
                                                                />
                                                                <label className="form-check-label" htmlFor={`loai-khach-1`} style={{ color: 'black' }}>
                                                                    Cá nhân
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-4 mb-2'>
                                                            <div
                                                                className="form-check"
                                                                style={{ width: '236px', height: '31px', paddingLeft: '32px', paddingTop: '4px', border: '1px solid #ccc' }}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                    type="radio"
                                                                    name="ins_pax_type"
                                                                    id={`loai-khach-2`}
                                                                    value="2"
                                                                    checked={ins_pax_type == "2"}
                                                                    onClick={handle_pax_type_check}
                                                                />
                                                                <label className="form-check-label" htmlFor={`loai-khach-2`} style={{ color: 'black' }}>
                                                                    Gia đình
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-4 mb-2 d-none'>
                                                            <div className='mr15px'>
                                                                <div
                                                                    className="form-check"
                                                                    style={{ width: '236px', height: '31px', paddingLeft: '32px', paddingTop: '4px', marginLeft: '8px', border: '1px solid #ccc' }}
                                                                >
                                                                    <input
                                                                        className="form-check-input"
                                                                        style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                        type="radio"
                                                                        name="ins_pax_type"
                                                                        id={`loai-khach-3`}
                                                                        value="3"
                                                                        checked={ins_pax_type == "3"}
                                                                        onClick={handle_pax_type_check}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`loai-khach-3`} style={{ color: 'black' }}>
                                                                        Nhóm
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <label className="form-label required">Loại hợp đồng</label>
                                            <div className='col-sm-12'>
                                                <div className='d-flex'>
                                                    <div className='mr15px'>
                                                        <div
                                                            className="form-check"
                                                            style={{ width: '236px', height: '31px', paddingLeft: '32px', paddingTop: '4px', border: '1px solid #ccc' }}
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                type="radio"
                                                                name="ins_pkg_type"
                                                                id={`hop-dong-1`}
                                                                defaultValue={"TRIP"}
                                                                value="TRIP"
                                                                checked={ins_pkg_type == "TRIP"}
                                                                onClick={handle_pkg_type_check}
                                                            />
                                                            <label className="form-check-label" htmlFor={`hop-dong-1`} style={{ color: 'black' }}>
                                                                Hợp đồng chuyến
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        ins_location !== "Other" && (<>
                                                            <div className='mr15px'>
                                                                <div
                                                                    className="form-check"
                                                                    style={{ width: '236px', height: '31px', paddingLeft: '32px', paddingTop: '4px', marginLeft: '8px', border: '1px solid #ccc' }}
                                                                >
                                                                    <input
                                                                        className="form-check-input"
                                                                        style={{ marginRight: '8px', cursor: 'pointer' }}
                                                                        type="radio"
                                                                        name="ins_pkg_type"
                                                                        id={`hop-dong-2`}
                                                                        value="ANNUAL"
                                                                        checked={ins_pkg_type == "ANNUAL"}
                                                                        onClick={handle_pkg_type_check}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`hop-dong-2`} style={{ color: 'black' }}>
                                                                        Hợp đồng năm
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (ins_pax_type === "2" && ins_pkg_type === "TRIP") && (<>
                                                <div className='row mt-4'>
                                                    <div className='col-sm-12'>
                                                        <p style={{textAlign: 'justify'}}>
                                                            <span style={{fontWeight: '700'}}>* Hợp đồng gia đình chuyến:</span> Tối đa là 2 người lớn và 2 trẻ em. 2 người lớn không cần có quan hệ huyết thống với nhau nhưng Trẻ em phải là con ruột / con nuôi hợp pháp với một trong hai người lớn theo định nghĩa Trẻ em. Tất cả Người được bảo hiểm phải cùng xuất phát và trở về Việt Nam cùng nhau và cùng thời điểm.
                                                        </p>
                                                    </div>
                                                </div>
                                            </>) 
                                        }
                                        {
                                            (ins_pax_type === "2" && ins_pkg_type === "ANNUAL") && (<>
                                                <div className='row mt-4'>
                                                    <div className='col-sm-12'>
                                                        <p style={{textAlign: 'justify'}}>
                                                            <span style={{fontWeight: '700'}}>* Hợp đồng gia đình năm:</span> Tối đa là 2 người lớn và 2 trẻ em. 2 người lớn là vợ / chồng hợp pháp và Trẻ em phải là con ruột / con nuôi hợp pháp với hai người lớn theo định nghĩa Trẻ em. Tất cả Người được bảo hiểm phải cùng xuất phát và trở về Việt Nam cùng nhau và cùng thời điểm.
                                                        </p>
                                                    </div>
                                                </div>
                                            </>)
                                        }
                                        <div className='row mt-2 d-none'>
                                            <label className="form-label">Thông tin</label>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    {(state_regions && state_regions.length > 0) ? (
                                                        <div>
                                                            <div style={{ backgroundColor: 'rgb(32, 201, 151)', color: 'white' }}>
                                                                <div className='row' style={{ padding: '8px', fontWeight: '700' }}>
                                                                    <div className='col-sm-2'>Vùng du lịch</div>
                                                                    <div className='col-sm-8'>Quốc gia</div>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                {state_regions.map((region) => (
                                                                    <div className='row' key={region.code}>
                                                                        <div className='col-sm-2'>{region.name}</div>
                                                                        <div className='col-sm-8'>
                                                                            {
                                                                                region.code !== "PTI_REGION_3" ? (
                                                                                    <>
                                                                                        {region.list_nation.map((nation) => (
                                                                                            <span key={nation.code} style={{
                                                                                                marginRight: '4px',
                                                                                                wordWrap: 'break-word',  // Ensures text wraps
                                                                                                whiteSpace: 'normal'      // Allows text to break
                                                                                            }}>
                                                                                                {nation.name + ";"}
                                                                                            </span>
                                                                                        ))}
                                                                                        <hr />
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span>Bao gồm tất cả các quốc gia, khu vực và vùng lãnh thổ bên ngoài lãnh thổ Việt Nam</span>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p>Loading regions...</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        }
                        <div className='row mt-2'>
                            <div className='col-md-4'>
                                <label className="form-label required">{ins_location === "Vietnam" ? "Ngày đi" : "Ngày bắt đầu"}</label>
                                <div className="col-md-12">
                                    <DateInputCustom
                                        dateFormat={"dd/MM/yyyy HH:mm"}
                                        selected={fm_dt || addDays(new Date(), 1)}
                                        minDate={addDays(new Date(), 1)}
                                        onChange={change_fm_dt}
                                        className={"ptb-16px " + (stateError.veh_fm_dt ? "error" : "")}
                                        disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                    />
                                    <ValidateMessage isShow={stateError.veh_fm_dt} msgType={"required"} />
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <label className="form-label required">{ins_location === "Vietnam" ? "Ngày về" : "Ngày kết thúc"}</label>
                                <div className="col-md-12">
                                    <DateInputCustom
                                        dateFormat={"dd/MM/yyyy HH:mm"}
                                        selected={to_dt || (fm_dt ? addDays(fm_dt, 1) : addDays(new Date(), 2))}
                                        minDate={fm_dt ? addDays(fm_dt, 1) : addDays(new Date(), 2)}
                                        maxDate={fm_dt ? addDays(fm_dt, 180) : addDays(new Date(), 180)}
                                        onChange={change_to_dt}
                                        className={"ptb-16px " + (stateError.veh_to_dt ? "error" : "")}
                                        disabled={state.status !== 7 && state.status !== 6 && state.status !== 3 ? false : true}
                                    />
                                    <ValidateMessage isShow={stateError.veh_to_dt} msgType={"required"} />
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <label className="form-label required">Số ngày được bảo hiểm</label>
                                <div className="col-md-12">
                                    <p style={{ width: '210px', height: '32px', backgroundColor: 'rgb(32, 201, 151)', color: 'white', paddingLeft: '8px', paddingTop: '2px' }}>{num_of_days} ngày</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PtiTrip;

function AutoCompleteCustom(props) {
    return (
      <Typeahead
        {...props}
        // clearButton
        flip={true}
        size='sm'
        labelKey="name" />
    )
  }
  
export { AutoCompleteCustom } 